import { useState } from 'react'
import { TableBox } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_RelatedProducts.scss"

const ProductCardExtended_RelatedProducts = () => {

    const [showDropBox, setShowDropBox] = useState(true)

    return (
        <section className='related-products-inside-content'>
            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                paginator={true}
            />
        </section>
    )
}

export { ProductCardExtended_RelatedProducts }