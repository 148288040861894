import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs } from "@lk-gtcom/ecomlab-components";
import { TableBox } from "@lk-gtcom/ecomlab-components";
import "./OfferAndContract.scss"

const OfferAndContract = () => {

    const tabBtn = [
        {
            label: 'Акты',
            value: 'act'
        },
        {
            label: 'Счета и фактуры',
            value: 'invoice'
        },
        {
            label: 'Договора',
            value: 'contract'
        },
    ]

    const navigate = useNavigate()
    const activeTab = useParams()['*']
    const [pageValue, setPageValue] = useState(tabBtn[0].value)

    useEffect(() => {
        if (activeTab == '') {
            navigate(`act`)
        }
    }, [])

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    return (
        <div className='offer-and-contract'>
            <h3 className='title__main'>Договора и отчетные документы</h3>

            <Tabs tabs={tabBtn} pageValue={pageValue} />

            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                fetchCallback={e => (e)}
                hideExport
            />
        </div>
    )
}

export { OfferAndContract }