import { useEffect, useState } from 'react'
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import done_icon from './img/done_icon.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalNewTaskList.scss'

export const ModalNewTaskList = ({ add, close }) => {
    const [input, setInput] = useState("")

    useEffect(() => {
        const handler = (e) => {
            if (e.target.className.includes('blackout')) close()
        }

        document.addEventListener('click', handler)

        return () => {
            document.removeEventListener('click', handler)
        }
    }, [])

    return (
        <div className='blackout'>
            <div className='modal-new-task-list'>
                <img src={done_icon} />
                <InputDinamycPlaceholder
                    classname='name-input-field'
                    placeholder='Название'
                    variant='outlined'
                    size='small'
                    type='text'
                    onChange={e => setInput(e)}
                />
                <ButtonBasic
                    text='Создать'
                    width='210px'
                    size='56px'
                    onClick={() => {
                        add(input)
                        close()
                    }}
                />
            </div>
        </div>
    )
}