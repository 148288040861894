import { useState } from 'react'
import { TableBox } from "@lk-gtcom/ecomlab-components"
import { CardDynamic } from '../../components/CardDynamic/CardDynamic'
import "./ProductCardExtended_Feedbacks.scss"

const ProductCardExtended_Feedbacks = () => {

    return (
        <section className='feedbacks-inside-content'>
            <div className='card-group'>
                {[...Array(5)]?.map((e, i) =>
                    <CardDynamic
                        title={''}
                        dynamic={''}
                        value={''}
                    />
                )}
            </div>

            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                paginator={true}
            />
        </section>
    )
}

export { ProductCardExtended_Feedbacks }