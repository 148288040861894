import { Page404 } from '../Page404/Page404';
import { NotActive } from '../../components/NotActive/NotActive';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { NicheSearchVer2 } from '../../components/NicheSearchVer2/NicheSearchVer2';
import { AnalysisCategory } from '../../components/AnalysisCategory/AnalysisCategory';
import { useState, useEffect } from 'react'
import { ProductCardExtended } from '../../components/ProductCardExtended/ProductCardExtended';
import { AnalysisPlatformByBrand } from '../../components/AnalysisPlatformByBrand/AnalysisPlatformByBrand';
import { AnalysisPlatformBySellers } from '../../components/AnalysisPlatformBySellers/AnalysisPlatformBySellers';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import './AnalysisPlatform.scss'


const sidebarItems = [
    {
        label: 'Анализ площадок',
        path: '',
        subItems: [
            {
                label: 'Анализ категорий',
                path: 'analysis-category/goods',
            },
            {
                label: 'Поиск ниши',
                path: 'niche-search/summary',
            },
            {
                label: 'Анализ по артикулу',
                path: '/analysis-by-SKU/position',
            },
            {
                label: 'Анализ брендов',
                path: 'analysis-by-brand/goods',
            },
            {
                label: 'Анализ продавцов',
                path: 'analysis-by-sellers/goods',
            }
        ],
    },

]

const AnalysisPlatform = () => {
    const pathMain = useParams()
    const navigate = useNavigate()
    const [sidebarList, setSidebarList] = useState([])
    const [isActive, setIsActive] = useState('')
    const [isActiveItems, setIsActiveItems] = useState('')


    // Подсказки
    const [showTooltipAnalysisCategory, setShowTooltipAnalysisCategory] = useState(false)
    const [showTooltipNicheSearch, setShowTooltipNicheSearch] = useState(false)
    const [showTooltipBrandAnalysis, setShowTooltipBrandAnalysis] = useState(false)
    const [showTooltipAnalysisSellers, setShowTooltipAnalysisSellers] = useState(false)

    useEffect(() => {
        // После перезагрузки страницы, посвечиваем корректные пункты меню

        sidebarItems?.forEach(({ label, subItems, path }) => {
            const title = label
            const comparePaths = path && (pathMain['*'].split('/')[0] == path.split('/')[0])
            if (comparePaths) {
                setIsActive(null)
                setIsActiveItems(label)
            } else {
                subItems.forEach(({ label, path }) => {
                    if (comparePaths) {
                        setIsActive(title + label)
                        setIsActiveItems(null)
                    }
                })
            }
        })

        if (pathMain['*'].split('/')[0] == 'not-active') {
            navigate('analysis-category/product')
            setIsActive('')
            setIsActiveItems('Анализ категорий')
        }
    }, [])

    useEffect(() => {
        setSidebarList([
            ...sidebarItems.map((el, idx, { label }) => <SidebarMenu isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} key={label + '' + idx} {...el} setActive={e => setIsActive(e)} active={isActive} />)
        ])
    }, [isActive, isActiveItems])

    return (
        <div className='settings__content'>
            <div className='flex'>
                <div className='title-and-nav-box'>
                    <div className='sidebar-inside'>
                        <nav className='sidebar-inside__nav'>
                            {sidebarList}
                        </nav>
                    </div>
                </div>
                <div className='settings__main-box'>
                    <Routes>
                        <Route path='analysis-category/*' element={<AnalysisCategory setShowTooltipAnalysisCategory={(e) => setShowTooltipAnalysisCategory(e)} />} />
                        <Route path='niche-search/*' element={<NicheSearchVer2 setShowTooltipNicheSearch={(e) => setShowTooltipNicheSearch(e)} />} />
                        {/* <Route path='analysis-by-article/*' element={<AnalysisByArticle setShowTooltipAnalysisByArticle={(e) => setShowTooltipAnalysisByArticle(e)} />} /> */}
                        <Route path='analysis-by-article/*' element={<ProductCardExtended title='Анализ по артикулу'/>} />
                        <Route path='analysis-by-brand/*' element={<AnalysisPlatformByBrand setShowTooltipBrandAnalysis={(e) => setShowTooltipBrandAnalysis(e)} />} />
                        <Route path='analysis-by-sellers/*' element={<AnalysisPlatformBySellers setShowTooltipAnalysisSellers={(e) => setShowTooltipAnalysisSellers(e)} />} />
                        <Route path='not-active' element={<NotActive />} />
                        <Route path='*' element={< Page404 />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export { AnalysisPlatform }