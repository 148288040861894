import currentHeaders from '../../common/headers';
import { LineChart } from '../../charts/LineChart/LineChart';
import { OlegHttps, OlegHttp } from '../../fetchUrls';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { BtnDropCheckbox } from '../BtnDropCheckbox/BtnDropCheckbox';
import {
  DropDownSelector,
  SliderRange,
  DropContainer,
  Tabs,
  TableBox,
  ButtonBasic,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import './AnalyticsPageComponent.scss';
import { colors } from '@mui/material';
import { useChartData } from '../../common/hooks';
import {
  addFiltersByDataObj,
  addFiltersToUrlQuery,
  getDataObjByFilters,
  getFiltersByUrlQuery,
} from '../../common/utils';
import useGeneralStore from '../../store/general';

const options_period_date = [
  {
    label: 'часам',
    value: 'hour',
  },
  {
    label: 'дням',
    value: 'day',
  },
  {
    label: 'неделям',
    value: 'week',
  },

  {
    label: 'месяцам',
    value: 'month',
  },
];

const options_period = [
  {
    label: 'дням',
    value: 'day',
  },
  {
    label: 'неделям',
    value: 'week',
  },

  {
    label: 'месяцам',
    value: 'month',
  },
];

const summary_list = [
  {
    label: 'Баланс на начало периода',
    color: '#0C2037',
    sum: 0,
    units: '₽',
  },
  {
    label: 'Поступления, итого за период',
    color: '#51CA64',
    sum: 0,
    units: '₽',
  },
  {
    label: 'Списания, итого за период',
    color: '#F88600',
    sum: 0,
    units: '₽',
  },
  {
    label: 'Баланс на конец периода',
    color: '#365EFF',
    sum: 0,
    units: '₽',
  },
];

const AnalyticsPageComponent = ({
  tabs,
  relativeUrl,
  filters,
  rangeBox,
  date,
  selectedApi,
  selectedBrand,
  selectedCategory,
  selectedCampaign,
  selectedWarehouse,
  activeSubItems,
  route,
  setSelectedMp,
  setSelectedApi,
  setSelectedCategory,
  setSelectedBrand,
  setSelectedWarehouse,
  setSelectedOffer,
  setDate,
  setQuery,
  setFbofbs,
  setSelectedCategoryBreadCrumbs,
  setDefaultDate
}) => {
  const [iniitalQuery, setInitialQuery] = useState(true)
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = {...currentHeaders, selectedSpace}
  const [showDropBox, setShowDropBox] = useState(false);

  const [pageValue, setPageValue] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState({
    label: 'дням',
    value: 'day',
  });

  const [sort, setSort] = useState({});
  const [columnSize, setColumnSize] = useState([]);

  // const [chartData, setChartData] = useState([]);
  const [setChartData, chartData, setChartFuncs] = useChartData();
  const { resetChart } = setChartFuncs;

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);

  const paginatorRef = useRef();

  const params = useParams();
  const activeTab = params['*'];

  const [metrics, setMetrics] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const [showTooltip, setShowTooltip] = useState(true);

  const [isReverse, setIsReverse] = useState(false);

  const navigate = useNavigate();

  const [selectedMetric, setSelectedMetric] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableMetrics, setTableMetrics] = useState([]);
  const [arrayTableMetrics, setArrayTableMetrics] = useState(null);

  function transformDataMetrics(data, colors) {
    return data.map((item, index) => {
      const unit = item.label.includes('руб.') ? '₽' : 'шт';
      const label = item.label.replace(/, шт\.|, руб\./, '');
      const value = parseFloat(item.value.replace(/\s/g, ''));
      const color = colors[index % colors.length];
      return {
        label: `${label}:`,
        value: value,
        unit: unit,
        color: color,
      };
    });
  }

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setTableHeaders([]);
    setTableData([]);
  };

  const fetchMetricsList = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analytics/general/graph/metrics`;
    } else {
      url = `${OlegHttp}/api/v1/analytics/general/graph/metrics`;
    }

    fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(`Error: ${error.default || response.statusText}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setMetrics(data);
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
  };

  const fetchMetricsForTable = (params) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analytics/general/summary_data`;
    } else {
      url = `${OlegHttp}/api/v1/analytics/general/summary_data`;
    }

    let filtersParam;

    if (params) {
      [filtersParam] = params;
    }

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let campaign_id_list;
    let warehouse_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }
    if (selectedCampaign?.length > 0) {
      campaign_id_list = selectedCampaign?.map((el) => el.value);
    } else {
      campaign_id_list = ['all'];
    }
    if (selectedWarehouse?.length > 0) {
      warehouse_id_list = selectedWarehouse?.map((el) => el.value);
    }

    let [date_from, date_to] = date;
    date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0];
    date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

    const body = JSON.stringify({
      // filters: filtersParam,
      page: 1,
      limit: 10,
      date_from,
      date_to,
      warehouse_id_list,
      campaign_id_list,
      offer_id_list: ['all'],
      category_id_list,
      brand_id_list,
      api_id_list,
      period: pageValue === 'date' ? selectedPeriod?.value : '',
      reverse: false,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(`Error: ${error.default || response.statusText}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setTableMetrics(data);
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
  };

  const fetchChartData = (
    abortController = new AbortController(),
    clearOnSuccess = false
  ) => {
    if (selectedCategory?.length > 0 && selectedMetric) {
      setChartLoading(true);

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${OlegHttps}/api/v1/analytics/${relativeUrl}/graph/${pageValue}`;
      } else {
        url = `${OlegHttp}/api/v1/analytics/${relativeUrl}/graph/${pageValue}`;
      }

      let api_id_list;
      let brand_id_list;
      let category_id_list;
      let campaign_id_list;
      let warehouse_id_list;

      if (selectedApi?.length > 0) {
        api_id_list = selectedApi?.map((el) => el.value);
      }
      if (selectedBrand?.length > 0) {
        brand_id_list = selectedBrand?.map((el) => el.value);
      }
      if (selectedCategory?.length > 0) {
        category_id_list = selectedCategory?.map((el) => el.value);
      }
      if (selectedCampaign?.length > 0) {
        campaign_id_list = selectedCampaign?.map((el) => el.value);
      } else {
        campaign_id_list = ['All'];
      }
      if (selectedWarehouse?.length > 0) {
        warehouse_id_list = selectedWarehouse?.map((el) => el.value);
      }

      let [date_from, date_to] = date;
      date_from = new Date(date_from + 86401000)
        ?.toISOString()
        ?.split('T')?.[0];
      date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

      const body = JSON.stringify({
        category_id_list,
        brand_id_list,
        api_id_list,
        date_from,
        date_to,
        campaign_id_list,
        warehouse_id_list,
        initial: !(selectedMetrics?.length > 0),
        metrics: selectedMetric ? [metrics[selectedMetric - 1]?.label] : [],
        period: pageValue === 'date' ? selectedPeriod?.value : '',
        graph_filter: checkedId?.length > 0 ? checkedId : ['all'],
        offer_id_list: ['all'],
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          const {
            all_metrics,
            datasets: {
              data,
              label,
              multiAxisData,
              multiAxisKeys,
              multiAxisUnit,
              type,
              order,
            },
            labels,
            title,
          } = json;

          // if (all_metrics) {
          //   setMetrics(all_metrics);
          //   setSelectedMetrics(['']);
          // }

          // setChartData({
          //   datasets_prop: data,
          //   labels_prop: labels,
          //   data_name: label,
          //   multiAxisData_prop: multiAxisData,
          //   multiAxisKeys_prop: multiAxisKeys,
          //   multiAxisUnits: multiAxisUnit,
          //   type,
          //   title,
          //   order,
          // });

          setChartData(json);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setChartLoading(false);
        });
    }
  };

  const fetchTableData = (params) => {
    setCheckedProducts([]);
    if (pageValue) {
      let sort;
      let filtersParam;

      if (params) {
        [sort, filtersParam] = params;
      }
      setTableLoading(true);
      let externalPath =
        pageValue === 'date' ? '/' + selectedPeriod?.value : '';

      let url;
      if (!window.location.hostname.match('localhost')) {
        if (relativeUrl === 'orders') {
          url = `${OlegHttps}/api/v1/analytics/${relativeUrl}/${pageValue}/table${
            sort?.length > 0
              ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
              : ''
          }`;
        }
        if (
          relativeUrl === 'general' ||
          relativeUrl === 'stock' ||
          relativeUrl === 'advertising' ||
          relativeUrl === 'prices'
        ) {
          url = `${OlegHttps}/api/v1/analytics/${relativeUrl}/table/${pageValue}${
            sort?.length > 0
              ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
              : ''
          }`;
        }

        if (relativeUrl === 'returns_cancellations') {
          url = `${OlegHttps}/api/v1/analytics/${relativeUrl}/${pageValue}${
            sort?.length > 0
              ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
              : ''
          }`;
        }

        // url = `${OlegHttps}/api/v1/analytics/${relativeUrl}/table/${pageValue}${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
      } else {
        if (relativeUrl === 'orders') {
          url = `${OlegHttp}/api/v1/analytics/${relativeUrl}/${pageValue}/table${
            sort?.length > 0
              ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
              : ''
          }`;
        }
        if (
          relativeUrl === 'general' ||
          relativeUrl === 'stock' ||
          relativeUrl === 'advertising' ||
          relativeUrl === 'prices'
        ) {
          url = `${OlegHttp}/api/v1/analytics/${relativeUrl}/table/${pageValue}${
            sort?.length > 0
              ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
              : ''
          }`;
        }

        if (relativeUrl === 'returns_cancellations') {
          url = `${OlegHttp}/api/v1/analytics/${relativeUrl}/${pageValue}${
            sort?.length > 0
              ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
              : ''
          }`;
        }

        // url = `${OlegHttp}/api/v1/analytics/${relativeUrl}/table/${pageValue}${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
      }

      let api_id_list;
      let brand_id_list;
      let category_id_list;
      let campaign_id_list;
      let warehouse_id_list;

      if (selectedApi?.length > 0) {
        api_id_list = selectedApi?.map((el) => el.value);
      }
      if (selectedBrand?.length > 0) {
        brand_id_list = selectedBrand?.map((el) => el.value);
      }
      if (selectedCategory?.length > 0) {
        category_id_list = selectedCategory?.map((el) => el.value);
      }
      if (selectedCampaign?.length > 0) {
        campaign_id_list = selectedCampaign?.map((el) => el.value);
      } else {
        campaign_id_list = ['all'];
      }
      if (selectedWarehouse?.length > 0) {
        warehouse_id_list = selectedWarehouse?.map((el) => el.value);
      }

      let [date_from, date_to] = date;
      date_from = new Date(date_from + 86401000)
        ?.toISOString()
        ?.split('T')?.[0];
      date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

      const body = JSON.stringify({
        filters: filtersParam,
        category_id_list,
        brand_id_list,
        api_id_list,
        campaign_id_list,
        offer_id_list: ['all'],
        warehouse_id_list,
        date_from,
        date_to,
        page: paginatorRef.current.page,
        limit: isReverse ? 90 : paginatorRef.current.limit,
        period: pageValue === 'date' ? selectedPeriod?.value : '',
        reverse: pageValue === 'date' ? isReverse : false,
      });

      fetch(url, { body, method: 'POST', headers })
        .then((res) => res.json())
        .then((json) => {
          const { data, labels, total, sort, column_size } = json;
          if (total) paginatorRef.current?.setTotal(total);
          if (data) setTableData(data);
          if (labels) setTableHeaders(labels);
          if (column_size) setColumnSize(column_size);
          if (sort) setSort(sort);
          setTableUrl(url);
          setTableBody(body);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setTableLoading(false));
    }
  };

  const fetchAllData = () => {
    const abortController = new AbortController();
    setChartData([]);
    // fetchChartData(abortController);

    fetchTableData();

    return () => abortController.abort();
  };

  useEffect(() => {
    const colors = ['red', 'blue'];
    const transformedData = transformDataMetrics(tableMetrics, colors);
    setArrayTableMetrics(transformedData);
  }, [tableMetrics]);

  useEffect(() => {
    fetchMetricsForTable();
  }, [
    pageValue,
    selectedPeriod,
    selectedApi,
    selectedBrand,
    selectedCategory,
    selectedWarehouse,
    selectedCampaign,
    date,
  ]);

  useEffect(() => {
    resetChart();
    setSelectedMetric(null);
  }, [pageValue]);

  useEffect(() => {
    fetchMetricsList();
  }, [tableData]);

  useEffect(() => {
    navigate(tabs?.[0]?.value);
  }, []);

  useEffect(() => {
    setPageValue(activeTab);
    setIsReverse(false);
    resetTable();
  }, [activeTab]);

  useEffect(() => {
    if (filters) fetchAllData();
    setIsReverse(false);

    return () => {
      if (paginatorRef.current) resetTable();
    };
  }, [tabs]);

  useEffect(() => {
    fetchAllData();
    resetChart();
  }, [
    pageValue,
    selectedPeriod,
    selectedApi,
    selectedBrand,
    selectedCategory,
    selectedWarehouse,
    selectedCampaign,
    date,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchTableData();
    return () => abortController.abort();
  }, [isReverse]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!(route == 'orders-and-sales') || pageValue == 'status') {
      if (selectedMetric) {
        fetchChartData(abortController);
      }
    }

    return () => abortController.abort();
  }, [
    selectedMetric,
    checkedId,
    selectedPeriod,
    selectedApi,
    selectedBrand,
    selectedCategory,
    selectedWarehouse,
    selectedCampaign,
    date,
  ]);

  useEffect(() => {
    if(iniitalQuery){
      const dataObj = getFiltersByUrlQuery(searchParams);
      addFiltersByDataObj({
        dataObj, setSelectedMp, setSelectedApi, setSelectedCategory, setSelectedBrand,
        setSelectedWarehouse, setSelectedOffer,
        setDate: setDefaultDate,
        setSelectedPeriod, setQuery,
        setFbofbs, setSelectedCategoryBreadCrumbs,
      })
      setInitialQuery(false)
    }
  }, [searchParams]);

  useEffect(() => {    
    if(iniitalQuery) return
    const dataObj = getDataObjByFilters({
      selectedApi,
      selectedBrand,
      selectedCategory,
      selectedPeriod,
      selectedWarehouse,
      date,
    });
    addFiltersToUrlQuery(dataObj, setSearchParams);
  }, [
    selectedApi,
    selectedBrand,
    selectedCategory,
    selectedPeriod,
    selectedWarehouse,
    date,
  ]);

  useEffect(() => {
    if (
      selectedApi.length < 1 ||
      selectedBrand.length < 1 ||
      selectedCategory.length < 1
    ) {
      setShowTooltip(false);
    }
  }, [selectedApi, selectedBrand, selectedCategory]);

  useEffect(() => {
    let list_id;
    if (pageValue == 'brand') {
      list_id = checkedProducts.map(({ brand_id }) => brand_id);
    }
    if (pageValue == 'category') {
      list_id = checkedProducts.map(({ category_id }) => category_id);
    }
    if (pageValue == 'product') {
      list_id = checkedProducts.map(({ offer_id }) => offer_id);
    }

    setCheckedId(list_id);
  }, [checkedProducts]);

  return (
    <>
      <div className="analytics-page">
        {activeSubItems?.label && (
          <div className="title-main-content">
            <h1 className="title">{activeSubItems?.label}</h1>
          </div>
        )}

        <div className="toolbar-top">
          <div className="filter-group">{filters}</div>
        </div>

        <div className="toolbar-medium">
          <Tabs tabs={tabs} pageValue={pageValue} />

          <div className="range-group-btn-and-range-box">
            {pageValue == 'date' && (
              <div className="drop-box">
                <p className="text">По</p>
                <DropDownSelector
                  options_prop={
                    relativeUrl === 'general'
                      ? options_period_date
                      : options_period
                  }
                  state={selectedPeriod}
                  setState={(e) => setSelectedPeriod(e)}
                  defaultValue={selectedPeriod}
                  className="connections_page_selector"
                  isClearable={false}
                />
              </div>
            )}

            {rangeBox}
          </div>
        </div>

        {!(route === 'orders-and-sales' || pageValue === 'status') ? (
          <DropContainer
            showDropBox={showDropBox}
            setShowDropBox={(e) => setShowDropBox(e)}
            name={
              chartData?.title
                ? chartData?.title
                : 'Динамика показателей за выбранный период'
            }
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            metricsList={metrics}
          >
            <section
              className="charts-container"
              style={date ? { flexDirection: 'column', gap: '24px' } : {}}
            >
              <div
                className="charts-group"
                style={{ flexDirection: 'column', gap: '24px', width: '100%' }}
              >
                <LineChart
                  {...chartData}
                  legendPosition="top"
                  loading={chartLoading}
                  legend={metrics}
                  selectedLegend={selectedMetrics}
                  setSelectedLegend={setSelectedMetrics}
                />
              </div>
            </section>
          </DropContainer>
        ) : null}

        {pageValue === 'price' && <SliderRange />}

        <TableBox
          metrics={
            Array.isArray(arrayTableMetrics) && arrayTableMetrics.length > 0
              ? arrayTableMetrics
              : null
          }
          loading={tableLoading}
          fetchedData={tableData}
          headers={tableHeaders}
          sort={sort}
          paginator={!isReverse}
          hideSorting={isReverse}
          fetchCallback={(e) => fetchTableData(e)}
          columnSize={columnSize}
          ref={paginatorRef}
          onCheck={(e) => setCheckedProducts(e)}
          tableUrl={tableUrl}
          tableBody={tableBody}
          onUncheckAllDependence={[pageValue, date]}
          child={
            pageValue === 'date' && (
              <div>
                <p style={{ display: 'flex', gap: '.5em' }}>
                  Перевернуть
                  <Checkbox
                    value={isReverse}
                    onChange={(checked) => setIsReverse(checked)}
                  />
                </p>
              </div>
            )
          }
        />
      </div>
    </>
  );
};

export { AnalyticsPageComponent };
