import { useState, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import calendar from './img/calendar.svg'
import ru from 'date-fns/locale/ru'
import { CalendarContainer } from 'react-datepicker';
import './SingleDatePicker.scss'

const SingleDatePicker = ({
    style,
    range,
    setDate,
    date,
    disabled,
    time,
    filterTime = false,
    ...props
}) => {

    const datePickerRef = useRef(null)

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const MyContainer = ({ className, children }) => {

        return (
            <div className='calendar_wrapper_single'>
                <CalendarContainer className={className}>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    }


    return (
        <div onClick={() => datePickerRef.current.setFocus()} className={disabled ? 'single-date-picker_grey' : 'single-date-picker'} style={style ? style : {}}>
            <DatePicker
                // showMonthDropdown
                // showYearDropdown
                // dateFormat='dd.MM.yyyy'
                ref={datePickerRef}
                selected={date}
                disabled={disabled}
                showTimeSelect={time ? true : false}
                dateFormat={time ? 'dd.MM.yyyy h:mm' : 'dd.MM.yyyy'}
                onChange={(date) => setDate(date)}
                locale={ru}
                filterTime={filterTime ? filterPassedTime : null}
                calendarContainer={MyContainer}
                calendarClassName="date-picker-custom"
                {...props}
            />
            <img className='icon-img' src={calendar} />
        </div>
    );
};

export { SingleDatePicker }