import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import "./ActVerification.scss"

const header_table = [
    'Дата',
    'Документ',
    'Дебет',
    'Кредит'
]

const body_table = [
    [
        'дд.мм.гггг',
        'Оплата (ХХ (это номер платежного поручения) от дд.мм.гггг)',
        '',
        458312
    ],
    [
        'дд.мм.гггг',
        'Оплата (ХХ (это номер платежного поручения) от дд.мм.гггг)',
        '',
        458312
    ],
    [
        'дд.мм.гггг',
        'Оплата (ХХ (это номер платежного поручения) от дд.мм.гггг)',
        '',
        458312
    ],
    [
        'дд.мм.гггг',
        'Оплата (ХХ (это номер платежного поручения) от дд.мм.гггг)',
        '',
        458312
    ],
    [
        'дд.мм.гггг',
        'Оплата (ХХ (это номер платежного поручения) от дд.мм.гггг)',
        '',
        458312
    ],
]

const ActVerification = (props) => {

    const {
        inn_executor,
        inn_client,
        date_from,
        date_to,
        executor,
        client,
        position_executor,
        position_client,
        name_executor,
        name_client
    } = props

    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const canvas = await html2canvas(reportTemplateRef.current)
        const imgData = canvas.toDataURL("image/png")
        const doc = new jsPDF({
            orientation: "landscape",
            format: 'a4',
            unit: 'px',
        });

        const width = doc.internal.pageSize.getWidth()
        const height = (canvas.height * width) / canvas.width
        doc.addImage(imgData, "PNG", 0, 0, width, height)
        doc.save('document')
    };


    return (
        <div className='document-page'>
            <ButtonBasic
                onClick={handleGeneratePdf}
                green
                text='Сгенерировать PDF'
            />
            <div className='act-verification' ref={reportTemplateRef}>
                <h1 className='act-verification__title'>Акт сверки</h1>
                <p className='act-verification__text-10 act-verification__text-center'>
                    взаимных расчетов за период: <span>{date_from || 'дд.мм.гггг'} - {date_to || 'дд.мм.гггг'}</span>
                </p>
                <p className='act-verification__text-10 act-verification__text-center'>
                    между {executor || '[Название фирмы заказчика]'} (ИНН {inn_executor || 'ХХХХХХХХХ'})
                </p>
                <p className='act-verification__text-10 act-verification__text-center'>
                    и <span>{client || '[Название фирмы клиента]'} (ИНН {inn_client || 'ХХХХХХХХХ'})</span>
                </p>

                <div className='act-verification__item'>
                    <p className='act-verification__text-10'>
                        Мы, нижеподписавшиеся, {position_executor || '[Должность заказчика]'} {executor || '[Название фирмы заказчика]'} {name_executor || '[фио заказчика]'},
                        с одной стороны, <span>{position_client || '[Должность клиента]'} {client || '[Название фирмы клиента]'} {name_client || '[фио клиента]'}</span>,
                        с другой стороны, составили настоящий акт сверки в том, что состояние взаимных
                        расчетов по данным учета следующее:
                    </p>
                </div>

                <div className='act-verification__table-group'>
                    <table className='act-verification__table'>
                        <thead>
                            <tr>
                                <th colSpan={4}>По данным {executor || '[Название фирмы заказчика]'},  руб</th>
                            </tr>
                            <tr>
                                {header_table.map((el, ind) => {
                                    return <th key={el + ind}>{el}</th>
                                })}
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className='td-left' colSpan={2}>Сальдо начальное</td>
                                <td></td>
                                <td></td>
                            </tr>
                            {body_table.map((el, ind) => {
                                return <tr key={el + ind}>
                                    {el.map((el, ind) => <td key={el + ind} >{el}</td>)}
                                </tr>
                            })}
                            <tr>
                                <td className='td-left' colSpan={2}>Обороты за период</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className='td-left' colSpan={2}>Сальдо конечное</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='act-verification__table'>
                        <thead>
                            <tr>
                                <th colSpan={4}>По данным <span>{client || '[Название фирмы клиента]'},  руб</span></th>
                            </tr>
                            <tr>
                                {header_table.map((el, ind) => {
                                    return <th key={el + ind}>{el}</th>
                                })}
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className='td-left' colSpan={2}>Сальдо начальное</td>
                                <td></td>
                                <td></td>
                            </tr>
                            {body_table.map((el, ind) => {
                                return <tr key={el + ind}>
                                    {el.map((el, ind) => <td key={el + ind} >{el}</td>)}
                                </tr>
                            })}
                            <tr>
                                <td className='td-left' colSpan={2}>Обороты за период</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className='td-left' colSpan={2}>Сальдо конечное</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>



                <div className='act-verification__place-signature-group'>
                    <div className='act-verification__place-signature'>
                        <p className='act-verification__text-8'>По данным {executor || '-'}</p>
                        <p className='act-verification__text-8 act-verification__text-bold'>На <span>дд.мм.гггг
                            задолженность в пользу ООО "ЕКОМСЕЛЛЕР" 50 000,00 руб.
                            (Пятьдесят тысяч рублей 00 копеек).</span>
                        </p>
                        <p className='act-verification__text-8'>{executor || '-'}</p>
                        <p className='act-verification__text-8'>{position_executor || '[Должность заказчика]'}</p>
                        <div className='act-verification__field'></div>
                        <p className='act-verification__text-8 act-verification__text-center'>{name_executor || '[фио заказчика]'}</p>
                    </div>

                    <div className='act-verification__place-signature'>
                        <p className='act-verification__text-8'>По данным <span>{client || '-'}</span></p>
                        <br />
                        <p className='act-verification__text-8'>От <span>{client || '[Название фирмы заказчика]'}</span></p>
                        <p className='act-verification__text-8'>{position_executor || '[Должность клиента]'}</p>
                        <div className='act-verification__field'></div>
                        <p className='act-verification__text-8 act-verification__text-center'><span>{name_client || '[фио клиента]'}</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ActVerification }