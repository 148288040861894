import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import done from './img/done.svg'
import edit_icon from './img/edit_icon.svg'
import price_icon from './img/price_icon.svg'
import transfer_icon from './img/transfer_icon.svg'
import './ModalCardCreated.scss'

const info_content = [
    {
        label: 'Переносить товары',
        icon: transfer_icon
    },
    {
        label: 'Менять цены',
        icon: price_icon
    },
    {
        label: 'Редактировать',
        icon: edit_icon
    }
]

const ModalCardCreated = ({ ...props }) => {
    const {
        setIsModal,
        fetchProductIds
    } = props

    const navigate = useNavigate();

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-card-created'>
                <img src={done} />
                <div className='modal-card-created__content'>
                    <h2 className='title'>Карточка создана успешно</h2>
                    <p className='text'>Добавим ее в течение 2 минут в <Link className='link_blue' to='/products_list_master_product/all'>Список товаров</Link></p>
                </div>

                <div className='modal-card-created__info-content'>
                    <h3 className='title'>Вы можете работать с вашими товарами:</h3>
                    <div className='content-points'>
                        {info_content.map(({ label, icon }, ind) =>
                        <div className='point-item' key={label + ind}>
                            <p className='text'>{label}</p>
                            <img className='icon' src={icon} />
                        </div>
                    )}
                    </div>
                </div>

                <div className='btn-group'>
                    <ButtonBasic
                        lilac
                        text='К списку товаров'
                        width={`calc(50% - 6px)`}
                        size='56px'
                        onClick={(e) => {
                            navigate('/products_list_master_product/all')
                            setIsModal(false)
                        }}
                    />
                    <ButtonBasic
                        violet
                        text='Добавить новый товар'
                        width={`calc(50% - 6px)`}
                        size='56px'
                        onClick={(e) => {
                            fetchProductIds(false)
                            setIsModal(false)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalCardCreated }