import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls';
import { Checkbox } from "@lk-gtcom/ecomlab-components";
import './ModalAddTypeNotification.scss'

const ModalAddTypeNotification = ({ setIsModal }) => {

    const [listTypesNotifications, setListTypesNotifications] = useState([])
    const auth = new Cookies().get('auth')

    const fetchGetTypesNotifications = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/notification/get_notification_type_for_client`
        } else {
            url = `${RavshanHttp}/api/notification/get_notification_type_for_client`
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => res.json())
            .then(json => {
                setListTypesNotifications(json)
                //  const data = json?.map(({ client_id, mp_name, account_id, account_name, enabled }) )

            })
            .catch(err => console.error(err))
    }

    const fetchChangeTypeNotifications = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/notification/change_notification_type_for_client`
        } else {
            url = `${RavshanHttp}/api/notification/change_notification_type_for_client`
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        const bodyParametr = listTypesNotifications.map(({ type_id, enabled }) => {
           return { type_id: type_id, enabled: enabled }
        })

        const body = JSON.stringify(
            bodyParametr
        )

        fetch(url, { body, method: 'PUT', headers })
            .then(res => res.json())
            .then(json => {
                console.log(json)
            })
            .catch(err => console.error(err))
    }


    useEffect(() => {
        fetchGetTypesNotifications()
    }, []);

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-add-type-notification'>
                <h2 className='title'>Выберите тип уведомлений</h2>
                <div className='container-list-notification'>
                    {listTypesNotifications?.map(({ client_id, enabled, type_id, type_name }, ind) =>
                        <div className='type-notification-item' key={type_name + ind}>
                            <Checkbox
                                value={enabled}
                                onChange={(e) => {
                                    let status = e
                                    let current_id = type_id
                                    const obj = listTypesNotifications.map(({ client_id, enabled, type_id, type_name }) => {
                                        return {
                                            client_id: client_id,
                                            enabled: (current_id == type_id) ? status : enabled,
                                            type_id: type_id,
                                            type_name: type_name
                                        }
                                    })
                                    setListTypesNotifications(obj)
                                }}
                            />
                            <p className='text'>{type_name}</p>
                        </div>
                    )}
                </div>
                <button className='btn__blue'
                    onClick={(e) => {
                        fetchChangeTypeNotifications()
                        setIsModal(false)
                    }}
                >Применить</button>
            </div>
        </div>
    )
}

export { ModalAddTypeNotification }