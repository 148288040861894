import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import "./ActService.scss"

const header_table = [
    '№',
    'Наименование работ, услуг',
    'Кол-во',
    'Ед.',
    'Цена',
    'Сумма',
]

const body_table = [
    [
        1,
        'Услуга 1 за период дд.мм.ггг - дд.мм.гггг',
        '',
        'Услуга',
        800,
        800
    ],
    [
        1,
        'Услуга 1 за период дд.мм.ггг - дд.мм.гггг',
        '',
        'Услуга',
        800,
        800
    ]
]

const ActService = (props) => {

    const {
        number,
        date,
        executor,
        client,
        signature_footer_executor,
        signature_footer_client,
        name_executor,
        name_client
    } = props

    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const canvas = await html2canvas(reportTemplateRef.current)
        const imgData = canvas.toDataURL("image/png")
        const doc = new jsPDF({
            format: 'a4',
            unit: 'px',
        });

        const width = doc.internal.pageSize.getWidth()
        const height = (canvas.height * width) / canvas.width
        doc.addImage(imgData, "PNG", 0, 0, width, height)
        doc.save('document')
    };

    return (
        <div className='document-page'>
            <ButtonBasic
                onClick={handleGeneratePdf}
                green
                text='Сгенерировать PDF'
            />
            <div className='act-service' ref={reportTemplateRef}>
                <h1 className='act-service__title'>Акт <span>№ {number || 'ХХ'}</span> от <span>{date || 'дд.мм.гггг'}</span></h1>

                <div className='act-service__executor'>
                    <p className='act-service__placeholder'>Исполнитель:</p>
                    <p className='act-service__text-bold'>{client || '[Реквизиты исполнителя]'}</p>
                </div>

                <div className='act-service__executor'>
                    <p className='act-service__placeholder'>Заказчик:</p>
                    <p className='act-service__text-bold act-service__text-blue'>{executor || '[Реквизиты заказчика]'}</p>
                </div>

                <div className='act-service__executor'>
                    <p className='act-service__placeholder'>Основание:</p>
                </div>

                <table className='act-service__table'>
                    <thead>
                        <tr>
                            {header_table.map((el, ind) => {
                                return <th key={el + ind}>{el}</th>
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {body_table.map((el, ind) => {
                            return <tr key={el + ind}>
                                {el.map((el, ind) => <td key={el + ind} >{el}</td>)}
                            </tr>
                        })}
                        <tr className='tr-total'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Итого <br /> Без налога (НДС)</td>
                            <td>{body_table.reduce((acc, num) => acc + num[5], 0)}</td>
                        </tr>
                    </tbody>
                </table>

                <div className='act-service__item'>
                    <p className='act-service__text-8'>Всего оказано услуг <span>{body_table?.length}</span>, на сумму <span>{body_table.reduce((acc, num) => acc + num[5], 0)?.toLocaleString()}</span> руб.</p>
                    <p className='act-service__text-bold act-service__text-blue'>Восемьсот рублей 00 копеек</p>
                </div>

                <div className='act-service__item'>
                    <p className='act-service__text-9'>Вышеперечисленные услуги выполнены полностью и в срок.
                        Заказчик претензий по объему, качеству и срокам оказания услуг не имеет.</p>
                </div>

                <div className='act-service__delimetr'></div>

                <div className='act-service__place-signature-group'>
                    <div className='act-service__place-signature'>
                        <p className='act-service__text-10 act-service__text-bold'>ИСПОЛНИТЕЛЬ</p>
                        <p className='act-service__text-8'>{signature_footer_executor || '-'}</p>
                        <div className='act-service__field'></div>
                        <p className='act-service__text-8 act-service__text-center'>{name_client || '[фио клиента]'}</p>
                    </div>

                    <div className='act-service__place-signature'>
                        <p className='act-service__text-10 act-service__text-bold'>ЗАКАЗЧИК</p>
                        <p className='act-service__text-8 act-service__text-blue'>{signature_footer_client || '-'}</p>
                        <div className='act-service__field'></div>
                        <p className='act-service__text-8 act-service__text-center'>{name_executor || '[фио заказчика]'}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ActService }