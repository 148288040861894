import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import banner_space from './img/banner_space.svg'
import banner_why from './img/banner_why.png'
import banner_how from './img/banner_how.svg'
import './ModalUnboardingForSpace.scss'

const ModalUnboardingForSpace = ({ setIsModal }) => {

    const [currentStep, setCurrenStep] = useState(1)

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <>
                {currentStep === 1 && <div className='modal-unboarding-for-space-2'>
                    <img src={banner_space} />
                    <h1 className='modal-unboarding-for-space-2__title'>
                        Мы создали для Вас первое Пространство
                    </h1>
                    <p className='modal-unboarding-for-space-1__description'>
                        Теперь вы можете добавлять подключения и сотрудников в него,
                        или создать новое Пространство.
                    </p>
                    <ButtonBasic
                        green
                        width='100%'
                        size='30px'
                        text='Далее'
                        onClick={(e) => setCurrenStep(2)}
                        style={{
                            marginTop: '6px'
                        }}
                    />
                </div>}

                {currentStep === 2 && <div className='modal-unboarding-for-space-2'>
                    <img src={banner_why} />
                    <h1 className='modal-unboarding-for-space-2__title'>
                        Зачем нужно пространство?
                    </h1>
                    <p className='modal-unboarding-for-space-1__description'>
                        Это может пригодиться если вы, например,
                        хотите чтобы у Вас разные группы сотрудников
                        видели разные Магазины. Или если вы хотите чтобы
                        управлять разными компаниями из одного кабинета,
                        но разделить группы сотрудников по каждой из них.
                    </p>
                    <ButtonBasic
                        green
                        width='100%'
                        size='30px'
                        text='Далее'
                        onClick={(e) => setCurrenStep(3)}
                        style={{
                            marginTop: '6px'
                        }}
                    />
                    <ButtonBasic
                        grey
                        width='100%'
                        size='30px'
                        text='Вернуться назад'
                        onClick={(e) => setCurrenStep(1)}
                    />
                </div>}

                {currentStep === 3 && <div className='modal-unboarding-for-space-2'>
                    <img src={banner_how} />
                    <h1 className='modal-unboarding-for-space-2__title'>
                        Как пользоваться пространствами?
                    </h1>
                    <p className='modal-unboarding-for-space-1__description'>
                        Посмотреть примеры использования пространства и возможности можно в
                        <a href='wiki.ecomru.ru' target='_blank'
                        onClick={(e) =>   window.open(`https://wiki.ecomru.ru/articles/start`, '_blank')}
                        > wiki.ecomru.ru</a>
                    </p>
                    <ButtonBasic
                        green
                        width='100%'
                        size='30px'
                        text='Закрыть'
                        onClick={(e) => setIsModal(false)}
                        style={{
                            marginTop: '6px'
                        }}
                    />
                    <ButtonBasic
                        grey
                        width='100%'
                        size='30px'
                        text='Вернуться назад'
                        onClick={(e) => setCurrenStep(2)}
                    />
                </div>}
            </>

        </div>
    )
}

export { ModalUnboardingForSpace }