import { useDataTable } from '../../common/hooks'
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from 'react'
import { BtnDropCheckbox } from '../../components/BtnDropCheckbox/BtnDropCheckbox';
import { Tabs, TableBox, DropDownSelector, ButtonBasic, RangeBox } from "@lk-gtcom/ecomlab-components";
import './CalculationDelivery.scss'

const list_select = [
    {
        label: 'Площадка',
    },
    {
        label: 'Бренд',
    },
    {
        label: 'Категория',
    },
];


const btn_tab = [
    {
        label: 'Рассчет',
        value: 'calculation',
    },
    {
        label: 'Параметры',
        value: 'parameters',
    },
    {
        label: 'Интеллектуальный рассчет',
        value: 'intelligent_calculation',
    }
]

const CalculationDelivery = () => {

    const [pageValue, setPageValue] = useState('')
    const [checkboxValues, setCheckboxValues] = useState([
        'Площадка',
        'Категория',
        'Бренд',
    ]);


    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)

    const activeTab = useParams()['*']

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    return (
        <div className='calculation-delivery'>
            <h1 className='calculation-delivery__title-main'>Расчет поставки</h1>

            <div className='calculation-delivery__toolbar-top'>
                <FilterContainer
                    onReset={(e) => (e)}
                >
                    {checkboxValues.includes('Площадка') &&
                        <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={e => (e)}
                            placeholder='Площадка'
                        />}

                    {checkboxValues.includes('Категория') &&
                        <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={e => (e)}
                            placeholder='Категория'
                        />}

                    {checkboxValues.includes('Бренд') &&
                        <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={e => (e)}
                            placeholder='Бренд'
                        />}
                </FilterContainer>
                '',

                <div className='calculation-delivery__toolbar-top_right'>
                    {(pageValue == 'intelligent_calculation' || pageValue == 'parameters')
                        &&
                        <RangeBox setDate={e => setDate(e)} hideBtnGroup />}
                    <BtnDropCheckbox
                        checkboxValues={checkboxValues}
                        setCheckboxValues={(e) => setCheckboxValues(e)}
                        list={list_select}
                    />
                </div>

            </div>

            <Tabs tabs={btn_tab} pageValue={pageValue} />

            <TableBox
                {...tableProps}
                paginator={true}
                ref={paginatorRef}
                onCheck={e => (e)}
                fetchCallback={[]}
                child={
                    <>
                        {pageValue == 'calculation' && <div className='calculation-delivery__header-table'>
                            <label className='calculation-delivery__label'>
                                <span>Кол-во дней до поставки:</span>
                                <input
                                    type='number'
                                    className='calculation-delivery__input'></input>
                            </label>
                            <ButtonBasic
                                green
                                maxWidth='270px'
                                width='100%'
                                text='Рассчитать'
                            />
                        </div>}

                        {pageValue == 'intelligent_calculation' && <div className='calculation-delivery__header-table'>
                            <div className='calculation-delivery__info-content'>
                                <h4 className='calculation-delivery__title-tooltip'>Мы рассчитываем когда вам наиболее выгодно везти товар на склад</h4>
                                <p className='calculation-delivery__text-tooltip'>Например – ваши потери продаж могут составлять 100 руб.
                                    А себестоимость доставки 10 000 руб. По этому мы указываем
                                    дату когда наиболее эффективно везти товар</p>
                            </div>
                            <ButtonBasic
                                green
                                maxWidth='270px'
                                width='100%'
                                text='Рассчитать'
                            />
                        </div>}
                    </>
                }
            />
        </div>
    )
}

export { CalculationDelivery }