import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import { KonstantinAccountInSettingsHttps, KonstantinAccountInSettingsHttp } from '../../fetchUrls';
import { validationEmail } from '../../common/validationFunc';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful'
import Cookies from 'universal-cookie'
import "./AccountSettings.scss"

const AccountSettings = ({ changeDataUser, setChangeDataUser }) => {

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [tel, setTel] = useState('+7')
    const [errName, setErrName] = useState(false)
    const [errEmail, setErrEmail] = useState(false)
    const [errTel, setErrTel] = useState(false)
    const [err, setErr] = useState('')
    const [isModal, setIsModal] = useState(false)
    const auth = new Cookies().get('auth')

    const handlerSetName = (e) => {
        setName(e)
    }

    const handlerSetEmail = (e) => {
        setEmail(e)
    }

    const handlerSetTel = (e) => {
        setTel(e)
    }

    const deleteErr = () => {
        setErr('')
        setErrTel(false)
        setErrName(false)
        setErrEmail(false)
    }

    const fetchUser = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinAccountInSettingsHttps}/web/users/data`
        } else {
            url = `${KonstantinAccountInSettingsHttp}/web/users/data`
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => res.json())
            .then(json => {
                const { email, name, phone_number } = json
                if (email) setEmail(email)
                if (name) setName(name)
                if (phone_number) setTel(phone_number)
                console.log(json)
            })
            .catch(err => console.error(err))
    }

    const fetchChangeUserData = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinAccountInSettingsHttps}/web/users/data`
        } else {
            url = `${KonstantinAccountInSettingsHttp}/web/users/data`
        }

        const body = JSON.stringify({
            name: name,
            email: email,
            phone_number: tel
        })

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json()
                .then(json => {
                    if (json?.detail == 'User does not exist') {
                        setErr('Такого пользователя не существует')
                    } else {
                        setIsModal(true)
                    }

                }))
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        deleteErr()
    }, [name, email, tel]);

    useEffect(() => {
        console.log(email, name, tel);
        if (changeDataUser) {
            
            if (name?.length < 1) setErrName(true)
            if (!validationEmail(email)) setErrEmail(true)


            if ((name?.length > 0) && (validationEmail(email))) {
                fetchChangeUserData()
            }
        }
        setChangeDataUser(false)
    }, [changeDataUser]);

    useEffect(() => {
        fetchUser()
    }, []);

    return (
        <>
            {isModal && <ModalSuccessful title='Данные аккаунта успешно изменены' setIsModal={(e) => setIsModal(e)} />}
            <div className='account-settings'>
                <h2 className='title' style={{margin: 0}}>Настройки аккаунта</h2>

                <InputDinamycPlaceholder
                    onChange={(e) => handlerSetEmail(e)}
                    changeValue={email}
                    type="text"
                    err={errEmail}
                    textErr={'Неверная электронная почта'}
                    classname='autorization__input-field'
                    placeholder='Электронная почта'
                    autoComplete={false}
                />

                <InputDinamycPlaceholder
                    onChange={(e) => handlerSetName(e)}
                    changeValue={name}
                    type="text"
                    err={errName}
                    textErr={'Введите имя'}
                    classname='autorization__input-field'
                    placeholder='Имя пользователя'
                    autoComplete={false}
                />

                <InputDinamycPlaceholder
                    onChange={(e) => handlerSetTel(e)}
                    changeValue={tel}
                    type="text"
                    err={errTel}
                    textErr={'Неверный номер телефона'}
                    classname='autorization__input-field'
                    placeholder='Контактный телефон'
                    autoComplete={false}
                />
            </div>

            <div className='err-box'>
                {err && <span className='text text_red'>{err}</span>}
            </div>
        </>
    )
}

export { AccountSettings }