import { useEffect, useState } from 'react'
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import './AccAuthBlock.scss'

const AccAuthBlock = ({
    attr_name,
    attribute_name,
    description,
    service_id,
    mp_name,
    mp_id,
    setData = false,
    attribute_value = '',
    required,
    setIsEmptyReqired,
    error
}) => {

    const [inputValue, setInputValue] = useState(attribute_value ? attribute_value : '')

    useEffect(() => {
        if((typeof setIsEmptyReqired === 'function') & required){
            setIsEmptyReqired(prev => ({...prev, [service_id]: !inputValue?.length > 0}))
        }
    }, [inputValue])

    return (
        <div className='acc-auth__input'>
            <InputDinamycPlaceholder
                onChange={value => {
                    setInputValue(value)
                    if (setData) {
                        setData(prev => ({
                            ...prev,
                            [service_id]: value
                        }))
                    }
                }}
                changeValue={inputValue}
                type="text"
                placeholder={attribute_name}
                autoComplete={false}
                tooltipStatic={error & required & inputValue?.length < 1 ? '' : description}
                err={error & required & inputValue?.length < 1}
                textErr={'Поле обязательно к заполнению'}
            />
        </div>
    )
}

export { AccAuthBlock }