import "./TabsGreyBtn.scss"

const TabsGreyBtn = ({ title, btns, currentBtn, setCurrentBtn, checkValue = false, returnEvent }) => {

    return (
        <div className='tabs-grey-btn'>
            {title && <p className='text'>{title}</p>}
            <div className='tabs-grey-btn__content'>
                {
                    btns.map((el, ind) => {
                        const {label, value, count} = el
                        return (
                            <button className={checkValue ? currentBtn == value ? 'btn_active' : 'btn' : currentBtn == label ? 'btn_active' : 'btn'}
                                onClick={(e) => {
                                    if(returnEvent){
                                        setCurrentBtn(el)
                                    }
                                    checkValue ?
                                        setCurrentBtn(value)
                                        :
                                        setCurrentBtn(label)
                                }}
                            >{label}
                                <div className="count">{`${count ? count : ''}`}</div>
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export { TabsGreyBtn }