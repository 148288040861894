import customHeaders, {getSpace} from '../../common/headers';
import { Comment } from '../Comment/Comment';
import { useState } from 'react';
import { RavshanHttps } from '../../fetchUrls';
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor';
import useGeneralStore from '../../store/general';
import "./CommentItem.scss"
const parse = require('html-react-parser')
const email = localStorage.getItem('email')
const CommentItem = ({...props}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const { id, author, event_id, text, date, fetchComments, disabled } = props
    const { name, login, color } = author

    const [comment, setComment] = useState(text ? text : '')
    const [edit, setEdit] = useState(false)
    const [showImg, setShowImg] = useState('')

    const fetchChangeComment = () => {
        const url = `${RavshanHttps}/api/tasks/change_comment`
        const body = JSON.stringify({
            text: comment,
            event_id,
            id
        })

        fetch(url, { body, method: 'PUT', headers: headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .catch((e) => console.error(e))
    }

    const fetchDeleteComment = () => {
        const url = `${RavshanHttps}/api/tasks/delete_comment`
        const body = JSON.stringify({
            id
        })

        fetch(url, { body, method: 'DELETE', headers: headers })
            .then(res => {
                if (res.ok) {
                    return
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .then(() => {
                console.log(fetchComments);
                if (fetchComments) {
                    fetchComments()
                }
            })
            .catch((e) => console.error(e))
    }

    return (
        <>
            {showImg && <div className='show-img' onClick={(e) => setShowImg('')}>
                <img src={showImg}/>
            </div>}

            <div className={login == email ? 'comment__wrapper_author' : 'comment__wrapper'}>
                {login != email && <div className='comment__executor-content'>
                    <ExecutorPlaceholder name={name} id={author?.id} login={login} color={color} />
                </div>}
                <div className={login == email ? 'comment-item_author' : 'comment-item'}>
                    {login != email && <div className='comment-item__header'>
                        <p className='text_name'>{name}</p>
                    </div>
                    }
                    <div className='comment-item__content'>
                        {
                            edit ?
                                <Comment
                                    {...props}
                                    {...author}
                                    email={login}
                                    commentValue={comment}
                                    changeCommentValue={e => setComment(e)}
                                />
                                :
                                <p className='text'>{parse(comment, {
                                    replace: (domNode) => {
                                        if (domNode.name === "img") {
                                            return <img src={domNode.attribs.src} 
                                            style={{cursor: 'pointer'}}
                                            onClick={(e) => {
                                                setShowImg(domNode.attribs.src)
                                            }}
                                            />
                                        }
                                    },
                                })}</p>
                        }
                    </div>

                    <p className='text_date'>{new Date(date).toLocaleDateString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' })}</p>

                    {!disabled && <div className='comment-item__btn-group'>
                        <a href='#' className='btn-text-grey'
                            onClick={e => {
                                if (!edit) {
                                    setEdit(true)
                                } else {
                                    setEdit(false)
                                    fetchChangeComment()
                                }
                            }}
                        >
                            {edit ? 'Сохранить' : 'Редактировать'}
                        </a>
                        <a href='#' className='btn-text-grey' onClick={e => fetchDeleteComment()}>Удалить</a>
                    </div>}
                </div>
            </div>
        </>

    )
}

export { CommentItem }