import { CarouselSlick } from '../../components/CarouselSlick/CarouselSlick'
import "./ProductCardExtended_Description.scss"

const ProductCardExtended_Description = ({description, images, characteristics}) => {

    return (
        <section className='description-inside-content'>
           <CarouselSlick imgArr={Array.isArray(images) && images?.length > 0 ? images : typeof images === 'string' ? [images] : []} />

            <div className='text-box'>
                <div className='text-box__header'>
                    <h3 className='title'>Описание</h3>
                </div>
                <p className='text_description'>{description ? description : 'Описание отсутствует'}</p>
            </div>

            <div className={'general-information'}>
                <h3 className='title_info'>ХАРАКТЕРИСТИКИ</h3>
                <div className='info-content'>
                    {
                        !!characteristics ?
                            Object?.keys(characteristics)?.map(key =>
                                <div className='info-content__item'>
                                    <p className='text_label'>{key}</p>
                                    <p className='text_value'>{characteristics?.[key]}</p>
                                </div>
                            )
                        :
                            'Характеристики отсутствуют'
                    }
                        {/* <p className='text_label'>Дата отгрузки</p>
                        <p className='text_value'>01.01.2024</p> */}
                </div>
            </div>
        </section>
    )
}

export { ProductCardExtended_Description }