import { useState, useEffect } from 'react'
import { DropDownSelector, InputDinamycPlaceholder, ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { HintsNotifications } from '../../Tooltip/HintsNotifications/HintsNotifications';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls'
import customHeaders, {getSpace} from '../../../common/headers';
import useGeneralStore from '../../../store/general';
import './ModalTaskStatus.scss'


const task_actions = [
    {
        label: 'Удалить через',
        value: 'delete'
    },
    {
        label: 'Переместить в',
        value: 'transfer'
    }
]

const ModalTaskStatus = ({
    setIsModal,
    columns,
    currentColumn,
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [activeMenuItem, setAtiveMenuItem] = useState({})
    const [selectedTaskActions, setSelectedTaskActions] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([])
    const [numberDays, setNumberDays] = useState(0)
    const [settingsColumn, setSettingsColumn] = useState({})
    const projectName = sessionStorage.getItem('taskProjectName')
    const options_prop_column = columns.map(({ typeId, typeName }) => {
        return { label: typeName, value: typeId }
    })

    const fetchSettingsColumn = (task_type_id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v2/tasks/task_type/${task_type_id}`
        } else {
            url = `${RavshanHttp}/api/v2/tasks/task_type/${task_type_id}`
        }

        const currentHeaders = {
            ...headers,
            'language': 'ru',
            'portal-id': '4',
        }

        fetch(url, { method: 'GET', headers: currentHeaders })
            .then(res => res.json())
            .then(json => {
                setSettingsColumn(json)
            })
            .catch(err => console.error(err))
    }

    const fetchActivateSettings = (activate) => {

        let status = activate ? 'activate' : 'deactivate'
        if (!selectedTaskActions?.value) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/task_type/${status}`
        } else {
            url = `${RavshanHttp}/api/tasks/task_type/${status}`
        }

        const body = JSON.stringify({
            task_type_id: settingsColumn?.id,
            lifetime: numberDays ? Number(numberDays) : settingsColumn?.lifetime,
            action: selectedTaskActions?.value ? selectedTaskActions.value : settingsColumn?.action,
            transfer: selectedStatus?.value ? selectedStatus?.value : (settingsColumn?.transfer ? settingsColumn?.transfer : undefined)
        })

        fetch(`${url}`, { body, method: 'POST', headers })
        .then(res => {
            if (res.ok) {
                fetchSettingsColumn(activeMenuItem.typeId)
                return res.json()
            } else {
                throw Error(JSON.stringify(res.json()))
            }
        })
            .then(json => {
 
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetchSettingsColumn(currentColumn)
        let id = columns?.filter((el) => el.typeId == currentColumn)
        setAtiveMenuItem(id[0])
    }, [selectedSpace]);

    useEffect(() => {
        setNumberDays(settingsColumn?.lifetime ? settingsColumn?.lifetime : 0)
        let status_id = options_prop_column?.filter(({ value }) => value == settingsColumn?.transfer)
        let action = task_actions?.filter(({ value }) => value == settingsColumn?.action)
        setSelectedStatus(status_id[0] ? status_id[0] : [])
        setSelectedTaskActions(action[0] ? action[0] : [])
    }, [settingsColumn, selectedSpace]);


    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-task-status'>
                <header className='modal-task-status__header'>
                    <h1 className='modal-task-status__title'>Состояние задач по истечению времени в проекте - <span>{projectName}</span></h1>
                </header>
                <div className='modal-task-status__content'>
                    <nav className='modal-task-status__sidebar'>
                        <ul>
                            {columns?.map((el, ind) =>
                                <li key={el.typeId + ind}>
                                    <button
                                        className={activeMenuItem.typeId == (el.typeId) ? 'btn-point_active' : 'btn-point'}
                                        onClick={(e) => {
                                            fetchSettingsColumn(el.typeId)
                                            setAtiveMenuItem(el)
                                        }}
                                    >{el.typeName}</button>
                                </li>
                            )}
                        </ul>
                    </nav>

                    <main className='modal-task-status__main'>
                        <h1 className='modal-task-status__title'>Параметры задач: <span>{activeMenuItem.typeName}</span></h1>
                        <div className='modal-task-status__main-content'>
                            <div className='modal-task-status__select-group'>
                                <DropDownSelector
                                    state={selectedTaskActions}
                                    options_prop={task_actions}
                                    setState={e => setSelectedTaskActions(e)}
                                    placeholder='Что делать с задачей?'
                                    width={'100%'}
                                    disabled={settingsColumn?.active}
                                />

                                {selectedTaskActions.value == 'transfer' && <DropDownSelector
                                    state={selectedStatus}
                                    options_prop={options_prop_column}
                                    setState={e => setSelectedStatus(e)}
                                    placeholder='Переместить в'
                                    width={'100%'}
                                    disabled={settingsColumn?.active}
                                />}

                                <InputDinamycPlaceholder
                                    changeValue={numberDays?.toString()}
                                    onChange={(e) => setNumberDays(e)}
                                    type='number'
                                    autoComplete={false}
                                    size='s'
                                    placeholder='Количество дней'
                                    disabled={settingsColumn?.active}
                                />
                            </div>

                            <ButtonBasic
                                green={!settingsColumn?.active}
                                red={settingsColumn?.active}
                                onClick={(e) => {
                                    if(settingsColumn?.active) {
                                        fetchActivateSettings(false)
                                    } else {
                                        fetchActivateSettings(true)
                                    }
                                }}
                                text={settingsColumn?.active ? 'Деактивировать' : 'Активировать'}
                            />

                            {
                                settingsColumn?.active && 
                                    <HintsNotifications
                                        text={
                                            <p>
                                                Задачи которые находятся в статусе 
                                                <span> «{activeMenuItem.typeName}»</span> будут 
                                                {
                                                    selectedTaskActions.value == 'transfer' ? 
                                                        <>
                                                            <span> перемещены </span>
                                                            в <span> «{selectedStatus?.label}» </span> через
                                                        </> 
                                                    : 
                                                        <> 
                                                            <span> удалены</span> из системы через
                                                        </>
                                                } 
                                                <span> {numberDays} дней</span>
                                            </p>
                                        }
                                        style={{ maxWidth: '434px' }}
                                    />
                            }

                        </div>

                        <div className='modal-task-status__btn-box'>
                            <ButtonBasic
                                text='Закрыть'
                                size='56px'
                                onClick={e => setIsModal(false)}
                            />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export { ModalTaskStatus }