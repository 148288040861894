import { useCallback, useRef, useState } from "react";

function useDebounce(callback, delay) {
    const timer = useRef()
    const [prevId, setPrevId] = useState(null)

    const debounceCallback = useCallback((...args) => {
        const id = args?.[args?.length - 1]?.attribute_id
        if (timer.current) {
            if(prevId === id){
                clearTimeout(timer.current)
            }
        }
        setPrevId(id)
        timer.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }, [callback, delay, prevId])

    return debounceCallback
}

export {useDebounce}