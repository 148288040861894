import React, { useEffect, useState } from 'react';
import trash_icon from './img/trash_icon.svg'
import settings_icon from './img/settings.svg'
import './CrmModal.scss';
import { RavshanHttps } from "../../fetchUrls";
import customHeaders, {getSpace} from "../../common/headers";
import { DropDownSelector, InputDinamycPlaceholder, ButtonBasic } from '@lk-gtcom/ecomlab-components'
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { CrmCommonAttrSubModal } from "./CrmCommonAttrSubModal";
import { CrmUncommonAttrSubModal } from "./CrmUncommonAttrSubModal";
import { CrmAttrControlModal } from "./CrmAttrControlModal";
import close_icon from './img/close_icon.svg'
import useGeneralStore from '../../store/general';

export const CrmModal = ({ isOpen, close, fetchTableData }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [tableId, setTableId] = useState('')
    const [title, setTitle] = useState('')
    const [selectedTableType, setSelectedTableType] = useState('')
    const [commonAttributes, setCommonAttributes] = useState([])
    const [tableTypes, setTableTypes] = useState([])
    const [isCommonAttrSubModalOpen, setIsCommonAttrSubModalOpen] = useState(false)
    const [isUncommonAttrSubModalOpen, setIsUncommonAttrSubModalOpen] = useState(false)
    const [uncommonAttrTypes, setUncommonAttrTypes] = useState()
    const [isAttrControlModalOpen, setIsAttrControlModalOpen] = useState(false)
    const [selectedAttributes, setSelectedAttributes] = useState([])
    const [currentAttr, setCurrentAtt] = useState(null)
    const [valuesAtr, setValuesAtr] = useState([])
    const [checkedAttr1, setCheckedAttr1] = useState([])
    const [checkedAttr2, setCheckedAttr2] = useState([])

    const [currentIndex, setCurrentIndex] = useState(null)
    const [objValuesAttr, setObjValuesAttr] = useState({})

    //  console.log(objValuesAttr, selectedAttributes)

    const fetchCommonAttributes = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_common_attr`
        } else {
            url = `${RavshanHttps}/api/crm/get_common_attr`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                setCommonAttributes(json)
            })
            .catch(err => {
                console.error(err)
            })
    }

    // Получение типов аттрибутов
    const fetchUncommonAttributesTypes = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_attr_types`
        } else {
            url = `${RavshanHttps}/api/crm/get_attr_types`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                setUncommonAttrTypes(json)
            })
            .catch(err => {
                console.error(err)
            })
    }

    // Получение значений атрибутов
    // const fetchUncommonAttributesValues = (id) => {
    //     let url
    //     if (!window.location.hostname.match('localhost')) {
    //         url = `${RavshanHttps}/api/crm/get_attr_values`
    //     } else {
    //         url = `${RavshanHttps}/api/crm/get_attr_values`
    //     }

    //     const _headers = {
    //         ...headers,
    //         "attr-id": id,
    //     }

    //     fetch(url, { method: 'GET', headers: _headers })
    //         .then(res => {
    //             if (res.ok) {
    //                 return res.json()
    //             } else {
    //                 throw new Error(res.json())
    //             }
    //         })
    //         .then(json => {
    //             console.log(json)
    //             setValuesAtr((prev) => [...prev, json])
    //         })
    //         .catch(err => {
    //             console.error(err)
    //         })
    // }

    //Получение типов таблицы
    const fetchTableTypes = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_table_types`
        } else {
            url = `${RavshanHttps}/api/crm/get_table_types`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                setTableTypes(json?.map(el => ({ label: el?.title, value: el?.id })))
            })
            .catch(err => {
                console.error(err)
            })

    }

    //Создание таблицы
    const fetchCreateTable = (common, attr_new) => {
        if (title && selectedTableType?.value) {
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${RavshanHttps}/api/crm/create_table`
            } else {
                url = `${RavshanHttps}/api/crm/create_table`
            }

            const body = JSON.stringify({
                portal_id: 4,
                title,
                table_type_id: selectedTableType?.value,
                common,
                attr_new
            })

            fetch(url, { body, method: 'POST', headers })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw new Error(res.json())
                    }
                })
                .then(json => {
                    setTableId(json)
                    fetchTableData()
                    close()
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    // Создание аттрибута из common
    // const fetchCreateAttrCommon = (title) => {
    //     let url
    //     if (!window.location.hostname.match('localhost')) {
    //         url = `${RavshanHttps}/api/crm/add_attr_common`
    //     } else {
    //         url = `${RavshanHttps}/api/crm/add_attr_common`
    //     }

    //     const body = JSON.stringify({
    //         table_id: 0,
    //         common_id: 0,
    //         title: title,
    //         required: true,
    //     })

    //     fetch(url, { body, method: 'POST', headers })
    //         .then(res => {
    //             if (res.ok) {
    //                 return res.json()
    //             } else {
    //                 throw new Error(res.json())
    //             }
    //         })
    //         .then(json => {
    //             console.log(json)
    //         })
    //         .catch(err => {
    //             console.error(err)
    //         })

    // }

    // Изменение аттрибута из common
    const fetchChangeAttrCommon = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/change_attr_common`
        } else {
            url = `${RavshanHttps}/api/crm/change_attr_common`
        }

        const body = JSON.stringify({
            table_id: 0,
            common_id: 0,
            title: "string",
            required: true,
            attr_id: 0
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Создание своего аттрибута
    // const fetchCreateYourAttr = (title, is_dict) => {
    //     let url
    //     if (!window.location.hostname.match('localhost')) {
    //         url = `${RavshanHttps}/api/crm/add_attr_new`
    //     } else {
    //         url = `${RavshanHttps}/api/crm/add_attr_new`
    //     }

    //     const body = JSON.stringify({
    //         table_id: 0,
    //         datatype: 0,
    //         title: title,
    //         required: true,
    //         is_dict: is_dict
    //     })

    //     fetch(url, { body, method: 'POST', headers })
    //         .then(res => {
    //             if (res.ok) {
    //                 return res.json()
    //             } else {
    //                 throw new Error(res.json())
    //             }
    //         })
    //         .then(json => {
    //             console.log(json)
    //         })
    //         .catch(err => {
    //             console.error(err)
    //         })

    // }

    // Изменение своего аттрибута
    const fetchChangeYourAttr = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/change_attr_new`
        } else {
            url = `${RavshanHttps}/api/crm/change_attr_new`
        }

        const body = JSON.stringify({
            table_id: 0,
            datatype: 0,
            title: '',
            required: true,
            is_dict: true,
            attr_id: 0
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Удаление атрибута
    const fetchDeleteAttr = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/delete_attr`
        } else {
            url = `${RavshanHttps}/api/crm/delete_attr`
        }

        const body = JSON.stringify({
            attr_id: 0
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    //Добавление значения для аттрибута, если он is_dict
    const fetchAddValueAttr = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/add_attr_values`
        } else {
            url = `${RavshanHttps}/api/crm/add_attr_values`
        }

        const body = JSON.stringify({
            table_attr_id: 0,
            values: [
                "string"
            ]
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    //изменение значения для аттрибута, если он is_dict
    const fetchChangeValueAttr = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/change_attr_value`
        } else {
            url = `${RavshanHttps}/api/crm/change_attr_value`
        }

        const body = JSON.stringify({
            table_attr_id: 0,
            values: [
                "string"
            ]
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    //Удаление значения для аттрибута
    const fetchDeleteValueAttr = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/delete_attr_value`
        } else {
            url = `${RavshanHttps}/api/crm/delete_attr_value`
        }

        const body = JSON.stringify({
            value_id: 0
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    useEffect(() => {
        fetchTableTypes()
        fetchCommonAttributes()
        fetchUncommonAttributesTypes()
    }, [])

    const handleClose = () => {
        close()
        setSelectedTableType('')
    }

    const handleDeleteSelectedAttr = (idx) => {
        setSelectedAttributes(it => it.filter((_, i) => i !== idx))
    }

    const onChangeTitle = (e, idx) => {
        const obj_values = {
            ...objValuesAttr,
            [idx]: {
                name: e
            }
        }
        setObjValuesAttr(obj_values)
    }


    const onChangeId = (e, idx) => {
        let attr_values
        if(objValuesAttr[idx]) {
            attr_values = objValuesAttr[idx]?.attr_values  
        }
        const obj_values = {
            ...objValuesAttr,
            [idx]: {
                title: e,
                attr_values: attr_values
            }
        }
        setObjValuesAttr(obj_values)
    }

    const onHandlerAttr = () => {
        const arr_common = []
        const your_common = []
        //console.log(objValuesAttr)
        selectedAttributes.map((el, ind) => {
            const el_obj = Object.assign(el)

            if (el.common === true) {
                arr_common.push({ 
                    title: objValuesAttr[ind]?.title, 
                    required: false, 
                    common_id: el.id, 
                    // id: undefined, 
                    // datatype: undefined, 
                    // common: undefined, 
                    // type_id: undefined, 
                    // type_name: undefined, 
                    is_dict: el?.is_dict ? el?.is_dict : false,
                    attr_values: objValuesAttr[ind]?.attr_values
                 })
            }

            if (el.common === false) {
                your_common.push({ 
                    title: objValuesAttr[ind]?.title, 
                    required: false, 
                    datatype: el.id, 
                    // id: undefined, 
                    is_dict: el?.is_dict ? el?.is_dict : false , 
                    // common: undefined,
                    attr_values: objValuesAttr[ind]?.attr_values
                 })
            }

        })

        console.log(arr_common, your_common)

        fetchCreateTable(arr_common.length > 0 ? arr_common : null, your_common.length > 0 ? your_common : null)
    }

    // console.log(objValuesAttr)
    // console.log(selectedAttributes)

    const deleteTitle = (idx) => {
        const delete_obj = objValuesAttr
        delete_obj[idx] = false
        const new_obj = {}
        let count = 0
        for (let key in delete_obj) {
            if (delete_obj[key]) {
                new_obj[new String(count)] = delete_obj[key]
                count++
            }
        }
        setObjValuesAttr(new_obj)
    }

    if (!isOpen) return null;

    return (
        <div className="modal-crm" onClick={handleClose}>
            <div className="modal-crm__main-content"
                onClick={e => e.stopPropagation()}>

                <div className="modal-crm__header" >
                    <h1 className='modal-crm__title'>Создание новой таблицы</h1>
                    <button className='modal-crm__btn-close'
                        onClick={handleClose}
                    >
                        <img src={close_icon} />
                    </button>
                </div>

                <div className="modal-crm__input-group-main">
                    <InputDinamycPlaceholder
                        onChange={(e) => setTitle(e)}
                        type="text"
                        classname='table_title_input'
                        placeholder='Название таблицы'
                        autoComplete={false}
                        size='s'
                    />

                    <DropDownSelector
                        options_prop={tableTypes}
                        state={selectedTableType}
                        setState={e => setSelectedTableType(e)}
                        placeholder='Тип таблицы'
                        width='100%'
                    />
                </div>

                <div className='modal-crm__btn-group-violet'>
                    {selectedTableType &&
                        <button
                            className='modal-crm__btn-violet'
                            onClick={() => setIsCommonAttrSubModalOpen(true)} >
                            Выбрать поля
                        </button>}

                    {selectedTableType &&
                        <button
                            className='modal-crm__btn-violet'
                            onClick={() => setIsUncommonAttrSubModalOpen(true)} >
                            Добавить поле
                        </button>}
                </div>

                <div className='modal-crm__content'>
                    {selectedAttributes && selectedAttributes.map((attr, idx) => {

                        if (attr?.attr_values || attr?.title == 'single_choice') {
                            return <div
                                key={attr.id}
                                className='modal-crm__item-parametr'>

                                <DropDownSelector
                                    options_prop={
                                        attr?.attr_values ?
                                            attr?.attr_values?.map(({ id, value }) => {
                                                return { label: value, value: id }
                                            })
                                            : []
                                    }
                                    state={[]}
                                    setState={e => (e)}
                                    placeholder={attr.title}
                                />

                                <InputDinamycPlaceholder
                                    size='s'
                                    key={attr.id}
                                    type='text'
                                    onChange={(e) => onChangeId(e, idx)}
                                    placeholder={'Название стандартного поля'}
                                />

                                <button className='modal-crm__btn-close'
                                    onClick={() => {
                                        deleteTitle(idx)
                                        //setObjValuesAttrList()
                                        handleDeleteSelectedAttr(idx)
                                    }}
                                ><img src={close_icon} /></button>


                                {attr?.is_dict &&
                                    <TooltipLightBlue
                                        top='24px'
                                        right='100%'
                                        position={'absolute'}
                                        text='Управлять списком'
                                        child={
                                            <button
                                                className='modal-crm__btn-settings'
                                                onClick={() => {
                                                    setCurrentIndex(idx)
                                                    setIsAttrControlModalOpen(true)
                                                }}
                                            >
                                                <img src={settings_icon} />
                                            </button>
                                        }
                                    />
                                }
                            </div>
                        } else {
                            return <div
                                key={attr.id}
                                className='modal-crm__item-parametr'>

                                <InputDinamycPlaceholder
                                    type="text"
                                    placeholder={attr.title}
                                    value={attr.title}
                                    onChange={(e) => onChangeTitle(e, idx)}
                                    style={{ maxWidth: '100px' }}
                                    size='s'
                                />
                                <InputDinamycPlaceholder
                                    size='s'
                                    key={attr.id}
                                    type="text"
                                    onChange={(e) => onChangeId(e, idx)}
                                    placeholder={'Название стандартного поля'}
                                />

                                <button className='modal-crm__btn-close'
                                    onClick={() => {
                                        deleteTitle(idx)
                                        handleDeleteSelectedAttr(idx)
                                    }}
                                ><img src={close_icon} /></button>


                                {attr?.is_dict &&
                                    <TooltipLightBlue
                                        top='24px'
                                        right='100%'
                                        position={'absolute'}
                                        text='Управлять списком'
                                        child={
                                            <button
                                                className='modal-crm__btn-settings'
                                                onClick={() => {
                                                    setCurrentIndex(idx)
                                                    setIsAttrControlModalOpen(true)
                                                    setSelectedAttributes([])
                                                }}
                                            >
                                                <img src={settings_icon} />
                                            </button>
                                        }
                                    />
                                }
                            </div>
                        }
                    })}
                </div>

                <div className='modal-crm__btn-group'>
                    <ButtonBasic
                        green
                        width='150px'
                        text={'Создать таблицу'}
                        onClick={e => {
                            //  fetchCreateTable()
                            onHandlerAttr()
                            // setSelectedAttributes([])
                            // setObjValuesAttr({})
                        }} />

                    <ButtonBasic
                        grey
                        width='auto'
                        text={'Отменить'}
                        onClick={e => (e)} />
                </div>

                <CrmCommonAttrSubModal
                    checkedAttr={checkedAttr2}
                    setCheckedAttr={(e) => setCheckedAttr2(e)}
                    commonAttributes={commonAttributes}
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    isOpen={isCommonAttrSubModalOpen}
                    setCurrentAtt={(e) => setCurrentAtt(e)}
                    close={() => setIsCommonAttrSubModalOpen(false)}
                />
                <CrmUncommonAttrSubModal
                    checkedAttr={checkedAttr1}
                    setCheckedAttr={(e) => setCheckedAttr1(e)}
                    uncommonAttrTypes={uncommonAttrTypes}
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    isOpen={isUncommonAttrSubModalOpen}
                    close={() => setIsUncommonAttrSubModalOpen(false)}
                />
                <CrmAttrControlModal
                    objValuesAttr={objValuesAttr}
                    setObjValuesAttr={(e) => setObjValuesAttr(e)}
                    currentIndex={currentIndex}
                    setCurrentIndex={(e) => setCurrentIndex(e)}
                    selectedTableType={selectedTableType}
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    isOpen={isAttrControlModalOpen}
                    close={() => setIsAttrControlModalOpen(false)}
                />
            </div>
        </div>
    );
};
