import { Link } from 'react-router-dom';
import { user_light_grey } from '../../common/svg_img';
import { ReactComponent as Time } from './img/time.svg'
import "./TaskItemAdmin.scss"

const TaskItemAdmin = ({text, time, number}) => {

    const colorGreen =''

    return (
        <div className='task-item'>
            <button className='btn__points'></button>
            <p className='text'>{text}</p>
            <div className='number-of-cards'>
                <p className='text_grey'>Кол-во карточек:</p>
                <p className='text_number'>{number}</p>
            </div>
            <div className="notes-box">
                <div className="term-box">
                    <Time className='time-svg'/>
                    <p className='text'>{`Срок исполнения ${time}`}</p>
                </div>
            </div>
        </div>
    )
}

export { TaskItemAdmin }