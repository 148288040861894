import customHeaders, { getSpace } from '../../common/headers';
import add_icon from './img/add_icon.svg';
import rules_tab from './img/rules_tab.svg';
import percent_tab from './img/percent_tab.svg';
import refresh_icon from './img/refresh_icon.svg';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { ModalPromotionRules } from '../Modal/ModalPromotionRules/ModalPromotionRules';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
  fetchMasterBrandFilter,
  fetchMasterCategoryFilter,
} from '../../common/filtersFunc';
import {
  DropDownSelector,
  SearchComponent,
  Tabs,
  ButtonBasic,
  TableBox,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import {
  KonstantinCreateProductHttps,
  KonstantinMasterHttp,
  KonstantinMasterHttps,
} from '../../fetchUrls';
import './SetPriceAndMarginMasterProduct.scss';
import useGeneralStore from '../../store/general';
const btn_tab = [
  {
    label: 'Установка цен',
    value: 'price_setting',
    icon: percent_tab,
  },
  {
    label: 'Правила установки цен',
    value: 'rules',
    icon: rules_tab,
  },
];

const SetPriceAndMarginMasterProduct = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState('product');

  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [productPage, setProductPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [productQuery, setProductQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { loading, sort } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const [checkZero, setCheckZero] = useState(false);

  const [isModal, setIsModal] = useState(false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [dateFrom, dateTo] = date;

  const activeTab = useParams()['*'];
  const navigate = useNavigate();

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchMasterCategoryFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
    return () => abortController.abort();
  }, [
    selectedSpace,
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery,
    selectedBrand,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchMasterBrandFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
    return () => abortController.abort();
  }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery]);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/tables/prices${
        sort?.length > 0
          ? '?order_param=' +
            sort[0] +
            '&order_param_direction=' +
            sort[1] +
            '&without_zero_stock=' +
            checkZero
          : '?without_zero_stock=' + checkZero
      }`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/tables/prices${
        sort?.length > 0
          ? '?order_param=' +
            sort[0] +
            '&order_param_direction=' +
            sort[1] +
            '&without_zero_stock=' +
            checkZero
          : '?without_zero_stock=' + checkZero
      }`;
    }

    let brand_id_list;
    let category_id_list;
    let offer_id_name_search = searchValue;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam,
      offer_id_name_search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`;
    }

    let brand_id_list;
    let category_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setSearchData(json.map(({ id, name }) => ({ label: name, value: id })));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchUpdatePrices = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    } else {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    }

    const prices = checkedProducts?.map((el) => {
      const { product_id, Артикул, Цена } = el;
      let old_price = +el?.['Цена до скидки'];

      return {
        product_id,
        offer_id: Артикул,
        price: Цена,
        old_price,
        currency: 'RUB',
      };
    });

    const body = JSON.stringify({
      prices,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        const abortController = new AbortController();
        fetchTableData([], abortController);
        return () => abortController.abort();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchOnePrice = (productId, articul, newPrice, oldPrice) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    } else {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    }

    const prices = [
      {
        product_id: productId,
        offer_id: articul,
        price: newPrice,
        old_price: oldPrice,
        currency: 'RUB',
      },
    ];

    const body = JSON.stringify({
      prices,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        const abortController = new AbortController();
        fetchTableData([], abortController);
        return () => abortController.abort();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSaveOnePrice = (e) => {
    const { inputEvent, actionEvent } = e;
    const offerId = inputEvent.id?.[0];
    const productId = actionEvent?.[0]?.product_id;
    const colName = actionEvent?.[2];
    let newPrice, oldPrice;

    const extractPrice = (obj, colName) => {
      const price =
        +obj?.[colName === 'Цена' ? 'Цена до скидки' : colName]?.[0];
      return price ? price : 0;
    };

    if (colName === 'Цена') {
      newPrice = +inputEvent?.value;
      oldPrice = extractPrice(actionEvent?.[0], colName);
    } else {
      newPrice = extractPrice(actionEvent?.[0], colName);
      oldPrice = +inputEvent?.value;
    }

    console.log(newPrice, oldPrice);

    if ((newPrice || newPrice === 0) && (oldPrice || oldPrice === 0)) {
      fetchOnePrice(productId, offerId, newPrice, oldPrice);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    resetTable();
    fetchTableData([], abortController);

    return () => abortController.abort();
  }, [selectedSpace, selectedBrand, selectedCategory, checkZero]);

  const handleSearch = useCallback((e) => {
    const abortController = new AbortController();
    fetchSearch(e, abortController);
    return () => abortController.abort();
  });

  return (
    <>
      {isModal && <ModalPromotionRules setIsModal={(e) => setIsModal(e)} />}

      <div className="set-price-and-margin-master-product">
        <h1 className="title_main">
          {pageValue == 'rules'
            ? 'Цены мастер товаров'
            : 'Установка цен мастер товаров'}
        </h1>

        <div className="toolbar-top">
          <FilterContainer
            onReset={(e) => {
              setSelectedProduct([]);
              setSelectedCategory([]);
              setSelectedBrand([]);
            }}
          >
            <DropDownSelector
              options_prop={categoryData}
              state={selectedCategory}
              setState={(e) => setSelectedCategory(e)}
              placeholder="Категория"
              multi={true}
              fetchCallback={(e) => setCategoryPage(e)}
              setInputChange={(e) => setCategoryQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
            />

            <DropDownSelector
              options_prop={brandData}
              state={selectedBrand}
              setState={(e) => setSelectedBrand(e)}
              placeholder="Бренд"
              multi={true}
              fetchCallback={(e) => setBrandPage(e)}
              setInputChange={(e) => setBrandQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
            />

            {pageValue == 'rules' && (
              <DropDownSelector
                options_prop={productData}
                state={selectedProduct}
                setState={(e) => setSelectedProduct(e)}
                className="connections_page_selector"
                placeholder="Бренд"
                fetchCallback={(e) => setProductPage(e)}
                setInputChange={(e) => setProductQuery(e)}
                multi
              />
            )}
            {pageValue != 'rules' && (
              <SearchComponent
                searchValue={searchValue}
                errSearch={false}
                placeholder="Искать по артикулу или названию"
                errText="Неверный артикул или название"
                setSearchValue={setSearchValue}
                fetchData={fetchTableData}
                fetchCallback={handleSearch}
                data={searchData}
              />
            )}
            <Checkbox value={checkZero} onChange={(e) => setCheckZero(e)}>
              Без нулевого остатка
            </Checkbox>
          </FilterContainer>

          <ButtonBasic
            violet
            // disabled={isDisabledBtn}
            text={
              pageValue != 'rules' ? (
                <div className="content-btn" onClick={fetchUpdatePrices}>
                  Обновить цены
                  <img src={refresh_icon} />
                </div>
              ) : (
                <div className="content-btn">
                  Добавить правило <img src={add_icon} />
                </div>
              )
            }
            onClick={(e) => {
              if (pageValue == 'rules') {
                setIsModal(true);
              }
            }}
            width="auto"
            size="40px"
          />
        </div>

        <div className="toolbar-medium">
          <Tabs tabs={btn_tab} pageValue={pageValue} />
        </div>

        <TableBox
          {...tableProps}
          paginator={true}
          ref={paginatorRef}
          onCheck={(e) => setCheckedProducts(e)}
          fetchCallback={fetchTableData}
          tableUrl={tableUrl}
          tableBody={tableBody}
          onInputAction={(e) => handleSaveOnePrice(e)}
        />
      </div>
    </>
  );
};

export { SetPriceAndMarginMasterProduct };
