import React, { useState } from 'react';
import './CrmSubModal.scss';
import close_icon from './img/close_icon.svg'
import { Checkbox, ButtonBasic, InputSearch } from '@lk-gtcom/ecomlab-components'

export const CrmCommonAttrSubModal = (props) => {
    const { isOpen, close, commonAttributes, setSelectedAttributes, selectedAttributes } = props;

    const [checkedAttr, setCheckedAttr] = useState([])

    const onCheckboxChange = (id) => {
        if (checkedAttr.includes(id)) {
            setCheckedAttr(checkedAttr.filter(item => item !== id));
        } else {
            setCheckedAttr([...checkedAttr, id]);
        }
    };

    const onHandleClose = () => {
        close();
    }

    const onHandleSubmit = () => {
        const selectedCommonAttr = commonAttributes.filter(attr => checkedAttr.includes(attr.id));
        const current = selectedCommonAttr.map((item) => ({...item, common : true}))
        setSelectedAttributes(it => [...it, ...current]);
        setCheckedAttr([]);
        close();
    }

    if (!isOpen) return null;

    return (
        <div className="modal-crm-sub-modal" onClick={onHandleClose}>
            <div className="modal-crm-sub-modal__main-content"
                onClick={e => e.stopPropagation()}>

                <div className="modal-crm-sub-modal__header" >
                    <h2 className="modal-crm-sub-modal__title">Выбор стандартного поля</h2>
                    <button className='modal-crm__btn-close'
                        onClick={() => close()}
                    >
                        <img src={close_icon} />
                    </button>
                </div>

                <InputSearch />

                <div>
                    <h2 className='modal-crm-sub-modal__title'></h2>
                    <div className='modal-crm-sub-modal__checkbox-group'>
                        {commonAttributes.map(attr => (
                            <label className={checkedAttr.includes(attr.id) ? 'modal-crm-sub-modal__label_checked' : 'modal-crm-sub-modal__label'}>
                                <Checkbox
                                    key={attr.id}
                                    value={checkedAttr.includes(attr.id)}
                                    onChange={() => onCheckboxChange(attr.id)}
                                />
                                <span className='modal-crm-sub-modal__label-text'>{attr.title}</span>
                            </label>
                        ))}
                    </div>
                </div>


                <div className='modal-crm-sub-modal__btn-group'>
                    <ButtonBasic
                        green
                        width='110px'
                        text={'Выбрать'}
                        onClick={onHandleSubmit}
                    ></ButtonBasic>
                    <ButtonBasic
                        grey
                        width='110px'
                        text={'Отмена'}
                        onClick={onHandleClose}
                    ></ButtonBasic>
                </div>
            </div>
        </div>
    );
};
