import warning from './img/warning_icon.svg'
import './ModalExitConfirmations.scss'

const ModalExitConfirmations = ({ setIsModal }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-exit-confirmations'>
                <img src={warning} />
                <h2 className='title'>Отменить создание кампании без сохранения?</h2>
                <div className='btn-group'>
                    <button className='btn__red'>Отменить</button>
                    <button className='btn__white-blue'
                        onClick={(e) => setIsModal(false)}
                    >Продолжить</button>
                </div>
            </div>
        </div>
    );
}

export { ModalExitConfirmations };