import { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { InputSearch } from "@lk-gtcom/ecomlab-components";
import './ModalAddProduct.scss'

const ModalAddProduct = ({ setIsModal }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-add-product'>
                <h2 className='title'>Добавление товара</h2>
                <div className='top-panel'>
                    <ButtonBasic
                                               white
                        text='Выбрать все товары'
                        width='157px'
                        minWidth='157px'
                        size='40px'
                    />
                    <InputSearch
                        placeholder='Введите артикул или название товара' />
                </div>
                <TableBox
                    hideColumn
                    hideExport
                    loading={null}
                    fetchedData={[]}
                    headers={[]}
                    paginator={false}
                />
            </div>
        </div>
    )
}

export { ModalAddProduct }