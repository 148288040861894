import { Link } from 'react-router-dom';
import "./ModalsCompetitors.scss"
import { done } from '../../../common/svg_img';

const ModalAddLinks = ({ setShowModal, status }) => {

    return (
        <div className='modal-box'>
            <div className='modal-add-links'>
                <CompetitorsNotificationModal
                    status={status}
                    itemNameNominative='ссылки'
                    itemNameGenitive='ссылок'
                    linkName='Ссылки конкурентов'
                    linkTo='#'
                />

                <img src={done} className='icon_done-round'></img>
                <button className='btn__blue-modal' onClick={() => setShowModal(false)}>Перейти →</button>
            </div>
        </div>

    )
}

const ModalAddCategories = ({ setShowModal, status }) => {

    return (
        <div className='modal-box'>
            <div className='modal-add-links'>
                <CompetitorsNotificationModal
                    status={status}
                    itemNameNominative='категории'
                    itemNameGenitive='категорий'
                    linkName='Список категорий'
                    linkTo='#'
                />

                <img src={done} className='icon_done-round'></img>
                <button className='btn__blue-modal' onClick={() => setShowModal(false)}>Перейти →</button>
            </div>
        </div>

    )
}

const ModalAddBrands = ({ setShowModal, status }) => {

    return (
        <div className='modal-box'>
            <div className='modal-add-links'>
                <CompetitorsNotificationModal
                    status={status}
                    itemNameNominative='бренды'
                    itemNameGenitive='брендов'
                    linkName='Список брендов'
                    linkTo='#'
                />

                <img src={done} className='icon_done-round'></img>
                <button className='btn__blue-modal' onClick={() => setShowModal(false)}>Перейти →</button>
            </div>
        </div>

    )
}

const ModalAddKeywords = ({ setShowModal, status }) => {

    return (
        <div className='modal-box'>
            <div className='modal-add-links'>
                <CompetitorsNotificationModal
                    status={status}
                    itemNameNominative='ключевые слова'
                    itemNameGenitive='ключевых слов'
                    linkName='Список ключевых слов'
                    linkTo='#'
                />
                <img src={done} className='icon_done-round'></img>
                <button className='btn__blue-modal' onClick={() => setShowModal(false)}>Перейти →</button>
            </div>
        </div>

    )
}

const CompetitorsNotificationModal = ({ status, itemNameNominative, itemNameGenitive, linkName, linkTo }) => {
    return ( 
            // any 200 status code
            status?.toString()[0] === '2'
            ?
                <div style={{paddingLeft: '40px', paddingRight: '40px', paddingBottom: '52px'}}>
                    <h2 className='title'>Спасибо, {itemNameNominative} добавлены!</h2>
                    <p className='text'>Посмотреть список {itemNameGenitive} можно перейдя в разделе Просмотреть - <Link className='link__blue' to={linkTo}>{linkName}</Link></p>
                </div>
            :
                <div style={{paddingLeft: '40px', paddingRight: '40px', paddingBottom: '52px'}}>
                    <h2 className='title'>Что-то пошло не так</h2>
                    <p className='text'>Посмотреть список {itemNameGenitive} можно перейдя в разделе Просмотреть - <Link className='link__blue' to={linkTo}>{linkName}</Link></p>
                </div>
    )
} 

export { ModalAddLinks, ModalAddCategories, ModalAddBrands, ModalAddKeywords }