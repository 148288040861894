import {useState, useEffect} from 'react'
import { RavshanHttps } from '../../fetchUrls'
import customHeaders, {getSpace} from '../../common/headers'
import { useNavigate } from 'react-router-dom'
import { DropDownSelector, InputDinamycPlaceholder, Checkbox, Tabs, ButtonBasic } from '@lk-gtcom/ecomlab-components'
import useGeneralStore from '../../store/general'
import './CrmCreateTable.scss'

const tabs = [
    {
        label: 'Готовые',
        value: 'common'
    },
    {
        label: 'Пользовательские',
        value: 'user'
    },
]

const CrmCreateTable = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [tableTypes, setTableTypes] = useState([])
    const [selectedTableType, setSelectedTableType] = useState()
    const [pageValue, setPageValue] = useState(tabs[0]?.value)
    const [tableId, setTableId] = useState(-1)

    const [selectedAttr, setSelectedAttr] = useState([])
    const [selectedAttrInfo, setSelectedAttrInfo] = useState({})
    const [isAttrRequired, setIsAttrRequired] = useState(false)

    const [attributes, setAttributes] = useState([])
    const [addedAttributes, setAddedAttributes] = useState([])
    const [isChangeListModal, setIsChangeListModal] = useState(false)

    useEffect(() => {
        const info = attributes?.filter(el => el?.id === selectedAttr?.value)
        setSelectedAttrInfo(...info)
        if(info?.length < 0){
            setIsAttrRequired(false)
        }
    }, [selectedAttr, attributes])

    const fetchCreateTable = () => {
        if(title && selectedTableType?.value){
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${RavshanHttps}/api/crm/create_table`
            } else {
                url = `${RavshanHttps}/api/crm/create_table`
            }
    
            const body = JSON.stringify({
                portal_id: 4,
                title,
                table_type_id: selectedTableType?.value
            })
            fetch(url, {body, method: 'POST', headers })
                .then(res => {   
                    if(res.ok){
                        return res.json()
                    } else {
                        throw new Error(res.json())
                    }
                })
                .then(json => {
                    setTableId(json)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    const fetchTableTypes = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_table_types`
        } else {
            url = `${RavshanHttps}/api/crm/get_table_types`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {   
                if(res.ok){
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                setTableTypes(json?.map(el => ({label: el?.title, value: el?.id})))
            })
            .catch(err => {
                console.error(err)
            })

    }

    const fetchCommonAttributes = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_common_attr`
        } else {
            url = `${RavshanHttps}/api/crm/get_common_attr`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if(res.ok){
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
               setAttributes(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    const fetchAddCommonAttr = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/add_attr_common`
        } else {
            url = `${RavshanHttps}/api/crm/add_attr_common`
        }

        const body = JSON.stringify({
            table_id: tableId,
            common_id: selectedAttrInfo?.id,
            title: selectedAttrInfo?.title,
            required: isAttrRequired
        })


        fetch(url, {body, method: 'POST', headers })
            .then(res => {
                if(res.ok){
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
               alert(json)
               fetchTablesAttributes()
            })
            .catch(err => {
                console.error(err)
            })

    }

    const fetchTablesAttributes = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_tables_attr`
        } else {
            url = `${RavshanHttps}/api/crm/get_tables_attr`
        }

        fetch(url, { method: 'GET', headers:{...headers, 'table-id': tableId} })
            .then(res => {
                if(res.ok){
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                setAddedAttributes(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    useEffect(() => {
        fetchTableTypes()

        if(pageValue === 'common'){
            fetchCommonAttributes()
        } else {
            
        }
    }, [])


  return (
    <div style={{width: '100%'}}>
        <div className='blackout' style={{display: isChangeListModal ? 'block' : 'none', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,.3)'}}>
            <div className='modal'>

            </div>
        </div>
        <div 
            style={{marginBottom: '1em', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}} 
            className='create_table_header'
        >
            <h2>CRM создание таблицы</h2>
            <ButtonBasic text={'К списку таблиц'} onClick={() => navigate('../table-list')}/>
        </div>
        <div 
            style={{backgroundColor: '#ebebeb', width: '98%', height: '100%', borderRadius: '1em', boxSizing: 'content-box', padding: '1em'}} 
            className='create_table_area'
        > 
            <div className='table_info_box' style={{display: 'flex', width: '100%', alignItems: 'flex-end', gap: '1em', marginBottom: '2em'}}>
                <DropDownSelector 
                    options_prop={tableTypes}
                    state={selectedTableType}
                    setState={e => setSelectedTableType(e)}
                    placeholder='Тип таблицы'
                    style={{minHeight: '56px'}}
                />
                <div className='table_title_box' style={{width: '30%'}}>
                    <InputDinamycPlaceholder 
                        onChange={(e) => setTitle(e)}
                        type="text"
                        textErr={'Неверный пароль'}
                        classname='table_title_input'
                        placeholder='Название таблицы'
                        autoComplete={false}
                    />
                </div>
                <ButtonBasic text={'Создать'} style={{height: '55px'}} onClick={e => fetchCreateTable()}/>
            </div>
            <div className='table_attr_box' >
                
                <div style={{display: 'flex', flexDirection: 'column', gap: '1em', flexWrap: 'wrap'}}>
                    {
                        (tableId >= 0 & Array.isArray(attributes)) ?
                            <>
                                <div className='table_title_box' style={{width: '35%', marginTop: '2em'}} >
                                    <div style={{backgroundColor: '#50aaff', color: 'white', borderRadius: '1em', padding: '1em', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: '1em'}}>
                                    {
                                            addedAttributes?.length > 0 &&
                                                <>
                                                    <span>Добавленные атрибуты</span>
                                                    {
                                                        addedAttributes?.map((el, ind) => {
                                                            let {id, title, is_dict, type_name, type_id, attr_values, required} = el
                                                            return(
                                                                <div className='table_title_box' style={{width: '100%', marginTop: 0}} key={id} >
                                                                    {
                                                                        type_name === 'string' ? 
                                                                            <div style={{display: 'flex', gap: '1em', alignItems: 'center'}}>
                                                                                <div style={{width: '100%'}}> 
                                                                                    <InputDinamycPlaceholder 
                                                                                        onChange={(e) => {}}
                                                                                        type="text"
                                                                                        textErr={'Неверный пароль'}
                                                                                        classname='table_title_input'
                                                                                        placeholder={title}
                                                                                        autoComplete={false}
                                                                                        info={required ? 'Поле обязательное к заполнению' : ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        :
                                                                            <div style={{display: 'flex', gap: '1em', alignItems: 'center'}}>
                                                                                <div style={{width: '100%'}}> 
                                                                                    <DropDownSelector 
                                                                                        options_prop={attr_values?.map(el => ({label: el?.value, value: el?.id}))}
                                                                                        // state={selectedTableType}
                                                                                        // setState={e => setSelectedTableType(e)}
                                                                                        placeholder={title}
                                                                                        style={{minHeight: '56px', marginTop: ind !== 0 ? '.5em' : 0, width: '100%', maxWidth: '100%'}}
                                                                                        multi={is_dict}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                        }

                                        <span style={addedAttributes?.length > 0 ? {marginTop: '2em'} : {}}>Добавить атрибут</span>
                                        <div style={{display: 'flex'}}>
                                            {tableId >= 0 && 
                                            <Tabs 
                                                tabs={tabs} 
                                                pageValue={pageValue} setPageValue={e => setPageValue(e)} 
                                                style={{ color: 'white' }} choosedStyle={{ color: '#1d00ff', borderColor: '#1d00ff', fontWeight: 900 }}
                                            />}
                                        </div>
                                        <div className="add_attr_container" style={{width: '100%'}}>
                                            <DropDownSelector
                                                options_prop={attributes.map(el => ({label: el?.title, value: el?.id}))} 
                                                state={selectedAttr}
                                                setState={e => setSelectedAttr(e)}
                                                style={{width: '100%', maxWidth: 'unset'}} 
                                                placeholder='Выберите атрибут'
                                            />
                                        </div>
                                        {
                                            selectedAttrInfo?.id &&
                                            <>
                                                <div style={{display: 'flex', gap: '1em'}}>
                                                    <p>Тип: {selectedAttrInfo?.type_name}</p>
                                                    <span><Checkbox value={isAttrRequired} onChange={e => setIsAttrRequired(e)} /> Обязательный к заполнению</span>
                                                </div>
                                                {
                                                    selectedAttrInfo?.is_dict &&
                                                        <div style={{position: 'relative', backgroundColor: 'white', color: 'black', padding: '1em', borderRadius: '.5em', boxSizing: 'content-box'}}>
                                                            <p>Список: {selectedAttrInfo?.title}</p>
                                                            <span 
                                                                style={{color: '#1890FF', position: 'absolute', top: '1em', right: '1em', userSelect: 'none', cursor: 'pointer'}}
                                                                // onClick={e => setIsChangeListModal(true)}
                                                            >
                                                                Изменить
                                                            </span>
                                                            <div style={{marginTop: '.5em', backgroundColor: '#e7e7e7', borderRadius: '1em', padding: '.5em 1em', borderRadius: '.5em',  paddingLeft: '1em', display: 'flex', flexWrap: 'wrap', gap: '.5em'}}>
                                                                {selectedAttrInfo?.attr_values?.map((el, ind) => <span>{el?.value}{ind !== selectedAttrInfo?.attr_values?.length -1 && ','}</span>)}
                                                            </div>
                                                        </div>
                                                }

                                                <span 
                                                    style={{
                                                        backgroundColor: '#0266c5', padding: '1em', textAlign: 'center', fontSize: '16px', 
                                                        boxSizing: 'border-box', borderRadius: '1em', cursor: 'pointer'
                                                    }} 
                                                    onClick={() => fetchAddCommonAttr()}
                                                > 
                                                    Добавить поле
                                                </span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        :
                            null
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export {CrmCreateTable}