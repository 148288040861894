import customHeaders, {getSpace} from '../../common/headers';
import { Editor } from '../Editor/Editor';
import { RavshanHttps } from '../../fetchUrls';
import { useState, useEffect, useRef } from 'react';
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor';
import useGeneralStore from '../../store/general';
import "./Comment.scss"



const Comment = ({ name, email, event_id, color, fetchUpdateComms = false, commentValue = false, changeCommentValue, disabled }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [comment, setComment] = useState(commentValue ? commentValue : '')
    const editorRef = useRef()
    const fetchCreateComment = () => {
        const url = `${RavshanHttps}/api/tasks/create_comment`
        const body = JSON.stringify({
            text: comment,
            event_id
        })

        fetch(url, { body, method: 'POST', headers: headers })
            .then(res => {
                if (res.ok) {
                    setComment('')
                    editorRef?.current?.reset()
                    if (fetchUpdateComms) setTimeout(() => fetchUpdateComms(), 300)
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .catch((e) => console.error(e))
    }

    useEffect(() => {
        if (comment?.length > 0) {
            if (commentValue) {
                if (changeCommentValue) {
                    changeCommentValue(comment)
                }
                return
            }
            fetchCreateComment()
        }
    }, [comment])

    return (
        <div className={'comment'} >
            {/* <div className='comment__executor-content'>
                <ExecutorPlaceholder name={name} email={email} color={color} />
            </div> */}
            <div className='comment__content'>
                <Editor
                    disabled={disabled}
                    comment
                    name={name}
                    bounds='.main-info'
                    title={null}
                    placeholder='Оставьте комментарий'
                    description={comment}
                    hideModules
                    saveOnBtn={!commentValue}
                    setDescription={e => setComment(e)}
                    editValue={commentValue}
                    ref={editorRef}
                />
            </div>

        </div>
    )
}

export { Comment }