import { useEffect } from 'react'
import red_icon from './img/red_icon.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalDeleteCol.scss'

const ModalDeleteCol = ({ setIsModal, listName, deleteTask }) => {

    return (
        <div className='blackout'  onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-delete-col'>
                <img src={red_icon} />
                <p className='text'>{`Удалить колонку “${listName}”?`}</p>
                <div className='btn-group'>
                    <ButtonBasic
                        red
                        text='Удалить'
                        size='56px'
                        width='calc(50% - 6px)'
                        onClick={(e) => {
                            deleteTask()
                            setIsModal(false)
                        }}
                    />
                    <ButtonBasic
                        whiteBlue
                        text='Отмена'
                        size='56px'
                        width='calc(50% - 6px)'
                        onClick={(e) => setIsModal(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalDeleteCol }