import { useEffect, useState } from 'react';
import { ReactComponent as StarIcon } from './img/Star.svg'
// import { Rating } from '@mui/material';
// import StarIcon from '@mui/icons-material/Star'
import "./SelectedRatingMulti.scss"

const SelectedRatingMulti = ({ value = 5, onChange = false }) => {

    const [selectedRating, setSelectedRating] = useState([])

    useEffect(() => {
        setSelectedRating(value)
     }, [value]);

    return (
        <div className='selected-rating-multi'>
            {[...Array(5)].map((el, ind) => {
                return <button
                    className={selectedRating.includes(ind + 1) ? 'selected-rating-multi__item_active' : 'selected-rating-multi__item'}
                    key={ind}
                    onClick={(e) => {
                        let index = selectedRating.indexOf(ind + 1)
                        if (index === -1) {
                            onChange([...selectedRating, ind + 1])
                        } else {
                            let new_rating = selectedRating.filter((el) => el != ind + 1)
                            onChange(new_rating)
                        }
                    }}
                >
                    <span className='selected-rating-multi__num'>{ind + 1}</span>
                    <StarIcon
                        key={ind}
                    />
                </button>

            })}
        </div>
    )
}

export { SelectedRatingMulti }