import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./DownloadHistory.scss"
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { download } from '../../common/svg_img'; // нет иконки


const DownloadHistory = () => {

    return (
        <div className='download-history'>
            <h3 className='title_main'>История загрузок</h3>

            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                paginator={false}
            />
        </div>
    )
}

export { DownloadHistory }