export const ACTION_TYPES = {
    setAccountId: 'set account id',
    setName: 'set name',
    setServiceData: 'set service data'
}

export const setAccountId = id => ({
    type: ACTION_TYPES.setAccountId,
    payload: id
})

export const setName = name => ({
    type: ACTION_TYPES.setName,
    payload: name
})

export const setServiceData = data => ({
    type: ACTION_TYPES.setServiceData,
    payload: data
})

export const defaultState = {
    accountId: 0,
    name: "",
    serviceData: []
}

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.setAccountId:
            return { ...state, accountId: action.payload }
        case ACTION_TYPES.setName:
            return { ...state, name: action.payload }
        case ACTION_TYPES.setServiceData:
            return { ...state, serviceData: action.payload }
        default:
            return defaultState
    }
}

export default reducer