'use client'
import { ButtonBasic, InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components'
import close_icon from './img/close.svg'
import { useState } from 'react'
import "./ModalAddChatBot.scss"

const ModalAddChatBot = ({ setIsModal }) => {

    const [name, setName] = useState('')

    return (
        <div className={'blackout'} onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-add-chat-bot'>
                <header className='modal-add-chat-bot__header'>
                    <h1 className='modal-add-chat-bot__title'>Создание чат бота</h1>
                    <button className='modal-add-chat-bot__btn-close'
                        onClick={(e) => {
                            setIsModal(false)
                        }}
                    >
                        <img src={close_icon} />
                    </button>
                </header>
                <InputDinamycPlaceholder
                    placeholder='Введите название бота'
                    onChange={e => setName(e)}
                />
                <ButtonBasic
                    green
                    width='100%'
                    text='Сохранить'
                />
            </div>
        </div>
    )
}

export { ModalAddChatBot }