import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import done from './img/done.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalSuccessful.scss'

const ModalSuccessful = ({ setIsModal, title, description = false }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-successful'>
                <h2 className='title'>{title}</h2>
                {description && <p className='text'>{description}</p>}
                <img src={done} />
                <ButtonBasic
                   blue
                    text='Ok'
                    width='100%'
                    size='56px'
                    onClick={(e) => setIsModal(false)}
                />
            </div>
        </div>
    )
}

export { ModalSuccessful }