import not_img from './img/not_img.svg'
import trash_icon from './img/trash_icon.svg'
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import "./CardVariantProduct.scss"

const CardVariantProduct = ({...props}) => {
    const {
        product_id, 
        name, 
        offer_id,
        deleteProduct, 
        setCurrentProductId, 
        currentProductId,
    } = props
    let img

    
    return (
        <div className='card-variant-product' 
            style={currentProductId === product_id ? {border: '1px solid #3900B1'} : {}} 
            onClick={e => setCurrentProductId({name, product_id})}
        >
            <div className='card-variant-product__img-box'>
                <img className={img ? 'img' : 'not_img'} src={img ? img : not_img} />
            </div>
            <div className='card-variant-product__content'>
                <p className='text_article'>
                    { offer_id ? offer_id : 'Не указано' }
                </p>
                <h5 className='title_product'>
                    { name ? name : 'Нет названия' }
                </h5>
            </div>
            <TooltipLightBlue
                child={
                <button className='btn-delete' 
                    onClick={e => {
                        e.stopPropagation()
                        deleteProduct(product_id)
                    }}
                >
                    <img src={trash_icon}/>
                </button>}
                text='Удалить товар'
                top='100%'
                right='0px'
            />
        </div>
    )
}

export { CardVariantProduct }