import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./TaskListAdmin.scss"
import { DropDownSelector } from "@lk-gtcom/ecomlab-components";
import { TaskItemAdmin } from '../../components/TaskItemAdmin/TaskItemAdmin';
import { ModalAddTask } from '../../components/Modal/ModalAddTask/ModalAddTask';

const TaskListAdmin = () => {

    const [isModal, setIsModal] = useState(false)

    const taskStop = [
        {
            text: 'Разработать контент для запуска новогодней акции',
            color: 'red',
            time: '4 апр 2023',
            user: ['Елена Налоева', 'Константин Носов']
        },
        {
            text: 'Отсортироовать товары для выявления товаров с низкой маржинальностью',
            color: 'yellow',
            time: '4 апр 2023',
            user: ['Елена Налоева', 'Юлия Федорец']
        },
        {
            text: 'Разработать контент для запуска новогодней акции ',
            color: 'green',
            time: '4 апр 2023',
            user: ['Юлия Федорец', 'Константин Носов']
        },
    ]

    const taskNew = [
        {
            text: 'Разработать контент для запуска новогодней акции',
            color: 'red',
            time: '4 апр 2023',
            user: ['Елена Налоева', 'Константин Носов']
        }
    ]

    const members = [
        { name: 'Юлия Федорец' },
        { name: 'Константин Носов' },
        { name: 'Елена Налоева' },
    ]

    return (
        <div className='task-list'>
            <h3 className='title'>Задачи</h3>      
            <div className='main-task-box'>
                <div className='task-list__content'>
                    <div className='col-task col-task_admin'>
                        <div className='col-task__header'>
                            <div className='col-task__grey'>
                                <h4 className='title'>НОВЫЕ ЗАДАЧИ:</h4>
                                <p className='sum-task'>2 ЗАДАЧИ</p>
                            </div>
                        </div>

                        <div className='task-box'>
                            <div className='btn-box'>
                                <button className='btn__add-green'  onClick={() => setIsModal(true)}></button>
                            </div>
                            {taskNew.map(({ text, time, user, color }) =>
                                < TaskItemAdmin
                                    text={text}
                                    number='4'
                                    time={time}
                                />
                            )}
                        </div>

                    </div>
                    <div className='col-task col-task_admin'>
                        <div className='col-task__header'>
                            <div className='col-task__grey'>
                                <h4 className='title'>В РАБОТЕ:</h4>
                                <p className='sum-task'>2 ЗАДАЧИ</p>
                            </div>
                        </div>
                        <div className='task-box'>
                             <div className='btn-box'>
                                <button className='btn__add-green'  onClick={() => setIsModal(true)}></button>
                            </div>
                            {taskStop.map(({ text, time, user, color }) =>
                                < TaskItemAdmin
                                text={text}
                                number='4'
                                time={time}
                                />
                            )}
                        </div>
                    </div>
                    <div className='col-task col-task_admin'>
                        <div className='col-task__header'>
                            <div className='col-task__grey'>
                                <h4 className='title'>ГОТОВО:</h4>
                                <p className='sum-task'>2 ЗАДАЧИ</p>
                            </div>
                        </div>
                        
                        <div className='task-box'>
                            <div className='btn-box'>
                                <button className='btn__add-green'  onClick={() => setIsModal(true)}></button>
                            </div>
                            {taskStop.map(({ text, time, user, color }) =>
                                < TaskItemAdmin
                                text={text}
                                number='4'
                                time={time}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { TaskListAdmin }