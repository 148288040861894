import customHeaders, {getSpace} from '../../common/headers'
import download_icon from './img/download_icon.svg'
import { Tabs } from "@lk-gtcom/ecomlab-components"
import { RavshanHttps } from '../../fetchUrls'
import { useEffect, useState } from 'react'
import useGeneralStore from '../../store/general'
import "./ProductImprovement_CreateRICHContent.scss"


const btn_tab = [
    {
        label: 'Wildberries',
        value: 'wb'
    },
    {
        label: 'OZON',
        value: 'ozon'
    }
]

const sizes = {
    size3: '16px',
    size5: '24px',
    size7: '38px',
}

const lineHeights = {
    size3: '20px',
    size5: '30px',
    size7: '46px',
}

const colors = {
    color1: 'black',
    color2: '#3d5165',
    color3: '#808d9a',
    color4: '#fff',
}

const template = JSON.stringify({
    "content": [
      {
        "widgetName": "raShowcase",
        "type": "chess",
        "blocks": [
          {
            "img": {
              "src": "https://cdn1.ozone.ru/s3/multimedia-1-x/6927610101.jpg",
              "srcMobile": "https://cdn1.ozone.ru/s3/multimedia-1-x/6927610101.jpg",
              "alt": "",
              "position": "to_the_edge",
              "positionMobile": "to_the_edge"
            },
            "imgLink": "",
            "title": {
              "content": [
                "НАЗВАНИЕ"
              ],
              "size": "size5",
              "align": "center",
              "color": "color1"
            },
            "text": {
              "size": "size7",
              "align": "center",
              "color": "color1",
              "content": [
                "Салфетки бумажные двухслойные 800 шт.(5 уп. *160 шт) Kami Shodji \"Piko\" Япония, Платочки сухие косметические носовые в коробке без ароматизаторов для всей семьи экологичные"
              ]
            },
            "reverse": true
          }
        ]
      },

      {
        "widgetName": "raShowcase",
        "type": "chess",
        "blocks": [
          {
            "img": {
              "src": "https://cdn1.ozone.ru/s3/multimedia-1-2/6927610106.jpg",
              "srcMobile": "https://cdn1.ozone.ru/s3/multimedia-1-2/6927610106.jpg",
              "alt": "",
              "position": "to_the_edge",
              "positionMobile": "to_the_edge"
            },
            "imgLink": "",
            "title": {
              "content": [
                "Описание товара"
              ],
              "size": "size5",
              "align": "center",
              "color": "color1"
            },
            "text": {
              "size": "size3",
              "align": "center",
              "color": "color1",
              "content": [
                "Японские двухслойные, мягкие, гигиенические салфетки, изготовлены из высококачественного экологически чистого сырья. Обладают большой впитывающей способностью. Не вызывают аллергию, не раздражают чувствительную кожу. Просты и удобны в использовании. Применяются дома и в офисе, на работе и на отдыхе. Производитель заботится об экологии, все спиленные деревья компенсируются новыми посадками."
              ]
            },
            "reverse": false
          }
        ]
      }
    ],
    "version": 0.3
  }, null, 3)

const parseOzonJson = (data) => {
    return data?.content?.map(({blocks}) => {
        return blocks?.map(({img, title, text, reverse}) => {
            return <div className='rich-item__content' style={{flexDirection: reverse ? 'row' : 'row-reverse'}}>
                <div className='text_container'>
                    <h2 
                        className='title' 
                        style={{ fontSize: sizes?.[title?.size], lineHeight: lineHeights?.[title?.size], textAlign: title?.align, color: colors?.[title?.color] }}
                    >
                        {title?.content}
                    </h2>
                    <p 
                        className='text'
                        style={{ fontSize: sizes?.[text?.size], lineHeight: lineHeights?.[text?.size], textAlign: text?.align, color: colors?.[text?.color] }}
                    >
                        {text?.content}
                    </p>
                </div>
                <div className='img-group'>
                    <div className='img-box'>
                        <img 
                            src={img?.src} alt={img?.alt} 
                            // style={{height: 633, width: 633}} 
                        />
                    </div>
                </div>
            </div>
        })
    })
}   

const donwloadJson = (data) => {

    const blob = new Blob([JSON.stringify(data, null, 3)], { type: "text/json" });
    const link = document.createElement("a");

    link.download = 'ozonRich.json';
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
}


const ProductImprovement_CreateRICHContent = ({ setIsModal, product_id }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [richData, setRichData] = useState(<></>)
    const [ozonJSON, setOzonJSON] = useState(null)

    const getOzonJson = () => {
        const url = `${RavshanHttps}/api/products/get_ozon_rich_content`

        const body = JSON.stringify({
            product_id
        })

        fetch(url, {body, method: 'POST', headers})
            .then(async res => {
                if(res.ok){
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setOzonJSON(json)
            })
            .catch(err => console.error(err))
    }

    const saveRich = () => {
        const url = `${RavshanHttps}/api/products/generate_rich_content_ozon`

        const body = JSON.stringify({
            product_id_list: [product_id],
        })

        fetch(url, {body, method: 'POST', headers})
            .then(async res => {
                if(res.ok){
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                console.log(json);
                getOzonJson()
            })
            .catch(err => console.error(err))
    }


    useEffect(() => {
        if(ozonJSON){
            setRichData(parseOzonJson(ozonJSON))
        }
    }, [ozonJSON])

    useEffect(() => {
        if(product_id){
            saveRich()
        }
    }, [product_id])

    return (
        <section className='create-RICH-content-inside-content'>
            <p className='create-RICH-content-inside-content__tooltip'>Загрузите или выберите
                нужное видео, нажмите “Изменить параметры” и после сгенерируйте видео</p>

            <Tabs stopRedirect tabs={btn_tab} pageValue={pageValue} setPageValue={setPageValue} />

            <div className='create-RICH-content-inside-content__content'>

                <div className='content-item' style={{width: '100%'}}>
                    <div className='rich-item'>
                        <div className='rich-item__header'>
                            <h3 className='title'>RICH описание для {pageValue == 'wb' ? 'Wildberries' : 'OZON'} (превью)</h3>
                            <div className='btn-group'>
                                <button className='btn' onClick={e => donwloadJson(ozonJSON)}><img src={download_icon} />Скачать JSON</button>
                            </div>
                        </div>
                        {richData}
                    </div>
                </div>

                {/* <div className='content-item' style={{width: '30%'}}>
                    <div className='rich-item'>
                        <div className='rich-item__header' >
                            <h3 className='title'>JSON</h3>
                            <div className='btn-group'>
                                <button className='btn'><img src={download_icon} />Скачать JSON</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </section>
    )
}

export { ProductImprovement_CreateRICHContent }