import add_icon from './img/add_icon.svg';
import customHeaders, { getSpace } from '../../../common/headers';
import { RavshanHttps } from '../../../fetchUrls';
import { useEffect, useState } from 'react';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
  fetchProductFilter,
} from '../../../common/filtersFunc';
import {
  DropDownSelector,
  ButtonBasic,
  InputDinamycPlaceholder,
  CheckboxToggle,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import settings_icon from './img/settings.svg';
import close_icon from './img/close.svg';
import useGeneralStore from '../../../store/general';
import './ModalPromotionRules.scss';

// Карточка
// status == 'Активно'/ 'Не активно'
const CardDrop = ({ ...props }) => {
  const {
    id,
    name,
    status = false,
    onDragStart,
    onDragOver,
    onStrategieToggle,
    onGetParams,
  } = props;

  return (
    <div
      className="col-item__card"
      draggable={true}
      onDragStart={(e) => onDragStart(props)}
      onDragOver={(e) => onDragOver(e)}
    >
      <p className="text">{name}</p>
      <div className="status-content">
        <button
          className="col-item__btn_settings"
          onClick={(e) => {
            e.stopPropagation();
            onGetParams(id, name, status);
          }}
        >
          <img src={settings_icon} />
        </button>
        <Checkbox
          green
          value={status}
          onChange={(e) => {
            if (onStrategieToggle) {
              onStrategieToggle({ ...props, status: e });
            }
          }}
        />
        {/* <div className={`status ${statusCard(status)}`}>{status}</div> */}
      </div>
    </div>
  );
};

// Колонка
const ColItemDrop = ({
  title = '',
  arr = [],
  onDragStart,
  onDragOver,
  onDrop,
  loading = false,
  onStrategieToggle,
  onGetParams,
}) => {
  return (
    <div className="col-item">
      <div className="col-item__header">
        <h3 className="title">
          {title} <span>{arr?.length ? arr.length : 0}</span>
        </h3>
      </div>
      <div
        className="col-item__list"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => onDrop(e)}
        draggable={true}
      >
        {!loading
          ? arr?.map((props, ind) => {
              // Карточка
              const { id } = props;
              return (
                <CardDrop
                  key={id}
                  {...props}
                  onDragStart={(e) => onDragStart(e)}
                  onDragOver={(e) => onDragOver(e)}
                  onStrategieToggle={(e) => onStrategieToggle(e)}
                  onGetParams={(e) => onGetParams(e)}
                />
              );
            })
          : 'Loading...'}
        {arr?.length < 1 && (
          <div className="no-list">
            <img src={add_icon} />
            <p className="text">Добавьте правило</p>
          </div>
        )}
      </div>
    </div>
  );
};

// Модальное окно
const ModalPromotionRules = ({ ...props }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const {
    setIsModal,
    status,
    rule_id,
    name,
    isEdit,
    isCreate,
    setRuleInfo,
    service_id,
  } = props;

  const [edit, setEdit] = useState(isEdit ? isEdit : false);
  const [create, setCreate] = useState(isCreate ? isCreate : false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [productPage, setProductPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [productQuery, setProductQuery] = useState('');

  const [title, setTitle] = useState(name ? name : '');
  const [currentCard, setCurrentCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState([]);
  const [disactive, setDisactive] = useState([]);
  const [isModalParametr, setIsModalParametr] = useState(false);
  const [ruleId, setRuleId] = useState(rule_id ? rule_id : -1);

  const [strategyInfo, setStrategyInfo] = useState(status ? { status } : {});
  const [strategyParams, setStrategyParams] = useState([]);
  const [strategyParamValue, setStrategyParamValue] = useState([]);

  const onCreate = () => {
    const url = `${RavshanHttps}/api/strategies/create_rule`;

    const body = JSON.stringify({
      api_id: selectedApi?.value,
      brand: [selectedBrand?.value],
      category: [selectedCategory?.value],
      product_id: [selectedProduct?.value],
      name: title,
      service: 1,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((id) => {
        setCreate(false);
        setRuleId(id);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeStrategy = (status, id) => {
    const url = `${RavshanHttps}/api/strategies/rule/add`;

    const param = Object.keys(strategyParamValue).map((id) => ({
      id,
      value: strategyParamValue[id],
    }));

    const body = JSON.stringify({
      rule_id: ruleId,
      status: id ? status : strategyInfo?.status,
      strategy_id: id ? id : strategyInfo?.id,
      param,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setStrategyInfo({});
        setStrategyParamValue({});
        fetchStrategies();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchStrategies = () => {
    const url = `${RavshanHttps}/api/strategies/rule/change`;

    const body = JSON.stringify({
      rule_id: ruleId,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then(({ active, disactive }) => {
        setActive(active?.map((el) => ({ ...el, status: true })));
        setDisactive(disactive?.map((el) => ({ ...el, status: false })));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetParams = async (id, name, status) => {
    setStrategyParams([]);
    setStrategyParamValue([]);
    const url = `${RavshanHttps}/api/strategies/strategies/param`;

    await fetch(url, {
      method: 'GET',
      headers: { ...headers, 'rule-id': ruleId, 'strategy-id': id },
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        if (json?.length > 0) {
          setStrategyInfo({ name, status, id });
          setStrategyParams(json);
          setIsModalParametr(true);
        }
      })
      .catch((err) => {
        setStrategyParams([]);
        setStrategyInfo({
          name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
          status,
          id,
        });
        setIsModalParametr(true);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetStrategyParams = (id, name, status) => {
    const url = `${RavshanHttps}/api/strategies/rule/get/strategy/param`;

    fetch(url, {
      method: 'GET',
      headers: { ...headers, 'rule-id': ruleId, 'strategy-id': id },
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        if (json?.length > 0) {
          setStrategyParams((prev) => {
            return prev?.map((props) => {
              const { name } = props;
              const filledParam = json?.filter((el) => el?.name === name)?.[0];
              if (name === filledParam?.name) {
                return { ...props, value: filledParam?.value };
              } else {
                return props;
              }
            });
          });
        }
      })
      .catch((err) => {
        setStrategyParams([]);
        setStrategyInfo({
          name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
          status,
          id,
        });
        setIsModalParametr(true);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClientParams = async (id, name, status) => {
    await onGetParams(id, name, status);
    onGetStrategyParams(id, name, status);
  };

  const onReset = () => {
    setRuleInfo({});
    setStrategyInfo({});
    setStrategyParamValue({});
    setStrategyParams([]);
    setRuleId(-1);
  };

  const onStrategieToggle = (props) => {
    const { id, name, status, param } = props;
    if (status) {
      onGetParams(id, name, status);
    } else {
      setStrategyInfo({ name, status, id });
      onChangeStrategy(status, id);
    }
  };

  const dragStartHandler = (e, card) => {
    // Перетаскиваемая карточка
    setCurrentCard(e);
  };

  const dragOverHandler = (e, card) => {
    e.preventDefault();
  };

  const dropHandler = (e, status) => {
    e.preventDefault();
    if (!currentCard) return;
    onStrategieToggle({ ...currentCard, status });
  };

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
    });
  }, [apiPage, apiQuery, selectedSpace]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery]);

  useEffect(() => {
    fetchProductFilter({
      selectedSpace: selectedSpace?.value,
      page: productPage,
      apiData,
      categoryData,
      brandData,
      selectedCategory,
      selectedBrand,
      selectedApi,
      searchParam: productQuery,
      setProductsData: (e) => setProductsData(e),
    });
  }, [
    selectedSpace,
    selectedBrand,
    selectedApi,
    selectedCategory,
    apiData,
    brandData,
    categoryData,
    productPage,
    productQuery,
  ]);

  useEffect(() => {
    fetchStrategies();
  }, [ruleId, selectedSpace]);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
          onReset();
        }
      }}
    >
      <div
        className="modal-promotion-rules"
        onClick={(e) => {
          let el = e.target;
          if (!el.closest('.promotion-rules-parametr')) {
            setIsModalParametr(false);
            // onChangeStrategy()
          }
        }}
      >
        <div className="modal-promotion-rules__header">
          <h1 className="title">
            {edit ? 'Редактирование правила' : 'Создание правила'}
          </h1>
          <InputDinamycPlaceholder
            onChange={(e) => setTitle(e)}
            textErr={'Неверное название правила'}
            placeholder="Название правила"
            changeValue={title}
            autoComplete={false}
            style={{ maxWidth: '500px', minWidth: '500px' }}
          />
          <div
            className="modal-promotion-rules__btn-close"
            onClick={(e) => setIsModal(false)}
          >
            <img src={close_icon} />
          </div>
        </div>

        <div className="modal-promotion-rules__toolbar-top">
          <div className="modal-promotion-rules__toogle-checkbox">
            <p className="modal-promotion-rules__text_label">
              Правило:
              {/* класс для красного цвета */}
              {/* modal-promotion-rules__text_status_disactive */}
              <span className="modal-promotion-rules__text_status_active">
                {' '}
                Активно
              </span>
            </p>
            <CheckboxToggle checked />
          </div>

          {!isEdit ? (
            <ButtonBasic
              disabled={!((title?.length > 0) & selectedApi?.value)}
              green
              minWidth="172px"
              width="172px"
              maxWidth="172px"
              text={create ? 'Создать' : 'Сохранить'}
              style={{ height: '100%' }}
              onClick={(e) => {
                if (create) {
                  onCreate();
                } else {
                  setIsModal(false);
                }
              }}
            />
          ) : null}
        </div>

        <div className="modal-promotion-rules__toolbar-medium">
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={apiData}
            state={selectedApi}
            setState={(e) => setSelectedApi(e)}
            className="connections_page_selector"
            placeholder="Магазин"
            fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setApiQuery(e)}
          />
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={categoryData}
            state={selectedCategory}
            setState={(e) => setSelectedCategory(e)}
            className="connections_page_selector"
            placeholder="Категория"
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
          />
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            className="connections_page_selector"
            placeholder="Бренд"
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
          />
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={productsData}
            state={selectedProduct}
            setState={(e) => setSelectedProduct(e)}
            className="connections_page_selector"
            placeholder="Товары"
            fetchCallback={(e) => setProductPage(e)}
            setInputChange={(e) => setProductQuery(e)}
          />
        </div>

        <div className="modal-promotion-rules__content">
          <div className="rules-content">
            {!create ? (
              <section className="rules-content__content">
                <div className="rules-content__tooltip">
                  Активируйте нужное правило переключателем или просто
                  перенесетие его вправо
                  <CheckboxToggle checked />
                </div>
                <div className="column-group">
                  <ColItemDrop
                    title="Доступные"
                    loading={loading}
                    arr={disactive}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, false)}
                    onStrategieToggle={(e) => onStrategieToggle(e)}
                    onGetParams={(e) => getClientParams(e)}
                  />

                  <ColItemDrop
                    title="Активные"
                    arr={active}
                    loading={loading}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, true)}
                    onStrategieToggle={(e) => onStrategieToggle(e)}
                    onGetParams={(e) => getClientParams(e)}
                  />
                </div>
              </section>
            ) : null}
          </div>
        </div>

        {isModalParametr && (
          <div className="promotion-rules-parametr">
            <div className="promotion-rules-parametr__header">
              <h1 className="title_main">Параметры</h1>
            </div>

            <div className="promotion-rules-parametr__content">
              <h1 className="title">
                {strategyInfo?.name ? strategyInfo?.name : 'Название стратегии'}
              </h1>
              {strategyParams?.map(
                ({ name, type, description, id, value = '' }) => {
                  return (
                    <InputDinamycPlaceholder
                      onChange={(e) => {
                        if (e) {
                          setStrategyParamValue((prev) => {
                            return { ...prev, [id]: e };
                          });
                        }
                      }}
                      placeholder={description}
                      autoComplete={false}
                      changeValue={value}
                      size="s"
                    />
                  );
                }
              )}
              <ButtonBasic
                grey
                width="100%"
                text={strategyParams?.length < 1 ? 'ОК' : 'Сохранить'}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalParametr(false);
                  onChangeStrategy();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ModalPromotionRules };
