import customHeaders, {getSpace} from '../../common/headers'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { ArtemBillingHttps, ArtemBillingHttp } from '../../fetchUrls'
import { icon_add_balance } from '../../common/svg_img'
import useGeneralStore from '../../store/general'
import './AddBalanceModal.scss'

const AddBalanceModal = ({
    setIsModalActive
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const navigate = useNavigate()
    const [balanceValue, setBalanceValue] = useState('')
    const [errorVisability, setErrorVisability] = useState(false)

    const balanceIncreaseRegister = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemBillingHttps}/register-order`
        } else {
            url = `${ArtemBillingHttp}/register-order`
        }


        const body = JSON.stringify({
            amount: +balanceValue*100,
            bankId: 1,
            currency: 'RUB',
            // returnUrl: `${window.location.href}-increase`
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const { orderId, formUrl, errorCode } = json
                if(formUrl){
                    window.location.assign(formUrl)
                }
                if(errorCode){
                    setErrorVisability(true)
                }
            })
            .catch(err => {
                console.error(err)
                setErrorVisability(true)
            })
    }

    return (
        <div className='blackout'>
            <div className='add-balance-modal' >
                <button className="close" onClick={e => setIsModalActive(false)}></button>

                <img src={icon_add_balance}/>
               
                    <h2 className='title' style={{padding: '0'}}>Пополнить баланс</h2>
                      <span style={{color: 'red', fontSize: '14px', margin: 0, visibility: errorVisability ? 'visible' : 'hidden'}}>Что-то пошло не так</span>
                      <div className='input-and-text-box' style={{marginTop: '.5em'}}>
                            <span className="text">Сумма пополнения:</span>
                            <div className='input-box'>
                                     <input className='add-balance-modal__input-field' type='number' placeholder='0.00' value={balanceValue} onChange={e => setBalanceValue(e.target.value)} ></input>
                            </div>
                        </div>
                    
                    <button
                        className='btn__green'
                        onClick={e => balanceIncreaseRegister(e)}
                    >
                        Пополнить баланс
                    </button>
               
            </div>
        </div>
    )
}

export { AddBalanceModal }