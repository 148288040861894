import customHeaders, { getSpace } from '../../common/headers';
import participate_tab from './img/participate_tab.svg';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import { RavshanHttps } from '../../fetchUrls';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { useState, useEffect, useRef } from 'react';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCampaignFilter,
  fetchCategoryFilter,
} from '../../common/filtersFunc';
import {
  TableBox,
  DropDownSelector,
  Tabs,
  ButtonBasic,
  RangeBox,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './ProductsInCampaign.scss';

const btn_tab = [
  {
    label: 'Участвуют',
    value: 'participate_items',
    icon: participate_tab,
  },
  {
    label: 'Не участвуют',
    value: 'items_without_advertisement',
    icon: participate_tab,
  },
];

const options_prop = [
  {
    label: 'Ozon',
    value: 'ozon',
  },
  {
    label: 'Wildberries',
    value: 'wb',
  },
  {
    label: 'Yandex',
    value: 'yandex',
  },
];

const ProductsInCampaign = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState('all');
  const [searchParams, setSearchParams] = useSearchParams();

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedStock, setSelectedStock] = useState([]);

  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [campaignPage, setCampaignPage] = useState(1);

  const [apiPage, setApiPage] = useState(1);
  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [campaignQuery, setCampaignQuery] = useState('');

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const [checkedProducts, setCheckedProducts] = useState([]);

  const activeTab = useParams()['*'];

  const fetchTableData = (params, abortController = new AbortController()) => {
    if (!pageValue) return;
    if (
      !selectedApi.length ||
      !selectedCategory.length ||
      !selectedBrand.length ||
      !selectedMarketplace
    )
      return;

    setLoading(true);

    let sort, filters;
    if (params) {
      [sort, filters] = params;
    }

    const url = `${RavshanHttps}/api/campaign/${pageValue}`;

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let campaign_id_list;

    if (selectedApi?.length) {
      api_id_list = selectedApi?.map((el) => String(el?.value));
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }
    if (selectedCampaign?.length > 0) {
      campaign_id_list = selectedCampaign?.map((el) => el.value);
    }

    const [date_from, date_to] = date;

    const body = JSON.stringify({
      filters,
      category_id_list,
      brand_id_list,
      api_id_list,
      campaign_id_list,
      date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      order_param: sort?.[0],
      order_param_direction: sort?.[1],
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  useEffect(() => {
    setPageValue(activeTab);
    const campaignName = searchParams.get('campaignName');
    const campaignId = searchParams.get('campaignId');
    const apiName = searchParams.get('apiName');
    const apiId = searchParams.get('apiId');
    if (
      (campaignName?.length > 0) &
      (campaignId?.length > 0) &
      (apiName?.length > 0) &
      (apiId?.length > 0)
    ) {
      setSelectedCampaign([{ label: campaignName, value: campaignId }]);
      setSelectedApi([{ label: apiName, value: apiId }]);
    }
  }, [activeTab]);

  useEffect(() => {
    if (
      selectedApi.length > 0 &&
      selectedCategory.length > 0 &&
      selectedBrand.length > 0
    ) {
      setIsDisabledBtn(false);
    } else setIsDisabledBtn(true);
  }, [selectedApi, selectedCategory, selectedBrand]);

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: selectedMarketplace?.value,
    });
  }, [selectedSpace, apiPage, apiQuery, selectedApi, selectedMarketplace]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery]);

  useEffect(() => {
    fetchCampaignFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      brandData,
      selectedBrand,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setCampaing: (e) => setCampaignData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedCategory,
    brandData,
    selectedBrand,
    campaignPage,
    campaignQuery,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchTableData([], abortController);
    return () => {
      abortController.abort();
    };
  }, [
    selectedSpace,
    selectedApi,
    selectedBrand,
    selectedCategory,
    selectedCampaign,
    date,
    pageValue,
  ]);

  return (
    <div className="products-in-campaign">
      <h1 className="title_main">Товары в кампании</h1>

      <div className="filter-group">
        <FilterContainer
          onReset={(e) => {
            setSelectedApi([]);
            setSelectedCategory([]);
            setSelectedBrand([]);
            setSelectedStock([]);
          }}
        >
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={options_prop}
            state={selectedMarketplace}
            setState={(e) => setSelectedMarketplace(e)}
            placeholder="Площадка"
            defaultValue={options_prop[0]}
          />

          <DropDownSelector
            options_prop={apiData}
            state={selectedApi}
            setState={(e) => setSelectedApi(e)}
            placeholder="Магазин"
            fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setApiQuery(e)}
            multi
          />
          <DropDownSelector
            options_prop={categoryData}
            state={selectedCategory}
            setState={(e) => setSelectedCategory(e)}
            placeholder="Категория"
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />
          <DropDownSelector
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            placeholder="Бренд"
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />
          <DropDownSelector
            options_prop={campaignData}
            state={selectedCampaign}
            setState={(e) => setSelectedCampaign(e)}
            placeholder="Кампания"
            fetchCallback={(e) => setCampaignPage(e)}
            setInputChange={(e) => setCampaignQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
          />
        </FilterContainer>

        <RangeBox setDate={(e) => setDate(e)} />
      </div>

      <Tabs tabs={btn_tab} pageValue={pageValue} />

      <TableBox
        {...tableProps}
        paginator={true}
        ref={paginatorRef}
        onCheck={(e) => setCheckedProducts(e)}
        tableUrl={tableUrl}
        tableBody={tableBody}
        child={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
            <ButtonBasic action text={'Удалить'} width="110px" size="32px" />

            <ButtonBasic
              action
              text={'Удалить все'}
              width="110px"
              size="32px"
            />
          </div>
        }
      />
    </div>
  );
};

export { ProductsInCampaign };
