import { MoonLoader } from 'react-spinners'
import './Loader.scss'

export const Loader = ({style}) => (
    <div className='loader-container'style={style}>
        <MoonLoader
            color="#7b7b7b"
            speedMultiplier={0.5}
        />
    </div>
)