import done_img from './img/done_img.svg'
import './ModalFeedbackDone.scss'

const ModalFeedbackDone = ({ setIsModal }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-feedback-done'>
                <button className="btn-close"
                    onClick={(e) => setIsModal(false)}
                ></button>
                <h2 className='title'>Сообщение отправлено успешно</h2>
                <p className='text_grey-blue'>Спасибо за обратную связь, в самое ближайшее время мы свяжемся с Вами</p>
                <img src={done_img} />
                <button className='btn__blue'
                    onClick={(e) => setIsModal(false)}
                >Ок</button>
            </div>
        </div>
    )
}

export { ModalFeedbackDone }