import React, { useState } from 'react';
import './CrmSubModal.scss';
import close_icon from './img/close_icon.svg'
import { Checkbox, ButtonBasic, InputSearch } from '@lk-gtcom/ecomlab-components'

export const CrmUncommonAttrSubModal = (props) => {
    const { isOpen, close, uncommonAttrTypes, setSelectedAttributes, selectedAttributes } = props;

    const [checkedAttr, setCheckedAttr] = useState([])

    const onCheckboxChange = (id) => {
        if (checkedAttr.includes(id)) {
            setCheckedAttr(checkedAttr.filter(item => item !== id));
        } else {
            setCheckedAttr([...checkedAttr, id ]);
        }
    };

    const onHandleClose = () => {
        close();
    }

    const onHandleSubmit = () => {
        const selectedUncommonAttrs = uncommonAttrTypes.filter(attr => checkedAttr.includes(attr.id));
        // console.log(checkedAttr)
        const current = selectedUncommonAttrs.map((item) => ({...item, common : false}))
        setSelectedAttributes(it => [...it, ...current]);
        setCheckedAttr([]);
        close();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-crm-sub-modal" onClick={onHandleClose}>
            <div className="modal-crm-sub-modal__main-content">

                <div className="modal-crm-sub-modal__header" >
                    <h2 className="modal-crm-sub-modal__title">Выбор пользовательского поля</h2>
                    <button className='modal-crm__btn-close'
                        onClick={() => close()}
                    >
                        <img src={close_icon} />
                    </button>
                </div>

                <InputSearch />

                <div className='modal-crm-sub-modal__checkbox-group'>
                    {uncommonAttrTypes.map(attr => (
                        <label className={checkedAttr.includes(attr.id) ? 'modal-crm-sub-modal__label_checked' : 'modal-crm-sub-modal__label'}>
                            <Checkbox
                                key={attr.id}
                                value={checkedAttr.includes(attr.id)}
                                onChange={() => onCheckboxChange(attr.id)}
                            />
                            <span className='modal-crm-sub-modal__label-text'>{attr.title}</span>
                        </label>

                    ))}
                </div>

                <div className='modal-crm-sub-modal__btn-group'>
                    <ButtonBasic
                        green
                        width='110px'
                        text={'Выбрать'}
                        onClick={onHandleSubmit}
                    ></ButtonBasic>

                    <ButtonBasic
                        grey
                        width='110px'
                        text={'Отменить'}
                        onClick={onHandleClose}
                    ></ButtonBasic>
                </div>

            </div>
        </div>
    );
};
