import { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import dropBlue from './img/drop-blue.svg';
import { AnaliticsReview } from '../../components/AnaliticsReview/AnaliticsReview';
import { WorkWithReviews } from '../../components/WorkWithReviews/WorkWithReviews';
import { TooltipBlue } from '../../components/TooltipBlue/TooltipBlue'
import { TooltipYellow } from '../../components/TooltipYellow/TooltipYellow';
import { TooltipGreen } from '../../components/TooltipGreen/TooltipGreen';
import { NotActive } from '../../components/NotActive/NotActive';
import { Page404 } from '../Page404/Page404';
import "./Reviews.scss"


const Reviews = ({ }) => {
    const pathMain = useParams()
    const navigate = useNavigate()

    const [isActive, setIsActive] = useState('')
    const [isActiveItems, setIsActiveItems] = useState('')
    const [sidebarItems, setSidebarItems] = useState(
        [
            {
                label: 'Обратная связь',
                subItems: [
                    {
                        label: 'Отзывы и вопросы',
                        path: 'work-with-review/reviews',
                    },
                    {
                        label: 'Аналитика по отзывам',
                        path: 'analitics-review/product',
                    },
                    {
                        label: 'Аналитика по вопросам',
                        path: ''
                    },
                ],
            },
            {
                label: 'Настройки',
                subItems: [
                    {
                        label: 'Уведомления',
                    },
                    {
                        label: 'Правила',
                    }
                ],
            },
            {
                label: 'Примеры использования',
                subItems: [],
            }
        ]
    )
    const [sidebarList, setSidebarList] = useState([])
    const [hideMenu, setHideMenu] = useState(true)
    const [showTooltipBlue, setShowTooltipBlue] = useState(true)
    const [showTooltipYellow, setShowTooltipYellow] = useState(true)
    const [showTooltipGreen, setShowTooltipGreen] = useState(true)
    const dropClass = hideMenu ? 'icon-drop' : 'icon-drop_active'

    useEffect(() => {

        // После перезагрузки страницы, посвечиваем корректные пункты меню

        sidebarItems?.forEach(({ label, subItems, path }) => {
            const title = label
            const comparePaths = path && (pathMain['*'].split('/')[0] == path.split('/')[0])
            if (comparePaths) {
                setIsActive(null)
                setIsActiveItems(label)
            } else {
                subItems.forEach(({ label, path }) => {
                    if (comparePaths) {
                        setIsActive(title + label)
                        setIsActiveItems(null)
                    }
                })
            }
        })

        if (pathMain['*'].split('/')[0] == 'not-active') {
            navigate('work-with-review/reviews')
            setIsActive('Обратная связьОтзывы и вопросы')
            setIsActiveItems('')
        }
    }, [])

    useEffect(() => {
        setSidebarList([
            ...sidebarItems.map((el, idx, { label }) => <SidebarMenu {...el} isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} setActive={e => setIsActive(e)} active={isActive} key={label + '' + idx} />)
        ])
    }, [isActive])

    return (
        <div className='settings__content'>
            <div className='title-box' onClick={() => setHideMenu(!hideMenu)}>
                <h2 className='title'>Отзывы</h2>
                <img src={dropBlue} className={dropClass} />
            </div>
            {/* <div className='tooltip-group-fixed'>
                {((pathMain['*'] === 'work-with-review/reviews' ||
                    pathMain['*'] === 'work-with-review/questions' ||
                    pathMain['*'] === 'work-with-review/archive')
                    && showTooltipBlue) &&
                    <TooltipBlue
                        setShowTooltip={(e) => setShowTooltipBlue(e)}
                        text='Отвечайте на вопросы не позднее чем за 24 часа чтобы повысить качество обслуживания' />}
                {((pathMain['*'] === 'work-with-review/reviews' ||
                    pathMain['*'] === 'work-with-review/questions' ||
                    pathMain['*'] === 'work-with-review/archive')
                    && showTooltipYellow) && <TooltipYellow
                        setShowTooltip={(e) => setShowTooltipYellow(e)}
                        text='У вас есть неотвеченный вопрос' />}
                {((pathMain['*'] === 'work-with-review/reviews' ||
                    pathMain['*'] === 'work-with-review/questions' ||
                    pathMain['*'] === 'work-with-review/archive')
                    && showTooltipGreen) && <TooltipGreen
                        setShowTooltip={(e) => setShowTooltipGreen(e)}
                        text={'Ответ успешно отправлен'}
                    />}
            </div> */}

            <div className='flex'>
                <div className={hideMenu ? 'sidebar-inside' : 'sidebar-inside_active'}>
                    <nav className='sidebar-inside__nav'>
                        {sidebarList}
                    </nav>
                </div>
                <div className='settings__main-box'>
                    <Routes>
                        <Route path='analitics-review/*' element={<AnaliticsReview />} />
                        <Route path='work-with-review/*' element={<WorkWithReviews />} />
                        <Route path='not-active' element={<NotActive />} />
                        <Route path='*' element={< Page404 />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export { Reviews }