import { useState } from 'react';
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { message_icon, tel_icon, tg_icon, whatsapp_icon } from '../../common/svg_img';
import "./Notifications.scss"

const Notifications = () => {
    const email = localStorage.getItem('email')

    return (
        <div className='notification'>
            <div className='notification-box'>
                <h3 className='title' style={{ paddingLeft: 0 }}>Настроить отправку уведомлений</h3>
                <p className='text'>Способы уведомления</p>
                <div className='notification__info-box'>
                    <div className='contacts-box'>
                        <p className='text-and-icon text-and-icon_messages'>Email</p>
                        <div className='contact-and-edit'>
                            <p className='text'>{email}</p>
                            <button className='btn__edit'></button>
                        </div>
                    </div>

                    <div className='contacts-box'>
                        <p className='text-and-icon text-and-icon_tel'>Телефон</p>
                        <div className='contact-and-edit'>
                            <p className='text'>8(800)301-65-63</p>
                            <button className='btn__edit'></button>
                        </div>
                    </div>

                    <div className='contacts-box'>
                        <p className='text-and-icon text-and-icon_tg'>Телеграмм</p>
                        <div className='contact-and-edit'>
                            <a className='link__blue'>Настройте аккаунт или группу</a>
                        </div>
                    </div>

                    <div className='contacts-box'>
                        <p className='text-and-icon text-and-icon_whatsapp'>WhatsApp</p>
                        <div className='contact-and-edit'>
                            <a className='link__blue'>Настройте аккаунт или группу</a>
                        </div>
                    </div>
                </div>
            </div>



            <div className='table-box'>
                <TableBox
                    name='Способы уведомления'
                    fetchedData={[]}
                    headers={[]}
                    btn='Сохранить'
                    onBooleanCheckbox={e => console.log(e)}
                />
            </div>


        </div>
    )
}

export { Notifications }