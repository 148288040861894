import { Link } from 'react-router-dom';
import signature_icon from './img/Подпись_печать.jpeg'
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import "./InvoiceWithSignature.scss"
import { useRef } from 'react';

const header_table = [
    '№',
    'Наименование работ, услуг',
    'Кол-во',
    'Ед.',
    'Цена',
    'Сумма',
]

const body_table = [
    [
        1,
        'Услуга 1 за период дд.мм.ггг - дд.мм.гггг',
        '',
        'Услуга',
        800,
        800
    ],
    [
        1,
        'Услуга 1 за период дд.мм.ггг - дд.мм.гггг',
        '',
        'Услуга',
        800,
        800
    ]
]

const InvoiceWithSignature = () => {

    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const canvas = await html2canvas(reportTemplateRef.current)
        const imgData = canvas.toDataURL("image/png")
        const doc = new jsPDF({
            format: 'a4',
            unit: 'px',
        });

        const width = doc.internal.pageSize.getWidth()
        const height = (canvas.height * width) / canvas.width
        doc.addImage(imgData, "PNG", 0, 0, width, height)
        doc.save('document')
    };

    return (
        <div className='document-page'>
            <ButtonBasic
                onClick={handleGeneratePdf}
                green
                text='Сгенерировать PDF'
            />
            <div className='invoice' ref={reportTemplateRef}>
                <table className='invoice__table-requisites'>
                    <tbody>
                        <tr>
                            <td colSpan={2}>ФИЛИАЛ "ХАБАРОВСКИЙ" АО "АЛЬФА-БАНК" г. Хабаровск</td>
                            <td>БИК</td>
                            <td>040813770</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>Банк получателя</td>
                            <td>Сч. №</td>
                            <td>30101810800000000770</td>
                        </tr>
                        <tr>
                            <td>ИНН 9728036721</td>
                            <td>КПП 772801001</td>
                            <td>Сч. №</td>
                            <td>40702810620020005373</td>
                        </tr>
                        <tr>
                            <td>ООО "ЕКОМСЕЛЛЕР" <br /> Получатель</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <h1 className='invoice__title'>Счет на оплату № <span>ХХ</span> от <span>дд.мм.гггг г.</span></h1>

                <div className='invoice__executor'>
                    <p className='invoice__placeholder'>Поставщик<br /> (Исполнитель):</p>
                    <p className='invoice__text-bold'>ООО "ЕКОМСЕЛЛЕР", ИНН 9728036721, 117342, Город Москва, вн.тер.г.
                        муниципальный округ Коньково, ул Бутлерова, д. 17Б, этаж 2,
                        помещ. XI, ком. 69, офис 215, тел.: 985-137-91-94, р/с 40702810620020005373, в
                        банке ФИЛИАЛ "ХАБАРОВСКИЙ" АО "АЛЬФА-БАНК", БИК 040813770, к/с 30101810800000000770</p>
                </div>

                <div className='invoice__executor'>
                    <p className='invoice__placeholder'>Покупатель <br />(Заказчик):</p>
                    <p className='invoice__text-bold invoice__text-blue'>ООО "Ромашка", ИНН 7ХХХХХХХХ1, 140125, Московская
                        обл, г. Ромашково, ул. Хорошая, дом 1, строение 1, этаж 1, помещение 5, тел.:
                        8-ХХХ-ХХХ-ХХ-ХХ, р/с ХХХХХХХХХХХХХ, в банке ПАО Сбербанк, БИК ХХХХХХХХХХ, к/с ХХХХХХХХХХХХХ</p>
                </div>

                <div className='invoice__executor'>
                    <p className='invoice__placeholder'>Основание:</p>
                </div>

                <table className='invoice__table'>
                    <thead>
                        <tr>
                            {header_table.map((el, ind) => {
                                return <th key={el + ind}>{el}</th>
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {body_table.map((el, ind) => {
                            return <tr key={el + ind}>
                                {el.map((el, ind) => <td key={el + ind} >{el}</td>)}
                            </tr>
                        })}
                        <tr className='tr-total'>
                            <td colSpan={4}></td>
                            <td>Итого</td>
                            <td>{body_table.reduce((acc, num) => acc + num[5], 0)}</td>
                        </tr>
                        <tr className='tr-total'>
                            <td colSpan={4}></td>
                            <td>Без налога</td>
                            <td>-</td>
                        </tr>
                        <tr className='tr-total'>
                            <td colSpan={4}></td>
                            <td>Всего к оплате:</td>
                            <td>{body_table.reduce((acc, num) => acc + num[5], 0)}</td>
                        </tr>
                    </tbody>
                </table>

                <p className='invoice__text-9'>
                    Всего наименований <span>1</span>, на сумму <span>800,00</span> руб.
                </p>
                <p className='invoice__text-9 invoice__text-bold'>
                    <span>Восемьсот рублей 00 копеек</span>
                </p>

                <div className='act-service__delimetr'></div>

                <div className='invoice__place-signature-group'>
                    <div className='invoice__place-signature'>
                        <p className='invoice__text-9  invoice__text-bold'>Руководитель</p>
                        {/* <p className='invoice__text-8 invoice__text-nowrap'>Рипп В. В.</p> */}
                        <img className='invoice__text-8 invoice__signature' src={signature_icon} />
                    </div>

                    <div className='invoice__place-signature'>
                        <p className='invoice__text-9  invoice__text-bold'>Бухгалтер</p>
                        {/* <p className='invoice__text-8 invoice__text-nowrap'>Рипп В. В.</p> */}
                        <img className='invoice__text-8 invoice__signature' src={signature_icon} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { InvoiceWithSignature }