import { useState } from 'react'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components"
import product_img from './img/product_img.svg'
import video_icon from './img/video_icon.svg'
import "./ProductImprovement_EnhanceVideo.scss"

const arrVideo = []

const ProductImprovement_EnhanceVideo = ({oldVideo, newVideo}) => {

    console.log(newVideo);

    return (
        <section className='enhance-video-inside-content'>
            <div className='content-item'>
                <p className='text_sum-video'>Видео</p>
                <div className='video-group'>
                    {oldVideo?.map((el, ind) =>
                        <div className='video-box' key={el + ind}>
                            <video className='video' controls style={{width: '320px'}}>
                                <source src={el} type='video/mp4' />
                            </video>
                        </div>
                    )}
                </div>
                <div className='drop-box'>
                    <ButtonBasic
                        grey
                        text={<div className='content-photo-btn'><img src={video_icon} />Добавить видео</div>}
                        width='100%'
                    />
                    <input type='file'/>
                </div>
            </div>
            <div className='content-item'>
                <p className='text_sum-photo'>Улучшенное видео</p>
                <div className='video-group'>
                    {newVideo?.map((el, ind) =>
                        <div className='video-box' key={el + ind}>
                            <video className='video' controls style={{width: '320px'}}>
                                <source src={el} type='video/mp4' />
                            </video>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export { ProductImprovement_EnhanceVideo }