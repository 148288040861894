import customHeaders, {getSpace} from '../../common/headers'
import { Flag } from './img/flag'
import { Checkbox } from "@lk-gtcom/ecomlab-components"
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack'
import { ModalDeleteTask } from '../Modal/ModalDeleteTask/ModalDeleteTask'
import { user_light_grey } from '../../common/svg_img'
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor'
import { useState, useEffect } from 'react'
import { PRIORITY_TO_COLOR_MAP } from '../Modal/ModalAddTask2/ModalAddTask2'
import { RavshanHttps, RavshanHttp } from '../../fetchUrls'
import { ReactComponent as DeleteIcon } from './img/delete_icon.svg'
import { ReactComponent as LinkCopyIcon } from './img/link_copy.svg'
import useGeneralStore from '../../store/general'
import "./TaskItem.scss"


const TaskItem = ({
    link,
    author,
    projectName,
    title,
    priority,
    status,
    executor,
    assignee_name,
    time,
    style,
    id,
    done,
    openTask,
    deleteTask,
    fetchProjects,
    isOpenActiveMenu,
    setIsOpenActiveMenu }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}

    const [showOption, setShowOption] = useState(null)
    const [blurPrevent, setBlurPrevent] = useState(false)
    const priorityColor = PRIORITY_TO_COLOR_MAP[priority]
    const email = localStorage.getItem('email')
    const taskProjectId = sessionStorage.getItem('taskProjectId')
    const [isDone, setIsDone] = useState(done ? done : false)

    const [isModalDeleteTask, setIsModalDeleteTask] = useState(false)


    const colorTime = (priority) => {
        switch (priority) {
            case 'Высокий':
                return ['high', '#D81104']
            case 'Средний':
                return ['medium', '#EAAE00']
            case 'Низкий':
                return ['low', '#44484F']
            default:
                return ['', '']
        }
    }

    const colorStatus = (status) => {
        switch (status) {
            case 'НА СТОПЕ':
                return 'info-status-color_red'
            case 'НОВЫЕ ЗАДАЧИ':
                return 'info-status-color_grey'
            case 'В РАБОТЕ':
                return 'info-status-color_blue'
            case 'НА ПРОВЕРКЕ':
                return 'info-status-color_yellow'
            case 'ГОТОВО':
                return 'info-status-color_green'
            default:
                return 'info-status-color_grey'
        }
    }

    const fetchUpdateTask = (done) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/event/done`
        } else {
            url = `${RavshanHttp}/api/tasks/event/done`
        }


        const body = JSON.stringify({
            done,
            event_id: id
        })

        fetch(url, { body, method: 'POST', headers: { ...headers, 'from-preview': true } })
            .then(res => {
                if(res.ok){
                    setIsDone(done)
                } else {
                    setIsDone(!done)
                }
            })
    }



    // Обработчик на клик вне элемента,для закрытия блока с options
    useEffect(() => {
        const hideMenu = (e) => {
            let el = e.target
            if (!el.closest('.context-more')) {
                setIsOpenActiveMenu(false)
            }
        }
        document.addEventListener('click', hideMenu)

        return () => {
            document.addEventListener('click', hideMenu)
        }
    }, [])


    return (
        <>
            {isModalDeleteTask && <ModalDeleteTask
                deleteTask={(e) => deleteTask(id)}
                taskNameName={title}
                setIsModal={(e) => setIsModalDeleteTask(e)}
                fetchProjects={(e) => fetchProjects(e)}
            />}

            <div className='task-item' style={isDone ? {...style, background: 'rgba(0,0,0,.1)'} : style} >

                <div className='task-item__content'>
                    {
                        <div className='context-more'>
                            <button className='btn-more'
                                onClick={(e) => {
                                    setIsOpenActiveMenu(id)
                                }}
                            ></button>
                            <div className={(isOpenActiveMenu == id) ? 'drop-menu_active' : 'drop-menu'}>
                                <button className='drop-menu__btn'
                                    onClick={(e) => {
                                        navigator.clipboard.writeText(link)
                                        setIsOpenActiveMenu(false)
                                    }}
                                >
                                    <LinkCopyIcon />
                                    Скопировать ссылку
                                </button>

                                {(author == email) && <button className='drop-menu__btn delete'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsOpenActiveMenu(false)
                                        setIsModalDeleteTask(true)
                                        //  deleteTask(id)
                                    }}
                                >
                                    <DeleteIcon /> Удалить
                                </button>}
                            </div>
                        </div>
                    }

                    <div className='info-container'>
                        <p className='text' style={isDone ? {textDecoration: 'line-through'} : {}}>{title ? title : 'Нет названия'}</p>

                        <div className='list-members'>
                            <div className='members-list-box'>{
                                assignee_name ? <ExecutorPlaceholder {...executor} email={executor?.login} /> : <img src={user_light_grey} />}
                            </div>

                            <div className='term-box'>
                                {/* <Time className='time-svg' /> */}
                                <div className='term-box__top'>
                                    {priority != 'Не назначено' &&
                                        <div className='priority' >
                                            <Flag
                                                color={colorTime(priority)[1]}
                                            />
                                            {/* <p className='text'>{colorTime(priority)[0]}</p> */}
                                        </div>}
                                    <TooltipBlack
                                        text={`ID-${id}`}
                                        top='18px'
                                        right='0px'
                                        left='none'
                                        child={<p className='text_grey'>{(`ID-${(id.toString()).substr(0, 4)}...`)}</p>}
                                    />
                                    <p className='text' style={{ color: colorTime(priority)[1] || '#44484F' }}>{time.split('г.')[0]}</p>
                                </div>
                                <Checkbox green onChange={value => fetchUpdateTask(value)} value={isDone} />
                            </div>
                        </div>

                        {
                            taskProjectId == -1 ?
                                (status?.length > 11) ?
                                    <TooltipBlack
                                        text={status}
                                        top='18px'
                                        left='0px'
                                        child={
                                            <div className={colorStatus(status)}>
                                                <p>{status ? status : ''}</p>
                                            </div>
                                        }
                                    />
                                    :
                                    <div className={colorStatus(status)}>
                                        <p>{status ? status : ''}</p>
                                    </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export { TaskItem }