import { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import print_icon from './img/print_icon.svg'
import box_icon from './img/box_icon.svg'
import product_img from './img/product_img.svg'
import drag_zone from './img/drag_icon.svg'
import plus_icon from './img/plus_icon.svg'
import points_icon from './img/points_icon.svg'
import './ModalAboutTheOrder.scss'

const info_list = [
    {
        label: 'Дата отгрузки',
        value: '01.01.2024'
    },
    {
        label: 'Склад',
        value: 'Название склада'
    },
    {
        label: 'Доставка фываыувпывпыврыварыварвыарвырвыврыврвыраыврыварыварыварыварыварыварыварвыарв',
        value: 'Название доставки может тоже быть  длинным и тогдафывпывапвыраыврвырыварыввыарвыарвыарварвыарварвыар '
    },
    {
        label: 'Дата доставки',
        value: '01.01.2024'
    },
    {
        label: 'Принят в обработку',
        value: '01.01.2024'
    },
]

const data_list = [
    {
        label: 'Название должно полностью вмещаться опэтому оно увеличивает высоту самой карточки например в три строки выглядит так',
        sum: 100,
        date: '10.10.2023',
        img: product_img,
        category: 'Аксессуары/Наушники/Для компьютера и еще',
        brand: 'Название бренда длинное',
        dimensions: '1000х1000х1000',
        price: 6000,
        final_price: 6000
    },
    {
        label: 'Название должно полностью вмещаться опэтому оно увеличивает высоту самой карточки например в три строки выглядит так',
        sum: 100,
        date: '10.10.2023',
        img: product_img,
        category: 'Аксессуары/Наушники/Для компьютера и еще',
        brand: 'Бренд',
        dimensions: '1000х1000х1000',
        price: 6000,
        final_price: 6000
    }
]

const CardDrop = ({ label, sum, date, img, category, brand, dimensions, price, final_price }) => {
    return <div
        draggable={true}
        className='order-card-drop'
        key={label + sum + date}>
        <div className='order-card-drop__header'>
            <div className='left-card-info'>
                <div className='img-box'>
                    <img src={img} />
                </div>
                <h3 className='title'>{label}</h3>
                <p className='text'>{sum} <span>шт</span></p>
            </div>

            <div className='right-card-info'>
                <div className='right-card-info__top'>
                    <div className='status'>Ожидает сборки</div>
                    <button className='btn-points'>
                        <img src={points_icon}/>
                    </button>
                </div>
                <p className='text'>{`Заказан ${date}`}</p>
            </div>
        </div>

        <div className='order-card-drop__content'>
            <p className='text_category'>{category}</p>
            <p className='text_brand'>{brand}</p>
            <div className='item-info'>
                <p className='parametr'>Д Ш В (мм)</p>
                <p className='val'>{dimensions}</p>
            </div>
            <div className='item-info'>
                <p className='parametr'>Цена до скидки</p>
                <p className='val'>{price} <span>₽</span></p>
            </div>
            <div className='item-info'>
                <p className='parametr'>Итоговая цена</p>
                <p className='val'>{final_price} <span>₽</span></p>
            </div>
        </div>

        <div className='drag-zone'>
            <img src={drag_zone} />
        </div>
    </div>
}

const ModalAboutTheOrder = ({ title, setIsModal }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-about-order'>
                <div className='modal-about-order__header'>
                    <div className='main-info'>
                        <h1 className='title'>📦 32623200-0111-1</h1>
                        <div className='status'>Ожидает сборки</div>
                    </div>

                    <div className='btn-group'>
                        <ButtonBasic
                            grey
                            text={<p className='btn-print'><img src={print_icon} />Распечатать этикетку</p>}
                            width='233px'
                            size='56px'
                        />
                        <ButtonBasic
                            green
                            text='Собрать заказ'
                            width='180px'
                            size='56px'
                        />
                    </div>
                </div>

                <div className='modal-about-order__content'>
                    <div className='left-content'>
                        <h2 className='title'>Информация о заказе</h2>
                        <div className='order-info'>
                            {info_list.map(({ label, value }) =>
                                <div className='order-info__item' key={label + value}>
                                    <p className='parametr'>{label}</p>
                                    <p className='value'>{value}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='right-content'>
                        <h2 className='title'>Отправление - <span>1</span></h2>
                        <div className='shipment-of-goods'
                               onDragOver={e => e.preventDefault()}
                        >
                            <div className='drop-zone-plus'>
                                <img className='add-icon' src={plus_icon} />
                                <input type='file' />
                            </div>

                            {data_list.map(({ label, sum, date, img, category, brand, dimensions, price, final_price }) =>
                                <CardDrop
                                    label={label}
                                    sum={sum}
                                    date={date}
                                    img={img}
                                    category={category}
                                    brand={brand}
                                    dimensions={dimensions}
                                    price={price}
                                    final_price={final_price}
                                />
                            )}
                        </div>

                        <h2 className='title'>Отправление - <span>2</span></h2>
                        <div className='shipment-of-goods'
                               onDragOver={e => e.preventDefault()}
                        >
                            <div className='drop-zone-plus'>
                                <img className='add-icon' src={plus_icon} />
                                {/* <input type='file' /> */}
                            </div>

                            {data_list.map(({ label, sum, date, img, category, brand, dimensions, price, final_price }) =>
                                <CardDrop
                                    label={label}
                                    sum={sum}
                                    date={date}
                                    img={img}
                                    category={category}
                                    brand={brand}
                                    dimensions={dimensions}
                                    price={price}
                                    final_price={final_price}
                                />
                            )}
                        </div>

                        <h2 className='title_bold'>Создать новое отправление</h2>
                        <div className='drop-zone-white'
                        >
                            <div className='drop-zone-white__content'>
                                <img src={box_icon} />
                                <p className='text'>Чтобы создать новое отправление перетащите сюда нужные товары
                                    Каждое отправление будет отправлено покупателю в отдельной коробке</p>
                                {/* <input type='file' /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ModalAboutTheOrder }