import customHeaders, {getSpace} from '../../common/headers';
import icon_tab from './img/icon_tab.svg'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SliderRange } from '../Slider/Slider';
import { ModalLoader } from '../Modal/ModalLoader/ModalLoader';
import { useChartData, useDataTable } from '../../common/hooks';
import { ModalWithTable } from '../Modal/ModalWithTable/ModalWithTable';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { FiltersBreadCrumbs } from '../FiltersBreadCrumbs/FiltersBreadCrumbs';
import { useState, useEffect, useRef } from 'react';
import { AndreyHttps, AndreyHttp, AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { TableBox, RangeBox, DropDownSelector, Tabs, CheckboxToggleBlue, SearchComponent, Checkbox, LineChart, DropContainer } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from '../../store/general';
import "./AnalysisByCategory.scss"

const btn_tab = [
    {
        label: 'Запросы',
        value: 'queries',
        icon: icon_tab
    },
    // {
    //     label: 'Товары',
    //     value: 'goods',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Бренды',
    //     value: 'brands',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Продавцы',
    //     value: 'sellers',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Ценовые сегменты',
    //     value: 'price_segments',
    //     icon: icon_tab
    // },
    {
        label: 'По датам',
        value: 'days',
        icon: icon_tab
    }
]


const options_period = [
    {
        label: 'дням',
        value: 'day'
    },
    {
        label: 'неделям',
        value: 'week'
    },
    {
        label: 'месяцам',
        value: 'month'
    }
]

const title_list = [
    {
        label: 'По категории',
        path: `by-category`
    },
    {
        label: 'По категорийному запросу',
        path: `categorical_tree`
    }
]

const AnalysisByCategory = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const activeTab = useParams()['*']
    const [searchParams, setSearchParams] = useSearchParams()

    const [pageValue, setPageValue] = useState({
        label: 'Ozon',
        value: 'ozon'
    })

    const [currentTitle, setCurrentTitle] = useState(title_list[0])


    const [modalLoader, setModalLoader] = useState(false)
    const [dynamic, setDynamic] = useState(0)
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const [selectedPeriod, setSelectedPeriod] = useState(options_period[0])
    const [selectedItems, setSelectedItems] = useState([])

    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(20000)
    const [price, setPrice] = useState([minValue, maxValue])
    const [segments, setSegments] = useState(1)

    const [selectedBreadcrumbsValue, setSelectedBreadcrumbsValue] = useState([])
    const [showMenu, setShowMenu] = useState(false)
    const [checkFbs, setCheckFbs] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [searchData, setSearchData] = useState([])

    const [queryNameFilter, setQueryNameFilter] = useState('')
    const [actionColumn, setActionColumn] = useState('')
    const [isModalAction, setIsModalAction] = useState(false)
    const [isReverse, setIsReverse] = useState(false)

    const [tableMetrics, setTableMetrics] = useState([])
    const [tableMetric, setTableMetric] = useState('IDC')
    const [showDropBox, setShowDropBox] = useState(false)


    const [setChartData, chartData, setChartFuncs] = useChartData()
    const { setChartLoading, resetChart } = setChartFuncs

    const onHandleSliderChange = (minMax) => {
        setPrice(minMax)
    }

    const getModalTableData = () => {
        let pageEndPoint = currentTitle?.path === title_list[0]?.path ? 'category' : 'by_category_request'
        const _url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pageEndPoint}/${actionColumn}/table/`
        let [date_from, date_to] = date

        date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        let categoryBody = currentTitle?.path === title_list[0]?.path ?
            { category_id_filter: selectedBreadcrumbsValue?.map(el => el?.label) }
            :
            pageValue === 'queries' ? { category_name_filter: [searchValue] } : { query_name_filter: [searchValue] }

        const body = {
            fbofbs_filter: checkFbs,
            date_from,
            date_to,
            query_name_filter: [queryNameFilter],
            ...categoryBody,
        }

        return { _url, body }
    }

    const fetchSearch = ([search, page = 1], abortController = new AbortController()) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${AndreySkuHttps}/api/v1/ecomru/keywords/by_category_request/search_string/`
        } else {
            url = `${AndreySkuHttp}/api/v1/ecomru/keywords/by_category_request/search_string/`
        }

        // let [date_from, date_to] = date
        // date_from = new Date(date_from+10)?.toISOString().split('T')?.[0]
        // date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        const body = JSON.stringify({
            page: page,
            limit: 10,
            search_filter: search
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                let result = []
                if (json?.Category_request) {
                    result = json?.Category_request.map(({ request_name }, ind) => {
                        return ({ label: request_name, value: request_name })
                    }
                    )
                }
                setSearchData(result)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const resetTable = () => {
        paginatorRef.current.reset()
        paginatorRef.current.setTotal(0)
        // setCheckedProducts([])
        setFetchedData([])
        setHeaders([])
        setSort({})
        setFilters([])
    }

    const fetchTableData = (params, abortController = new AbortController()) => {
        if (!!pageValue & (selectedBreadcrumbsValue?.length > 0 || searchValue?.length > 0)) {
            let sort
            let filtersParam

            if (params) {
                [sort, filtersParam] = params
            }
            setLoading(true)


            let apiVer = currentTitle?.path === title_list[0]?.path ? 'v2' : 'v1'
            let pageEndPoint = currentTitle?.path === title_list[0]?.path ? 'category' : 'by_category_request'
            let tableLevel = pageEndPoint === title_list?.[0] ? 'first_level/' : ''


            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/${apiVer}/ecomru/keywords/${pageEndPoint}/${pageValue}/table/${tableLevel}`
            } else {
                url = `${AndreySkuHttps}/api/${apiVer}/ecomru/keywords/${pageEndPoint}/${pageValue}/table/${tableLevel}`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            let category_values = selectedBreadcrumbsValue?.map(el => el?.value?.id)
            let res;
            if (category_values?.length > 0) {
                res = category_values.at(-1) ? category_values.at(-1) : category_values.at(-2)
            }

            const priceData = pageValue == 'price_segments'
                ?
                {
                    price_from: price[0],
                    price_to: price[1],
                    price_segments: segments
                }
                : {}

            let categoryBody = currentTitle?.path === title_list[0]?.path ?
                { category_id_filter: res }
                :
                { category_name_filter: [searchValue] }

            const metricName = pageValue === 'days' ? { metric_name: tableMetric } : {}


            const body = JSON.stringify({
                ...categoryBody,
                date_from,
                date_to,
                fbofbs_filter: checkFbs,
                page: paginatorRef.current.page,
                limit: paginatorRef.current.limit,
                filters: filtersParam,
                sort_name: sort ? sort[0] : 'продажи в день',
                sort_order: sort ? sort[1] : "DESC",
                reverse: isReverse,
                ...priceData,
                ...metricName
            })

            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setTable(json)
                    setTableUrl(url)
                    setTableBody(body)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchChartData = (abortController = new AbortController()) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${AndreySkuHttps}/api/v1/ecomru/keywords/by_category_request/${pageValue}/graph/`
        } else {
            url = `${AndreySkuHttps}/api/v1/ecomru/keywords/by_category_request/${pageValue}/graph/`
        }

        let searchId
        let [date_from, date_to] = date;
        date_from = new Date(date_from + 86401000)
            ?.toISOString()
            ?.split('T')?.[0];
        date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

        const id_arr = selectedItems?.map(el => {
            if (el) {
                if (pageValue === 'queries' && el?.['Поисковый запрос']) {
                    if (Array.isArray(el?.['Поисковый запрос'])) {
                        const [img, name] = el?.['Поисковый запрос']
                        return el?.['Поисковый запрос'][0]
                    }
                }

                if (pageValue === 'days' && el?.['Поисковый запрос']) {
                    if (Array.isArray(el?.['Поисковый запрос'])) {
                        const [img, name] = el?.['Поисковый запрос']
                        return el?.['Поисковый запрос'][0]
                    }
                }
            }
        })
        searchId = { query: id_arr }

        let categoryBody = currentTitle?.path === title_list[0]?.path ?
            { category_id_filter: selectedBreadcrumbsValue?.at(-1)?.value?.id }
            :
            { query: [searchValue] }


        const body = JSON.stringify({
            ...categoryBody,
            date_from,
            date_to,
            fbofbs_filter: checkFbs,
            // page: paginatorRef.current.page,
            // limit: paginatorRef.current.limit,
            metric_name: tableMetric,
            // initial: !(chartData?.selectedLegend?.length > 0),
            metrics: [],
            ...searchId
        })

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setChartData(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    const fetchBreadCrumbs = (parentId, page, mp) => {
        let _page = page, limit = 25
        const shop_id = 8


        const params = new URL(document.location).searchParams
        let query = params.get("mp")

        let url = `${AndreySkuHttps}/api/v1/general/categories/${!parentId ? (shop_id + '/') : ''}`
        let options

        if (!parentId) {
            _page = 1
            options = { method: 'GET', headers }
        } else {
            const body = JSON.stringify({
                shop_id,
                parent_id: parentId,
                page: _page,
                limit
            })
            options = { body, method: 'POST', headers }
        }

        return fetch(url, options)
    }

    const fetchBreadCrumbsSearch = (query, page = 1, abortController = new AbortController()) => {
        let _page = page, limit = 25
        let url = `${AndreySkuHttps}/api/v1/general/categories/search/`

        const wb_id = 8

        const body = JSON.stringify({
            limit,
            page: _page,
            query,
            shop_id: wb_id
        })

        let options = { body, method: 'POST', headers, signal: abortController.signal }


        return fetch(url, options)
    }

    const fetchMinMaxPrice = () => {
        if (selectedBreadcrumbsValue.length) {

            let pageEndPoint = currentTitle?.path === title_list[0]?.path ? 'categories' : 'by_category_request'
            let pageEndPoint2 = currentTitle?.path === title_list[0]?.path ? 'price_segments/' : ''
            let apiVerEndPoint = currentTitle?.path === title_list[0]?.path ? '' : 'api/v1'


            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${currentTitle?.path === title_list[0]?.path ? AndreyHttps : AndreySkuHttps}/${apiVerEndPoint}/ecomru/keywords/${pageEndPoint}/${pageEndPoint2}price_range/`
            } else {
                url = `${currentTitle?.path === title_list[0]?.path ? AndreyHttp : AndreySkuHttps}/${apiVerEndPoint}/ecomru/keywords/${pageEndPoint}/${pageEndPoint2}price_range/`
            }

            let [date_from, date_to] = date
            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                categories: selectedBreadcrumbsValue,
                fbs: checkFbs,
                date_from,
                date_to,
            })

            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    const { min_price, max_price } = json
                    setMinValue(min_price)
                    setMaxValue(max_price)
                    setPrice([min_price, max_price])
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const onActionHandler = (el) => {
        const [dataObj, targetData, targetColumn] = el
        const query_name_filter = dataObj?.['Поисковый запрос']
        setQueryNameFilter(String(query_name_filter)?.replace(',', ''))

        if (targetColumn === 'Подкатегорий, шт') {
            setActionColumn('category')
        }

        if (targetColumn === 'Товаров, шт') {
            setActionColumn('goods')
        }

        if (targetColumn === 'Брендов, шт') {
            setActionColumn('brands')
        }

        setIsModalAction(true)
    }

    const fetchMetricsList = () => {
        if (pageValue === 'days' || pageValue === 'queries') {
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v2/ecomru/keywords/category/${pageValue}/metric_list/`
            } else {
                url = `${AndreySkuHttps}/api/v2/ecomru/keywords/category/${pageValue}/metric_list/`
            }


            fetch(url, { method: 'GET', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setTableMetrics(json);
                    setTableMetric(json[0]?.value)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const handleCheck = (data) => {
        setSelectedItems(data)
    }

    useEffect(() => {
        setDynamic(100)
    }, []);


    useEffect(() => {
        // resetTable()
        const abortController = new AbortController()
        let defaultSort
        if (!sort?.length > 0) {
            defaultSort = ['Сред. запросов в неделю', 'DESC']
            setSort(defaultSort)
        } else {
            defaultSort = sort
        }

        if (pageValue !== 'price_segments') {
            // resetTable()
            fetchTableData([defaultSort, filters], abortController)
        }


        if (pageValue === 'price_segments') {
            fetchMinMaxPrice()
        }

        return () => abortController.abort()
    }, [selectedSpace, pageValue, date, selectedBreadcrumbsValue, searchValue, checkFbs, isReverse, tableMetric])

    useEffect(() => {
        if (pageValue === 'days' || pageValue === 'queries') {
            fetchMetricsList()
        } else {
            setTableMetrics([])
            setTableMetric('IDC')
        }
    }, [pageValue, selectedSpace])

    useEffect(() => {
        setPageValue(activeTab)
        const query = searchParams.get('query')
        if (query) {
            setSelectedBreadcrumbsValue([{ label: query, value: query }])
        }
    }, [activeTab, currentTitle])

    useEffect(() => {
        const abortController = new AbortController()

        if (selectedItems?.length > 0 && searchValue?.length > 0) {
            fetchChartData(abortController)
        }

        return () => abortController.abort()
    }, [selectedSpace, chartData?.selectedLegend, selectedItems, date, pageValue, searchValue, checkFbs, tableMetric])

    useEffect(() => {
        resetTable()
    }, [pageValue, currentTitle]);


    return (
        <>
            {modalLoader &&
                <ModalLoader
                    setIsModal={(e) => setModalLoader(e)}
                    dynamic={dynamic}
                    title='Формируется отчет'
                    text='Не закрывайте страницу, загрузка может занять до 2 минут'
                />}

            {
                isModalAction &&
                <ModalWithTable
                    {...getModalTableData()}
                    setIsModal={setIsModalAction}
                />
            }

            <div className='analysis-by-category'>
                <div className='content-1320'>
                    <div className='tabs-title'>
                        {title_list.map((el) =>
                            <h1 className={currentTitle.label == el.label ? 'title_active' : 'title'}
                                key={el.label}
                                onClick={() => {
                                    setCurrentTitle(el)
                                }}
                            >{el.label}</h1>
                        )}
                    </div>

                    <div className='tooltip-top'>
                        {currentTitle.label == 'По категории' && <FiltersBreadCrumbs
                            showMenu={showMenu}
                            isSelectedBreadCrumsAfterButton
                            selectedBreadcrumbsValue={selectedBreadcrumbsValue}
                            fetchCallback={fetchBreadCrumbs}
                            fetchSearch={fetchBreadCrumbsSearch}
                            setShowMenu={(e) => setShowMenu(e)}
                            setSelectedBreadcrumbsValue={setSelectedBreadcrumbsValue}
                            mapFunc={el => ({ label: el.category_name, value: { id: el.category_id, hasChildren: el.has_children } })}
                        />}

                        {currentTitle.label == 'По категорийному запросу' &&
                            <SearchComponent
                                searchValue={searchValue}
                                errSearch={false}
                                placeholder='Искать по артикулу или названию'
                                errText='Неверный артикул или название'
                                setSearchValue={setSearchValue}
                                // fetchData={fetchTableData}
                                fetchCallback={fetchSearch}
                                data={searchData}
                            />
                        }
                    </div>

                    <div className='toolbar-medium'>
                        <Tabs tabs={btn_tab} disabled={loading} pageValue={pageValue} />
                        <div className='range-group-btn-and-range-box'>
                            {pageValue == 'by_days' && <div className='drop-box'>
                                <p className='text'>По</p>
                                <DropDownSelector
                                    options_prop={options_period}
                                    state={selectedPeriod}
                                    setState={e => setSelectedPeriod(e)}
                                    defaultValue={selectedPeriod}
                                    className='connections_page_selector'
                                    isClearable={false}
                                />
                            </div>}
                            <TooltipLightBlue
                                text={<p className='text_tooltip'>FBO <span>(продажи со склада площадки)</span> по умолчанию.
                                    <br /><br />
                                    Нажмите на переключатель чтобы увидеть FBS <span>(продажи со склада продавца)</span>
                                </p>}
                                top='32px'
                                left='0px'
                                maxWidth='110px'
                                child={
                                    <label className='checkbox-container'>
                                        <CheckboxToggleBlue
                                            value={checkFbs}
                                            onChange={(e) => setCheckFbs(!checkFbs)}
                                        />
                                        <p className='text_label'>FBS</p>
                                    </label>
                                }
                            />

                            <RangeBox setDate={e => setDate(e)} />
                        </div>
                    </div>

                    {pageValue == 'price_segments' && <SliderRange
                        minValue={minValue} maxValue={maxValue}
                        setSegments={e => setSegments(e)}
                        setState={e => onHandleSliderChange(e)}
                        callbackHandler={e => {
                            // resetTable()
                            fetchTableData()
                            //    fetchChartData()
                        }}
                    />}
                </div>

                {
                    currentTitle === title_list?.[1] &&
                    <DropContainer
                        showDropBox={showDropBox}
                        setShowDropBox={(e) => setShowDropBox(e)}
                    >
                        <LineChart
                            {...chartData}
                        />

                    </DropContainer >
                }

                <TableBox
                    {...tableProps}
                    fetchCallback={fetchTableData}
                    onAction={e => onActionHandler(e)}
                    onActionInfo
                    onCheck={e => handleCheck(e)}
                    onUncheckAllDependence={[pageValue, searchValue, currentTitle]}
                    ref={paginatorRef}
                    paginator={true}
                    newTable={true}
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    isInsideTable={currentTitle === 'category'}
                    child={
                        <div style={{ display: 'flex', gap: '.5em', flexDirection: 'column', boxSizing: 'border-box', padding: '.5em 0 1em' }}>
                            {
                                pageValue === 'days' &&
                                <div>
                                    <p style={{ display: 'flex', gap: '.5em' }}>
                                        Перевернуть
                                        <Checkbox value={isReverse} onChange={checked => setIsReverse(checked)} />
                                    </p>
                                </div>
                            }
                            {
                                (pageValue !== 'summary' & pageValue !== 'top_keywords') ?
                                    <div style={{ width: '90%', overflow: 'overlay', height: '100%', display: 'flex', flexWrap: 'wrap', gap: '.5em', justifyContent: 'space-between' }}>
                                        {tableMetrics.map(({ label, value }) =>
                                            <div
                                                style={{
                                                    width: '200px', border: '1px solid lightgrey', borderRadius: '10px', boxSizing: 'border-box',
                                                    padding: '1em', backgroundColor: tableMetric === value ? '#d5d5f9' : '', cursor: 'pointer',
                                                    fontSize: '12px'
                                                }}
                                                onClick={e => setTableMetric(value)}
                                            >
                                                {label}</div>)}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    }
                />
            </div>
        </>

    )
}

export { AnalysisByCategory }