import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import baner_logo from './img/banner_loader.svg'
import "./ModalLoader.scss"

const ModalLoader = ({ setIsModal, dynamic, title, text }) => {

    useEffect(() => {
        if (dynamic == 100) {
            setTimeout(() => {
                setIsModal(false)
            }, 2000)
            
        }
    }, [dynamic]);

    return (
        <div className='blackout'>
            <div className='modal-loader'>
                <img src={baner_logo} />
                <h2 className='title'>Формируется отчет</h2>
                <p className='text_grey-blue'>Не закрывайте страницу, загрузка может занять до 2 минут</p>
                <div className='loading-dynamics'>
                    <div className='dynamics' style={{ width: dynamic + '%' }}></div>
                </div>
            </div>
        </div>
    )
}

export { ModalLoader }