import {useState, useEffect} from 'react'
import { Routes, Route } from 'react-router-dom'
import { CrmCreateTable } from '../../components/CrmCreateTable/CrmCreateTable'
import { CrmTablesList } from '../../components/CrmTablesList/CrmTablesList'
import './Crm.scss'

const Crm = () => {

  return (
    <Routes>
      <Route path='create-table/*' element={<CrmCreateTable />} />
      <Route path='table-list/*' element={<CrmTablesList />} />
  </Routes>
  )
}

export {Crm}