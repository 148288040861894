import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from 'react';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import { TooltipBlack } from '../../Tooltip/TooltipBlack/TooltipBlack'
import { ReactComponent as FixIcon } from './img/fix_icon.svg'
import './ModalFilters.scss'


const FilterFromToo = ({ label, value, unit, fixbtn = false, setState, state }) => {

    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')

    useEffect(() => {
        if(typeof setState === 'function'){
            setState(prev => ({
                ...prev,
                [value?.[0]]: from,
                [value?.[1]]: to,
            }))
        }
    }, [from, to])

        return <div className='filter-item' key={label + unit}>
            <p className='filter-item__placeholder' >{label}</p>
            <div className='input-container'>
                <InputDinamycPlaceholder
                    onChange={(e) => setFrom(e)}
                    setValue={(e) => setFrom(e)}
                    changeValue={state?.[value?.[0]]}
                    placeholder='from'
                    value={from}
                    size='s'
                    showClearIndicator
                />
            </div>

            <div className='input-container'>
            <InputDinamycPlaceholder
                    onChange={(e) => setTo(e)}
                    placeholder='to'
                    changeValue={state?.[value?.[1]]}
                    value={to}
                    size='s'
                    setValue={(e) => setTo(e)}
                    showClearIndicator
                />
            </div>
            {unit && <p className='text'>{unit}</p>}
            {fixbtn && <TooltipBlack
                child={<button className='fix-btn'>
                    <FixIcon />
                </button>}
                text='Hint'
                top='130%'
                right='0'
            />}
        </div>
    }

const ModalFilters = ({ onClick, setIsModal, filterList, setState, state }) => {

    const filtersArr = useMemo(() => {
        return (
            filterList?.map(({ label, value, unit }) =>
                <FilterFromToo
                    key={label + value}
                    label={label}
                    unit={unit}
                    value={value}
                    setState={setState}
                    state={state}
                    fixbtn
                />
            )
        )

    }, [filterList])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-filters'>
                <div className='modal-filters__header'>
                    <h1 className='title'>Все фильтры</h1>
                </div>
                <div className='modal-filters__content'>
                    {filtersArr}
                </div>
                <div className='modal-filters__bottom-content'>
                    <ButtonBasic
                        green
                        text='Применить'
                        width='100%'
                        size='56px'
                        onClick={onClick}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalFilters }