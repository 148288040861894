import { Link } from 'react-router-dom'
import { FooterItem } from './FooterItem/FooterItem';
import { CopyrightBox } from './CopyrightBox/CopyrightBox';
import './Footer.scss'

const mir = require('./img/mir.png')
const visa = require('./img/visa.png')
const masterCard = require('./img/masterCard.png')
const sber = require('./img/sberPay.png')
const google = require('./img/googlePay.png')
const samsung = require('./img/samsungPay.png')


const footerItems = [

    {
        label: 'ПАРТНЕРАМ',
        subItems: [
            {
                label: 'Реферальная программа',
            },
        ],
    },
    {
        label: 'О компании',
        subItems: [
            {
                label: 'Миссия',
            },
            {
                label: 'О компании',
            },
            {
                label: 'Новости',
            },
        ],
    },
    {
        label: 'ОБУЧЕНИЕ',
        subItems: [
            {
                label: 'Кейсы',
            },
            {
                label: 'Блог',
            },
            {
                label: 'Обучение',
            }
        ],
    },
];

const transactionPlatforms = [
    mir, visa, masterCard, sber, google, samsung
]


const Footer = () => {
    return (
        <footer className="footer">
            <div className='footer__content'>
                <div className="footer__upper-box">
                    <nav className="footer__nav">
                        {footerItems.map(({ label, subItems }) => (
                            <FooterItem
                                key={label}
                                label={label}
                                subItems={subItems}
                            />
                        ))}
                    </nav>

                    <div className="footer__org-info">
                        <p className='text'>Безналичный расчет для Юрлиц и ИП</p>
                        <div className="footer_org-inner">
                            {transactionPlatforms.map((el, ind) =>
                                <div className='img-box' key={el + ind}>
                                    <img src={el} alt="Фото платежной платформы" />
                                </div>)}

                        </div>
                    </div>
                    
                    <CopyrightBox />
                </div>


            </div>
        </footer>
    )
}

export { Footer }