import customHeaders, { getSpace } from '../../common/headers';
import ModalDeleteConnection from '../Modal/ModalDeleteConnection/ModalDeleteConnection';
import { ModalFeedback } from '../Modal/ModalFeedback/ModalFeedback';
import { ModalFeedbackDone } from '../Modal/ModalFeedbackDone/ModalFeedbackDone';
import { ConnectionsContext } from '../../App';
import { ButtonBasic, TableBox } from '@lk-gtcom/ecomlab-components';
import { setAccountId, setName, setServiceData } from './actions';
import { useState, useEffect, useRef, useContext } from 'react';
import {
  OlegHttps,
  OlegHttp,
  RavshanHttps,
  RavshanHttp,
} from '../../fetchUrls';
import './SettingsConnection.scss';
import useModal from '../../store/modal';
import { useShallow } from 'zustand/react/shallow';
import { useSearchParams } from 'react-router-dom';
import useGeneralStore from '../../store/general';

const SettingsConnection = ({
  // setIsModalActive,
  // isModalActive,
  setConnectedModal,
  isModalFeedback,
  setIsModalFeedback,
  currentHeaders,
  currentSpace,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [fetchedData, setFetchedData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [sort, setSort] = useState({});
  const [columnSize, setColumnSize] = useState([]);

  const [loading, setLoading] = useState(false);

  const [choosedAccount, setChoosedAccount] = useState(null);

  const paginatorRef = useRef();

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalFeedbackDone, setIsModalFeedbackDone] = useState(false);

  const [isDelete, setIsDelete] = useState(false);
  const { dispatch } = useContext(ConnectionsContext);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const {
    isModalActive,
    setIsModalActive,
    isServiceSelected,
    setIsServiceSelected,
    isServiceConnected,
    setIsServiceConnected,
  } = useModal(
    useShallow((state) => ({
      isModalActive: state.isModalActive,
      setIsModalActive: state.setIsModalActive,
      isServiceSelected: state.isServiceSelected,
      setIsServiceSelected: state.setIsServiceSelected,
      isServiceConnected: state.isServiceConnected,
      setIsServiceConnected: state.setIsServiceConnected,
    }))
  );

  const fetchAllAccountData = (params) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/account/all${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    } else {
      url = `${OlegHttp}/api/v1/account/all${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    }

    const headers_new = {
      ...headers,
    };

    const body = JSON.stringify({
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
      filters: filtersParam,
    });

    fetch(url, { body, method: 'POST', headers: headers_new })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const { data, labels, total, sort, column_size } = json;
        if (total) paginatorRef.current.setTotal(total);
        if (sort) setSort(sort);
        if (labels) setTableHeaders(labels);
        if (column_size) setColumnSize(column_size);
        setFetchedData(data.length > 0 ? data : [[]]);
        setLoading(false);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const onDeleteAccount = ({ account_id }) => {
    setIsModalDelete(false);
    if (account_id) {
      setLoading(true);
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${RavshanHttps}/api/service/delete_account`;
      } else {
        url = `${RavshanHttp}/api/service/delete_account`;
      }

      const body = JSON.stringify({
        account_id,
      });

      fetch(url, { body, method: 'DELETE', headers })
        .then((res) => res.json())
        .then((json) => {
          fetchAllAccountData();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onDeleteAccountHandler = (e) => {
    setIsModalDelete(true);
    setChoosedAccount(e);
  };

  const onEditAccount = ({ account_id }) => {
    setLoading(true);
    if (account_id) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${RavshanHttps}/api/service/get_service_attr_for_account`;
      } else {
        url = `${RavshanHttp}/api/service/get_service_attr_for_account`;
      }

      const currentHeaders = {
        ...headers,
        'account-id': account_id,
      };

      fetch(url, { method: 'GET', headers: currentHeaders })
        .then((res) => {
          if (res.status === 204) {
            console.log('no change data for this service');
            return;
          }
          return res.json();
        })
        .then((json) => {
          if (!json) return;
          dispatch(setAccountId(account_id));
          dispatch(setName(json?.name));
          dispatch(setServiceData(json));
          setConnectedModal(true);
          setIsModalActive(true);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  };

  const contextMenuArr = [
    { label: 'Редактировать', onClick: onEditAccount, type: 'edit' },
    { label: 'Удалить', onClick: onDeleteAccountHandler, type: 'delete' },
  ];

  useEffect(() => {
    if (!isModalActive) {
      searchParams.delete('connection');
      fetchAllAccountData();
    }
  }, [isModalActive, isModalDelete, selectedSpace]);

  const [searchParams] = useSearchParams();
  const connection = searchParams.get('connection');
  useEffect(() => {
    if (connection === 'true') {
      setIsModalActive(true);
      setIsServiceSelected(true);
      setIsServiceConnected(true);
    }
  }, [connection]);

  return (
    <>
      {isModalDelete ? (
        <ModalDeleteConnection
          setIsModalDelete={(e) => setIsModalDelete(e)}
          setIsDelete={(e) => setIsDelete(e)}
          onDeleteAccount={(e) => onDeleteAccount(e)}
          choosedAccount={choosedAccount}
        />
      ) : null}

      {isModalFeedbackDone && (
        <ModalFeedbackDone setIsModal={(e) => setIsModalFeedbackDone(e)} />
      )}

      {isModalFeedback && (
        <ModalFeedback
          setIsModal={(e) => setIsModalFeedback(e)}
          setIsModalFeedbackDone={(e) => setIsModalFeedbackDone(e)}
        />
      )}

      {/* <ModalChangeConnectionDetails /> */}

      <div className="connections">
        <h1 className="title_main">Список подключений</h1>

        <TableBox
          loading={loading}
          tableUrl={tableUrl}
          tableBody={tableBody}
          fetchedData={fetchedData}
          headers={tableHeaders}
          sort={sort}
          paginator={true}
          fetchCallback={(e) => fetchAllAccountData(e)}
          sortingFunc={(e) => fetchAllAccountData(e)}
          ref={paginatorRef}
          onEdit={(e) => onEditAccount(e)}
          // onAddAcc={e => onEditAccountAdditional(e)}
          isInsideTable={false}
          columnSize={columnSize}
          contextMenuArr={contextMenuArr}
          child={
            <ButtonBasic
              green
              text="Добавить подключение +"
              onClick={() => setIsModalActive(true)}
              width="200px"
              size="32px"
            />
          }
          hideExport
        />
      </div>
    </>
  );
};

export { SettingsConnection };
