import { useState, useEffect, useRef } from 'react'
import { DropDownSelector } from "@lk-gtcom/ecomlab-components";
import calendar_img from './img/calendar.svg'
import { RangeDatePicker } from '../RangeDatePicker/RangeDatePicker';
import "./RangeBoxSingle.scss"

const RangeBoxSingle = ({ date, setDate, minMaxDate = [], filterDate = true, tooltip = false, single = false }) => {

    const [btnPeriod, setBtnPeriod] = useState(null)

    const [min, max] = minMaxDate


    const [selectedRange, setSelectedRange] = useState({ label: 'За неделю', value: '7' })
    const [dropDirection, setDropDirection] = useState(false)

    const rangePickerRef = useRef()

    const options_prop = [
        {
            label: 'За 30 дней',
            value: '29'
        },
        {
            label: 'За неделю',
            value: '6'
        },
        {
            label: 'За 2 недели',
            value: '13'
        },
        {
            label: 'Текущий месяц',
            value: '29'
        },
        {
            label: '3 месяца',
            value: '89'
        },
        {
            label: '6 месяцев',
            value: '179'
        },
        {
            label: 'За год',
            value: '359'
        },

    ]


    useEffect(() => {
        const directionDrop = () => {
            const popup_el = document.querySelector('.calendar_wrapper')
            if (popup_el) {
                setDropDirection(true)
            } else setDropDirection(false)
        }
        document.addEventListener('click', directionDrop)

        return () => document.removeEventListener('click', directionDrop)
    });



    return (
            <div className='range-box-single' style={{border: dropDirection ? '2px solid #1890FF' : ''}} >
                <div style={{ opacity: 0, visibility: 'hidden' }}>
                    <DropDownSelector
                        ref={rangePickerRef}
                        options_prop={options_prop}
                        state={selectedRange}
                        setState={e => setSelectedRange(e)}
                        className='connections_page_selector'
                        isClearable={false}
                        controlShouldRenderValue={false}
                        value={selectedRange}
                    />
                </div>

                <RangeDatePicker
                    dateFromUnix={date?.at(0)}
                    dateToUnix={date?.at(1)}
                    btnPeriod={btnPeriod}
                    setBtnPeriod={(e) => setBtnPeriod(e)}
                    resetRange={() => {
                        rangePickerRef.current.clearValue()
                    }}
                    setDate={(e) => setDate(e)}
                    filterDate={filterDate}
                    newCalendar
                    dateFormat='dd.MM.yy'
                    max={max ? max : null}
                    min={min ? min : null}
                />
                 <img className='calendar-img' src={calendar_img} />

                 {tooltip && <p className='range-box-single__tooltip'>{tooltip}</p>}
            </div>
    )
}

export { RangeBoxSingle }