import { useState } from "react"
import active from './img/active.svg'
import disactive from './img/disactive.svg'
import { MoonLoader } from 'react-spinners'
import add_icon from './img/add.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './TariffPlan.scss'

const TariffPlan = () => {

    const tarifs_cards_min_list = [
        {
            period: '1 Месяц',
        },
        {
            period: '3 месяца',
            discount: 20
        },
        {
            period: '6 месяцев',
            discount: 20
        },
        {
            period: '12 месяцев',
            discount: 20
        }
    ]

    const tariffs_list = [
        {
            label: 'Базовый',
            sum: 55777
        },
        {
            label: 'Базовый',
            sum: 55777,
        },
        {
            label: 'Базовый',
            sum: 55777,
        },
    ]

    const parametr_list = [
        {
            label: 'Расчет поставки:',
            base1: 'active',
            base2: 'active',
            base3: 'active',
        },
        {
            label: 'Обслуживание сервиса:',
            base1: 'active',
            base2: 'active',
            base3: 'active',
        },
        {
            label: 'Расчет поставки:',
            base1: 'active',
            base2: 'disactive',
            base3: 'disactive',
        },
        {
            label: 'Обслуживание сервиса:',
            base1: 'active',
            base2: 'active',
            base3: 'disactive',
        },
    ]

    const [currentTariff, setCurrentTariff] = useState('3 месяца')
    const [isModalTariffActivation, setIsModalTariffActivation] = useState(false)
    const [isModalSuccessful, setModalSuccessful] = useState(false)
    const [isActivePromoCode, setIsActivePromoCode] = useState(false)
    const [load, setLoad] = useState(true)

    return (
        <>
            <div className="tariffs-plan">
                <div className="tariffs-plan__header">
                    <h1 className='summary__title'>Тарифы</h1>
                    <ButtonBasic
                        green
                        width='190px'
                        text={<div className="content-btn"><img src={add_icon} alt="icon" />Пополнить баланс</div>}
                    />
                </div>

                <div className="tariffs-plan__top">
                    <div className="cards-group">
                        <div className="cards-min-grouop">
                            {tarifs_cards_min_list.map(({ period, discount }) =>
                                <label className={currentTariff == period ? "tariff-cards-min_active" : "tariff-cards-min"}
                                    onClick={(e) => setCurrentTariff(period)}>
                                    <p>
                                        {period}
                                    </p>
                                    {(discount && (currentTariff == period)) && <div className="discount">{'-' + discount + '%'}</div>}
                                </label>
                            )}
                        </div>


                        <table className="tariffs-table">
                            <thead>
                                <th></th>
                                {tariffs_list.map(({ label, sum }, ind) =>
                                    <th>
                                        <div className="tariff-card">
                                            <div className="name-box">
                                                <p className="name">{label}</p>
                                            </div>
                                            <div className="sum-box">
                                                <span>{sum.toLocaleString() + ' ₽'}<span>/мес</span></span>
                                            </div>
                                            <ButtonBasic
                                                whiteGreen
                                                text='Активировать'
                                                width='100%'
                                                size='40px'
                                                onClick={(e) => setIsModalTariffActivation(true)}
                                            />
                                        </div>
                                    </th>
                                )}
                            </thead>

                            <tbody>
                                {parametr_list.map(({ label, base1, base2, base3 }) => {
                                    return <tr>
                                        <td>
                                            <p className="text_label">{label}</p>
                                        </td>
                                        <td>
                                            <div className="td-content">
                                                <img src={base1 == 'active' ? active : disactive} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="td-content">
                                                <img src={base2 == 'active' ? active : disactive} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="td-content">
                                                <img src={base3 == 'active' ? active : disactive} />
                                            </div>
                                        </td>
                                    </tr>
                                })}



                            </tbody>
                        </table>






                        {/* <div className="tariff-cards-group">
                            {tariffs_list.map(({ label, sum }) =>
                                <div className="tariff-card">
                                    <div className="name-box">
                                        <p className="name">{label}</p>
                                    </div>
                                    <div className="sum-box">
                                        <span>{sum.toLocaleString() + ' ₽'}<span>/мес</span></span>
                                    </div>
                                    <ButtonBasic
                                        blue
                                        text='Активировать'
                                        width='100%'
                                        size='52px'
                                        onClick={(e) => setIsModalTariffActivation(true)}
                                    />
                                </div>
                            )}
                        </div> */}
                    </div>
                </div>

                {/* <div className="parameter_string">
                    <h3 className="title">Название:</h3>
                    <div className="parameter_string__content">
                        {parametr_list.map(({ label, settings, discount }) =>
                            <div className="parameter-item">
                                <p className="text_grey">{label}</p>
                                <div className="discount-box">
                                    <div className="discount">{"-" + discount + '%'}</div>
                                </div>
                                {settings.map((el) =>
                                    <div className="status-box">
                                        <img src={el == 'active' ? active : disactive} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="parameter_string">
                    <h3 className="title">Название:</h3>
                    <div className="parameter_string__content">
                        {parametr_list.map(({ label, settings, discount }) =>
                            <div className="parameter-item">
                                <p className="text_grey">{label}</p>
                                <div className="discount-box">
                                    <div className="discount orange">{"-" + discount + '%'}</div>
                                </div>
                                {settings.map((el) =>
                                    <div className="status-box">
                                        <img src={el == 'active' ? active : disactive} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="parameter_string">
                    <h3 className="title">Название:</h3>
                    <div className="parameter_string__content">
                        {parametr_list.map(({ label, settings, discount }) =>
                            <div className="parameter-item">
                                <p className="text_grey">{label}</p>
                                <div className="discount-box">
                                    <div className="discount blue">{"-" + discount + '%'}</div>
                                </div>
                                {settings.map((el) =>
                                    <div className="status-box">
                                        <img src={el == 'active' ? active : disactive} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div> */}
            </div>
        </>
    )
}

export { TariffPlan }