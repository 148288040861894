'use client'
import { ButtonBasic } from '@lk-gtcom/ecomlab-components'
import close_icon from './img/close.svg'
import { useState } from 'react'
import { TableBox } from '@lk-gtcom/ecomlab-components'
import "./ModalChatBot.scss"

const ModalChatBot = ({ setIsModal }) => {

    return (
        <div className={'blackout'} onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-chat-bot'>
                <header className='modal-chat-bot__header'>
                    <h1 className='modal-chat-bot__title'>Чат боты</h1>
                    <button className='modal-chat-bot__btn-close'
                        onClick={(e) => {
                            setIsModal(false)
                        }}
                    >
                        <img src={close_icon} />
                    </button>
                </header>
                < TableBox
                    hideHeader
                    maxHeight='500px'
                />
            </div>
        </div>
    )
}

export { ModalChatBot }