import customHeaders, {getSpace} from '../../../common/headers'
import { MoonLoader } from 'react-spinners'
import { RavshanHttps } from '../../../fetchUrls'
import { CardExecutor } from '../../CardExecutor/CardExecutor'
import { useState, useEffect } from 'react'
import { DropDownSelector, InputSearch, ButtonBasic, Tabs } from "@lk-gtcom/ecomlab-components"
import useGeneralStore from '../../../store/general'
import './ModalAddExecutor.scss'

const btn_tab = [
    {
        label: 'Добавить',
        value: 'add'
    },
    {
        label: 'Добавленные',
        value: 'added'
    }  
]

const ModalAddExecutor = ({ addNewMember, close, projectAttendees, projectId }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [executorOptions, setExecutorOptions] = useState([])
    const [showOption, setShowOptions] = useState(false)
    const [executorSearchValue, setExecutorSearchValue] = useState('')
    const [checkboxState, setCheckboxState] = useState([])
    const [hideExecutors, setHideExecutors] = useState(false)
    const [selectedEmployeesPosition, setSelectedEmployeesPosition] = useState()
    const [load, setLoad] = useState(false)
    const [sort, setSort] = useState(null)
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    const currentExecutorOptions = () => {
        const sorting_list = executorOptions?.sort((el1, el2) => {
            if (el1.name.toLowerCase() > el2.name.toLowerCase()) {
                if (sort == 'ASC') {
                    return 1
                } else return -1
            }
            if (el1.name.toLowerCase() < el2.name.toLowerCase()) {
                if (sort == 'ASC') {
                    return -1
                } else return 1
            }

        })
        return sorting_list
    }

    const checkCheckboxValue = (id) => {
        return checkboxState?.indexOf(id) >= 0
    }

    const fetchPossibleAttendees = (abortController = new AbortController()) => {
        setLoad(true)
        const url = `${RavshanHttps}/api/v2/tasks/get_client_team`
        
        fetch(url, { headers: { ...headers, 'project-id': projectId }, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .then(json => {
                setExecutorOptions(json ? json : [])
            })
            .catch((e) => console.error(e))
            .finally((e) => {
                if(abortController.signal.aborted) return
                setLoad(false)
            })
    }

    useEffect(() => {
        const abortController = new AbortController()
        setCheckboxState(projectAttendees?.map(el => el?.id))
        fetchPossibleAttendees(abortController)
        const handler = (e) => {
            if (e.target.className.includes('blackout')) close()
            if (!e.target.className.includes('search_add-executor_active' || 'text')) setShowOptions(false)
        }

        document.addEventListener('click', handler)

        return () => {
            document.removeEventListener('click', handler)
            abortController.abort()
        }
    }, [projectAttendees]);

    return (
        <div className='blackout'>
            <div className='modal-add-executor'>
                <div className='modal-add-executor__header'>
                    <h1 className='title'>Список сотрудников</h1>
                </div>
                <div className='toolbar-medium'>
                    <DropDownSelector
                        options_prop={[]}
                        state={selectedEmployeesPosition}
                        setState={e => setSelectedEmployeesPosition(e)}
                        placeholder='Должность'
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                    />

                    <InputSearch
                        placeholder='Имя или почта сотрудника'
                        value={executorSearchValue}
                        onChange={e => setExecutorSearchValue(e)} />

                    {/* <ButtonBasic
                        lilac
                        width='440px'
                        text='Сортировать по сотруднику'
                        size='40px'
                        onClick={(e) => setSort(sort == 'ASC' ? 'DESC' : 'ASC')}
                    /> */}
                </div>

                <Tabs tabs={btn_tab} stopRedirect pageValue={pageValue} setPageValue={(e) => setPageValue(e)} />

                {load ?
                    <div className='center' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MoonLoader
                            size={40}
                            color="#7b7b7b"
                            speedMultiplier={0.5}
                        />
                    </div>
                    :
                    <div className='modal-add-executor__content'>
                        {currentExecutorOptions()?.filter(e => {
                            const nameLowerCased = e?.name.toLowerCase()
                            const id = e?.id
                            return (
                                hideExecutors ?
                                    !checkCheckboxValue(id) &
                                    (executorSearchValue ? nameLowerCased.includes(executorSearchValue.toLowerCase()) : true)
                                    :
                                    (executorSearchValue ? nameLowerCased.includes(executorSearchValue.toLowerCase()) : true)
                            )
                        })?.map(({ id, name, login, color }) => {

                            if (pageValue == 'add' && (projectAttendees?.indexOf(id) == -1)) {
                                return <CardExecutor
                                    id={id}
                                    login={login}
                                    name={name}
                                    color={color}
                                    status={projectAttendees?.indexOf(id) !== -1}
                                    addNewMember={(e) => {
                                        if (projectAttendees?.indexOf(id) == -1) {
                                            addNewMember([...projectAttendees?.map(el => el), ...e])
                                        } else {
                                            addNewMember([...projectAttendees?.filter((el) => el !== id)])
                                        }
                                    }}
                                />
                            }
                            if (pageValue == 'added' && (projectAttendees?.indexOf(id) !== -1)) {
                                return <CardExecutor
                                    id={id}
                                    login={login}
                                    name={name}
                                    color={color}
                                    status={projectAttendees?.indexOf(id) !== -1}
                                    addNewMember={(e) => {
                                        if (projectAttendees?.indexOf(id) == -1) {
                                            addNewMember([...projectAttendees?.map(el => el), ...e])
                                        } else {
                                            addNewMember([...projectAttendees?.filter((el) => el !== id)])
                                        }
                                    }}
                                />
                            }
                        }
                        )}
                    </div>
                }
            </div>
        </div >
    )
}

export { ModalAddExecutor }