import { Link } from 'react-router-dom';
import './TabsTop.scss'

const TabsTop = ({ tabs, pageValue, stopRedirect = false, setPageValue}) => {

    return (
        <div className='tabs-top'>
            {tabs?.map(({ label, value, notification, counter }) =>
                <Link
                    onClick={(e) => {
                        if (!pageValue) {
                            e.preventDefault()
                        }
                        if (setPageValue) {
                            setPageValue(value)
                        }
                    }}
                    to={!stopRedirect ? value : false}
                    key={value}
                    className={(pageValue == value) ? 'tabs-top__item_active' : 'tabs-top__item'}>
                    <p className='value-content'  onClick={(e) => {
                                if (!pageValue) {
                                    e.preventDefault()
                                }
                                if (setPageValue) {
                                    setPageValue(value)
                                }
                            }}
                    >{label}</p>
                    {notification && <div className='tabs-top__notification'></div>}
                    {counter && <div className='tabs-top__counter'>{'+' + counter}</div>}
                </Link>
            )}
        </div>
    )
}

export { TabsTop }