import { Link } from 'react-router-dom';
import "./ProductsListAdmin.scss"
import { DropDownSelector } from "@lk-gtcom/ecomlab-components";
import { TableBox } from "@lk-gtcom/ecomlab-components";

const ProductsListAdmin = () => {

    return (
        <div className='products-list-admin'>
            <div className='toolbar-top'>
                <DropDownSelector
                    options_prop={[]}
                    setState={e => (e)}
                    multi={true}
                    placeholder='Магазин'
                />

                <DropDownSelector
                    options_prop={[]}
                    setState={e => (e)}
                    placeholder='Категория'
                    multi={true}
                />

                <DropDownSelector
                    options_prop={[]}
                    setState={e => (e)}
                    placeholder='Бренд'
                    multi={true}
                />
                <DropDownSelector
                    options_prop={[]}
                    setState={e => (e)}
                    placeholder='Товар'
                    multi={true}
                />
                <button className="btn__green">Выбрать</button>
            </div>

            <TableBox
                        name={`НАЗВАНИЕ ТАБЛИЦЫ`}//${advCostTotal}
                        fetchedData={[]}
                        headers={[]}
                        paginator={true}
                    />
        </div>
    )
}

export { ProductsListAdmin }