import { TooltipBlack } from "../Tooltip/TooltipBlack/TooltipBlack"
import "./OptionsExecutor.scss"

const ExecutorPlaceholder = ({ name = '', color, email, iconStyle = {}, hideTooltip = false, author = false, ind, bkg = false }) => {
    if (typeof name !== 'string') {
        console.error(`Неверный тип параметра {name} для компонента ExecutorPlaceholder
  Wrong type of {name} param in ExecutorPlaceholder component`);
        return (
            <TooltipBlack
                child={
                    <div className='executor-placeholder'>
                        <p>{'-'}</p>
                    </div>
                }
                text={'Ошибка данных'}
            />
        )
    }

    return (
        <TooltipBlack
            hideTooltip={hideTooltip}
            child={
                <div style={{ backgroundColor: bkg ? bkg : color, ...iconStyle }} className='executor-placeholder'>
                    <p className="executor-placeholder__text">
                        {name?.length > 0 ? name?.split(' ').map(str => str[0]).reduce((acc, current) => acc += current, '')?.toUpperCase() : '-'}</p>
                </div>
            }
            text={
                <>
                    <p>{!name?.match('@') ? name : null}</p>
                    <p>{email}</p>
                </>
            }
        />

    )
}

const OptionsExecutor = ({ id, name, email, color, img, onClick }) => {
    return (
        <div
            className='options-executor'
            onClick={() => onClick({ id, name, email, img, color })}
        >
            {img ? <img src={img} /> : <ExecutorPlaceholder name={name} email={email} hideTooltip color={color} />}
            <div className="text-box">
                <p className="text" style={{ textAlign: 'left' }}>{name}</p>
                <p className="text_grey">{email}</p>
            </div>
        </div>
    )
}

export { OptionsExecutor, ExecutorPlaceholder }