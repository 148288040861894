import mood_negative from './img/mood_negative.svg'
import moood_positive from './img/moood_positive.svg'
import { Checkbox } from '@lk-gtcom/ecomlab-components';
import { useState, useRef, useEffect } from "react"

export const ReviewLlist = ({ ...props }) => {
    const { keyword, keyword_sum, tag, tag_count, tag_in_feedback_count, keyword_list, tag_list, product_valuation, feedback_count, positive, setChecked, tags = false, checked } = props

    const [isCheck, setIscheck] = useState(false)
    const checkboxRef = useRef()

    useEffect(() => {
        if (tags) {
            setIscheck(checked.indexOf(tag) !== -1)
        } else {
            setIscheck(checked.indexOf(keyword) !== -1)
        }
         
    }, [checked, keyword_list, tag_list]);

    return <div key={keyword + keyword_sum + tag_count + positive}
        className={`review-item ${positive ?
            (isCheck ? ' positive-bkg_active' : ' positive-bkg')
            :
            (isCheck ? ' negative_bkg_active' : ' negative_bkg')}
        `}
        onClick={e => {
            checkboxRef.current?.click()
        }}

    >
        <div className='review-item__content'>
            <Checkbox
                value={isCheck}
                onChange={(e) => {
                    setIscheck(e)
                    if (typeof setChecked === 'function') {
                        if (e) {
                            setChecked(prev => [...prev, tags ? tag : keyword])
                        } else {
                            setChecked(prev => prev.filter(el => tags ? el !== tag : el !== keyword))
                        }
                    }
                }}
                ref={checkboxRef}
            />
            <p className='text'>{keyword ? keyword : '-'}</p>
            {tag && <p className='text'>{tag}</p>}
            <div className='info-sum'>
                {(feedback_count || feedback_count == 0) ? <p className='text_sum'>Отзывов: <span>{feedback_count}</span></p> : null}
                {(tag_count || tag_count == 0) ? <p className='text_sum'>Тегов: <span>{tag_count}</span></p> : null}
                {(tag_in_feedback_count || tag_in_feedback_count == 0) ? <p className='text_sum'>Товаров: <span>{tag_in_feedback_count}</span></p> : null}
            </div>
            <img src={positive ? moood_positive : mood_negative} />
        </div>
    </div>
}