
import "./CardStatisticsVer2.scss"

const CardStatisticsVer2 = ({ label, value, unit, dynamic }) => {

    return (
        <div className='card-statistics-ver2'>
            <h4 className='card-item__title'>{label}</h4>
            <div className='value-and-dynamic'>
                <p className='card-item__value-content'>{
                    (value || value == 0) ? (typeof value == 'number' ? value.toLocaleString() 
                        : 
                        Array.isArray(value) ? <>{value.slice(0, value?.length - 1)} {<span> {value?.at(-1)}</span>} </> : value
                    ) : 0
                }
                </p>
               {(dynamic || dynamic == 0)  && <div className={dynamic >= 0 ? 'dynamic_violet' : 'dynamic_red'}>{`${dynamic}%`}</div>}
            </div>
        </div>
    )
}

export { CardStatisticsVer2 }