import { useState, useEffect, useRef } from 'react';
import { DropDownSelector, RangeBox } from '@lk-gtcom/ecomlab-components';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
  fetchCampaignFilter,
  fetchWarehouseFilter,
} from '../../common/filtersFunc';
import { AnalyticsPageComponent } from '../../components/AnalyticsPageComponent/AnalyticsPageComponents';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { BtnDropCheckbox } from '../../components/BtnDropCheckbox/BtnDropCheckbox';
import routes from './routes';
import useGeneralStore from '../../store/general';

const pageTabsMap = {
  [routes.analytics]: [
    {
      label: 'По датам',
      value: 'date',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
  ],
  [routes.advertising]: [
    {
      label: 'По датам',
      value: 'date',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    {
      label: 'По упущенным продажам',
      value: 'missed_sales',
    },
  ],
  [routes.ordersAndSales]: [
    {
      label: 'Список заказов',
      value: 'list',
    },
    {
      label: 'По складам',
      value: 'warehouse',
    },
    {
      label: 'Товары в заказах',
      value: 'items',
    },
    {
      label: 'По географии',
      value: 'geo',
    },
    {
      label: 'Заказы по статусам',
      value: 'status',
    },
    // {
    //     label: 'По категориям',
    //     value: 'category'
    // },
    // {
    //     label: 'По возвратам',
    //     value: 'returns'
    // },
  ],
  [routes.returnAndCancellations]: [
    {
      label: 'Отмененные и возвращенные заказы',
      value: 'list',
    },
    {
      label: 'По причинам',
      value: 'reason',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    // {
    //     label: 'По возвратам',
    //     value: 'returns'
    // },
  ],
  [routes.prices]: [
    {
      label: 'По датам',
      value: 'date',
    },
    // {
    //     label: 'По актуальным ценам',
    //     value: 'actual_prices'
    // },
    {
      label: 'По маркетплейсам',
      value: 'by_mp',
    },
    // {
    //     label: 'По товарам',
    //     value: 'product',
    // },
    // {
    //     label: 'По площадкам',
    //     value: 'marketplace'
    // },
    // {
    //     label: 'По конкурентам',
    //     value: 'competitors'
    // },
    // {
    //     label: 'По эластичности',
    //     value: 'elasrtic'
    // },
    // {
    //     label: 'По себестоимости',
    //     value: 'net'
    // },
  ],
  [routes.stock]: [
    {
      label: 'По датам',
      value: 'date',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    {
      label: 'По дате и складам',
      value: 'date_and_wh',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    {
      label: 'По ABC',
      value: 'abc',
    },
  ],
};

const urlMap = {
  [routes.analytics]: 'general',
  [routes.advertising]: 'advertising',
  [routes.ordersAndSales]: 'orders',
  [routes.prices]: 'prices',
  [routes.stock]: 'stock',
  [routes.returnAndCancellations]: 'returns_cancellations',
};

// const list_select = [
//   {
//     label: 'Площадка',
//   },
//   {
//     label: 'Магазин',
//   },
//   {
//     label: 'Бренд',
//   },
//   {
//     label: 'Кампания',
//   },
//   {
//     label: 'Категория',
//   },
//   {
//     label: 'Склад',
//   },
// ];

const mp_options = [
  {
    label: 'Ozon',
    value: 1,
  },
  {
    label: 'Wildberries',
    value: 3,
  },
  {
    label: 'Yandex',
    value: 2,
  },
];

const AnalyticsPage = ({ route, activeSubItems }) => {
  const apiIdRef = useRef();
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);

  const [selectedMp, setSelectedMp] = useState([{ label: 'Ozon', value: 1 }]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(['all']);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [campaignPage, setCampaignPage] = useState(1);
  const [warehousePage, setWarehousePage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [campaignQuery, setCampaignQuery] = useState('');
  const [warehouseQuery, setWarehouseQuery] = useState('');

  const [checkboxValues, setCheckboxValues] = useState([
    'Площадка',
    'Магазин',
    'Категория',
    'Бренд',
    'Кампания',
    'Склад',
  ]);

  const [defaultDate, setDefaultDate] = useState('');
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: setApiData,
      searchParam: apiQuery,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
    });
  }, [
    apiPage,
    apiQuery,
    selectedMp,
    selectedCampaign,
    selectedWarehouse,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
    selectedCampaign,
    selectedWarehouse,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    categoryData,
    selectedApi,
    selectedCategory,
    brandPage,
    brandQuery,
    selectedCampaign,
    selectedWarehouse,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchCampaignFilter({
      selectedSpace: selectedSpace?.value,
      page: campaignPage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      brandData,
      dateFrom,
      dateTo,
      searchParam: campaignQuery,
      setCampaignData: (e) => setCampaignData(e),
    });
  }, [
    brandData,
    selectedApi,
    selectedCategory,
    selectedBrand,
    campaignPage,
    campaignQuery,
    selectedCampaign,
    selectedWarehouse,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchWarehouseFilter({
      selectedSpace: selectedSpace?.value,
      page: warehousePage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      brandData,
      dateFrom,
      dateTo,
      searchParam: warehouseQuery,
      setWarehouseData: (e) => setWarehouseData(e),
    });
  }, [
    brandData,
    selectedApi,
    selectedCategory,
    selectedBrand,
    selectedCampaign,
    warehousePage,
    warehouseQuery,
    selectedWarehouse,
    selectedSpace,
  ]);

  useEffect(() => {
    apiIdRef.current?.focus();
  }, []);

  const includeCampaign = Array.isArray(selectedMp)
    ? selectedMp?.some((item) => item.label === 'Все') ||
      selectedMp?.some((item) => item.label === 'Yandex')
    : false;

  const list_select = [
    { label: 'Площадка' },
    { label: 'Магазин' },
    { label: 'Бренд' },
    ...(includeCampaign ? [{ label: 'Кампания' }] : []),
    { label: 'Категория' },
    { label: 'Склад' },
  ];

  const filters = (
    <FilterContainer
      onReset={(e) => {
        setSelectedApi([]);
        setSelectedCategory([]);
        setSelectedBrand([]);
        setSelectedCampaign([]);
        setSelectedWarehouse([]);
      }}
    >
      {!checkboxValues.includes('Площадка') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={mp_options}
          state={selectedMp}
          setState={(e) => setSelectedMp(e)}
          className="connections_page_selector"
          placeholder="Площадка"
          defaultValue={[{ label: 'Ozon', value: 1 }]}
          multi
        />
      )}

      {!checkboxValues.includes('Магазин') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={apiData}
          state={selectedApi}
          setState={(e) => setSelectedApi(e)}
          className="connections_page_selector"
          placeholder="Магазин"
          fetchCallback={(e) => setApiPage(e)}
          setInputChange={(e) => setApiQuery(e)}
          inputRef={true}
          ref={apiIdRef}
          multi
        />
      )}

      {!checkboxValues.includes('Категория') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={categoryData}
          state={selectedCategory}
          setState={(e) => setSelectedCategory(e)}
          className="connections_page_selector"
          placeholder="Категория"
          fetchCallback={(e) => setCategoryPage(e)}
          setInputChange={(e) => setCategoryQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
        />
      )}

      {!checkboxValues.includes('Бренд') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={brandData}
          state={selectedBrand}
          setState={(e) => setSelectedBrand(e)}
          className="connections_page_selector"
          placeholder="Бренд"
          fetchCallback={(e) => setBrandPage(e)}
          setInputChange={(e) => setBrandQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
        />
      )}

      {!checkboxValues.includes('Кампания') && route == 'analytics'
        ? null
        : includeCampaign && (
            <DropDownSelector
              options_prop={campaignData}
              state={selectedCampaign}
              setState={(e) => setSelectedCampaign(e)}
              className="connections_page_selector"
              placeholder="Кампания"
              fetchCallback={(e) => setCampaignPage(e)}
              setInputChange={(e) => setCampaignQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              multi
            />
          )}

      {!checkboxValues.includes('Склад') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={warehouseData}
          state={selectedWarehouse}
          setState={(e) => setSelectedWarehouse(e)}
          className="connections_page_selector"
          placeholder="Склад"
          fetchCallback={(e) => setWarehousePage(e)}
          setInputChange={(e) => setWarehouseQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
        />
      )}

      {route === 'analytics' && (
        <BtnDropCheckbox
          checkboxValues={checkboxValues}
          setCheckboxValues={(e) => setCheckboxValues(e)}
          list={list_select}
        />
      )}
    </FilterContainer>
  );

  const rangeBox = (
    <RangeBox defaultValue={defaultDate} setDate={(e) => setDate(e)} />
  );

  return (
    <AnalyticsPageComponent
      activeSubItems={activeSubItems}
      tabs={pageTabsMap[route]}
      relativeUrl={urlMap[route]}
      rangeBox={rangeBox}
      date={date}
      filters={filters}
      selectedApi={selectedApi}
      selectedBrand={selectedBrand}
      selectedCategory={selectedCategory}
      selectedCampaign={selectedCampaign}
      selectedWarehouse={selectedWarehouse}
      route={route}
      checkboxValues={checkboxValues}
      setCheckboxValues={(e) => setCheckboxValues(e)}
      setSelectedMp={(e) => setSelectedMp(e)}
      setSelectedApi={(e) => setSelectedApi(e)}
      setSelectedCategory={(e) => setSelectedCategory(e)}
      setSelectedBrand={(e) => setSelectedBrand(e)}
      setSelectedWarehouse={(e) => setSelectedWarehouse(e)}
      setDate={(e) => setDate(e)}
      setDefaultDate={(e) => setDefaultDate(e)}
    />
  );
};

export { AnalyticsPage };
