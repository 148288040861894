import { useState, useEffect, useRef } from 'react';
import { DropDownSelector } from '@lk-gtcom/ecomlab-components';
import { AndreyHttps, AndreyHttp, OlegHttps, OlegHttp } from '../../fetchUrls';
import RangeDatePicker from '../RangeDatePicker';
import { TableBox } from '@lk-gtcom/ecomlab-components';
import {
  fetchApiFilter,
  fetchCategoryFilter,
  fetchBrandFilter,
  fetchProductFilter,
} from '../../common/filtersFunc';
import './UpdatePrices.scss';
import useGeneralStore from '../../store/general';

const UpdatePrices = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);

  const [tooltipShow, setTooltipShow] = useState(false);
  const [tooltipLink, setTooltipLink] = useState(false);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const filtersRef = useRef();

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [productPage, setProductPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [productQuery, setProductQuery] = useState('');

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [rangeOfView, setRangeOfView] = useState('7');
  const [contentTable, setContentTable] = useState(false);

  const [fetchedData, setFetchedData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [checkedProduct, setCheckedProduct] = useState([]);

  const [tableHeaders, setTableHeaders] = useState([]);
  const [columnSize, setColumnSize] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const paginatorRef = useRef();

  const [sort, setSort] = useState({});

  const client_id = localStorage.getItem('client_id');

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      client_id,
    });
  }, [selectedSpace, apiPage, apiQuery, client_id]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
      client_id,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
    client_id,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
      client_id,
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    brandPage,
    brandQuery,
    client_id,
  ]);

  useEffect(() => {
    fetchProductFilter({
      selectedSpace: selectedSpace?.value,
      page: productPage,
      apiData,
      categoryData,
      selectedCategory,
      selectedBrand,
      brandData,
      selectedApi,
      searchParam: productQuery,
      setProductsData: (e) => setProductsData(e),
      client_id,
    });
  }, [
    selectedSpace,
    selectedBrand,
    selectedApi,
    selectedCategory,
    apiData,
    brandData,
    categoryData,
    productPage,
    productQuery,
    client_id,
  ]);

  const fetchAllAccountData = (params) => {
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }
    const abortController = new AbortController();
    setLoading(true);
    setFetchedData([]);
    let url;

    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/product/list${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    } else {
      url = `${OlegHttp}/api/v1/product/list${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    }

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let sku_id_list;
    let limit = paginatorRef.current.limit;
    let page = paginatorRef.current.page;

    if (selectedApi.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedBrand.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedProduct.length > 0) {
      sku_id_list = selectedProduct.map((el) => el.value);
    } else {
      sku_id_list = ['all'];
    }

    const body = JSON.stringify({
      client_id,
      api_id_list,
      category_id_list,
      brand_id_list,
      sku_id_list,
      limit,
      page,
      filters,
    });

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: client_id,
    };

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then((json) => {
        const { labels, data, total, sort, column_size } = json;
        if (labels) setTableHeaders(labels);
        if (data) setFetchedData(data);
        if (total) paginatorRef.current.setTotal(total);
        if (sort) setSort(sort);
        if (column_size) setColumnSize(column_size);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return abortController;
  };

  return (
    <div className="update-prices">
      <div className="rich__select-box">
        <DropDownSelector
          options_prop={apiData}
          state={selectedApi}
          setState={(e) => setSelectedApi(e)}
          placeholder="Магазин"
          fetchCallback={(e) => setApiPage(e)}
          setInputChange={(e) => setApiQuery(e)}
        />

        <DropDownSelector
          options_prop={categoryData}
          state={selectedCategory}
          setState={(e) => setSelectedCategory(e)}
          placeholder="Категория"
          multi={true}
          fetchCallback={(e) => setCategoryPage(e)}
          setInputChange={(e) => setCategoryQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
        />

        <DropDownSelector
          options_prop={brandData}
          state={selectedBrand}
          setState={(e) => setSelectedBrand(e)}
          placeholder="Бренд"
          multi={true}
          fetchCallback={(e) => setBrandPage(e)}
          setInputChange={(e) => setBrandQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
        />
        <DropDownSelector
          options_prop={productsData}
          state={selectedProduct}
          setState={(e) => setSelectedProduct(e)}
          placeholder="Товар"
          multi={true}
          fetchCallback={(e) => setProductPage(e)}
          setInputChange={(e) => setProductQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
        />
        <button
          className="btn__green"
          onClick={(e) => {
            fetchAllAccountData();
          }}
        >
          Выбрать
        </button>
        <RangeDatePicker
          range={rangeOfView}
          setDateFrom={(e) => setDateFrom(e)}
          setDateTo={(e) => setDateTo(e)}
          style={{ display: 'none' }}
        />
      </div>

      <div className="rich__group-btn">
        <div className="round__group-blue">
          <button className="btn__bej">Обновить цены</button>
        </div>
        <div className="round__group-btn">
          <button className="btn__doc"></button>
          <button className="btn__doc-add"></button>
        </div>
      </div>

      <TableBox
        tableUrl={tableUrl}
        tableBody={tableBody}
        loading={loading}
        fetchedData={fetchedData}
        headers={tableHeaders}
        onCheck={(e) => setCheckedProduct(e)}
        paginator={true}
        sort={sort}
        sortingFunc={(e) => fetchAllAccountData(e)}
        fetchCallback={(e) => fetchAllAccountData(e)}
        paginatorDependencies={[
          selectedApi,
          selectedCategory,
          selectedBrand,
          dateTo,
          dateFrom,
        ]}
        ref={paginatorRef}
        columnSize={columnSize}
      />
    </div>
  );
};

export { UpdatePrices };
