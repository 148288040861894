import customHeaders, { getSpace } from '../../common/headers';
import add_icon from './img/add_icon.svg';
import rules_tab from './img/rules_tab.svg';
import refresh_icon from './img/refresh_icon.svg';
import setting_balances_tab from './img/setting_balances_tab.svg';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { ModalPromotionRules } from '../Modal/ModalPromotionRules/ModalPromotionRules';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from 'react';
import {
  fetchMasterBrandFilter,
  fetchMasterCategoryFilter,
} from '../../common/filtersFunc';
import {
  Tabs,
  ButtonBasic,
  TableBox,
  DropDownSelector,
  SearchComponent,
} from '@lk-gtcom/ecomlab-components';
import {
  KonstantinCreateProductHttps,
  KonstantinMasterHttp,
  KonstantinMasterHttps,
} from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import './SetRemainingGoodMasterProduct.scss';

const options_period = [
  {
    label: 'дням',
    value: 'day',
  },
  {
    label: 'неделям',
    value: 'week',
  },

  {
    label: 'месяцам',
    value: 'month',
  },
];

const btn_tab = [
  {
    label: 'Установка остатков',
    value: 'setting-balances',
    icon: setting_balances_tab,
  },
  {
    label: 'Правила установки остатков',
    value: 'rules',
    icon: rules_tab,
  },
];

const SetRemainingGoodMasterProduct = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState('product');

  const [warehouseData, setWarehouseData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(options_period[0]);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [shopPage, setShopPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [shopQuery, setShopQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [isModal, setIsModal] = useState(false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  const [dateFrom, dateTo] = date;
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { loading, sort } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;

  const activeTab = useParams()['*'];
  const navigate = useNavigate();

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/tables/stocks${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/tables/stocks${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    }

    let brand_id_list;
    let category_id_list;
    let offer_id_name_search = searchValue;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam,
      offer_id_name_search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`;
    }

    let brand_id_list;
    let category_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setSearchData(json.map(({ id, name }) => ({ label: name, value: id })));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchUpdateStock = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/master_products/stocks`;
    } else {
      url = `${KonstantinCreateProductHttps}/master_products/stocks`;
    }

    const stocks = checkedProducts?.map((el) => {
      const { product_id, Артикул } = el;

      return {
        product_id,
        offer_id: Артикул,
        stock: el?.['Остаток на ваших складах'],
      };
    });

    const body = JSON.stringify({
      stocks,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        const abortController = new AbortController();
        fetchTableData([], abortController);
        return () => abortController.abort();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchUpdateOneStock = (productId, offerId, stock) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/master_products/stocks`;
    } else {
      url = `${KonstantinCreateProductHttps}/master_products/stocks`;
    }

    const stocks = [
      {
        product_id: productId,
        offer_id: offerId,
        stock: stock,
      },
    ];
    const body = JSON.stringify({
      stocks,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        console.log(json);
        const abortController = new AbortController();
        fetchTableData([], abortController);
        return () => abortController.abort();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchMasterCategoryFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
    return () => abortController.abort();
  }, [
    selectedSpace,
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery,
    selectedBrand,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchMasterBrandFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
    return () => abortController.abort();
  }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery]);

  useEffect(() => {
    const abortController = new AbortController();

    resetTable();
    fetchTableData([], abortController);

    return () => abortController.abort();
  }, [selectedSpace, selectedBrand, selectedCategory]);

  const handleSearch = useCallback((e) => {
    const abortController = new AbortController();
    fetchSearch(e, abortController);
    return () => abortController.abort();
  });

  const handleSaveOneRemainder = (e) => {
    const { inputEvent, actionEvent } = e;
    const productId = actionEvent[0]?.product_id;
    const offerId = inputEvent?.id[0];
    const stock = +inputEvent?.value;
    if (productId && offerId && stock) {
      fetchUpdateOneStock(productId, offerId, stock);
    }
  };

  return (
    <>
      {isModal && <ModalPromotionRules setIsModal={(e) => setIsModal(e)} />}

      <div className="set-remaining-goods-master-product">
        <h1 className="title_main">Установка остатков</h1>

        <div className="toolbar-top">
          <FilterContainer
            onReset={(e) => {
              setSelectedWarehouse([]);
              setSelectedCategory([]);
              setSelectedBrand([]);
            }}
          >
            <DropDownSelector
              options_prop={categoryData}
              state={selectedCategory}
              setState={(e) => setSelectedCategory(e)}
              placeholder="Категория"
              multi={true}
              fetchCallback={(e) => setCategoryPage(e)}
              setInputChange={(e) => setCategoryQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
            />

            <DropDownSelector
              options_prop={brandData}
              state={selectedBrand}
              setState={(e) => setSelectedBrand(e)}
              placeholder="Бренд"
              multi={true}
              fetchCallback={(e) => setBrandPage(e)}
              setInputChange={(e) => setBrandQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
            />

            <DropDownSelector
              options_prop={warehouseData}
              state={selectedWarehouse}
              setState={(e) => setSelectedWarehouse(e)}
              className="connections_page_selector"
              placeholder="Склад"
              multi
            />
            {pageValue != 'rules' && (
              <SearchComponent
                searchValue={searchValue}
                errSearch={false}
                placeholder="Искать по артикулу или названию"
                errText="Неверный артикул или название"
                setSearchValue={setSearchValue}
                fetchData={fetchTableData}
                fetchCallback={handleSearch}
                data={searchData}
              />
            )}
          </FilterContainer>
          <ButtonBasic
            violet
            //   disabled={isDisabledBtn}
            text={
              pageValue != 'rules' ? (
                <div className="content-btn" onClick={fetchUpdateStock}>
                  Обновить остатки
                  <img src={refresh_icon} />
                </div>
              ) : (
                <div className="content-btn">
                  Добавить правило <img src={add_icon} />
                </div>
              )
            }
            onClick={(e) => {
              if (pageValue == 'rules') {
                setIsModal(true);
              }
            }}
            width="auto"
            size="40px"
          />
        </div>

        <div className="toolbar-medium">
          <Tabs tabs={btn_tab} pageValue={pageValue} />
        </div>

        <TableBox
          {...tableProps}
          paginator={true}
          ref={paginatorRef}
          onCheck={(e) => setCheckedProducts(e)}
          fetchCallback={fetchTableData}
          tableUrl={tableUrl}
          tableBody={tableBody}
          onInputAction={(e) => {
            handleSaveOneRemainder(e);
          }}
        />
      </div>
    </>
  );
};

export { SetRemainingGoodMasterProduct };
