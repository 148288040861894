import { useState, useEffect } from 'react';
import './ModalStrategyParameters.scss'
import { InputDinamycPlaceholder, DropDownSelector } from "@lk-gtcom/ecomlab-components"

const ModalStrategyParameters = ({ setIsModal, setIsModalChoiceOfStrategy, currentStrategy, setCurrentStrategy }) => {

    const strategy_item = [
        // {
        //     label: 'Мониторинг конкурентов',
        //     value: 'Мониторинг конкурентов'
        // },
        // {
        //     label: 'Максимум заказов',
        //     value: 'Максимум заказов'
        // },
        {
            label: 'Целевой ДРР, %',
            value: 'Целевой ДРР, %'
        },
        // {
        //     label: 'Высокая оборачиваемость',
        //     value: 'Высокая оборачиваемость'
        // },
    ]
    const [selectedStrategy, setSelectedStrategy] = useState([])

    useEffect(() => {
        setCurrentStrategy(selectedStrategy.value)
    }, [selectedStrategy]);

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-strategy-parameters'>
                <button className='btn-back'
                    onClick={(e) => {
                        setIsModalChoiceOfStrategy(true)
                        setIsModal(false)
                    }}
                ></button>
                <h2 className='title'>Параметры стратегии</h2>
                <DropDownSelector
                    options_prop={strategy_item}
                    state={selectedStrategy}
                    setState={e => setSelectedStrategy(e)}
                    placeholder='Выберите стратегию'
                    isClearable={false}
                    defaultValue={{ label: currentStrategy, value: currentStrategy }}
                />
                <div className='input-field-group'>
                    {currentStrategy == 'Целевой ДРР, %' && <InputDinamycPlaceholder
                        onChange={(e) => (e)}
                        err={false}
                        textErr={'Неверное значение'}
                        classname='autorization__input-field'
                        placeholder='Целевой ДРР %'
                        autoComplete={false}
                    />}

                    {currentStrategy == 'Высокая оборачиваемость' &&
                        <InputDinamycPlaceholder
                            onChange={(e) => (e)}
                            err={false}
                            textErr={'Неверное значение'}
                            classname='autorization__input-field'
                            placeholder='Минимальный остаток товара, шт.'
                            autoComplete={false}
                        />
                    }

                    {currentStrategy != 'Целевой ДРР, %' && <div className='input-field-description'>
                        <p className='text'>Цена товара</p>
                        <div className="double-group">
                            <InputDinamycPlaceholder
                                onChange={(e) => (e)}
                                err={false}
                                textErr={'Неверное значение'}
                                classname='autorization__input-field'
                                placeholder='От'
                                autoComplete={false}
                            />
                            <InputDinamycPlaceholder
                                onChange={(e) => (e)}
                                err={false}
                                textErr={'Неверное значение'}
                                classname='autorization__input-field'
                                placeholder='До'
                                autoComplete={false}
                            />
                        </div>
                    </div>}
                </div>
                <button className='btn__blue'
                    onClick={(e) => setIsModal(false)}
                >Сохранить</button>
            </div>
        </div>
    );
}

export { ModalStrategyParameters };