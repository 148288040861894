import customHeaders, {getSpace} from '../../common/headers';
import all_tab from './img/all_tab.svg'
import add_icon from './img/add_icon.svg'
import active_tab from './img/active_tab.svg'
import disactive_tab from './img/disactive_tab.svg'
import { useParams } from 'react-router-dom';
import { RavshanHttps } from '../../fetchUrls';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { ModalPromotionRules } from '../Modal/ModalPromotionRules/ModalPromotionRules';
import { useState, useEffect, useRef } from 'react';
import { TableBox, DropDownSelector, ButtonBasic, Tabs } from "@lk-gtcom/ecomlab-components";
import { fetchApiFilter, fetchBrandFilter, fetchCategoryFilter } from '../../common/filtersFunc';
import useGeneralStore from '../../store/general';
import "./PromotionRules.scss"

const options_prop = [
    {
        label: 'Ozon',
        value: 'ozon',
        intValue: 1
    },
    {
        label: 'Wildberries',
        value: 'wb',
        intValue: 2
    },
    {
        label: 'Yandex',
        value: 'yandex',
        intValue: 3
    },
]

const btn_tab = [
    {
        label: 'Все',
        value: 'all',
        icon: all_tab
    },
    {
        label: 'Активные',
        value: 'running',
        icon: active_tab
    },
    {
        label: 'Не активные',
        value: 'stopped',
        icon: disactive_tab
    }
]

const PromotionRules = ({ }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [isModal, setIsModal] = useState(false)

    const [apiData, setApiData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [brandData, setBrandData] = useState([])

    const [selectedMarketplace, setSelectedMarketplace] = useState([])
    const [selectedApi, setSelectedApi] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])

    const [apiPage, setApiPage] = useState(1)
    const [categoryPage, setCategoryPage] = useState(1)
    const [brandPage, setBrandPage] = useState(1)

    const [apiQuery, setApiQuery] = useState('')
    const [categoryQuery, setCategoryQuery] = useState('')
    const [brandQuery, setBrandQuery] = useState('')

    const activeTab = useParams()['*']
    const [pageValue, setPageValue] = useState(btn_tab[0])

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs
    const { total } = tableProps
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
   const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
    const [ruleInfo, setRuleInfo] = useState({})

    const fetchTable = (params) => {
        let sort, filters
        if(params){
            [sort, filters] = params
        }

        const url = `${RavshanHttps}/api/strategies/rule/get`

        const body = JSON.stringify({
            api_id: selectedApi?.map(el => el?.value),
            brand: selectedBrand?.map(el => el?.value),
            category: selectedCategory?.map(el => el?.value),
            order_param: sort?.[0],
            order_param_direction: sort?.[1],
            filters,
            page: paginatorRef.current?.page,
            limit: paginatorRef.current?.limit,
            status: pageValue[0]?.label
        })

        fetch(url, { 
            body,
            method: 'POST', 
            headers
        })
            .then(res => {
                if (res.ok) {
                    return res?.json()
                } else {
                    throw new Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setTable(json)
                setTableUrl(url) 
                setTableBody(body)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
            })

    }

    const onActionHandler = (prop) => {
        const name = prop?.['Название правила']
        const status = prop?.['Статус']
        const rule_id = prop?.Rule_id?.[0]
        setRuleInfo({name, status, rule_id, isEdit: true, isCreate: false})
        setIsModal(true)
    }

    useEffect(() => {
        const current_page = btn_tab.filter((el) => el?.value == activeTab)
        setPageValue(current_page)
    }, [activeTab])

    useEffect(() => {
        fetchApiFilter({ selectedSpace: selectedSpace?.value, page: apiPage, setApiData: e => setApiData(e), searchParam: apiQuery, type: selectedMarketplace?.value })
    }, [selectedSpace, apiPage, apiQuery, selectedMarketplace])

    useEffect(() => {
        fetchCategoryFilter({ selectedSpace: selectedSpace?.value, page: categoryPage, apiData, selectedApi, setCategoryData: e => setCategoryData(e), searchParam: categoryQuery })
    }, [selectedSpace, apiData, selectedApi, categoryPage, categoryQuery])

    useEffect(() => {
        fetchBrandFilter({ selectedSpace: selectedSpace?.value, page: brandPage, apiData, categoryData, selectedCategory, searchParam: brandQuery, selectedApi, setBrandData: e => setBrandData(e) })
    }, [selectedSpace, categoryData, selectedApi, selectedCategory, brandPage, brandQuery])

    useEffect(() => {
        if(!isModal){
            fetchTable()
        }
    }, [selectedSpace, selectedApi, selectedBrand, selectedCategory, isModal, pageValue])

    return (
        <>
            {isModal && <ModalPromotionRules isEdit={false} isCreate={true} {...ruleInfo} setRuleInfo={e => setRuleInfo(e)} setIsModal={setIsModal}  />}

            <div className='promotion-rules'>
                <h1 className='title_main-page'>Правила</h1>

                <div className='toolbar-top'>
                    <FilterContainer
                        onReset={(e) => {
                            setSelectedMarketplace([])
                            setSelectedApi([])
                            setSelectedCategory([])
                            setSelectedBrand([])
                        }}
                    >
                        <DropDownSelector
                            style={{ maxWidth: '210px' }}
                            options_prop={options_prop}
                            state={selectedMarketplace}
                            setState={e => setSelectedMarketplace(e)}
                            className='connections_page_selector'
                            placeholder='Площадка'
                            defaultValue={options_prop[0]}
                        />

                        <DropDownSelector
                            style={{ maxWidth: '210px' }}
                            options_prop={apiData}
                            state={selectedApi}
                            setState={e => setSelectedApi(e)}
                            className='connections_page_selector'
                            placeholder='Магазин'
                            fetchCallback={(e) => setApiPage(e)}
                            setInputChange={(e) => setApiQuery(e)}
                            multi
                            defaultValue={[{label: 'Все', value: 'all'}]}
                        />
                        <DropDownSelector
                            style={{ maxWidth: '210px' }}
                            options_prop={categoryData}
                            state={selectedCategory}
                            setState={e => setSelectedCategory(e)}
                            className='connections_page_selector'
                            placeholder='Категория'
                            fetchCallback={(e) => setCategoryPage(e)}
                            setInputChange={(e) => setCategoryQuery(e)}
                            multi
                            defaultValue={[{label: 'Все', value: 'all'}]}
                        />
                        <DropDownSelector
                            style={{ maxWidth: '210px' }}
                            options_prop={brandData}
                            state={selectedBrand}
                            setState={e => setSelectedBrand(e)}
                            className='connections_page_selector'
                            placeholder='Бренд'
                            fetchCallback={(e) => setBrandPage(e)}
                            setInputChange={(e) => setBrandQuery(e)}
                            multi
                            defaultValue={[{label: 'Все', value: 'all'}]}
                        />
                    </FilterContainer>

                    <ButtonBasic
                        violet
                        onClick={(e) => setIsModal(true)}
                        text={<p style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'center',
                            gap: '8px'
                        }}>Добавить правило <img src={add_icon} /></p>}
                    />
                </div>

                <div className='toolbar-medium'>
                    <Tabs tabs={btn_tab} 
                    pageValue={pageValue[0]?.value} />
                </div>

                <TableBox
                    tableUrl={tableUrl}
                        tableBody={tableBody}
                        onAction={e => onActionHandler(e)}
                        ref={paginatorRef}
                        paginator={true}
                        fetchCallback={e => fetchTable(e)}
                        {...tableProps}
                        statistic={[{
                            label: 'Правил',
                            sum: total
                    }]}
                />
            </div>
        </>

    )


}

export { PromotionRules }