import { Link } from 'react-router-dom';
import add_icon from './img/add_icon.svg'
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components"
import './CardExecutor.scss'

const CardExecutor = ({ key, name, login, id, color, isAdmin = true, addNewMember, status = false }) => {

    return (
        <div className='card-executor' key={key}>
            <div className='executor-absolute-box'>
                <ExecutorPlaceholder
                    name={name}
                    color={color}
                />
            </div>

            <div className='card-executor__content'>
                <h3 className='card-executor__name'>{name}</h3>
            </div>

            <p className='card-executor__email'>{login}</p>
            {
            isAdmin ?
                status ?

                    <button
                        className='card-executor__btn-red'
                        onClick={(e) => addNewMember([id])}
                    >
                        Убрать
                    </button>
                    // <ButtonBasic
                    //     grey
                    //     size='16px'
                    //     minWidth='100%'
                    //     width='100%'
                    //     text={<div className='content-btn' style={{ color: '#E11034' }}></div>}

                    // />
                    :

                    <button
                        className='card-executor__btn-green'
                        onClick={(e) => addNewMember([id])}
                    >
                        Добавить
                    </button>
                    // <ButtonBasic
                    //     grey
                    //     size='16px'
                    //     minWidth='100%'
                    //     width='100%'
                    //     text={<div className='content-btn' style={{ color: '#347A40' }}>Добавить</div>}
                    //     onClick={(e) => addNewMember([id])}
                    // />
                :
                    null
            }
        </div>
    )
}

export { CardExecutor }
