import { useState, useEffect } from 'react'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import CompetitorsLinks from '../../components/CompetitorsLinks'
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { ListLinksProductRelated } from '../../components/ListLinksProductRelated/ListLinksProductRelated';
import { LinksListProductsInCabinet } from '../../components/ListLinksProductsInCabinet/LinksListProductsInCabinet';
import { ContentTransfer } from '../../components/ContentTransfer/ContentTransfer';
import { OrderManagement } from '../../components/OrderManagement/OrderManagement';
import { UpdatePrices } from '../../components/UpdatePrices/UpdatePrices';
import { Page404 } from '../Page404/Page404';
import './Tools.scss'

const Tools = ({ setIsModalActive }) => {
    const path = useParams()
    const navigate = useNavigate()

    const [sidebarList, setSidebarList] = useState([])
    const [isActive, setIsActive] = useState('Список ссылок')
    const [isActiveItems, setIsActiveItems] = useState('')
    const [hideMenu, setHideMenu] = useState(true)
    const dropClass = hideMenu ? 'icon-drop' : 'icon-drop_active'

    const [sidebarItems, setSidebarItems] = useState(
        [
            {
                label: 'Добавить',
                subItems: [
                    {
                        label: 'Список ссылок',
                        path: 'links_list',
                        black: true
                    },
                    {
                        label: 'С привязкой к своему товару',
                        black: true,
                        path: 'links_list_product_related',
                    },
                    {
                        label: 'Ссылки к товарам в кабинете',
                        black: true,
                        path: 'links_list_product_in_cabinet'
                    },
                    {
                        label: 'Парсинг по категориям',
                        black: true
                    },
                    {
                        label: 'Парсинг по брендам',
                        black: true
                    },
                    {
                        label: 'Парсинг по ключевым словам',
                        black: true
                    },
                    {
                        label: 'Загрузка из файла',
                        black: true
                    },
                    {
                        label: 'Загрузка по API',
                        black: true
                    }
                ],
            },
            {
                label: 'Посмотреть',
                subItems: [
                    {
                        label: 'Ссылки конкурентов',
                        black: true
                    },
                    {
                        label: 'Список своих товаров',
                        black: true
                    },
                    {
                        label: 'Товары без конкурентов',
                        black: true
                    },
                    {
                        label: 'Список брендов',
                        black: true
                    },
                    {
                        label: 'Список ключевых слов',
                        black: true
                    },
                ],
            },
            {
                label: 'Уведомления',
                subItems: [
                    {
                        label: 'Настроить уведомления',
                        black: true
                    }
                ],
            },
            {
                label: 'Настроить правила',
                subItems: [
                    {
                        label: 'Установка правил',
                        black: true
                    },
                    {
                        label: 'Список правил',
                        black: true
                    },
                ],
            },
            {
                label: 'Список площадок для мониторинга',
                subItems: [],
            },
            {
                label: 'Примеры использования',
                subItems: [],
            },
            {
                label: 'Заказать поиск конкурентов',
                subItems: [],
            },
            {
                label: 'Менеджер загрузок',
                subItems: [],
            },
        ]
    )

    useEffect(() => {
        if (path['*'] === '') {
            navigate('competitors_links')
        }

        setSidebarList([
            ...sidebarItems.map((el, idx, { label }) => <SidebarMenu isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} key={label + '' + idx} {...el} setActive={e => setIsActive(e)} active={isActive} />)
        ])
    }, [isActive])

    return (

        <div className='flex'>
            <div className='settings__main-box'>
                <Routes>
                    <Route path='links_list' element={<CompetitorsLinks />} />
                    <Route path='links_list_product_related' element={<ListLinksProductRelated />} />
                    <Route path='links_list_product_in_cabinet' element={<LinksListProductsInCabinet />} />
                    {/* <Route path='/content-transfer/*' element={<ContentTransfer setIsModalActive={e => setIsModalActive(e)} />} /> */}
                    <Route path='/update-prices/*' element={<UpdatePrices />} />
                    <Route path='*' element={< Page404 />}/>
                </Routes>
            </div>
        </div>

    )
}

export { Tools }