import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { renderToString } from 'react-dom/server'
import 'react-quill/dist/quill.snow.css';
import './Editor.scss'
const parse = require('html-react-parser')

const editorFormats = ['size', 'bold', 'italic', 'underline', 'blockquote', 'color', 'list', 'bullet', 'indent', 'link', 'image', 'video']
const editorModules = {
    toolbar: [
        [[], [], [], { 'font': [] }, [], [], [], { size: [] }], [], [], [], [], [], [],
        ['link', 'image', 'video', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], [], [],
        ['bold', 'italic', 'underline', 'blockquote', 'code-block', 'color', 'background'],
    ]
}

const smallEditoModules = {
    toolbar: [
        [{ 'indent': '+1' }, 'link', 'image', 'video', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, 'bold', 'italic', 'underline', 'blockquote', 'code-block', 'color', 'background'],
    ]

}

const Editor = forwardRef(({
    placeholder = '',
    bounds = '',
    hideModules = false,
    noModules = false,
    description = '',
    saveOnBtn = false,
    setDescription = false,
    title = 'Добавьте описание',
    comment = false,
    editValue = false,
    disabled = false,
}, editorRef) => {

    const [value, setValue] = useState(editValue ? editValue : '')
    const [previusDesc, setPreviusDesc] = useState('')
    const [text, setText] = useState('')
    const [theme, setTheme] = useState('snow')
    const [isEdit, setIsEdit] = useState(!!editValue ? !!editValue : false)
    const [showImg, setShowImg] = useState('')

    useImperativeHandle(editorRef, () => ({

        reset() {
            setValue('')
            setText('')
            setPreviusDesc('')
        }

    }))

    const stringToHtml = (str) => {
        return parse(str, {
            replace: (domNode) => {
                if (domNode.name === "img") {
                    return <img src={domNode.attribs.src} 
                    style={{cursor: 'pointer'}}
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowImg(domNode.attribs.src)
                    }}
                    />
                }
            },
        })
    }

    const onSaveDesc = (html) => {
        if (setDescription) setDescription(renderToString(html))
    }

    const onSaveHandler = (value) => {
        setValue(value)
        const html = stringToHtml(value)
        setText(html)
        if (saveOnBtn) return
        onSaveDesc(html)
    }

    useEffect(() => {
        if (description?.length > 0 & !isEdit) {
            setText(stringToHtml(description))
            setValue(description)
        }
    }, [description])

    useEffect(() => {
        if (!previusDesc) setPreviusDesc(description ? description : 'none')
    }, [description, isEdit])


    
    return (
        <>
            {showImg && <div className='show-img' onClick={(e) => setShowImg('')}>
                <img src={showImg} />
            </div>}
            
            <div className={disabled ? 'editor-component_disabled' : 'editor-component'}>
                <div className='redactor-wrapper'>
                    {
                        isEdit ?
                            <>
                                <div className='text-content' style={{ cursor: 'pointer' }} onClick={e => setIsEdit(true)}>
                                    <div className='title'>{title}</div>
                                    <ReactQuill
                                        tabIndex='0'
                                        value={value}
                                        onChange={onSaveHandler}
                                        placeholder={placeholder}
                                        bounds={'.redactor-wrapper'}
                                        formats={editorFormats}
                                        modules={noModules ? { toolbar: [] } : hideModules ? smallEditoModules : editorModules}
                                        theme={theme}

                                    />
                                </div>
                            </>
                            :
                            ((!comment) ?
                                <div className='text-content' style={{ cursor: 'pointer' }} onClick={e => {
                                    if (!disabled) {
                                        setIsEdit(true)
                                    }
                                }}>
                                    <div className='title'>{title}</div>
                                    <p className='text'
                                        onClick={e => {
                                            if (e?.target?.localName === 'a') {
                                                e.stopPropagation()
                                            }
                                        }}

                                    >
                                        {text ? text : placeholder}
                                    </p>
                                </div>
                                :
                                <div className='comment__input-field' onClick={(e) => {
                                    e.stopPropagation()
                                    if (!disabled) {
                                        setIsEdit(true)
                                    }
                                }}>
                                    <input placeholder='Оставьте комментарий' disabled={disabled} />
                                </div>
                            )
                    }
                </div >

                {
                    isEdit & !editValue ?
                        <div className='btn-group'>
                            <a href='#' className='link-blue' key={'clear-btn'} onClick={e => {
                                e.preventDefault()
                                setIsEdit(false)
                                setText(previusDesc !== 'none' ? previusDesc : '')
                            }}>Отмена</a>

                            <a href='#' className='link-blue' key={'add-btn'} onClick={e => {
                                e.preventDefault()
                                setIsEdit(false)
                                setPreviusDesc(value)
                                if (saveOnBtn) onSaveDesc(text)
                            }}>Сохранить</a>
                        </div>
                        :
                        null
                }

            </div>  
        </>
        
    )
})

export { Editor }