import { useState } from 'react'
import product_img from './img/product_img.svg'
import not_img from './img/not-img.svg'
import "./ProductImprovement_EnhancePhoto.scss"

const arrImg = [product_img, product_img]

const ProductImprovement_EnhancePhoto = ({oldImages, newImages}) => {

    return (
        <section className='enhance-photo-inside-content'>
            <div className='content-item'>
                <p className='text_sum-photo'>Фото {oldImages?.length ? oldImages?.length : 0}/<span>15</span></p>
                <div className='img-group'>
                    {oldImages?.map((el, ind) =>
                        <div className='img-box' key={el + ind}>
                            <img src={el} />
                        </div>
                    )}
                </div>
            </div>
            <div className='content-item'>
                <p className='text_sum-photo'>Улучшенное фото</p>
                <div className='img-group'>
                    {newImages?.map((el, ind) =>
                        <div className='img-box' key={el + ind}>
                            <img src={el} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export { ProductImprovement_EnhancePhoto }