import { useState, useEffect } from 'react';
import { TableBox, ButtonBasic, DropDownSelector } from "@lk-gtcom/ecomlab-components"
import './ModalCreateNewBundle.scss'

const ModalCreateNewBundle = ({ title, setIsModal }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-create-new-bundle'>
                <div className='modal-create-new-bundle__header'>
                    <h1 className='title'>Создание новой связки</h1>
                    <ButtonBasic
                        green
                        text='Сохранить товры к связке'
                        size='32px'
                    />
                </div>

                <div className='toolbar-top'>
                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Площадка'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Магазин'
                        multi={true}
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Склад'
                        multi={true}
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Категория'
                        multi={true}
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Бренд'
                        multi={true}
                    />
                </div>

                <div className='modal-create-new-bundle__contact'>
                    <TableBox
                        fetchedData={[]}
                        headers={[]}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalCreateNewBundle }