import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as Play } from './img/Play.svg';
import { ChangesContentLanding } from '../../components/ChangesContentLanding/ChangesContentLanding';
import { change_1, change_2, change_3, change_4, logo_group_img } from '../../common/svg_img';
import "./Landing.scss"

const Landing = ({
    setIsModalActive
}) => {

    const landingItems = [
        {
            title: "РАБОТА С КОНТЕНТОМ",
            text: "Более 10 инструментов по автоматизации работы",
            icon: "./img/change-icon-1.svg",
            img: change_1,
            timernum: 0
        },
        {
            title: "ИНТЕГРАЦИИ",
            text: "100+ интеграции, посмотри наши возможности",
            icon: "./img/change-icon-2.svg",
            img: change_2,
            timernum: 1
        },
        {
            title: "РЕКЛАМНАЯ АНАЛИТИКА",
            text: "Cобирайте и анализируйте данные со всех личных кабинетов в одном месте",
            icon: "./img/change-icon-3.svg",
            img: change_3,
            timernum: 2
        },
        {
            title: "АНАЛИЗ КОНКУРЕНТОВ",
            text: "Анализируем более 30 площадок, подключись и смотри",
            icon: "./img/change-icon-4.svg",
            img: change_4,
            timernum: 3
        },
    ]

    const [openItems, setOpenItems] = useState("РАБОТА С КОНТЕНТОМ");
    const [activeBtn, setActiveBtn] = useState("РАБОТА С КОНТЕНТОМ");
    let [timer, setTimer] = useState(0)

    useEffect(() => {

        const interval = setInterval(() => {
            setOpenItems(landingItems[timer].title)
            setActiveBtn(landingItems[timer].title)
            setTimer(timer)
            timer = timer + 1
            if (timer === 4) {
                timer = 0
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    return (
        <div className='landing__content'>
            <main className='landing'>

                <div className='landing__box-small'>
                    <article className='landing__block_small'>
                        <div className='landing__content-changes-box'>
                            {landingItems.map(({ title, icon, img, text, id }) => (
                                <ChangesContentLanding
                                    key={title}
                                    text={text}
                                    title={title}
                                    icon={icon}
                                    img={img}
                                    activeClass={openItems === title ? "landing__content-changes" : "hidden"}
                                />
                            ))}
                            <div className='landing__content-bottom'>
                                <button
                                    className='btn__green'
                                    onClick={e => setIsModalActive(true)}
                                >
                                    ПОДКЛЮЧИТЬСЯ
                                </button>
                                <div className='btn-circle-group'>
                                    {landingItems.map(({ title, timernum }) => (
                                        <button
                                            key={title}
                                            onClick={() => {
                                                timer = timernum
                                                console.log(timer)
                                                setTimer(timer)
                                                setOpenItems(title)
                                                setActiveBtn(title)
                                            }
                                            }
                                            className={activeBtn === title ? 'btn__circle_active' : 'btn__circle'}></button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className='landing__block_small bkg'>
                        <div className='landing__header-box'>
                            <h3 className='title title_white'>Видео-инструкция</h3>
                        </div>
                        <div className='box-btn'>
                            <button className='btn__play'>
                                < Play />
                            </button>
                        </div>
                    </article>
                    <article className='landing__block_small bkg-grey'>
                        <div className='landing__header-box'>
                            <h3 className='title'>НАШИ КЕЙСЫ</h3>
                            <p className='text'>Узнайте о самых быстрых и эффективных способах как осуществлять перенос данных и настроить гибкую систему рекламных кампаний. </p>
                        </div>
                        <img src='/img/img-1.png' className='img-1' />
                    </article>
                    <article className='landing__block_small'>
                        <div className='landing__header-box'>
                            <h3 className='title'>БЛОГ И ОБУЧЕНИЕ</h3>
                            <p className='text'>Наши специалисты всегда на связи, мы помогаем и обучаем работе с маркетплейсами. В базе данных вы сможете найти обучающие материалы по любым интересующим вопросам.</p>
                        </div>
                        <img src='/img/img-2.png' className='img-2' />
                    </article>
                </div>
                <div className='landing__box-long'>
                    <article className='landing__block_long'>
                        <div className='landing__box-lists'>
                            < div className='landing__box-list'>
                                <h3 className='title'>ECOMRU</h3>
                                <ul className='landing__list'>
                                    <li className='landing__list-item'>Оперативная статистика ведущих маркетплейсов мира</li>
                                    <li className='landing__list-item'>Все интеграции в одном сервисе. Эффективное управление рекламными сервисами и данными</li>
                                    <li className='landing__list-item'>Всегда актуальный конкурентный анализ любых товарных направлений</li>
                                    <li className='landing__list-item'>Быстрый и простой перенос контента между площадками в 1 клик</li>
                                </ul>
                            </div>

                            < div className='landing__box-list'>
                                <h3 className='title'>В ЦИФРАХ</h3>
                                <ul className='landing__list'>
                                    <li className='landing__list-item'>1000+ клиентов успешно используют наши технологии</li>
                                    <li className='landing__list-item'>На 127% увеличили выручку наши клиенты с помощью сервиса</li>
                                    <li className='landing__list-item'>Р100+ интеграций и сервисов. Подключаем к крупнейшим площадкам рынка РФ и не только</li>
                                </ul>
                            </div>
                        </div>

                        <div className='landing__content-item'>
                            <embed type='image/svg+xml' src={logo_group_img} className='marketplaceGroup' />
                        </div>
                    </article>
                </div>
            </main>
        </div>
    )
}

export { Landing }
