import { useState, useEffect } from 'react'
import { useRef } from 'react'
import { RavshanHttps } from '../../fetchUrls'
import customHeaders, {getSpace} from '../../common/headers'
import { useNavigate, useParams } from 'react-router-dom'
import { useDataTable } from '../../common/hooks'
import useGeneralStore from '../../store/general'
import './CrmTablesList.scss'

import { Tabs, TableBox, ButtonBasic, DropDownSelector } from "@lk-gtcom/ecomlab-components";
import { CrmModal } from "../CrmCreateTable/CrmModal";

const CrmTabs = [
    {
        label: 'Все',
        value: 'all'
    },
    {
        label: 'Активные',
        value: 'notifications'
    },
    {
        label: 'Неактивные',
        value: 'unactiv'
    }
]


const CrmTablesList = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const navigate = useNavigate()
    const [tableList, setTableList] = useState([])
    const [attributes, setAttributes] = useState([])
    const [tabs, setTabs] = useState([])
    const [pageValue, setPageValue] = useState(null)
    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const fetchTableData = (params) => {
        // setLoading(true)
        // resetTable()

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_tables_list`
        } else {
            url = `${RavshanHttps}/api/crm/get_tables_list`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => res.json())
            .then(json => {
                setTable(json)
            })
            .catch(err => {
                // setLoading(false)
                console.error(err)
            })

    }

    const onChooseHandler = (tableId) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/get_tables_attr`
        } else {
            url = `${RavshanHttps}/api/crm/get_tables_attr`
        }

        fetch(url, { method: 'GET', headers: { ...headers, 'table-id': tableId } })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                setAttributes(json)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const onDeleteHandler = (table_id) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/crm/delete_table`
        } else {
            url = `${RavshanHttps}/api/crm/delete_table`
        }

        const body = JSON.stringify({
            table_id
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                alert(json)
                fetchTableData()
            })
            .catch(err => {
                console.error(err)
                alert(err)
            })
    }

    useEffect(() => {
        onChooseHandler()
    }, []);

    useEffect(() => {
        fetchTableData()
    }, [])

    useEffect(() => {
        
    }, [tableProps]);

    const activeTab = useParams()['*']
    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className='CRM'>
            <h2 className='title_main'>Список таблиц</h2>

            <div className='toolbar-top'>
                <DropDownSelector
                    className='connections_page_selector'
                    placeholder='Фильтр'
                    multi
                />
                <DropDownSelector
                    className='connections_page_selector'
                    placeholder='Фильтр'
                    multi
                />
                <DropDownSelector
                    className='connections_page_selector'
                    placeholder='Фильтр'
                    multi
                />

                {/*<ButtonBasic text={'Создать таблицу'} onClick={() => navigate('../create-table')}/>*/}
                <ButtonBasic text={'Создать таблицу'} onClick={() => setIsModalOpen(true)} />
            </div>

            <Tabs
                tabs={CrmTabs}
                pageValue={pageValue}
                setPageValue={(e) => setPageValue(e)}
            />

            {/*<Tabs tabs={CrmTabs} pageValue={pageValue} />*/}
            <div style={{ minWidth: "100wh" }}>
                <TableBox
                    {...tableProps}
                    ref={paginatorRef}
                    paginator={true}
                    fetchCallback={e => fetchTableData(e)}
                />
            </div>
            <CrmModal 
            isOpen={isModalOpen} 
            fetchTableData={(e) => fetchTableData(e)}
            close={() => setIsModalOpen(false)} />
        </div>
    )
}

export { CrmTablesList }