import box_icon from "./img/box_icon.svg";
import product_tab from "./img/product_tab.svg";
import shop_tab from "./img/shop_tab.svg";
import star_icon from "./img/star_icon.svg";
import customHeaders, {getSpace} from "../../../common/headers";
import { useDataTable } from "../../../common/hooks";
import { useEffect, useRef, useState } from "react";
import { KonstantinCreateProductHttps, KonstantinMasterHttp, KonstantinMasterHttps } from "../../../fetchUrls";
import { ButtonBasic, DropDownSelector, TableBox, Tabs } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from "../../../store/general";
import "./ModalСreatingСonnections.scss";

const btn_tab = [
  {
    label: "По магазинам",
    value: "by_stock",
    icon: shop_tab,
  },
  {
    label: "По товарам",
    // value: 'drafts',
    value: "by_product",
    icon: product_tab,
  },
];



const ModalСreatingСonnections = ({ ...props }) => {
  const { 
    title, setIsModal,
    selectedCategory,
    selectedBrand,
    offer_id_list
  } = props;
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = {...customHeaders, ...getSpace(selectedSpace)}
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [selectedMasterProduct, setSelectedMasterProduct] = useState([]);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef);
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([])
 const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

  const [checkedProducts, setCheckedProducts] = useState([]);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match("localhost")) {
      url = `${KonstantinMasterHttps}/master-products/tables/offers-mapping${ sort?.length > 0 ? "?order_param=" + sort[0] + "&order_param_direction=" + sort[1] : "" }`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/tables/offers-mapping${sort?.length > 0 ? "?order_param=" + sort[0] + "&order_param_direction=" + sort[1] : ""}`
    }

    let brand_id_list;
    let category_id_list;


    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      offer_id_list,
      category_id_list,
      brand_id_list,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam,
    });

    fetch(url, { body, method: "POST", headers, signal: abortController.signal })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url) 
                setTableBody(body)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchUploadOffersMapping = ( abortController = new AbortController() ) => {
    let url
    if (!window.location.hostname.match('localhost')) {
        url = `${KonstantinCreateProductHttps}/master_products/stocks`
    } else {
        url = `${KonstantinCreateProductHttps}/master_products/stocks`
    }

    const offer_mapping_list = checkedProducts?.map((el) => {
        const {Артикул} = el
        let mp_offer_id, mp_api_id, mp_id, update_price, update_stock
        return ({mp_offer_id, master_offer_id: Артикул, mp_api_id, mp_id, update_price, update_stock})
    })

    const body = JSON.stringify({
      offer_mapping_list
    })


    fetch(url, { body, method: 'POST', headers, signal: abortController.signal})
    .then(res => {
        if (res.ok) {
        return res.json()
        } else {
        throw Error(JSON.stringify(res.json()))
        }
    })
    .then(json => {
        const abortController = new AbortController()
        fetchTableData([], abortController)
        return () => abortController.abort()
    })
    .catch(err => {
        console.error(err)
    })
  }

  const fetchSearch = ([search, page = 1], abortController = new AbortController()) => {

    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`
    } else {
      url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`
    }

    let brand_id_list
    let category_id_list

    if (selectedBrand?.length > 0) {
     brand_id_list = selectedBrand?.map(el => el.value)
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map(el => el.value)
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search
    })


    fetch(url, { body, method: 'POST', headers, signal: abortController.signal})
    .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw Error(JSON.stringify(res.json()))
        }
    })
    .then(json => {
        setSearchData(json.map(({id, name}) => ({label: name, value: id})))
    })
    .catch(err => {
        console.error(err)
    })
  }


  useEffect(() => {
    fetchTableData();
  }, [selectedSpace]);

  useEffect(() => {
    const abortController = new AbortController()
    fetchSearch([searchValue, 1], abortController)
    return () => abortController.abort()
  }, [searchValue, selectedSpace])

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains("blackout")) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-creating-connections">
        <div className="modal-creating-connections__header">
          <h1 className="title">Создание связок по товарам</h1>
        </div>

        <div className="toolbar-medium">
          {/* <SearchComponent
              searchValue={searchValue}
              errSearch={false}
              errText='Неверный артикул или название'
              setSearchValue={setSearchValue}
              fetchData={fetchTableData}
              fetchCallback={handleSearch}
              data={searchData}

          /> */}
          
          <DropDownSelector
            width="100%"
            options_prop={searchData}
            state={selectedMasterProduct}
            setState={(e) => setSelectedMasterProduct(e)}
            // fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setSearchValue(e)}
            multi
            placeholder={
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                Мастер товар <img src={star_icon} />
              </p>
            }
          />

          <div className="right-content">
            <ButtonBasic width="200px" text="Связать товары" onClick={() => fetchUploadOffersMapping} />

            <div className="counter-product">
              <img src={box_icon} />
              <p className="text">{`${`0`} продуктов`}</p>
            </div>
          </div>
        </div>

        <div className="modal-creating-connections__content">
          <Tabs
            tabs={btn_tab}
            pageValue={pageValue}
            setPageValue={(e) => setPageValue(e)}
            stopRedirect
          />

          <TableBox
            {...tableProps}
            onCheck={e => setCheckedProducts(e)}
            fetchCallback={(e) => fetchTableData(e)}
            ref={paginatorRef}
            paginator={true}
            tableUrl={tableUrl}
                    tableBody={tableBody}
            statistic={[{ label: "Выбрано", sum: checkedProducts?.length }]}
          />
        </div>
      </div>
    </div>
  );
};

export { ModalСreatingСonnections };
