import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import xlc_icon from './img/xlc.svg'
import pdf_icon from './img/pdf.svg'
import { DropDownSelector, RangeBoxDoublePeriod } from "@lk-gtcom/ecomlab-components";
import { NotActive } from '../../components/NotActive/NotActive';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { Page404 } from '../Page404/Page404';
import './CrossAnalytics.scss'

const sidebarItemArr = [
    {
        label: 'Содержание отчета',
        subItems: [
            {
                label: 'Общие показатели и поведенческие факторы',
                path: 'general-indicators'
            },
            {
                label: 'Группы объявлений',
                path: '',
                subItems: [],
            },
            {
                label: 'Поплуярные фразы',
                path: 'employees',
            },
        ],
    },
]

const CrossAnalytics = () => {
    const pathMain = useParams()
    const navigate = useNavigate()
    const [hideMenu, setHideMenu] = useState(true)
    const [isBalanceModalActive, setIsBalanceModalActive] = useState(false)
    const [isActive, setIsActive] = useState('')
    const [isActiveItems, setIsActiveItems] = useState('')
    const [sidebarItems, setSidebarItems] = useState(sidebarItemArr);

    const [dateCurrent, setDateCurrent] = useState([(new Date().setHours(0, 0, 0, 10) - 604800000), new Date().setHours(23, 59, 59, 999)])
    const [datePrevious, setDatePrevious] = useState([new Date().setHours(0, 0, 0, 10) - 604800000, new Date().setHours(23, 59, 59, 999)])

    useEffect(() => {

        // После перезагрузки страницы, посвечиваем корректные пункты меню

        sidebarItems?.forEach(({ label, subItems, path }) => {
            const title = label
            const comparePaths = path && (pathMain['*'].split('/')[0] == path.split('/')[0])
            if (comparePaths) {
                setIsActive(null)
                setIsActiveItems(label)
            } else {
                subItems.forEach(({ label, path }) => {
                    if (comparePaths) {
                        setIsActive(title + label)
                        setIsActiveItems(null)
                    }
                })
            }
        })

        if (pathMain['*'].split('/')[0] == 'not-active') {
            navigate('connections')
            setIsActive('')
            setIsActiveItems('Список подключений')
        }
    }, [])

    useEffect(() => {
        if (pathMain['*'].split('/')[0] == 'download-history') {
            setIsActive('')
            setIsActiveItems('История загрузок')
        }

        if (pathMain['*'].split('/')[0] == 'connections') {
            setIsActive('')
            setIsActiveItems('Список подключений')
        }
    }, [pathMain]);



    return (
        <div className='cross-analytics'>
            <div className='cross-analytics__header'>
                <h1 class="title_main">Кросс аналитика</h1>

                <div className='cross-analytics__range'>
                    <RangeBoxDoublePeriod
                        dateCurrent={dateCurrent}
                        datePrevious={datePrevious}
                        setDateCurrent={setDateCurrent}
                        setDatePrevious={setDatePrevious}
                    />
                </div>
            </div>

            <div className="toolbar-top">
                <FilterContainer
                    onReset={(e) => (e)}
                >
                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Маркетплейс'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Маркетплейс'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Маркетплейс'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Маркетплейс'
                    />
                </FilterContainer>
            </div>

            <div className='flex'>

                <div className='cross-analytics__left-content'>
                    <div className='sidebar-inside'>
                        <nav className='sidebar-inside__nav'>
                            {sidebarItems.map((el, idx, { label }) => <SidebarMenu {...el} isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} setActive={e => setIsActive(e)} active={isActive} key={label + '' + idx} />)}
                        </nav>
                    </div>

                    <div className='cross-analytics__btn-group'>
                        <button className='cross-analytics__btn-download'><img src={xlc_icon} />Скачать в Excel</button>
                        <button className='cross-analytics__btn-download'><img src={pdf_icon} />Скачать в PDF</button>
                    </div>
                </div>


                <div className='settings__main-box'>
                    <Routes>
                        <Route path='*' element={< Page404 />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export { CrossAnalytics }