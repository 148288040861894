import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import icon_notification from './img/icon_notification.svg'
import './ModalUnsavedProduct.scss'

const ModalUnsavedProduct = ({ setIsModal }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-unsaved-product'>
                <img src={icon_notification} /> 
                <h2 className='title'>Выйти без сохранения?</h2>
                <div className='btn-group'>
                    <ButtonBasic
                        lilac
                        size='56px'
                        width='calc(50% - 6px)'
                        text='Выйти'
                        onClick={(e) => setIsModal(false)}
                    />
                    <ButtonBasic
                        violet
                        size='56px'
                        width='calc(50% - 6px)'
                        text='Продолжить'
                    />

                </div>
            </div>
        </div>
    )
}

export { ModalUnsavedProduct }