import './ModalDeleteConnection.scss'

const ModalDeleteConnection = ({setIsModalDelete, setIsDelete, onDeleteAccount, choosedAccount}) => {


    return (
        <div className='blackout'  onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModalDelete(false)
            }
        }}>
            <div className='modal-delete-connection'>
                <button className='close' 
                onClick={(e)=> setIsModalDelete(false)}
                ></button>
                <h2 className='title'>Вы точно хотите удалить аккаунт?</h2>
                <div className='group-btn'>
                    <button className='btn__green'
                    onClick={(e)=> {
                        onDeleteAccount(choosedAccount)
                    } }
                    >Да</button>
                    <button className='btn__green'
                     onClick={(e)=>  setIsModalDelete(false)}
                    >Нет</button>
                </div>
            </div>
        </div>
    )
}

export default ModalDeleteConnection;