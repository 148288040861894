import customHeaders, {getSpace} from '../../common/headers'
import arrow_right from './img/arrow_right.svg'
import { RavshanHttps, RavshanHttp } from '../../fetchUrls'
import { user_light_grey } from '../../common/svg_img'
import { useEffect, useState } from 'react'
import useGeneralStore from '../../store/general'
import './NotificationListItem.scss'

const NotificationListItem = ({...props}) => {
    const {
        id,
        lk_client_id,
        portal_id,
        active,
        status_read,
        img,
        name,
        annotation,
        date,
        reading_time,
        count_view,
        status,
        activeChat,
        setActiveChat,
        fetchGetNotificationsByPortal
    } = props
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [statusRead, setStatusRead] = useState(status_read)
    // const { setNotifCount } = useGeneralStore(state => ({
    //     setNotifCount: e => state.setNotificationCounter(e)
    // }))

    const fetchChangeStatusRead = async (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/lk/change_read_status`
        } else {
            url = `${RavshanHttp}/api/lk/change_read_status`
        }

        
        
        const body = JSON.stringify({
            id: id,
            status: true
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {

            })
            .catch(err => console.error(err))
    }

    const fetchNotificationsCount = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/get_count_unread_msg`
        } else {
            url = `${RavshanHttp}/get_count_unread_msg`
        }

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ count }) => {
                // setNotifCount(count)
            })
            .catch(err => console.error(err))
    }

  return (
    < li className={activeChat?.id == id ? "messages-list__item_active" : "messages-list__item"}
        onClick={async (e) => {
            setActiveChat({ id })
            if(!statusRead){
                await fetchChangeStatusRead(id)
                setStatusRead(true)
                setTimeout(() => {
                    fetchGetNotificationsByPortal()
                    fetchNotificationsCount()
                }, 100)
            }
        }}
    >
        <div className='img-box'>
            <img src={img?.[0] ? img[0] : user_light_grey} />
        </div>
        <div className='message-box'>
            <div className='title-and-date'>
                <p className='text'>{name ? name?.[0] : 'Неизвестное название'}</p>

                <div className='info-message'>
                    <h5 className='title'>{Array.isArray(annotation) ? annotation[0] : (annotation ? annotation : 'Неизвестный текст')}</h5>
                    <p className='text_grey'>{date}</p>
                </div>
            </div>
        </div>
        {statusRead ?
            <div className='read-message'>
                <img src={arrow_right} />
            </div>
            :
            <span className='new-message'></span>}
    </li>
  )
}

export default NotificationListItem