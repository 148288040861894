import './ModalWarning.scss'

const ModalWarning =
({
    headerText,
    messageText,
    children,
    approveButtonText,
    onApproveClick,
    cancelButtonText,
    onCancelClick
}) => {

    return (
        <div className='blackout' >
            <div className='modal-warning modal-password-changed'>
                <div style={{paddingLeft: '40px', paddingRight: '40px', paddingBottom: '52px'}}>
                    <h2 className='title'>{headerText}</h2>
                    <p className='text'>{messageText}</p>
                </div>
                {children}
                <div className='modal-warning__buttons-container'>
                    <button className='btn__green' onClick={onApproveClick}>{approveButtonText}</button>
                    <button className='btn__grey' onClick={onCancelClick}>{cancelButtonText}</button>
                </div>
            </div>
        </div>
    )
}

export default ModalWarning
