import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer, Tabs } from "@lk-gtcom/ecomlab-components"
import { DynamicsComponent } from '../../DynamicsComponent/DynamicsComponent'
import "./ProductCardExtended_FinancialAnalysis.scss"

const btn_tab = [
    {
        label: 'По ключевым словам',
        value: 'by_keywords',
    },
    {
        label: 'По категориям',
        value: 'by_category',
    },
    {
        label: 'По брендам',
        value: 'by_brand',
    },
    {
        label: 'По продавцу',
        value: 'by_seller',
    }
]


const ProductCardExtended_FinancialAnalysis = () => {

    const [showDropBox, setShowDropBox] = useState(true)
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    return (
        <section className='financial-analysis-inside-content'>
            <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <div className='inside-box'>
                    <LineChart
                        legendPosition='top'
                        datasets_prop={[]}
                        labels_prop={[]}
                        loading={null}
                    />

                    <DynamicsComponent
                        title='Воронка продаж с рекламных каналов'
                    />
                </div>
            </DropContainer>

            <Tabs tabs={btn_tab} pageValue={pageValue} setPageValue={e => setPageValue(e)} stopRedirect />

            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                paginator={true}
            />
        </section>
    )
}

export { ProductCardExtended_FinancialAnalysis }