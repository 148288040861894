import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_GeneralIndicators.scss"

const ProductCardExtended_GeneralIndicators = ({tableData, tableCallback, chartData}) => {

    const [showDropBox, setShowDropBox] = useState(true)

    return (
        <section className='general-indicators-inside-content'>
            <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <LineChart
                   {...chartData}
                />
            </DropContainer>

            <TableBox
                {...tableData}
                paginator={true}
                fetchCallback={tableCallback}
            />
        </section>
    )
}

export { ProductCardExtended_GeneralIndicators }