import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import "./PrivacyPolicy.scss"

const PrivacyPolicy = () => {

    return (
        <div className='privacy-policy' id="scrollElem">
            <div className='privacy-policy__content'>
                <h1 className='title'>Политика конфиденциальности</h1>

                <p className='text_big'> 1. Основные термины и определения</p>
                <p className='text_medium'>1.1. В настоящей политике используются следующие основные понятия:</p>
                <p className='text'><b>· персональные данные</b> - любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных); оператор персональных данных (оператор) - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                <p className='text'><b>· обработка персональных данных</b> - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных; автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники;</p>
                <p className='text'><b>· распространение персональных данных</b> - действия, направленные на раскрытие персональных данных неопределенному кругу лиц;</p>
                <p className='text'><b>· предоставление персональных данных</b> - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
                <p className='text'><b>· блокирование персональных данных</b> - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
                <p className='text'><b>· уничтожение персональных данных</b> - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;</p>
                <p className='text'><b>· обезличивание персональных данных</b> - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;</p>
                <p className='text'><b>·информационная система персональных данных</b>  - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств;</p>
                <p className='text'><b>·трансграничная передача персональных данных</b>  - передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу</p>
                <p className='text'><b>·Сайт Оператора</b>  - интернет-сайты, используемые Оператором для ведения своей деятельности, на которых может осуществляться сбор персональных данных.</p>
                <p className='text'><b>·Администрация сайта</b>  – уполномоченные сотрудники на управления сайтом, действующие от имени Оператора, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                <p className='text'><b>·Пользователь сайта</b>  – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт и предоставляющее свои данные, в том числе персональные данные и данные Cookies для обработки Оператору.</p>
                <p className='text'><b>·Cookies</b>  — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>

                <p className='text_big'>2. Общие положения</p>

                <p className='text'>
                    2.1. Оператор, основываясь на целях безусловного выполнения требований законодательства РФ и поддержания своей деловой репутации, считает своими задачами исполнение принципов справедливости, законности, конфиденциальности, безопасности при обработке персональных данных.<br />
                    2.2. Настоящая политика в отношении обработки персональных данных разработана:<br />
                    · определяет основные принципы, цели и способы обработки Оператор персональных данных, состав субъектов персональных данных и их права;<br />
                    · действия Оператор при обработке персональных данных, а также меры по защите персональных данных и контролю за соблюдением требований законодательства и данной политики;<br />
                    · является общедоступным документом, в котором декларируется деятельность Оператор при обработке персональных данных.<br />
                    2.3. Любое распространение персональных данных должно производиться исключительно с согласия субъекта персональных данных, если иное не установлено законодательством (в частности, данные, предоставляемые следственным органам по результатам решений соответствующих инстанций)<br />
                    2.4. Принятие решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающее его права и законные интересы на основании исключительно автоматизированной обработки Оператором не допускается.<br />
                    2.5. В случаях, предусмотренных федеральным законом «О персональных данных» №152-ФЗ от 27 июля 2006 г., обработка персональных данных осуществляется только с согласия в письменной форме субъекта персональных данных. Равнозначным содержащему собственноручную подпись субъекта персональных данных согласию в письменной форме на бумажном носителе признается согласие в форме электронного документа, подписанного электронной подписью в соответствии с Федеральным законом от 6 апреля 2011 года №63-ФЗ «Об электронной подписи».<br />
                    2.6. Акцепт субъектом персональных данных «Политики в отношении обработки персональных данных», осуществляется путем согласия субъекта с текстом документа, выраженного путем нажатия кнопки «согласен»/ «принимаю» / проставления «галочки» в поле «Политики в отношении обработки персональных данных» при пользовании Сайтом.<br />
                    2.7. Информация об Операторе<br />
                    Наименование: Общество с ограниченной ответственностью «ЕКОМСЕЛЛЕР» адрес: 117342, Россия, Г. Москва, Вн.Тер.Г. Муниципальный Округ Коньково, Бутлерова Ул., Д. 17б, Этаж/Пом 2/Xi, Ком/Оф 69/215, ИНН 9728036721, ОГРН 1217700225467.
                </p>


                <p className='text_big'>3. Правовые основания обработки персональных данных.</p>
                <p className='text'>
                    3.1. Данная политика в отношении обработки персональных данных составлена во исполнение Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных». Правовыми основаниями обработки персональных данных являются следующие нормативно-правовые акты РФ:<br />
                    · Конституция Российской Федерации;<br />
                    · Гражданский кодекс Российской Федерации;<br />
                    · Налоговый кодекс Российской Федерации;<br />
                    · Федеральный закон от 27.07.2006 N 149-ФЗ «Об информации, информационных технологиях и о защите информации»;<br />
                    · Федеральный закон от 07.08.2001г. № 115-ФЗ «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма»;<br />
                    · Федеральный закон от 07.07.2003г. № 126-ФЗ «О связи», ст. 53;<br />
                    · Федеральный закон от 27.06.2011 N 161-ФЗ «О национальной платежной системе»;<br />
                    · Федеральный закон от 03.06.2009 N 103-ФЗ "О деятельности по приему платежей физических лиц, осуществляемой платежными агентами";<br />
                    · Федеральный закон от 02.05.2006г. № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации»;<br />
                    · Федеральный закон Российской Федерации от 7 февраля 1992 г. N 2300-1 "О защите прав потребителей";<br />
                    · Указ Президента Российской Федерации от 06 марта 1997 г. № 188 «Об утверждении Перечня сведений конфиденциального характера»;<br />
                    · Постановление Правительства Российской Федерации от 13 сентября 2008 года № 687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации;<br />
                    · Постановление Правительства Российской Федерации от 06 июля 2008 года № 512 «Об утверждении требований к материальным носителям биометрических персональных данных и технологиям хранения таких данных вне информационных систем персональных данных»;<br />
                    · Постановление Правительства Российской Федерации от 01 ноября 2012 года №1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;<br />
                    · Постановление Госкомстата РФ от 18.08.1998 N 88 "Об утверждении унифицированных форм первичной учетной документации по учету кассовых операций, по учету результатов инвентаризации";<br />
                    · Приказ ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;<br />
                    · Приказ ФСБ России от 10.07.2014 N 378 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных с использованием средств криптографической защиты информации, необходимых для выполнения установленных Правительством Российской Федерации требований к защите персональных данных для каждого из уровней защищенности»;<br />
                    · Приказ Роскомнадзора от 15.03.2013 N 274 «Об утверждении перечня иностранных государств, не являющихся сторонами Конвенции Совета Европы о защите физических лиц при автоматизированной обработке персональных данных и обеспечивающих адекватную защиту прав субъектов персональных данных»;<br />
                    · Приказ Роскомнадзора от 05 сентября 2013 № 996 «Об утверждении требований и методов по обезличиванию персональных данных»;<br />
                    · иные нормативно-правовые акты Российской Федерации и нормативные документы уполномоченных органов государственной власти;<br />
                    · Уставные документы Оператора;<br />
                    · Договоры, заключаемые между Оператором и субъектом персональных данных;<br />
                    · Поручениями на обработку персональных данных от организаций, заключивших с Оператор договоры на оказание услуг;<br />
                    · Согласия субъектов персональных данных на обработку их персональных данных.<br />
                    3.2. Оператор обрабатывает персональные данные исключительно в целях:<br />
                    · осуществления хозяйственной деятельности, направленной на извлечение прибыли, включая, но не ограничиваясь путем оказания информационно-консультационных и маркетинговых услуг;<br />
                    · заключения, сопровождения, изменения, расторжения договоров и исполнения договорных обязательств, одной из сторон которых является:<br />
                    -субъект персональных данных (покупатель/клиент/выгодоприобретатель),<br />
                    - контрагент и поставщик, по условиям договоров, с которыми Оператор поручается обработка и передача персональных данных.<br />
                    · продвижения товаров, работ, услуг на рынке, путем осуществления прямых контактов с субъектом персональных данных, направления уведомлений, информации, о текущих специальных предложениях, акциях, скидках, иных сведений и запросов, связанных с исполнением договоров, обработкой заявлений, запросов и заявок субъекта персональных данных;<br />
                    · проведения статистических и иных исследований, на основе обезличенных персональных данных;<br />
                    · обеспечения приёма платежей, осуществления денежных переводов, исполнения обязательств по договорам с банковскими организациями о предоставлении услуг клиентам;<br />
                    · прием претензий и жалоб, проведение разбирательств по качеству предоставляемых услуг; подготовка ответов на запросы от государственных органов, включая правоохранительные органы и др., получение информации о результатах следствия, в котором Оператор является потерпевшей стороной; привлечение покупателей/ клиентов/ выгодоприобретателей/ контрагентов/ поставщиков к ответственности;<br />
                    · Создания учетной записи Субъекта на Сайте, идентификации Субъекта, зарегистрированного на сайте, предоставления доступа Субъекту на Сайт;<br />
                    · Исполнение требований законодательства Российской Федерации.<br />
                    Не допускается обработка персональных данных, которые не отвечают целям обработки.<br />
                    3.3. В информационных системах персональных данных Оператор обрабатываются персональные данные следующих категорий субъектов персональных данных (физических лиц):<br />
                    · покупателей Оператор;<br />
                    · клиентов и работников контрагентов/поставщиков Оператор;<br />
                    · потенциальных клиентов;<br />
                    3.4. Оператор осуществляет обработку следующих категорий персональных данных в указанном объеме:<br />
                    3.4.1. при заполнении регистрационной формы на Сайте:<br />
                    - фамилию, имя, отчество;<br />
                    - контактный номер телефона;<br />
                    - адрес электронной почты (e-mail).<br />
                    3.4.2. при заключении договора с Оператором:<br />
                    - паспортные данные или данные иного документа, удостоверяющего личность (ФИО, серия, номер, дата выдачи, наименование органа, выдавшего документ);<br />
                    - адрес места жительства (по паспорту и фактический) и дата регистрации по месту жительства или по месту пребывания;<br />
                    - номер телефона (мобильного и домашнего);<br />
                    - адрес электронной почты (e-mail);<br />
                    - банковские реквизиты, указанные Субъектом в целях проведения оплат по договору,<br />
                    - данные договоров, заключенных между Субъектом и Оператором;<br />
                    - сведения об идентификационном номере налогоплательщика;<br />
                    3.4.3. При доступе к Сайтам Оператора и последующих действиях на Сайте Оператора в соответствии с Правилами в отношении использования файлов cookie, размещаемыми на Сайте Оператора, Оператор осуществляет сбор следующих данных:<br />
                    - IP-адрес хоста;<br />
                    - данные о действии, совершаемым посетителем Сайта Оператора (например, завершение регистрации, изменение города, переход на сайт партнера и др.);<br />
                    - данные об аппаратных событиях, в том числе о сбоях и действиях в системе, а также о настройках, типе и языке браузера, дате и времени запроса и URL перехода;<br />
                    - информация, автоматически получаемая при доступе к Сайту Оператора с использованием закладок (cookies).<br />
                    3.5. Субъект персональных данных имеет право на:<br />
                    · получение информации об обработке его персональных данных в Оператор, в том числе: подтверждение факта обработки персональных данных; правовое основание, цели, сроков и способов обработки персональных данных;<br />
                    · уточнение, блокирование или уничтожение своих персональных данных, если они являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;<br />
                    · требовать от Оператора прекращения в любое время передачи (распространения, предоставления, доступа) персональных данных, разрешенных для распространения на основании письменного требования, в котором должны быть указаны: фамилия, имя, отчество (при наличии), контактная информация (номер телефона, адрес электронной почты или почтовый адрес) субъекта персональных данных, а также, перечень персональных данных, обработка которых подлежит прекращению;<br />
                    · обратиться с требованием прекратить передачу (распространение, предоставление, доступ) своих персональных данных, ранее разрешенных субъектом персональных данных для распространения, к любому лицу, обрабатывающему его персональные данные, в случае несоблюдения положений статьи 10.1. Федерального закона от 27.07.2006 N 152-ФЗ. или обратиться с таким требованием в суд;<br />
                    · обжалование действий или бездействий Оператор путем обращения в уполномоченных орган по защите прав субъектов персональных данных;<br />
                    · защиту своих прав и законных интересов, в том числе, на возмещение убытков и/или компенсацию морального вреда в судебном порядке.<br />
                    · получение иных сведений и осуществление иных прав, предусмотренных законодательством Российской Федерации.<br />
                    3.5.1. Права субъекта персональных данных на доступ к его персональным данным может быть ограничено:<br />
                    · если обработка персональных данных, включая те, что получены в результате оперативно - розыскной, контрразведывательной деятельности, выполняется в целях укрепления обороны страны, обеспечения безопасности государства и охраны правопорядка;<br />
                    · если обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма;<br />
                    · если доступ субъекта персональных данных нарушает права и законные интересы третьих лиц;<br />
                    · при условии, что обработка персональных данных производится органами, осуществляющими задержание субъекта персональных данных по подозрению в совершении преступления, либо предъявившими субъекту персональных данных обвинение по уголовному делу, либо применившими к субъекту персональных данных меру пресечения до предъявления обвинения, за исключением предусмотренных уголовно-процессуальным законодательством Российской Федерации случаев, когда допускается ознакомление подозреваемого или обвиняемого с такими персональными данными;<br />
                    · если обработка персональных данных осуществляется в случаях, предусмотренных законодательством Российской Федерации о транспортной безопасности, в целях обеспечения устойчивого и безопасного функционирования транспортного комплекса, защиты интересов личности, общества и государства в сфере транспортного комплекса от актов незаконного вмешательства.<br />
                    Для реализации своих прав и законных интересов субъект персональных данных может обратиться к Оператор. Оператор рассматривает обращения и жалобы со стороны субъектов персональных данных, тщательно расследует факты нарушений и принимает все необходимые меры для их немедленного устранения, наказания виновных лиц и урегулирования спорных и конфликтных ситуации в досудебном порядке.<br />
                    3.5.2. В случае отзыва субъектом персональных данных согласия на обработку персональных данных, разрешенных для распространения, Согласие на обработку персональных данных, разрешенных для распространения, прекращает свое действие с момента поступления Оператор соответствующего письменного требования от субъекта персональных данных.<br />
                    3.5.3. Лицо, получившее требование субъекта персональных данных прекратить передачу (распространение, предоставление, доступ) своих персональных данных, ранее разрешенных субъектом персональных данных для распространения, обязано прекратить передачу (распространение, предоставление, доступ) персональных данных в течение трех рабочих дней с момента получения требования субъекта персональных данных или в срок, указанный во вступившем в законную силу решении суда, а если такой срок в решении суда не указан, то в течение трех рабочих дней с момента вступления решения суда в законную силу.<br />
                    3.5.4. В случае отзыва субъектом персональных данных согласия на обработку персональных данных, Оператор прекращает обработку персональных данных или обеспечивает ее прекращение (если обработка персональных данных осуществляется другим лицом, действующим по поручению Оператор) и уничтожает персональные данные или обеспечивает их уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению Оператор) в срок не превышающий тридцати дней с даты поступления указанного отзыва.<br />
                    3.5.5. В случае отзыва субъектом персональных данных согласия на обработку персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в пунктах 2 - 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 года.<br />

                </p>

                <p className='text_big'>4. Порядок, условия и способы обработки персональных данных</p>

                <p className='text'>4.1. Обработка персональных данных Оператором ведется с учетом обеспечения защиты прав и свобод лиц при обработке их персональных данных, в том числе, прав на неприкосновенность частной жизни, личную и семейную тайну на основе следующих принципов:<br />
                    · законности и справедливости обработки персональных данных;<br />
                    · ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;<br />
                    · соответствия целей и способов обработки персональных данных тем целям, которые были заявлены при сборе данных;<br />
                    · недопустимости объединения баз данных, созданных с разными целями для обработки персональных данных;<br />
                    · соответствия необходимости и достаточности объема, характера и способов обработки персональных данных заявленным целям их обработки;<br />
                    · обеспечения точности, достоверности и, при необходимости, актуальности персональных данных по отношению к целям обработки;<br />
                    · хранения персональных данных в форме, позволяющей определить субъекта персональных данных не дольше, чем того требуют цели обработки, требования законодательства или договора, по которому стороной/выгодоприобретателем является субъект персональных данных;<br />
                    · уничтожения или обезличивания персональных данных по достижении целей или утраты необходимости в достижении этих целей, если иное не предусмотрено требованиями законодательства;<br />
                    · легитимности организационных и технических мер по обеспечению безопасности персональных данных.<br />
                    · стремления к постоянному совершенствованию системы защиты персональных данных.<br />
                    Трансграничная передача персональных данных на территории иностранных государств Оператором не осуществляется.<br />
                    4.2. При сборе персональных данных Оператор обеспечивает:<br />
                    · предоставление субъекту персональных данных по его просьбе информацию, предусмотренную ч.7 ст.14 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г.;<br />
                    · получение согласия субъекта персональных данных на обработку его персональных данных, предусмотренное ст. 9 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г., в форме, позволяющей подтвердить факт его получения;<br />
                    · запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, в соответствии с ч.5 ст.18. Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г., при сборе персональных данных, в том числе, посредством информационно-телекоммуникационной сети "Интернет", а также, осуществлении хранения персональных данных;<br />
                    · соблюдение требований конфиденциальности персональных данных, установленных ст.7 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г., а также, принятие мер, предусмотренных ч.2 ст. 18.1, ч.1 ст.19 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г.;<br />
                    · получение предварительного согласия субъекта персональных данных на осуществления прямых контактов с субъектом персональных данных с помощью средств связи, в целях продвижения услуг Оператора. В случае соответствующего требования субъекта персональных данных, Оператор обязано прекратить их обработку в указанных выше целях, в порядке и сроки, предусмотренные действующим законодательством Российской Федерации.<br />
                    4.3. Любое распространение персональных данных должно производиться исключительно с согласия субъекта персональных данных, если иное не установлено законодательством (в частности, данные, предоставляемые следственным органам по результатам решений соответствующих инстанций) Принятие решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающее его права и законные интересы на основании исключительно автоматизированной обработки в Оператор, не допускается. В случаях, предусмотренных федеральным законом «О персональных данных» №152-ФЗ от 27 июля 2006 г., обработка персональных данных осуществляется только с согласия в письменной форме субъекта персональных данных.<br />
                    4.4. . Оператор, в ходе своей деятельности, может на основании заключенного договора с другим лицом, предоставлять и (или) поручать обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом. При этом такой договор с другим лицом должен включать следующие существенные условия: перечень действий (операций) с персональными данными, которые будут совершаться другим лицом, осуществляющим обработку персональных данных, целей обработки, обязанности обеспечения указанным лицом конфиденциальности персональных данных и безопасности персональных данных при их обработке, а также, требований к защите обрабатываемых персональных данных в соответствии со статьей 19 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г. Обязательным условием предоставления и (или) поручения обработки персональных данных другому лицу является обязанность сторон по соблюдению конфиденциальности и обеспечению безопасности персональных данных при их обработке.<br />
                    4.5. Оператор вправе передавать персональные данные органам дознания и следствия, иным уполномоченным органам по основаниям, предусмотренным действующим законодательством Российской Федерации.<br />
                    4.6. Оператор осуществляется в форме, позволяющей определить субъекта персональных данных не дольше, чем этого требуют цели обработки персональных данных, кроме случаев, когда срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.<br />
                    4.7. Условием прекращения обработки Оператор персональных данных может являться: истечение срока, установленного в согласии на обработку персональных данных, если такой срок в согласии устанавливается, достижение целей обработки персональных данных, отзыв субъектом персональных данных согласия на обработку своих персональных данных, прекращение деятельности юридического лица, реорганизация юридического лица, а также, выявление неправомерной обработки персональных данных.<br />
                    4.8. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. Оператор обработка осуществляется следующими способами:<br />
                    · автоматизированная обработка персональных данных;<br />
                    · неавтоматизированная обработка персональных данных;<br />
                    · смешанная обработка персональных данных, в том числе, с передачей по внутренней сети Оператор и/или с передачей по сети Интернет.<br />
                    4.9. Оператор осуществляет актуализацию, исправление, удаление и уничтожение персональных данных, ответы на запросы субъектов на доступ к персональным данным. В случае подтверждения факта неточности персональных данных или неправомерности их обработки, персональные данные подлежат их актуализации Оператор, а обработка должна быть прекращена, соответственно. При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных согласия на их обработку персональные данные подлежат уничтожению, если:<br />
                    · иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;<br />
                    · Оператор не вправе осуществлять обработку без согласия субъекта персональных данных на основаниях, предусмотренных Федеральным законом "О персональных данных" или иными федеральными законами;<br />
                    · иное не предусмотрено иным соглашением между Оператор и субъектом персональных данных. Оператор обязано сообщить субъекту персональных данных или его представителю информацию об осуществляемой им обработке персональных данных такого субъекта по запросу последнего. Порядок и сроки реагирования на запросы/обращения субъектов персональных данных и их представителей, уполномоченных органов по поводу неточности персональных данных, неправомерности их обработки, отзыва согласия и доступа субъекта персональных данных к своим данным, а также формы соответствующих запросов/обращений, предусматриваются внутренними регламентами и/или иными локальными актами Оператор, разрабатываемыми в соответствии с положениями настоящей политики.<br />
                    При обработке персональных данных Оператор принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных в соответствии с требованиями статьи 19 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г.<br />
                    Для обеспечения безопасности персональных данных при их обработке, на основе определяемых моделей угроз, Оператор использует Информационную систему персональных данных (ИСПДн) 3-го класса.<br />
                </p>

                <p className='text_big'>5. Правила в отношении использования файлов cookie</p>

                <p className='text'>5.1. Посещая сайт Оператор в сети «Интернет», посетитель сайта соглашается с настоящими правилами, в том числе с тем, что Оператор может использовать файлы cookie и иные данные для их последующей обработки системами Google Analytics, Яндекс.Метрика и др., а также может передавать их третьим лицам для проведения исследований, выполнения работ или оказания услуг.<br />
                    5.2. Файлы cookie – текстовые файлы небольшого размера, которые сохраняются на вашем устройстве (персональном компьютере, ноутбуке, планшете, мобильном телефоне и т.п.), когда вы посещаете сайты в сети «Интернет».<br />
                    5.3. При посещении сайта Оператор в сети «Интернет» происходит автоматический сбор иных данных, в том числе: технических характеристик устройства, IP-адреса, информации об используемом браузере и языке, даты и времени доступа к сайту, адресов запрашиваемых страниц сайта и иной подобной информации.<br />
                    5.4. В зависимости от используемых посетителями сайта браузера и устройств используются разные наборы файлов cookie, включающие в себя строго необходимые, эксплуатационные, функциональные и аналитические файлы cookie.<br />
                    5.5. При посещении посетителями сайта Оператор в сети «Интернет» файлы cookie могут использоваться для:<br />
                    · обеспечения функционирования и безопасности сайта;<br />
                    · улучшения качества сайта;<br />
                    · регистрации в системе самообслуживания (личном кабинете);<br />
                    · предоставлении информации о Оператор, его продуктах и услугах;<br />
                    · усовершенствования продуктов и (или) услуг и для разработки новых продуктов и (или) услуг.<br />
                    5.6. Иная собираемая информация может быть использована для генерации «списка интересов» посетителей сайта, состоящего из случайного идентификатора, категории интереса и отметки времени для демонстрации посетителям сайта интернет-контента и рекламных объявлений, соответствующих интересам посетителей сайта.<br />
                    5.7. Используемые посетителями сайта браузер и (или) устройство могут позволять посетителям сайта блокировать, удалять или иным образом ограничивать использование фалов cookie. Но файлы cookie являются важной частью сайта Оператор в сети «Интернет», поэтому блокировка, удаление или ограничение их использования может привести к тому, что посетители сайта будут иметь доступ не ко всем функциям сайта.<br />
                    5.8. Чтобы узнать, как управлять файлами cookie с помощью используемых посетителями сайта браузера или устройства, посетители сайта могут воспользоваться инструкцией, предоставляемой разработчиком браузера или производителем устройства.<br />
                </p>

                <p className='text_big'>6. Разрешение споров</p>

                <p className='text'>6.1. Обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).<br />
                    6.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.<br />
                    6.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.<br />
                    6.4. К отношениям Сторон применяется законодательство Российской Федерации.<br />
                </p>

                <p className='text_big'>7. Заключительные положения</p>

                <p className='text'>7.1. Настоящая Политика подлежит пересмотру:<br />
                    • Ежегодно;<br />
                    • При изменении требований законодательства о персональных данных;<br />
                    • При изменении бизнес процессов Оператор в рамках которых производится обработка<br />
                    персональных данных.<br />
                    7.2. Пересмотренная Политика утверждается Генеральным директором Оператора и вступает в силу с момента ее размещения на Сайте.<br />
                    7.3. Настоящая Политика обязательна для соблюдения и подлежит доведению до всех работников Оператор.<br />
                    7.4. Для организации неограниченного доступа настоящая Политика обязательна к опубликованию на интернет сайтах Оператор</p>
            </div>

            <div className='privacy-policy__tab-box'>
                <div className='privacy-policy__tab-item'>
                    <Link className='link__grey' to="#">Политика конфиденциальности</Link>
                </div>
                <div className='privacy-policy__tab-item'>
                    <Link className='link__grey' to="#">Политика оплаты и возвратов</Link>
                </div>
                <div className='privacy-policy__tab-item'>
                    <Link className='link__grey' to="#">Пользовательское соглашение</Link>
                </div>
            </div>
        </div>
    )
}

export { PrivacyPolicy }