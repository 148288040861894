import { useState, useEffect } from 'react';
import { ButtonBasic, Checkbox } from "@lk-gtcom/ecomlab-components";
import print_icon from './img/print_icon.svg'
import { TabsGreyBtn } from '../../TabsGreyBtn/TabsGreyBtn';
import points_icon from './img/points_icon.png'
import box_icon from './img/box_icon.svg'
import { ButtonCopy } from '../../ButtonCopy/ButtonCopy';
import imageNotFound from './img/imageNotFound.svg'
import { BtnMore } from '../../Buttons/BtnMore/BtnMore';
import { ModalDelete } from '../ModalDelete/ModalDelete';
import { ModalTransferOrder } from '../ModalTransferOrder/ModalTransferOrder';
import { ArtemOrdersHttps, ArtemOrdersHttp } from '../../../fetchUrls';
import customHeaders, {getSpace} from '../../../common/headers';
import { ModalInputField } from '../ModalInputField/ModalInputField';
import { ModalTransferToSupply } from '../ModalTransferToSupply/ModalTransferToSupply';
import useGeneralStore from '../../../store/general';
import './ModalDeliveryCreation.scss'

const btn_tab = [
    {
        label: 'Добавленные',
        value: 'added',
    },
    {
        label: 'Можно добавить',
        value: 'available_to_add'
    }
]



//const api_id = ["wb_unek_1"]

export const CardDelivery = ({
    id,
    label,
    sum,
    date,
    ind,
    fetchGetWbSuppliesBoxes,
    currentSupplies,
    setCurrentSupplies,
    fetchPackSupply,
    fetchDeleteSupply
}) => {

    const btns = [
        {
            btn: 'Удалить',
            func: (id) => {
                fetchDeleteSupply(id)
            }
        }
    ]

    const [isOpenActiveMenu, setIsOpenActiveMenu] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [nameCard, setNameCard] = useState(label ? label : '')

    return <div className={currentSupplies === id ? 'card-delivery_active' : 'card-delivery'}
        onClick={(e) => {
            setCurrentSupplies(id)
            fetchGetWbSuppliesBoxes(id)
        }}
    >
        {isEdit ?
            <input className='input'
                value={nameCard}
                onChange={(e) => setNameCard(e.target.value)}
                placeholder='Название'
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        setIsEdit(false)
                    }
                }} />
            :
            <h3 className='title' onClick={(e) => setIsEdit(true)}>{nameCard}</h3>}

        <div className='card-delivery__info'>
            <p className='text'>Заказов в поставке - <span>{(sum || sum == 0) ? sum : '0'}</span></p>
            <p className='text_date'>{`Создано ${date}`}</p>
        </div>

        {(fetchDeleteSupply || fetchPackSupply) &&
            <div className='btn-group'>
                <ButtonBasic
                    grey
                    size='32px'
                    width='100%'
                    text='Отправить'
                    onClick={(e) => fetchPackSupply()}
                />

                <BtnMore
                    id={id}
                    btns={btns}
                    save={false}
                    label={'supply'}
                    isOpenActiveMenu={isOpenActiveMenu}
                    setIsOpenActiveMenu={(e) => setIsOpenActiveMenu(e)} />
            </div>}
    </div>
}

export const CardBoxes = ({ label, sum, currentBoxes, setCurrentBoxes, ind, fetchDeleteBox, id }) => {

    const [isOpenActiveMenu, setIsOpenActiveMenu] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [nameCard, setNameCard] = useState(label ? label : '')

    const btns = [
        {
            btn: 'Удалить',
            func: (id) => {
                fetchDeleteBox(id)
            }
        }
    ]

    return <div className={currentBoxes == id ? 'card-boxes_active' : 'card-boxes'}
        onClick={(e) => setCurrentBoxes(id)}
    >
        <div className='card-boxes__content'>
            <div className='icon-and-title'>
                <img src={box_icon} />
                {isEdit ?
                    <input className='input'
                        value={nameCard}
                        onChange={(e) => setNameCard(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                setIsEdit(false)
                            }
                        }} />
                    :
                    <h3 className='title' onClick={(e) => setIsEdit(true)}>{nameCard}</h3>
                }
            </div>


            <p className='text'>Заказов в поставке - <span>{(sum || sum == 0) ? sum : '0'}</span></p>
        </div>

        {fetchDeleteBox && <BtnMore
            btns={btns}
            id={id}
            save={false}
            label={'boxes'}
            isOpenActiveMenu={isOpenActiveMenu}
            setIsOpenActiveMenu={(e) => setIsOpenActiveMenu(e)} />}
    </div>
}

export const CardCollect = ({
    name,
    brand,
    category,
    offerId,
    img,
    sum,
    ind,
    id,
    fetchAddOrdersToBox,
    fetchRemoveOrders,
    currentProduct,
    add,
    checkedAdd,
    setCheckedAdd,
    checkedDelete,
    setCheckedDelete,
    setIsModalTransfer,
    currentSupplies,
    currentFilterCheck
}) => {

    const [isOpenActiveMenu, setIsOpenActiveMenu] = useState(null)
    const [isCheck, setIsCheck] = useState(false)

    const btns_1 = [
        {
            btn: 'Добавить в короб',
            func: (id) => {
                setCheckedAdd([])
                fetchAddOrdersToBox(id, 'wb')
            }
        },
        // {
        //     btn: 'Перенести в другую поставку',
        //     func: () => {
        //         if (currentSupplies) {
        //             setIsModalTransfer(true)
        //         } else {
        //             alert('Выберите поставку из которой требуется переместить заказы')
        //         }
        //     }
        // }
    ]

    const btns_2 = [
        {
            btn: 'Удалить',
            func: (id) => {
                setCheckedAdd([])
                fetchRemoveOrders(id)
            }
        }
    ]

    useEffect(() => {
        if (add) {
            setIsCheck(checkedAdd.indexOf(id) !== -1)
        } else {
            setIsCheck(checkedDelete.indexOf(id) !== -1)
        }
    }, [checkedAdd, checkedDelete, add, currentFilterCheck, id]);


    return <div className='card-collect__main-content'>
        <Checkbox
            value={isCheck}
            onChange={e => {
                if (e) {
                    if (add) {
                        setCheckedAdd(prev => [...prev, id])
                    } else {
                        setCheckedDelete(prev => [...prev, id])
                    }
                } else {
                    if (add) {
                        setCheckedAdd(prev => prev.filter(el => el !== id))
                    } else {
                        setCheckedDelete(prev => prev.filter(el => el !== id))
                    }
                }
            }}
        />

        <div className='card-collect'>
            <div className='collect_box' >
                <div className='img-box'>
                    <img className='collect_info_img' src={img ? img : imageNotFound} onError={e => e.target.src = imageNotFound} alt='table_info_img' />
                </div>
                <div className="text_info_box">
                    <div className='title-and-btn'>
                        <p className="text_info_name" >{name}</p>
                        <BtnMore
                            id={id}
                            btns={currentProduct == 'added' ? btns_2 : btns_1}
                            save={false}
                            label={'order'}
                            isOpenActiveMenu={isOpenActiveMenu}
                            setIsOpenActiveMenu={(e) => setIsOpenActiveMenu(e)} />
                    </div>

                    <p className="text_info_desc" >{category}</p>
                    <p className="text_info_brand" >{brand}</p>
                    <div className="text_info_sub_desc_box">
                        <p className="text_info_sub_desc">Арт: <span>{offerId}</span></p>
                        <p className='text_sum'>{sum} <span> шт</span></p>
                        <ButtonCopy value={offerId} />
                    </div>
                </div>
            </div>
        </div>
    </div>

}

const ModalDeliveryCreation = ({ setIsModal, api_id }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [currentProduct, setCurrentProduct] = useState(btn_tab[0].value)

    const [currentBoxes, setCurrentBoxes] = useState(null)
    const [currentBoxesExemptions, setCurrentBoxesExemptions] = useState(null)

    const [currentSupplies, setCurrentSupplies] = useState(null)
    const [currentSuppliesExemptions, setCurrentSuppliesExemptions] = useState(null)

    const [isModalDeleteDelivery, setIsModalDeleteDelivery] = useState(false)
    const [isModalDeleteBox, setIsModalDeleteBox] = useState(false)
    const [isModalTransfer, setIsModalTransfer] = useState(false)
    const [isModalNameSupply, setIsModalNameSupply] = useState(false)

    const [supplies, setSupplies] = useState([])
    const [suppliesWithExemptions, setSuppliesWithExemptions] = useState([])

    const [boxes, setBoxes] = useState([])
    const [boxesWithExemptions, setBoxesWithExemptions] = useState([])

    const [notAddedOrders, setNotAddedOrders] = useState([])
    const [addedOrders, setAddedOrders] = useState([])

    const [nameSupply, setNameSupply] = useState('')
    const [checkNotInSupply, setCheckNotInSupply] = useState(true)
    const [checkInThisSupplyNoBox, setCheckInThisSupplyNoBox] = useState(false)
    const [checkInOtherSupplyNoBox, setCheckInOtherSupplyNoBox] = useState(false)

    const [checkedAdd, setCheckedAdd] = useState([])
    const [checkedDelete, setCheckedDelete] = useState([])

    const [currentFilterCheck, setCurrentFilterCheck] = useState('no_supply')

    // Получить поставки
    // Done
    // Переделан - не работает 500
    // Работает

    const fetchGetWbSupplies = (id_to_exclude) => {
        if (!api_id) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/get-supply-list?api_id=${api_id + (id_to_exclude ? '&id_to_exclude=' + id_to_exclude : '')}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/get-supply-list?api_id=${api_id + (id_to_exclude ? '&id_to_exclude=' + id_to_exclude : '')}`
        }


        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then((json) => {
                if (json?.supplies) {
                    setSupplies(json?.supplies)
                }
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Получить боксы
    // Done
    // У всех одиннаковы id
    // Переделан - не работает 500
    // Работает

    const fetchGetWbSuppliesBoxes = (supply_id) => {
        if (!supply_id) {
            return
        }

        if (!api_id) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/get-supply-box-list?api_id=${api_id}&supply_id=${supply_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/get-supply-box-list?api_id=${api_id}&supply_id=${supply_id}`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then((json) => {
                if (json?.boxes) {
                    setBoxes(json?.boxes)
                }
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Получить заказы бокса
    // Done
    // Переделан - не работает 500
    // Работает

    const fetchGetWbBoxOrders = () => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/get-box-order-list?api_id=${api_id}&supply_id=${currentSupplies}&box_id=${currentBoxes}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/get-box-order-list?api_id=${api_id}&supply_id=${currentSupplies}&box_id=${currentBoxes}`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then((json) => {
                if (json?.orders) {
                    setAddedOrders(json?.orders)
                }
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Cписок заказов, которые не относятся ни к одной поставке
    // Done
    // Переделан - не работает 500
    // Работает

    const fetchGetWbUnassignedOrders = () => {
        if (!api_id) return

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/get-unassigned-order-list?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/get-unassigned-order-list?api_id=${api_id}`
        }

        const body = JSON.stringify({
            order_type: currentFilterCheck,
            supply_id: currentSupplies
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then((json) => {
                if (json?.orders) {
                    setNotAddedOrders(json?.orders)
                }
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Создать поставку
    // Done
    // Переделан - не работает 500
    // Работает

    const fetchCreateSupply = (name) => {
        if (!api_id) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/create-supply?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/create-supply?api_id=${api_id}`
        }

        const body = JSON.stringify({
            name
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                // получить все поставкм
                setSupplies([])
                fetchGetWbSupplies()
            })
            .catch(err => console.error(err))

    }

    // Удалить поставку
    // Работает

    const fetchDeleteSupply = (supply_id) => {
        if (!api_id) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/delete-supply?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/delete-supply?api_id=${api_id}`
        }

        const body = JSON.stringify({
            supply_id: supply_id
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                if (json?.status == 'ok') {
                    alert(`Поставка ${supply_id} удалена`)
                    setSupplies([])
                    fetchGetWbSupplies()
                } else {
                    alert(`Невозможно удалить эту поставку, в ней находятся заказы`)
                }
            })
            .catch(err => console.error(err))

    }

    // Добавления ящиков к существующей поставке.
    // Done
    // Переделан - не работает 500
    // Работает

    const fetchAddBoxesToSupply = () => {
        if (!api_id) return
        if (!currentSupplies) {
            //    alert('Выберите поставку')
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/add-boxes?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/add-boxes?api_id=${api_id}`
        }

        const body = JSON.stringify({
            amount: 1, // Необязательный параметр. Количество добавляемых ячеек. Значение по умолчанию - 1
            supply_id: currentSupplies
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                fetchGetWbSuppliesBoxes(currentSupplies)
                // Обновить поставки
                fetchGetWbSupplies()
            })
            .catch(err => console.error(err))

    }

    // Удалить бокс
    // Done
    // Переделан - не работает 500
    // Работает
    const fetchDeleteBox = (id) => {
        if (!api_id) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/delete-boxes?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/delete-boxes?api_id=${api_id}`
        }

        const body = JSON.stringify({
            box_ids: [id],
            supply_id: currentSupplies
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                setAddedOrders([])
                setCurrentBoxes(null)
                fetchGetWbSuppliesBoxes(currentSupplies)
                fetchGetWbUnassignedOrders()
            })
            .catch(err => console.error(err))

    }

    // Чтобы закрыть поставку и пометить все заказы в ней как ожидающие доставки
    // ошибка 500
    // Переделан - не работает 500
    // 409 - есть товары без коробок

    const fetchPackSupply = () => {
        if (!api_id) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/pack-supply?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/pack-supply?api_id=${api_id}`
        }

        const body = JSON.stringify({
            supply_id: currentSupplies
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.status == 409) {
                    alert('Ошибка. Есть товары без коробок')
                } else return res.json()
            })
            .then(json => {
                if (json?.status == 'ok') {
                    alert('Поставка закрыта')
                }
            })
            .catch(err => console.error(err))

    }

    // Удалить заказы из ящика. Заказы не удаляются
    // Done
    // Переделан - не работает 500
    // Работает 
    const fetchRemoveOrders = (order_ids) => {
        if (!api_id) {
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/remove-order-from-box?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/remove-order-from-box?api_id=${api_id}`
        }

        const body = JSON.stringify({
            box_id: currentBoxes,
            order_ids: Array.isArray(order_ids) ? order_ids : [order_ids],
            supply_id: currentSupplies
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                // обновить добавленные товары
                fetchGetWbBoxOrders(currentBoxes)
                // Обновить боксы
                fetchGetWbSuppliesBoxes(currentSupplies)
                // Обновить поставки
                fetchGetWbSupplies()
            })
            .catch(err => console.error(err))

    }

    // Получить список заказов в указанной поставке, у которых нет коробки.

    const fetchGetLooseOrderList = () => {
        if (!api_id) {
            return
        }

        let supply_id;
        if (currentSupplies) {
            supply_id = currentSupplies
        } else return

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/get-loose-order-list?api_id=${api_id}&supply_id=${supply_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/get-loose-order-list?api_id=${api_id}&supply_id=${supply_id}`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then((json) => {
                console.log('Заказы без коробки:' + json?.orders)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // useEffect(() => {
    //     fetchGetLooseOrderList()
    // }, [currentSupplies]);

    // Добавить заказы в коробку
    // Done , работает странно
    // Переделан - не работает 500
    // Работает

    const fetchAddOrdersToBox = (order_ids, transfer = false) => {
        if (!api_id) {
            return
        }
        if (!currentBoxes && !currentSuppliesExemptions) {
            alert('Выберите короб для добавления товара')
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/add-order-to-box?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/add-order-to-box?api_id=${api_id}`
        }

        const body = JSON.stringify({
            box_id: currentBoxesExemptions ? currentBoxesExemptions : currentBoxes,
            order_ids: Array.isArray(order_ids) ? order_ids : [order_ids],
            supply_id: currentSuppliesExemptions ? currentSuppliesExemptions : currentSupplies
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                fetchGetWbUnassignedOrders()
                fetchGetWbSuppliesBoxes(currentSupplies)
                fetchGetWbBoxOrders(currentSupplies)
                // Обновить поставки
                fetchGetWbSupplies()
            })
            .catch(err => console.error(err))
    }

    // Получить двоичные файлы с меткой доставки
    // наклейка на заказ
    const fetchGetPostingLabels = (filter_type = 'wb') => {
        if (!api_id || api_id?.length == 0) {
            alert('Выберите магазин')
            return
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/get-posting-labels?filter_type=${filter_type}`
        } else {
            url = `${ArtemOrdersHttp}/add-orders-to-box?filter_type=${filter_type}`
        }

        const body = JSON.stringify({
            api_id: api_id,
            posting_numbers: [""] // Список номеров проводок/порядковых номеров
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                console.log(json)
            })
            .catch(err => console.error(err))
    }

    // Получение списка заказов, которые могут быть помещены в коробку

    const fetchGetOrderList = () => {
        if (!api_id) return
        if (!currentSupplies) return

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemOrdersHttps}/wb-supplies/get-order-list?api_id=${api_id}`
        } else {
            url = `${ArtemOrdersHttp}/wb-supplies/get-order-list?api_id=${api_id}`
        }

        const body = JSON.stringify({
            no_box_other_supplies: checkNotInSupply,
            no_box_this_supply: checkInThisSupplyNoBox,
            supply_id: currentSupplies
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                if (json?.orders) {
                    setNotAddedOrders(json?.orders)
                }
            })
            .catch(err => console.error(err))

    }

    const current_orders = currentProduct == 'available_to_add' ? notAddedOrders : addedOrders

    useEffect(() => {
        fetchGetWbSuppliesBoxes(currentSuppliesExemptions, true)
    }, [currentSuppliesExemptions, selectedSpace]);

    useEffect(() => {
        fetchGetWbUnassignedOrders()
    }, [currentFilterCheck, currentSupplies, selectedSpace]);

    useEffect(() => {
        fetchGetWbSupplies()
        fetchGetWbUnassignedOrders()
    }, [selectedSpace]);

    useEffect(() => {
        // if (currentBoxes) {
        //     fetchGetWbBoxOrders(currentBoxes)
        // }
        setAddedOrders([])
        setAddedOrders([])
        setCurrentBoxes(null)
    }, [currentSupplies]);

    useEffect(() => {
        if (currentBoxes) {
            fetchGetWbBoxOrders(currentBoxes)
        }
        fetchGetWbUnassignedOrders()
    }, [currentBoxes, currentProduct, selectedSpace]);

    useEffect(() => {
        setCurrentSuppliesExemptions(null)
        setCurrentBoxesExemptions(null)
    }, [isModalTransfer]);

    useEffect(() => {
        setCheckedAdd([])
        setCheckedDelete([])
    }, [currentProduct]);

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            {isModalDeleteDelivery && <ModalDelete
                setIsModal={(e) => setIsModalDeleteDelivery(e)}
                title={'Вы точно хотите удалить поставку "Название поставки"?'} />}

            {isModalDeleteBox && <ModalDelete
                setIsModal={(e) => setIsModalDeleteBox(e)}
                title={'Вы точно хотите удалить коробку "Название поставки?"?'} />}

            {/* {isModalTransfer && <ModalTransferOrder
                setIsModal={(e) => setIsModalTransfer(e)}
            />} */}

            {isModalNameSupply && <ModalInputField
                title='Название поставки'
                fetchAction={(e) => fetchCreateSupply(e)}
                name={nameSupply}
                setName={(e) => setNameSupply(e)}
                setIsModal={(e) => setIsModalNameSupply(e)}
            />}

            {/* {isModalTransfer && <ModalTransferToSupply
                fetchGetWbSupplies={(e) => fetchGetWbSupplies(e)}
                currentSupplies={currentSupplies}
                suppliesWithExemptions={suppliesWithExemptions}
                currentSuppliesExemptions={currentSuppliesExemptions}
                setCurrentSuppliesExemptions={(e) => setCurrentSuppliesExemptions(e)}
                boxesWithExemptions={boxesWithExemptions}
                currentBoxesExemptions={currentBoxesExemptions}
                setCurrentBoxesExemptions={(e) => setCurrentBoxesExemptions(e)}
                setIsModal={(e) => setIsModalTransfer(e)}
                fetchAddOrdersToBox={(e) => fetchAddOrdersToBox(e)}
                checkedAdd={checkedAdd}
            />} */}

            <div className='modal-delivery-creation'>
                <h1 className='title'>Управление поставками</h1>
                <div className='modal-delivery-creation__content'>

                    <div className='deliveries-list'>
                        <div className='deliveries-list__header'>
                            <h3 className='title'>Поставки - <span>{supplies?.length}</span></h3>
                            <ButtonBasic
                                green
                                width='100%'
                                text='Новая постака +'
                                size='32px'
                                onClick={(e) => setIsModalNameSupply(true)}
                            />
                        </div>

                        <div className='deliveries-list__content'>
                            {supplies?.map(({ supply_id, name, date, orders_total }, ind) =>
                                <CardDelivery
                                    id={supply_id}
                                    ind={ind}
                                    label={name}
                                    sum={orders_total}
                                    date={date}
                                    fetchGetWbSuppliesBoxes={(e) => fetchGetWbSuppliesBoxes(e)}
                                    currentSupplies={currentSupplies}
                                    setCurrentSupplies={(e) => setCurrentSupplies(e)}
                                    fetchPackSupply={(e) => fetchPackSupply(e)}
                                    fetchDeleteSupply={(e) => fetchDeleteSupply(e)}
                                />
                            )}
                        </div>
                    </div>

                    <div className='boxes'>
                        <div className='boxes__header'>
                            <h3 className='title'>Короба - <span>{boxes?.length}</span></h3>
                            <div className='btn-group'>
                                <ButtonBasic
                                    whiteGreen
                                    width='100%'
                                    text='Добавить короб +'
                                    size='32px'
                                    onClick={(e) => fetchAddBoxesToSupply()}
                                />
                                <button className='btn-print'
                                onClick={(e) => fetchGetPostingLabels()}
                                >
                                    <img src={print_icon} />
                                </button>
                            </div>
                        </div>

                        <div className='boxes__content'>
                            {
                                boxes.length > 0 ?
                                    boxes.map(({ box_id, orders_total }, ind) =>
                                        <CardBoxes
                                            id={box_id}
                                            ind={ind}
                                            label={`Короб - ${ind + 1}`}
                                            sum={orders_total}
                                            currentBoxes={currentBoxes}
                                            setCurrentBoxes={(e) => setCurrentBoxes(e)}
                                            fetchDeleteBox={(e) => fetchDeleteBox(e)}
                                            fetchRemoveOrders={(e) => fetchRemoveOrders(e)}
                                        />
                                    )
                                    :
                                    <span
                                        style={{ padding: '20px' }}
                                    >Нет коробок для этой поставки</span>

                            }

                        </div>
                    </div>

                    <div className='goods-in-box'>
                        <div className='goods-in-box__header'>
                            <h3 className='title'>Товары в коробе - <span>{addedOrders?.length}</span></h3>
                            <TabsGreyBtn
                                btns={btn_tab}
                                currentBtn={currentProduct}
                                setCurrentBtn={(e) => setCurrentProduct(e)}
                                checkValue={true}
                            />

                            {currentProduct == 'available_to_add' && <div className='goods-in-box__checkbox-group'>
                                {/* <Checkbox
                                    value={checkNotInSupply}
                                    onChange={e => {
                                        if (currentSupplies) {
                                            setCheckedAdd([])

                                            setCheckInThisSupplyNoBox(false)
                                            setCheckInOtherSupplyNoBox(false)

                                            setCurrentFilterCheck('no_supply')

                                            setCheckNotInSupply(e)
                                        } else alert('Выберите поставку')

                                    }}
                                ><span>Все без поставки</span></Checkbox >

                                <Checkbox
                                    value={checkInThisSupplyNoBox}
                                    onChange={e => {
                                        if (currentSupplies) {
                                            setCheckedAdd([])

                                            setCheckNotInSupply(false)
                                            setCheckInOtherSupplyNoBox(false)

                                            setCurrentFilterCheck('no_box_this_supply')

                                            setCheckInThisSupplyNoBox(e)
                                        } else alert('Выберите поставку')
                                    }}
                                >

                                </Checkbox >

                                <Checkbox
                                    value={checkInOtherSupplyNoBox}
                                    onChange={e => {
                                        if (currentSupplies) {
                                            setCheckedAdd([])

                                            setCheckNotInSupply(false)
                                            setCheckInThisSupplyNoBox(false)

                                            setCurrentFilterCheck('no_box_other_supplies')

                                            setCheckInOtherSupplyNoBox(e)
                                        } else alert('Выберите поставку')
                                    }}
                                ><span>Все в других поставках без коробки</span></Checkbox > */}

                                <label className='radio-box'>
                                    <input type="radio" name="radio"
                                        checked={currentFilterCheck == 'no_supply' ? true : false}
                                        onChange={(e) => {
                                            // setCheckedAdd([])
                                            setCurrentFilterCheck('no_supply')
                                        }} />
                                    <span>Все без поставки</span>
                                </label>

                                <label className='radio-box'>
                                    <input type="radio" name="radio"
                                        checked={currentFilterCheck == 'no_box_this_supply' ? true : false}
                                        onChange={(e) => {
                                            // setCheckedAdd([])
                                            setCurrentFilterCheck('no_box_this_supply')
                                        }} />
                                    <span>Все в этой поставке без коробки</span>
                                </label>

                                <label className='radio-box'>
                                    <input type="radio" name="radio"
                                        checked={currentFilterCheck == 'no_box_other_supplies' ? true : false}
                                        onChange={(e) => {
                                            // setCheckedAdd([])
                                            setCurrentFilterCheck('no_box_other_supplies')
                                        }} />
                                    <span>Все в других поставках без коробки</span>
                                </label>

                            </div>}

                            {(currentProduct == 'available_to_add') && <ButtonBasic
                                green
                                disabled={checkedAdd.length === 0}
                                width='100%'
                                size='32px'
                                text='Перенести в короб'
                                onClick={(e) => {
                                    fetchAddOrdersToBox(checkedAdd)
                                    setCheckedAdd([])
                                    setCheckedDelete([])
                                }}
                            />}

                            {(currentProduct == 'added') && <ButtonBasic
                                red
                                disabled={checkedDelete.length === 0}
                                width='100%'
                                size='32px'
                                text='Удалить из короба'
                                onClick={(e) => {
                                    fetchRemoveOrders(checkedDelete)
                                    setCheckedDelete([])
                                    setCheckedDelete([])
                                }}
                            />}
                        </div>

                        <div className='goods-in-box__content'>
                            {current_orders.length > 0
                                ?
                                current_orders?.map(({ order_id, name, brand, category, offer_id, quantity, img }, ind) =>
                                    <CardCollect
                                        id={order_id}
                                        fetchAddOrdersToBox={(e) => fetchAddOrdersToBox(e)}
                                        img={img}
                                        ind={ind}
                                        name={name}
                                        brand={brand}
                                        category={category}
                                        offerId={offer_id}
                                        sum={quantity}
                                        fetchRemoveOrders={(e) => fetchRemoveOrders(e)}
                                        currentProduct={currentProduct}
                                        checkedAdd={checkedAdd}
                                        setCheckedAdd={(e) => setCheckedAdd(e)}
                                        checkedDelete={checkedDelete}
                                        setCheckedDelete={(e) => setCheckedDelete(e)}
                                        add={currentProduct == 'available_to_add'}
                                        setIsModalTransfer={(e) => setIsModalTransfer(e)}
                                        currentSupplies={currentSupplies}
                                        currentFilterCheck={currentFilterCheck}
                                    />
                                )
                                :
                                <span
                                    style={{ padding: '20px' }}
                                >Нет заказов по заданным параметрам</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ModalDeliveryCreation }