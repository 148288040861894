import { DropZone } from '../../DropZone/DropZone'
import back_icon from './img/back_icon.svg'
import './ModalCoverProject.scss'

const ModalCoverProject = ({ setIsModal, imgArr, currentCover, setCurrentCover }) => {

    const loadImg = (e) => {
        setCurrentCover(e)
        setIsModal(false)
    }

    return (
        <div className='modal-cover-project'>
            <div className='modal-cover-project__header'>
                <button className='btn-back' onClick={(e) => {
                    setIsModal(false)
                } }><img src={back_icon} /></button>
                <h3 className='title'>Выберите обложку</h3>
            </div>

            <div className='modal-cover-project__content'>
                <DropZone setSave={(e) => {
                   loadImg(e)
                }} />

                <div className='img-content'>
                    {imgArr.map((img, ind) =>
                        <div className={currentCover == img ? 'img-box_active' : 'img-box'} key={ind + img}
                            onClick={(e) => {
                                setCurrentCover(img)
                                setIsModal(false)
                            }} >
                            <img src={img} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export { ModalCoverProject }