import { TaskList } from "../TaskList/TaskList"
import { ProjectForTasks } from '../ProjectForTasks/ProjectForTasks';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';


const TaskPage = () => {

    const path = useParams()
    const navigate = useNavigate()

    const [selectedProject, setSelectedProject] = useState(null)

     useEffect(() => {
        if (path['*'] === '') {
            navigate('/tasks/project-for-tasks')
            setSelectedProject(null)
        }
    }, [path])


    return (
        <Routes>
            <Route path='project-for-tasks/*' element={<ProjectForTasks selectedProject={selectedProject} setSelectedProject={(e) => setSelectedProject(e)}/>} />
            <Route path='task-list/*' element={<TaskList setSelectedProjectAbove={e => setSelectedProject(e)} />} />
        </Routes>
    )
}

export { TaskPage }