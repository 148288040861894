import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { ReactComponent as Info } from './img/info.svg'
import "./Hint.scss"

const list_recommendation = [
    {
        status_read: false,
        title: 'Реклама',
        solution: 'Советуем увеличить бюджет',
        reason: 'Среднего бюджета на день или месяц, cреднего бюджета на день или месяц',
        criticality: 'medium'
    },
    {
        status_read: true,
        title: 'Реклама',
        solution: 'Советуем увеличить бюджет',
        reason: 'Среднего бюджета на день или месяц',
        criticality: 'low'
    },
    {
        status_read: false,
        title: 'Реклама',
        reason: 'Среднего бюджета на день или месяц',
        criticality: 'hard'
    }
]

const statusCriticality = (status) => {
    const criticality = {
        'medium': ['Средне критично', 'orange'],
        'hard': ['Очень критично', 'red'],
        'low': ['Не критично', 'green'],
    }

    return <div className={`card-recommendation__status-criticality ${`status-${criticality[status][1]}`}`}>
        <Info />
        {criticality[status][0]}
    </div>
}

const CardRecommendation = (props) => {

    const {
        status_read,
        title,
        solution,
        reason,
        criticality
    } = props

    return <div className='card-recommendation' key={title + solution}>
        <header className='card-recommendation__header'>
            <div className='card-recommendation__info-panel'>
                {statusCriticality(criticality)}
                {
                    status_read ?
                        <div className='card-recommendation__status-read read'>Прочитано</div>
                        :
                        <div className='card-recommendation__status-read no-read'>Не прочитано</div>
                }

            </div>
            <h3 className='card-recommendation__title'>Реклама</h3>
        </header>

        <div className='card-recommendation__content'>
            <div className='card-recommendation__reason'>
                <h6 className='card-recommendation__title-reason'>Причина</h6>
                <p className='card-recommendation__text'>{reason}</p>
            </div>

            {solution && <div className='card-recommendation__solution'>
                <h6 className='card-recommendation__title-solution'>Решение</h6>
                <p className='card-recommendation__text'>{solution}</p>
            </div>}
        </div>

        {solution && <ButtonBasic
            green
            width='100%'
            text='Решить проблему'
            style={{
                marginTop: 'auto'
            }}
        />}
    </div>
}

const Hint = () => {

    return (
        <div className='hint-page'>
            <h1 className='hint-page__title'>Рекомендации</h1>
            <div className='hint-page__card-content'>
                {list_recommendation?.map((props, ind) => {
                    return <CardRecommendation
                        {...props}
                    />
                })}
            </div>
        </div>
    )
}

export { Hint }