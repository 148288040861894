import card_icon from './img/box_icon.svg'
import "./CardOrder.scss"

const CardOrder = ({
    date,
    count,
    key,
    currentCard,
    setCurrentCard = false,
    less12,
    expire,
    to_deliver,
    to_pack,
    overdue_count,
    total,
    urgent
}) => {

    const getSplitedDate = (date) => {
        const [day, month, year] = new Date(date)?.toLocaleDateString('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('.')
        return `${year}-${month}-${day}`
    }

    const dayMs = 86400000
    const today = getSplitedDate(new Date()) === date
    const tomorrow = getSplitedDate(new Date().getTime() + dayMs) === date
    const dayAfterTomorrow = getSplitedDate(new Date().getTime() + dayMs * 2) === date

    const formateDate = () => {
        if (!date) return '-'
        if (date == 'all') {
            return 'Все'
        } else {
            return new Date(date).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit' })
        }
    }

    return (
        <div className={(currentCard && currentCard == date) ? 'card-order_active' : 'card-order'} key={key}
            onClick={(e) => {
                if (setCurrentCard) {
                    setCurrentCard(date)
                }
            }}
        >
            <div className='card-order__header'>
                {(today || tomorrow || dayAfterTomorrow) ?
                    <div className='date-content'>
                        <p className='text_date'>{today ? 'Сегодня' : tomorrow ? 'Завтра' : 'Послезавтра'}</p>
                        <p className='text_grey'>{formateDate()}</p>
                    </div>
                    :
                    <p className='text_date'>{formateDate()}</p>
                }
                <div className='count-box'>
                    {(total || total == 0) ? total : '-'}
                    <img src={card_icon} />
                </div>
            </div>

            {<div className='card-order__content'>
                {(less12 || less12 == 0) && <p className="text">Менее 12 ч - <span>{less12}</span></p>}
                {(to_pack || to_pack == 0) && <p className="text text_overdue">Ожидают упаковки - <span>{to_pack}</span></p>}
                {(to_deliver || to_deliver == 0) && <p className="text text_overdue">Ожидают доставки - <span>{to_deliver}</span></p>}
                {(urgent || urgent == 0) && <p className="text text_overdue">Просрочено - <span>{urgent}</span></p>}
            </div>}
        </div>
    )
}

export { CardOrder }