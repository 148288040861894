import { useState, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import dayjs from 'dayjs'
import { calendar_date_picker } from '../../common/svg_img';
import { CalendarContainer } from 'react-datepicker';
import { getFormatDate } from '../../common/formatters';
import "react-datepicker/dist/react-datepicker.css";
import './RangeDatePicker.scss';

dayjs.extend(require('dayjs/plugin/weekday'))

const RANGE_OPTIONS = {
  today: 'today',
  yesterday: 'yesterday',
  last7Days: 'last7Days',
  last30Days: 'last30Days',
  thisWeek: 'thisWeek',
  thisMonth: 'thisMonth',
  lastMonth: 'lastMonth',
  priorThisDay: 'priorThisDay'
}

const RangeDatePicker = ({
  dateFromUnix,
  dateToUnix,
  range,
  resetRange,
  setDate,
  setRangeOfView = () => { },
  disabled = false,
  newCalendar = false,
  style,
  btnPeriod, setBtnPeriod,
  max = false,
  min = false,
  btn_period,
  filterDate = false,
  dateFormat = 'dd.MM.yyyy'
}) => {
  const dayMs = 86400000

  const calculateDateRange = range => [
    new Date((new Date().getTime() - dayMs) - (dayMs * range)),
    new Date(new Date().getTime() - dayMs)
  ]
  

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [prevRange, setPrevRange] = useState(0)

  useEffect(() => {
    if (dateFromUnix && dateToUnix) handleDatesChange([new Date(dateFromUnix), new Date(dateToUnix)])
  }, [dateFromUnix, dateToUnix])

  const handleDatesChange = (dates) => {
    const [startDate, endDate] = dates
    if (startDate && endDate && setDate) {
      setStartDate(startDate)
      setEndDate(endDate)
      let period = (startDate.getTime() - endDate.getTime()) / 84000000
      if(period < 0){
        period = period * -1
      }
      if(typeof period === 'number'){
        setRangeOfView(period.toFixed(0))
      }
    }
  }

  if (range && range !== prevRange) {
    setPrevRange(range)
    const dates = calculateDateRange(range)
    handleDatesChange(dates)
  }

  const showPriorThisDateBtn = startDate !== null && endDate === null

  const datePickerRef = useRef(null)

  const btn_list = [
    { label: 'Сегодня', value: RANGE_OPTIONS.today },
    { label: 'Вчера', value: RANGE_OPTIONS.yesterday },
    { label: 'За 7 дней', value: RANGE_OPTIONS.last7Days },
    { label: 'За 30 дней', value: RANGE_OPTIONS.last30Days },
    { label: 'Текущая неделя', value: RANGE_OPTIONS.thisWeek },
    { label: 'Текущий месяц', value: RANGE_OPTIONS.thisMonth },
    { label: 'Предыдущий месяц', value: RANGE_OPTIONS.lastMonth }
  ]

  const onButtonRangeClick = rangeOption => {
    resetRange()
    switch (rangeOption) {
      case RANGE_OPTIONS.today: {
        const startDate = new Date()
        const endDate = new Date()
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
        handleDatesChange([startDate, endDate])
        break;
      }
      case RANGE_OPTIONS.yesterday: {
        const startDate = new Date((new Date().getTime() - dayMs))
        const endDate = new Date((new Date().getTime() - dayMs))
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
        handleDatesChange([startDate, endDate])
        break;
      }
      case RANGE_OPTIONS.last7Days: {
        const [startDate, endDate] = calculateDateRange(7)
        handleDatesChange([startDate, endDate])
        break;
      }
      case RANGE_OPTIONS.last30Days: {
        const [startDate, endDate] = calculateDateRange(29)
        handleDatesChange([startDate, endDate])
        break;
      }
      case RANGE_OPTIONS.lastMonth: {
        const startDate = dayjs().subtract(1, 'M').date(1)
        const endDate = dayjs().subtract(1, 'M').date(31)
        handleDatesChange([startDate.toDate(), endDate.toDate()])
        break;
      }
      case RANGE_OPTIONS.thisWeek: {
        const startDate = dayjs().weekday(1)
        const endDate = dayjs().weekday(7)
        handleDatesChange([startDate.toDate(), endDate.toDate()])
        break;
      }
      case RANGE_OPTIONS.thisMonth: {
        const startDate = dayjs().date(1)
        const endDate = dayjs().date(31)
        handleDatesChange([startDate.toDate(), endDate.toDate()])
        break;
      }
      case RANGE_OPTIONS.priorThisDay: {
        const endDate = dayjs()
        handleDatesChange([startDate, endDate.toDate()])
        break;
      }
      default:
        break;
    }
    datePickerRef.current.setOpen(false)
  }

  const MyContainer = ({ className, children }) => {
    const showStartDateText = endDate !== null
    return (
      <div className='calendar_wrapper'>
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
          {btn_period && <div className='btn-group'>
            {btn_list.map(({ label, value }) => <button key={label} onClick={() => onButtonRangeClick(value)} className='btn'>{label}</button>)}
          </div>}
        </CalendarContainer>
        <div className='calendar-bottom-box'>
          <p className={'text_grey' + (showStartDateText ? '' : ' hide')}>Выберите дату начала периода</p>
          <button onClick={() => onButtonRangeClick(RANGE_OPTIONS.last7Days)} className='link__blue' href='#'>Очистить период</button>
          <button onClick={() => {
            onButtonRangeClick(RANGE_OPTIONS.priorThisDay)
          }} className={'btn__blue' + (showPriorThisDateBtn ? '' : ' hide')}>По сегодняшний день</button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (btnPeriod) {
      handleDatesChange(btnPeriod)
    }

  }, [btnPeriod]);

  const filterPassedTime = (time) => {
    if(!filterDate) return
      const currentDate = new Date(getFormatDate());
      const selectedDate =  new Date(getFormatDate(time));

      return currentDate.getTime() <= selectedDate.getTime();
  };

  return (
    <div className='date-picker' style={style ? style : {}}>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          const [newStartDate, newEndDate] = update
          setStartDate(newStartDate)
          setEndDate(newEndDate)
          handleDatesChange(update)
        }}
        range={true}
        disabled={disabled}
        isClearable={false}
        monthsShown={2}
        showMonthDropdown
        showYearDropdown
        closeOnScroll={true}
        dateFormat={dateFormat}
        locale={ru}
        calendarClassName={newCalendar ? "date-picker-custom" : ""}
        calendarContainer={newCalendar ? MyContainer : null}
        ref={datePickerRef}
        maxDate={max ? max : false}
        minDate={min ? min : false}
        filterDate={filterDate ? filterPassedTime : false}
      >
      </DatePicker>
      <img
        src={calendar_date_picker}
        alt="calendar-pic"
        onClick={() => datePickerRef.current.setFocus()}
      />
    </div>
  );
};

export { RangeDatePicker }