import { DropDownSelector, ButtonBasic, ViewSwitch, InputDynamicWidth } from "@lk-gtcom/ecomlab-components";
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import { useState, useRef, useEffect } from 'react';
import calendar_icon from './img/calendar.svg'
import user_icon from './img/users_icon.svg'
import done_icon from './img/done_icon.svg'
import bell_icon from './img/bell_icon.svg'
import close_icon from './img/close_icon.svg'

const CalendarToolBarTop = ({
    selectedPeriod,
    setSelectedPeriod,
    startDate,
    currentDate,
    setStartDate,
    setCurrentDate,
    setEventInfo,
    setReadOnly,
    setAddEvent,
    setIsModal,
    selectView,
    setSelectView }) => {

    const tab_btn = [
        {
            label: <>1 <span>Д</span></>,
            value: 'day'
        },
        {
            label: <>7 <span>Д</span></>,
            value: 'week'
        },
        {
            label: <>1 <span>M</span></>,
            value: 'month'
        }
    ]

    const tab_btn_2 = [
        {
            icon: user_icon,
            value: '1'
        },
        {
            icon: done_icon,
            value: '2'
        },
        {
            icon: bell_icon,
            value: '3'
        }
    ]

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [currentBtn, setCurrentBtn] = useState(tab_btn[0].value)
    const datePickerRef = useRef(null);
    const [search, setSearch] = useState('')

    const startMonth = new Date(startDate.getTime()).getMonth();
    const endMonth = new Date(startDate.getTime() + 86400000 * 4).getMonth();
    const monthList = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    const year = new Date(startDate.getTime()).getFullYear();
    const weekdayNames = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

    const currentDay = currentDate.getDay();

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!e.target.closest('.calendar-tab-box')) {
                setShowDatePicker(false)
            }
        }
        document.addEventListener('click', handleOutsideClick)
        return () => document.removeEventListener('click', handleOutsideClick)
    }, [])

    return (
        <div className='toolbar-top'>
            <div className="left-content">
                <button className='btn__add-green' onClick={() => {
                    setCurrentDate(new Date())
                    setEventInfo({
                        dateFrom: new Date(),
                        dateTo: new Date().getTime() + 3600000
                    })
                    setReadOnly(false)
                    setAddEvent(true)
                    setIsModal(true)
                }}>
                </button>

                <div className='btn-group'>
                    {tab_btn.map((el) => {
                        return <button className={selectedPeriod.value == el.value ? 'btn-range_active' : 'btn-range'}
                            onClick={(e) => {
                                setSelectedPeriod(el)
                            }}
                        >{el.label}</button>
                    })}
                </div>

                {selectedPeriod.value === 'day' ?
                    <>
                        <div className='calendar-tab-box' onClick={() => setShowDatePicker(!showDatePicker)}>
                            <p className='text'>{`${monthList[startMonth]} ${year}`}</p>
                            <div ref={datePickerRef}>
                                {showDatePicker && <DatePicker
                                    selected={startDate}
                                    startDate={startDate}
                                    onChange={(e) => setStartDate(e)}
                                    locale={ru}
                                    calendarClassName="date-picker-custom"
                                    inline
                                    onSelect={() => setShowDatePicker(false)}
                                />
                                }
                            </div>
                            <img className="img" src={calendar_icon} alt="calendar icon" />

                        </div>
                        <div className='btn-toolbar-box'>
                            <button className='btn__prev' onClick={() => {
                                const newStartDate = new Date((startDate.getTime() - 86400000))
                                setStartDate(newStartDate)
                                setCurrentDate(newStartDate)
                            }}>
                            </button>
                            <div className='date-box date-box_day'>
                                <p className='week_text'>{weekdayNames[currentDay]}</p>
                                <div className='number-box number-box_day'>
                                    <p className='text_number'>{startDate.getDate()}</p>
                                </div>
                            </div>
                            <button className='btn__next' onClick={() => {
                                const newStartDate = new Date((startDate.getTime() + 86400000))
                                setStartDate(newStartDate)
                                setCurrentDate(newStartDate)
                            }}>
                            </button>
                        </div>
                    </>
                    :
                    <div className='btn-toolbar-box'>
                        <button className='btn__prev' onClick={() => {
                            setStartDate(new Date((startDate.getTime() - 86400000 * 7)))
                        }}>
                        </button>
                        <div className='calendar-tab-box' onClick={() => setShowDatePicker(!showDatePicker)}>
                            <p className='text'>
                                {`${monthList[startMonth]} ${startMonth !== endMonth ? '- ' + monthList[endMonth] : ''} ${year}`}
                            </p>
                            {showDatePicker &&
                                <DatePicker
                                    selected={startDate}
                                    startDate={startDate}
                                    onChange={(e) => setStartDate(e)}
                                    locale={ru}
                                    calendarClassName="date-picker-custom"
                                    inline
                                    onSelect={() => setShowDatePicker(false)}
                                />
                            }
                            <img className="img" src={calendar_icon} alt="calendar icon" />
                        </div>
                        <button className='btn__next' onClick={() => {
                            setStartDate(new Date((startDate.getTime() + 86400000 * 7)))
                        }}>
                        </button>
                    </div>
                }

                <ButtonBasic
                    grey
                    width='183px'
                    text='К сегодняшнему дню'
                    onClick={() => {
                        setCurrentDate(new Date())
                        setStartDate(new Date())
                    }}
                />

                {selectedPeriod.value == 'month' && <ViewSwitch
                    tileLong={false}
                    calendar
                    tile={false}
                    selectView={selectView}
                    setSelectView={(e) => setSelectView(e)}
                />}
            </div>

            <div className="right-content">

                <InputDynamicWidth
                    position='left'
                    maxWidth='100%'
                    onChange={(e) => setSearch(e)}
                    placeholder='Ключевая фраза'
                />




                <div className='btn-group'>
                    {tab_btn_2.map((el) => {
                        return <button className={'btn-range'}
                            onClick={(e) => {
                                setCurrentBtn(el)
                            }}
                        >
                            <img src={el.icon} />
                        </button>
                    })}
                </div>
            </div>
        </div>
    );
}

export default CalendarToolBarTop;