import all_tab from './img/all_tab.svg'
import customHeaders, {getSpace} from '../../common/headers';
import participate_tab from './img/participate_tab.svg'
import not_participate_tab from './img/not-participate_tab.svg'
import { TabsGreyBtn } from '../TabsGreyBtn/TabsGreyBtn';
import { CardDiscount } from '../CardDiscount/CardDiscount';
import { RavshanHttps } from '../../fetchUrls';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react'
import { TableBox, DropDownSelector, Tabs, ButtonBasic, Checkbox } from "@lk-gtcom/ecomlab-components";
import { fetchActionFilter, fetchApiFilter, fetchBrandFilter, fetchCategoryFilter } from '../../common/filtersFunc';
import useGeneralStore from '../../store/general';
import './JoinedPromotions.scss'

const btn_tab = [
    {
        label: 'Все акции',
        value: 'all',
        icon: all_tab
    },
    {
        label: 'Участвуют',
        value: 'participate',
        icon: participate_tab
    },
    {
        label: 'Не участвуют',
        value: 'not_participate',
        icon: not_participate_tab
    }
]

const discounts = [
    {
        label: 'Все',
        value: '',
    },
    {
        label: '0 %',
        value: '0',
    },
    {
        label: '0-5 %',
        value: '0_5',
    },
    {
        label: '5-10 %',
        value: '5_10',
    },
    {
        label: '10-15%',
        value: '10_15',
    },
    {
        label: '15% и более',
        value: '15',
    },
]

const options_prop = [
    {
        label: 'Ozon',
        value: 'ozon'
    },
    // {
    //     label: 'Wildberries',
    //     value: 'wb'
    // },
    // {
    //     label: 'Yandex',
    //     value: 'yandex'
    // },
]

const JoinedPromotions = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [pageValue, setPageValue] = useState('all')
    const [sort, setSort] = useState({})

    const [apiData, setApiData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [brandData, setBrandData] = useState([])

    const [selectedMarketplace, setSelectedMarketplace] = useState([])
    const [selectedApi, setSelectedApi] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])
    const [selectedStocks, setSelectedStocks] = useState([])

    const [apiPage, setApiPage] = useState(1)
    const [categoryPage, setCategoryPage] = useState(1)
    const [brandPage, setBrandPage] = useState(1)
    const [actionPage, setActionPage] = useState(1)

    const [apiQuery, setApiQuery] = useState('')
    const [categoryQuery, setCategoryQuery] = useState('')
    const [brandQuery, setBrandQuery] = useState('')
    const [actionQuery, setActionQuery] = useState('')

    const [actionData, setActionData] = useState([])
    const [selectedAction, setSelectedAction] = useState([])

    const [tableData, setTableData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    const [columnSize, setColumnSize] = useState([])
    const [filters, setFilters] = useState([])
    const [checkedProducts, setCheckedProducts] = useState([])

    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [dateFrom, dateTo] = date
    const [isDisabledBtn, setIsDisabledBtn] = useState(true)
    const [currentDiscount, setCurrentDiscount] = useState('')
    const [checkedStateEmpty, setCheckedStateEmpty] = useState(false)
    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
    const [metricsData, setMetricsData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()

    const [loading, setLoading] = useState(false)
    const paginatorRef = useRef()
    const activeTab = useParams()['*']

    const [check, setCheck] = useState(false)

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    useEffect(() => {
        if ((selectedApi.length) && (selectedCategory.length > 0) && (selectedBrand.length > 0)) {
            setIsDisabledBtn(false)
        } else setIsDisabledBtn(true)
    }, [selectedApi, selectedCategory, selectedBrand]);

    useEffect(() => {
        fetchApiFilter({selectedSpace: selectedSpace?.value, page: apiPage, setApiData: e => setApiData(e), searchParam: apiQuery, type: selectedMarketplace?.value })
    }, [selectedSpace, apiPage, apiQuery, selectedApi, selectedMarketplace])

    useEffect(() => {
        fetchCategoryFilter({selectedSpace: selectedSpace?.value, page: categoryPage, apiData, dateFrom, dateTo, selectedApi, setCategoryData: e => setCategoryData(e), searchParam: categoryQuery })
    }, [selectedSpace, apiData, dateFrom, dateTo, selectedApi, categoryPage, categoryQuery])

    useEffect(() => {
        fetchBrandFilter({selectedSpace: selectedSpace?.value, page: brandPage, apiData, categoryData, selectedCategory, dateFrom, dateTo, searchParam: brandQuery, selectedApi, setBrandData: e => setBrandData(e) })
    }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery])

    useEffect(() => {
        fetchActionFilter({selectedSpace: selectedSpace?.value, page: actionPage, apiData, categoryData, selectedCategory, dateFrom, dateTo, searchParam: actionQuery, selectedApi, setActionData: e => setActionData(e) })
    }, [selectedSpace, selectedApi, selectedCategory, selectedBrand, brandPage])


    useEffect(() => {
        const abortController = new AbortController()
        setMetricsData([])
        setCheckedProducts([])
        paginatorRef.current?.reset()
        setCheckedStateEmpty(true)

        fetchTable([], abortController.signal)
        fetchBlocks(abortController)
        return () => {
            abortController.abort()
        }
    }, [selectedSpace, selectedApi, selectedBrand, selectedCategory, selectedAction])

    useEffect(() => {
        setCheckedProducts([])
        setCheckedStateEmpty(true)
        setTableData([])
        paginatorRef.current?.reset()
        const abortController = new AbortController()
        fetchTable([], abortController.signal)
        return () => {
            abortController.abort()
        }
    }, [selectedSpace, currentDiscount, pageValue, check])

    useEffect(() => {
        if (searchParams.size) {
            const actionId = searchParams.get('actionId')
            const actionName = searchParams.get('actionName')
            const mp_id = searchParams.get('mp_id')
            const api_names = searchParams.get('api_name')?.split(',')
            const api_ids = searchParams.get('api_id')?.split(',')
            const selectedApis = api_names?.map((el, ind) => ({ label: el, value: api_ids[ind] }))

            setSelectedAction([{ label: actionName, value: actionId }])
            setSelectedApi(selectedApis)
            setSelectedMarketplace(options_prop?.filter(({ value }) => value === mp_id))
        }
    }, [])

    const fetchTable = (params, signal = new AbortController().signal) => {
        if (selectedApi.length) {
            let sort, filters
            if (params) {
                [sort, filters] = params
            }
            setTableData([])
            setLoading(true)
            const url = `${RavshanHttps}/api/action/get_discount_product`


            let api_id_list
            let brand_id_list
            let category_id_list

            if (selectedApi?.length) {
                api_id_list = selectedApi?.map(el => el?.value)
            }

            if (selectedBrand?.length > 0) {
                brand_id_list = selectedBrand.map(el => el?.value)
            }

            if (selectedCategory?.length > 0) {
                category_id_list = selectedCategory.map(el => el?.value)
            }

            const body = JSON.stringify({
                discount: currentDiscount,
                category_id_list,
                brand_id_list,
                api_id_list,
                status: btn_tab.filter(el => el?.value === pageValue).map(el => el?.label)?.join(''),
                page: paginatorRef.current?.page,
                limit: paginatorRef.current?.limit,
                filters,
                action: selectedAction?.map(el => String(el?.value)),
                order_param: sort?.[0],
                order_param_direction: sort?.[1],
                with_stock: check
            })



            fetch(url, { body, method: 'POST', headers, signal })
                .then(res => res?.json())
                .then(json => {
                    const { total, labels, data, sort, filters, column_size } = json
                    if (labels) setTableHeaders(labels)
                    if (data) setTableData(data)
                    if (sort) setSort(sort)
                    if (column_size) setColumnSize(column_size)
                    if (filters) setFilters(filters)
                    if (total) paginatorRef.current?.setTotal(total)
                    setTableUrl(url)
                    setTableBody(body)
                })
                .catch(err => console.error(err))
                .finally(() => {
                    if (signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchBlocks = (abortController = new AbortController()) => {
        setMetricsData([])
        discounts.slice(1, discounts?.length).forEach(el => fetchBlock(el, abortController.signal))
    }

    const fetchBlock = (data, signal) => {
        const { label, value } = data
        if (selectedApi.length) {
            setLoading(true)
            const url = `${RavshanHttps}/api/action/get_count_product`


            let api_id_list
            let brand_id_list
            let category_id_list

            if (selectedApi?.length) {
                api_id_list = selectedApi?.map(el => el?.value)
            }

            if (selectedBrand?.length > 0) {
                brand_id_list = selectedBrand.map(el => el?.value)
            }

            if (selectedCategory?.length > 0) {
                category_id_list = selectedCategory.map(el => el?.value)
            }

            const body = JSON.stringify({
                category_id_list,
                brand_id_list,
                api_id_list,
                discount: value,
                action: selectedAction?.map(el => String(el?.value))
            })


            fetch(url, { body, method: 'POST', headers, signal })
                .then(async res => {
                    if (res.ok) {
                        return res?.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(async json => {
                    const { labels, data } = json
                    const { header } = labels
                    // const index = discounts.map(el => el?.label)?.indexOf(label)
                    const dataObj = { value, data, header, title: label }
                    setMetricsData(prev => [...prev, dataObj])
                })
                .catch(err => console.error(err))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onRemoveProductFromAction = () => {
        if (selectedApi.length) {
            const url = `${RavshanHttps}/api/action/delete_from_action`
            setLoading(true)

            let actionsObj = {}
            checkedProducts?.map(el => {
                const { product_id, action_id, api_id } = el
                const key = actionsObj?.[api_id]

                if (key) {
                    actionsObj[api_id] = {
                        ...actionsObj?.[api_id],
                        [action_id]: key?.[action_id] ? [...key?.[action_id], +product_id] : [+product_id]
                    }
                } else {
                    actionsObj[api_id] = { [action_id]: [+product_id] }
                }
            })

            const keys = Object.keys(actionsObj)
            const actions = keys?.map(api_id => {
                const innerObj = actionsObj?.[api_id]
                const innerKeys = Object.keys(innerObj)

                const actions = innerKeys.map(action_id => ({ action_id, products: actionsObj?.[api_id]?.[action_id] }))
                return ({ api_id, actions })
            })


            const body = JSON.stringify([...actions])

            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res?.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    alert(JSON.stringify(json))
                    fetchTable()
                    fetchBlocks()
                })
                .catch(err => {
                    console.error(err)
                    alert(JSON.stringify(err))
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onRemoveAllProductFromAction = () => {
        if (selectedApi.length & selectedBrand?.length > 0 & selectedCategory?.length > 0) {
            const url = `${RavshanHttps}/api/action/discount_delete_from_action`
            setLoading(true)

            let api_id_list
            let brand_id_list
            let category_id_list

            if (selectedApi?.length) {
                api_id_list = selectedApi?.map(el => el?.value)
            }

            if (selectedBrand?.length > 0) {
                brand_id_list = selectedBrand.map(el => el?.value)
            }

            if (selectedCategory?.length > 0) {
                category_id_list = selectedCategory.map(el => el?.value)
            }

            const body = JSON.stringify({
                discount: currentDiscount,
                category_id_list,
                brand_id_list,
                api_id_list,
            })


            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res?.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    alert(JSON.stringify(json))
                })
                .catch(err => {
                    console.error(err)
                    alert(JSON.stringify(err))
                    fetchTable()
                    fetchBlocks()
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onAddProductToAction = () => {
        if (selectedApi.length) {
            const url = `${RavshanHttps}/api/action/add_in_action`
            setLoading(true)
            let actionsObj = {}
            checkedProducts?.map(el => {
                const { product_id, action_id, api_id } = el
                const action_price = el?.["Цена товара"]?.[1]
                const key = actionsObj?.[api_id]

                if (key) {
                    actionsObj[api_id] = {
                        ...actionsObj[api_id],
                        [action_id]: key?.[action_id] ? [...key[action_id], { product_id, action_price }] : [{ product_id, action_price }]
                    }
                } else {
                    actionsObj[api_id] = { [action_id]: [{ product_id, action_price }] }
                }
            })


            const keys = Object.keys(actionsObj)
            const actions = keys?.map(api_id => {
                const innerObj = actionsObj?.[api_id]
                const innerKeys = Object.keys(innerObj)

                const actions = innerKeys.map(action_id => ({ action_id, products: actionsObj?.[api_id]?.[action_id] }))
                return ({ api_id, actions })
            })

            const body = JSON.stringify([
                ...actions
            ])



            alert(JSON.stringify(body));
            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res?.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    alert(JSON.stringify(json))
                    fetchTable()
                    fetchBlocks()
                })
                .catch(err => {
                    console.error(err)
                    alert(JSON.stringify(err))
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onAddAllProductToAction = () => {
        if (selectedApi.length > 0) {
            const url = `${RavshanHttps}/api/action/add_all_in_action`
            setLoading(true)

            let obj = {}
            tableData?.forEach((el) => {
                const action_id = el?.[11]
                const api_id = el?.[12]

                if (obj[api_id]) {
                    if (obj[api_id]?.indexOf(action_id) !== -1) return
                    obj[api_id] = [...obj[api_id], action_id]
                } else {
                    obj[api_id] = [action_id]
                }
            })

            const body = JSON.stringify(Object.keys(obj).map(el => ({ api_id: el, actions: obj[el] })))

            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res?.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    alert(JSON.stringify(json))
                })
                .catch(err => {
                    console.error(err)
                    alert(JSON.stringify(err))
                    fetchTable()
                    fetchBlocks()
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className='joined-promotions'>

            <h1 className='title_main'>Товары в акции</h1>

            <div className='filter-group'>
                <FilterContainer
                    onReset={(e) => {
                        setSelectedApi([])
                        setSelectedCategory([])
                        setSelectedBrand([])
                        setSelectedStocks([])
                    }}
                >
                    <DropDownSelector
                        style={{ maxWidth: '210px' }}
                        options_prop={options_prop}
                        state={selectedMarketplace}
                        setState={e => setSelectedMarketplace(e)}
                        placeholder='Площадка'
                        defaultValue={options_prop[0]}
                    />

                    <DropDownSelector
                        options_prop={apiData}
                        state={selectedApi}
                        setState={e => setSelectedApi(e)}
                        placeholder='Магазин'
                        fetchCallback={(e) => setApiPage(e)}
                        setInputChange={(e) => setApiQuery(e)}
                        multi
                    />
                    <DropDownSelector
                        options_prop={categoryData}
                        state={selectedCategory}
                        setState={e => setSelectedCategory(e)}
                        placeholder='Категория'
                        fetchCallback={(e) => setCategoryPage(e)}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        multi
                    />
                    <DropDownSelector
                        options_prop={brandData}
                        state={selectedBrand}
                        setState={e => setSelectedBrand(e)}
                        placeholder='Бренд'
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        multi
                    />

                    <DropDownSelector
                        options_prop={actionData}
                        state={selectedAction}
                        setState={e => setSelectedAction(e)}
                        placeholder='Акции'
                        fetchCallback={(e) => setActionPage(e)}
                        setInputChange={(e) => setActionQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        multi
                    />
                </FilterContainer>

                <Checkbox
                    value={check}
                    onChange={e => setCheck(!check)}>
                    <p className='label-check'>Без 0 остатков</p>
                </Checkbox>
            </div>

            <section className='metrics-items'>
                {
                    metricsData?.length > 0 ?
                        metricsData?.sort((a, b) => {
                            const aArr = (a?.value?.split('_'))
                            const bArr = (b?.value?.split('_'))
                            const sortRes = aArr[aArr?.length - 1] - bArr[bArr?.length - 1]
                            if (sortRes === 0) {
                                return bArr?.length - aArr?.length
                            }
                            return sortRes
                        }).map((el, i) => {
                            const { data, header, title } = el
                            return <CardDiscount
                                key={i + title}
                                header={header}
                                data={data}
                                title={title}
                                period=''
                                loading={null}
                            />
                        })
                        :
                        [...Array(5)]?.map((e, i) => {
                            return <CardDiscount
                                key={i}
                                data={[]}
                                title=''
                                period='7'
                                loading={null}
                            />
                        })
                }
            </section>

            <Tabs tabs={btn_tab} pageValue={pageValue} />

            <TableBox
                loading={loading}
                fetchedData={tableData}
                fetchCallback={e => {
                    const abortController = new AbortController()
                    fetchTable(e, abortController.signal)
                    return () => abortController.abort()
                }}
                headers={tableHeaders}
                tableUrl={tableUrl}
                tableBody={tableBody}
                sort={sort}
                paginator={true}
                ref={paginatorRef}
                columnSize={columnSize}
                filters={filters}
                setCheckedStateEmpty={checkedStateEmpty ? e => setCheckedStateEmpty(e) : false}
                onCheck={e => setCheckedProducts(e)}
                child={
                    <div className='child-content'>
                        <TabsGreyBtn
                            title='Скидка'
                            btns={discounts}
                            currentBtn={currentDiscount}
                            setCurrentBtn={(e) => setCurrentDiscount(e)}
                            checkValue={true}
                        />
                        {
                            pageValue !== 'all' &&
                            <>
                                <ButtonBasic
                                    action
                                    text={pageValue !== 'participate' ? 'Добавить все' : 'Удалить все'}
                                    size='32px'
                                    maxWidth='140px'
                                    width='140px'
                                    onClick={e => {
                                        if (pageValue !== 'participate') {
                                            onAddAllProductToAction()
                                        } else {
                                            onRemoveAllProductFromAction()
                                        }
                                    }}
                                />
                                <ButtonBasic
                                    action
                                    width='180px'
                                    maxWidth='180px'
                                    text={pageValue !== 'participate' ? 'Добавить выбранные' : 'Удалить выбранные'}
                                    size='32px'
                                    disabled={!checkedProducts?.length > 0}
                                    onClick={e => {
                                        if (pageValue !== 'participate') {
                                            onAddProductToAction()
                                        } else {
                                            onRemoveProductFromAction()
                                        }
                                    }}
                                />
                            </>
                        }
                    </div>

                }
            />

        </div >
    )
}

export { JoinedPromotions }