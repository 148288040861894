import { Link } from 'react-router-dom';
import { useState } from 'react';
import { CardStatisticsVer2 } from '../../components/CardStatisticsVer2/CardStatisticsVer2';
import { BarCharts } from '../../charts/BarCharts/BarCharts';
import { Tabs } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_Information.scss"

const btn_tab_3 = [
    {
        label: 'Продажи',
        value: 'sales',
    },
    {
        label: 'Цена',
        value: 'price',
    },
    {
        label: 'Остатки',
        value: 'stock',
    },
    {
        label: 'Выручка',
        value: 'revenue',
    }
]

const ProductCardExtended_Information = ({
    fetchedMetrics,
    pageValue3,
    setPageValue3,
    chartData,
    labels,
    chartDataName,
    chartLoading
}) => {

    return (
        <section className='product-info'>
            <div className='product-info__content'>

                <div className='card-container'>
                    {fetchedMetrics?.map(({ labels, data }) =>
                        <CardStatisticsVer2
                            label={labels?.header?.[0]}
                            value={data?.[0] && data?.[0]}
                            unit={'₽'}
                            dynamic={data?.[1] && data?.[1]}
                        />
                    )}
                </div>

                <Tabs tabs={btn_tab_3} pageValue={pageValue3} setPageValue={e => setPageValue3(e)} stopRedirect />
                <BarCharts
                    direction='x'
                    legendPosition='top'
                    datasets_prop={chartData}
                    labels_prop={labels}
                    data_name={chartDataName}
                    loading={chartLoading}
                />
            </div>
        </section>
    )
}

export { ProductCardExtended_Information }