import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { AndreyHttp, AndreyHttps } from '../../fetchUrls'
import './CompetitorsLinks.scss'
import { ModalAddLinks } from '../Modal/ModalsCompetitors/ModalsCompetitors'
import { splitByRedLine } from '../../common/mappers'
import Cookies from 'universal-cookie'

const CompetitorsLinks = ({ modalsPortalId }) => {
  const [textAreaValue, setTextAreaValue] = useState('')
  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isLinks, setIsLinks] = useState(true)
  const [status, setStatus] = useState(0)
  const auth = new Cookies().get('auth')
  

  const postLinks = (linksArr) => {
    if (linksArr.length > 0) {
      let url
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreyHttps}/parselinks/`
      } else {
        url = `${AndreyHttp}/parselinks/`
      }

      const client_id = localStorage.getItem('client_id')

      const body = JSON.stringify([{
        client_id,
        links: linksArr,
        map: false,
        type: 3
      }])

      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`
      }

      fetch(url, { body, method: 'POST', headers })
        .then(res => {
          setStatus(res.status)
          return res.json()
        })
        .then(json => {
          setShowModal(true)
       })
        .catch(err => {
          console.error(err)
          setStatus(0)
          setShowModal(true)
        })
    }
  }

  const checkIsLinks = (linksArr) => {
    setIsLinks(linksArr.length > 0)
  }

  return (
    <div className='competitors-links'>
      { showModal && createPortal(<ModalAddLinks setShowModal={(e) => { setShowModal(e) }} status={status} />, document.getElementById(modalsPortalId)) }
      <div className="competitors-links__header">
        <div className='competitor_link_main_wrapper'>
          <span className='competitor_link_main_span'>
            Ссылки на товары конкурентов
          </span>
          <button
            className="btn__green"
            style={{ maxWidth: '160px' }}
            onClick={e => {
              const linksArr = splitByRedLine(textAreaValue)
              checkIsLinks(linksArr)
              postLinks(linksArr)
            }}
          >
            Отправить ссылки
          </button>
        </div>

        <textarea
          className='competitors_textarea'
          autoFocus={true}
          value={textAreaValue}
          placeholder='https://www.ozon.ru/product/protsessor-intel-core-i9-13900k-oem-bez-kulera-790289147/?asb2=unKsgnW-6UYq--amUfhJ4bT_o8e1ctB9Y9cTDc_ja3lfX2xLewp-STrm0pOND5jw&avtc=1&avte=2&avts=1670939665&keywords=intel+core+i9+13900k&sh=j735KRJPsw'
          onChange={e => setTextAreaValue(e.target.value)}
        />
      </div>
    </div>
  )
}

export { CompetitorsLinks }