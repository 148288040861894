import React, { useState, useEffect } from 'react';
import './CrmAttrControlModal.scss';
import close_icon from './img/close_icon.svg'
import { RavshanHttps } from '../../fetchUrls';
import sort_icon from './img/sort.svg'
import edit_icon from './img/edit.svg'
import { InputDinamycPlaceholder, Checkbox, ButtonBasic, TextareaDynamicPlaceholder, InputSearch } from '@lk-gtcom/ecomlab-components'


export const CrmAttrControlModal = (props) => {
    const { isOpen, close, selectedAttributes, setSelectedAttributes, currentIndex, setCurrentIndex,  objValuesAttr, setObjValuesAttr } = props;
    const [checkedAttr, setCheckedAttr] = useState([])
    const [nameListPoint, setNameListPoint] = useState('')
    const [listPoints, setListPoints] = useState(objValuesAttr[currentIndex]?.attr_values ?? [])
    const [checkedValues, setCheckedValues] = useState([])

    

    useEffect(() => {
        if(objValuesAttr[currentIndex]?.attr_values) {
            setListPoints(objValuesAttr[currentIndex]?.attr_values)
        }
    }, [currentIndex]);

    const onCheckboxChange = (ind) => {

        const currentIndex = checkedValues.indexOf(ind);
        const newChecked = [...checkedValues];

        if (currentIndex === -1) {
            newChecked.push(ind);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedValues(newChecked)
    };

    const handleDeleteCheckedAttr = () => {
        setSelectedAttributes(it => it.filter((_, index) => !checkedAttr.includes(index)));
    };

    const handleAddPoints = () => {
        if(nameListPoint) {
          setListPoints((prev) => [...prev, nameListPoint])  
        }
        setNameListPoint('')
    }

    const onChangeSaveList = (e, idx) => {
        const obj = objValuesAttr
        obj[idx].attr_values = e
        setObjValuesAttr(obj)
    }

    if (!isOpen) return null;

    return (
        <div className="modal-crm-attr" onClick={close}>

            <div className="modal-crm-attr__main-content"
                style={{ display: "flex", justifyContent: "space-between", height: "100%", flexDirection: 'column' }}
                onClick={e => e.stopPropagation()}>

                <div className="modal-crm-attr__header" >
                    <h2 className="modal-crm-attr__title">Управление списком</h2>
                    <button className='modal-crm-attr__btn-close'
                        onClick={() => close()}
                    >
                        <img src={close_icon} />
                    </button>
                </div>

                <div className="modal-crm-attr__content">
                    <div className='modal-crm-attr__item'>
                        <h3 className="modal-crm-attr__title_min">Добавить элементы списка</h3>
                        <ButtonBasic
                            lilac
                            width='170px'
                            text={"Импортировать .xlx"}
                        />

                        <div className='modal-crm-attr__new-el-box'>
                            <div className='modal-crm-attr__header-new'>
                                <h4 className='modal-crm-attr__title-new'>Новые элементы списка</h4>
                                <ButtonBasic
                                    lilac
                                    width='105px'
                                    onClick={(e) => {
                                        handleAddPoints()
                                        //  fetchCreateTable()
                                    }}
                                    text="Добавить" />
                            </div>

                            <div className='modal-crm-attr__input-item'>
                                <TextareaDynamicPlaceholder
                                    changeValue={nameListPoint}
                                    placeholder='Элемент списка'
                                    onChange={e => setNameListPoint(e)}
                                />
                            </div>
                        </div>


                    </div>

                    <div className='modal-crm-attr__item'>
                        <h4>Изменить список</h4>

                        <InputSearch />

                        <div className='modal-crm-attr__medium-panel'>
                            <button className='modal-crm-attr__btn-sort'>
                                Сортировать по алфавиту
                                <img src={sort_icon} />
                            </button>

                            <ButtonBasic
                                lilac
                                width='100px'
                                text={"Удалить"}
                                onClick={handleDeleteCheckedAttr} />
                        </div>
                        <div className='modal-crm-attr__checkbox-group'>
                            {listPoints.map((it, idx) => (
                                <label className="modal-crm-attr__label">
                                    <button className='modal-crm-attr__btn-edit'>
                                        <img src={edit_icon} />
                                    </button>
                                    <Checkbox
                                        value={checkedValues.includes(idx)}
                                        key={it}
                                        onChange={(e) => onCheckboxChange(idx)}
                                    />
                                    <span className='modal-crm-sub-modal__label-text'>{it}</span>
                                </label>))
                            }
                        </div>
                    </div>
                </div>

                <div className='modal-crm-attr__btn-group'>
                    <ButtonBasic
                        green
                        width='110px'
                        text={'Выбрать'}
                        onClick={(e) => {
                            onChangeSaveList(listPoints, currentIndex)
                            setListPoints([])
                            close()
                        }}
                    ></ButtonBasic>

                    <ButtonBasic
                        grey
                        width='110px'
                        text={'Отменить'}
                        onClick={() => {
                            setListPoints([])
                            close()
                        }}
                    ></ButtonBasic>
                </div>
            </div>
        </div>
    );
};
