import { useState, useEffect } from 'react';
import { InputDinamycPlaceholder, ButtonBasic } from "@lk-gtcom/ecomlab-components"
import './ModalInputField.scss'

const ModalInputField = ({
    title,
    setIsModal,
    fetchAction,
    name, 
    setName
 }) => {

    const [namePoint, setNamePoint] = useState('')

    return (
        <div className='blackout_hide' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout_hide')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-input-field'>
                <h2 className='modal-input-field__title'>{title}</h2>
                <InputDinamycPlaceholder
                    onChange={(e) => setName(e)}
                    placeholder='Введите название'
                />
                <ButtonBasic
                    green
                    width='100%'
                    size='56px'
                    text='Применить'
                    onClick={(e) => {
                        fetchAction(name)
                        setName('')
                        setIsModal(false)
                    }}
                />
            </div>

        </div>
    )
}

export { ModalInputField }