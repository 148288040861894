import { useState } from 'react'
import { ModalCreateNewBundle } from '../../components/Modal/ModalCreateNewBundle/ModalCreateNewBundle'
import { TableBox, ButtonBasic } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_Mapping.scss"

const ProductCardExtended_Mapping = () => {

    const [showDropBox, setShowDropBox] = useState(true)
    const [isModal, setIsModal] = useState(false)

    return (
        <>
            {isModal && <ModalCreateNewBundle setIsModal={(e) => setIsModal(e)} />}

            <section className='mapping-inside-content'>
                <TableBox
                    loading={null}
                    fetchedData={[]}
                    headers={[]}
                    paginator={true}
                    child={
                        <div className='btn-group'>
                            <ButtonBasic
                                green
                                text='Создать'
                                size='32px'
                                width='auto'
                                onClick={(e) => setIsModal(true)}
                            />

                            <ButtonBasic
                                grey
                                text='Загрузить'
                                size='32px'
                                width='auto'
                            />

                            <ButtonBasic
                                grey
                                text='Выгрузить в Excel'
                                size='32px'
                                width='auto'
                            />
                        </div>
                    }
                />
            </section>
        </>

    )
}

export { ProductCardExtended_Mapping }