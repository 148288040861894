import customHeaders, {getSpace} from "../../common/headers";
import { useDataTable } from "../../common/hooks";
import { Tabs, TableBox, ButtonBasic, RangeBox, DropDownSelector } from "@lk-gtcom/ecomlab-components";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { KonstantinMasterHttps, KonstantinMasterHttp } from './../../fetchUrls';
import { ArtemBilling2Https, ArtemBilling2Http } from "../../fetchUrls";
import copy_icon from './img/bx-copy.svg'
import useGeneralStore from "../../store/general";
import './ReferralProgram.scss'

const summaryColors = ['#0C2037', '#51CA64', '#F88600', '#365EFF']

const tabs = [
    {
        label: 'Начисления',
        value: 'accruals'
    },
    {
        label: 'Выплаты',
        value: 'payout'
    },
    {
        label: 'Детализация начислений',
        value: 'detailed_accruals'
    }
]

const options_period = [
    {
        label: 'дням',
        value: 'day'
    },
    {
        label: 'неделям',
        value: 'week'
    },

    {
        label: 'месяцам',
        value: 'month'
    }
]

const list_copy = [
    {
        label: 'Ваша реферальная ссылка',
        value: 'https://ecomru.ru/fgwergwersweq'
    },
    {
        label: 'Ваш промокод',
        value: '344-123-442-131'
    }
]

const CopyTextBlock = ({ title, text }) => {

    return <div className='copy-text-block'>
        <h3 className='copy-text-block__title'>{title}</h3>
        <div className='copy-text-block__field'>
            <p className='copy-text-block__text'>{text}</p>
            <ButtonBasic
                onClick={(e) => navigator.clipboard.writeText(text)}
                green
                minWidth='42px'
                width='42px'
                size='36px'
                text={<img src={copy_icon} />}
            />
        </div>
    </div>
}

const ReferralProgram = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [balance, setBalance] = useState(0)
    const [currency, setCurrency] = useState('₽')

    const [isModalBalance, setIsModalBalance] = useState(false)
    const [pivotData, setPivotData] = useState([])
    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [pageValue, setPageValue] = useState(null)

    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [selectedPeriod, setSelectedPeriod] = useState(options_period[0])

    const fetchPivotData = (abortController = new AbortController()) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/finance/pivot/summary-for-period`
        } else {
            url = `${KonstantinMasterHttp}/finance/pivot/summary-for-period`
        }

        const body = JSON.stringify({})


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setPivotData(json?.data?.map((el, ind) => ({ ...el, label: json?.headers?.[ind] })))
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
            })
    }

    const fetchBalanceCount = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemBilling2Https}/balance`
        } else {
            url = `${ArtemBilling2Http}/balance`
        }

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ balance, currency }) => {
                setBalance(balance?.toLocaleString())
                setCurrency(currency === 'RUB' ? '₽' : currency)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetchBalanceCount()
    }, [selectedSpace])

    useEffect(() => {
        const abortController = new AbortController()
        fetchPivotData(abortController)
        return () => abortController.abort()
    }, [selectedSpace])

    const activeTab = useParams()['*']
    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    return (
        <div className='referral-program'>
            <div className='referral-program__header'>
                <h1 className='summary__title'>Реферальная программа</h1>
                <div className='referral-program__balance'>
                    <p className='referral-program__text-label'>Ваш баланс</p>
                    <div className='referral-program__balance-field'>
                        <p className='referral-program__text-blue'>{balance} {currency}</p>
                    </div>
                </div>
            </div>

            <div className='referral-program__fields'>
                {list_copy?.map(({ label, value }) => {
                    return <CopyTextBlock
                        key={value + label}
                        text={value}
                        title={label}
                    />
                })}
            </div>

            <div className="referral-program__period">
                <h2 className="summary__title_period">Сводная за период</h2>
                <div className="referral-program__items-period">
                    {pivotData?.map(({ label, amount, color, symb }, ind) => {
                        return <div className="referral-program__item-period">
                            <span className="referral-program__text-period">{label}</span>
                            <p className="referral-program__text-sum" style={{ color: summaryColors?.[ind] }}>{symb ? symb : ''}{amount?.toLocaleString()} ₽</p>
                        </div>
                    })}
                </div>
            </div>

            <div className="referral-program__table">
                <h2 className="referral-program__title_period">Финансы</h2>
                <Tabs
                    tabs={tabs}
                    pageValue={pageValue}
                />

                <div className="referral-program__table-and-filters">
                    <div className='range-group-btn-and-range-box'>
                        <div className='drop-box'>
                            <p className='text'>По</p>
                            <DropDownSelector
                                options_prop={options_period}
                                state={selectedPeriod}
                                setState={e => setSelectedPeriod(e)}
                                defaultValue={selectedPeriod}
                                className='connections_page_selector'
                                isClearable={false}
                            />
                        </div>
                        <RangeBox setDate={e => setDate(e)} />
                    </div>
                    <TableBox
                        ref={paginatorRef}
                        {...tableProps}
                        paginator={true}
                    />
                </div>
            </div>
        </div>
    )
}

export { ReferralProgram }
