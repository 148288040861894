import React, { useState, useEffect } from 'react';
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack';
import './FilterContainer.scss'

const FilterContainer = ({ children, onReset }) => {

    return (
        <div className='filters-container'>
            {children}
            <TooltipBlack
                child={
                    <button className='btn-reset-filters'
                        onClick={(e) => onReset(e)}
                    ></button>}
                text='Сбросить все'
                top='6px'
                left='calc(100% + 4px)'
            />
        </div>
    )
}

export { FilterContainer }