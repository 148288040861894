import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import done_icon from './img/done_icon.svg'
import bell_icon from './img/bell_icon.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalSuccessfulProductList.scss'

const ModalSuccessfulProductList = ({ setIsModal, title, description = false }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-successful-product-list'>
                <img className='img' src={done_icon} />
                <h2 className='title'>Принято в работу</h2>
                <p className='text_description'>Статус выполнения появится в <Link className='link_blue' to='/service'>уведомлениях</Link></p>
                <img src={bell_icon} />
                <div className='btn-group'>
                    <ButtonBasic
                        lilac
                        text='К списку товаров'
                        size='56px'
                        width='calc(50% - 6px)'
                        onClick={e => e}
                    />
                    <ButtonBasic
                        violet
                        text='Ok'
                        size='56px'
                        width='calc(50% - 6px)'
                        onClick={(e) => setIsModal(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalSuccessfulProductList }