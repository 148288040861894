import { useContext } from "react"
import { NotificationContext } from "../../../App"
import "./ModalRichNotification.scss"
import { icon_notification } from "../../../common/svg_img"

const ModalRichNotification = ({ setShowModal, setShowModalDone }) => {
    const { setShowNotificationBox } = useContext(NotificationContext)

    return (
        <div className='modal-rich-notification modal-box blackout'>
            <div className='modal-add-links'>
                <div>
                    <h2 className='title'>Задача принята в работу</h2>
                    <p className='body text'>
                        Статус выполнения появится в <a className='link__blue' onClick={() => {
                            setShowNotificationBox(true)
                            setShowModal(false)
                        }}>
                            уведомлении
                        </a> <img src={icon_notification}/>
                    </p>
                </div>

                <button className='btn_bell-round' onClick={() => {
                    setShowModal ? setShowModal(false) : setShowModalDone(false)   
                } }></button>

                <div className='blue-box'></div>
            </div>
        </div>

    )
}

export { ModalRichNotification }