
import customHeaders, {getSpace} from '../../common/headers';
import arrow_right from './img/arrow_right.svg'
import { ReactComponent as Help } from './img/help.svg'
import { ReactComponent as News } from './img/news.svg'
import { ReactComponent as Service } from './img/service.svg'
import { ReactComponent as Partners } from './img/partners.svg'

import { MoonLoader } from 'react-spinners';
import { SendMessage } from '../../components/SendMessage/SendMessage';
import { useSearchParams } from 'react-router-dom';
import { RavshanHttps, RavshanHttp } from '../../fetchUrls';
import { user_light_grey } from '../../common/svg_img'
import { useState, useEffect, useCallback, useRef } from 'react';
import { ArticleView, DropDownSelector, InputSearch, Checkbox } from '@lk-gtcom/ecomlab-components'
import { useDebounce } from '../../hooks/useDebounce';
import NotificationListItem from '../../components/NotificationListItem/NotificationListItem';
import useGeneralStore from '../../store/general';
import "./NotificationsVer2.scss"

const SORTING_OPTIONS = [
    {
        label: <p className='option-sort'><span style={{ color: 'rgba(17, 17, 17, 0.50' }}>По дате:</span> сначала новые</p>,
        value: 'DESC'
    },
    {
        label: <p className='option-sort'><span style={{ color: 'rgba(17, 17, 17, 0.50' }}>По дате:</span> сначала старые</p>,
        value: 'ASC'
    },
]




const NotificationsVer2 = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [searchParams] = useSearchParams()
    const [direction, setDirection] = useState('DESC')
    const [currentStatusRead, setCurrentStatusRead] = useState('all')

    const [sort, setSort] = useState([])
    const [total, setTotal] = useState(0)
    const [fetchedData, setFetchedData] = useState(null)

    const [listNotificationsPortals, setListNotificationsPortals] = useState([])
    const [currentArticle, setCurrentArticle] = useState(null)

    const [activeContent, setActiveContent] = useState(50)
    const [activeChat, setActiveChat] = useState(fetchedData ? fetchedData[0] : null)
    const [notifListCounter, setNotifListCounter] = useState(0)

    const [read, setRead] = useState(true)
    const [updateStatusRead, setUpdateStatusRead] = useState(false)

    const [contentData, setContentData] = useState([])
    const setContentDataCallback = useCallback(e => setContentData(e), [])
    const [contentLinks, setContentLinks] = useState([])
    const [apiData, setApiData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedSort, setSelectedSort] = useState(SORTING_OPTIONS[0])

    const [answerValue, setAnswerValue] = useState('')
    const [answer, setAnswer] = useState('')
    const [isEdit, setIsEdit] = useState(false)

    const listRef = useRef()
    const [pageHeight, setPageHeight] = useState(0)
    const [scrollTop, setScrollTop] = useState(-1)
    const [isSearchLastPage, setIsSearchLastPage] = useState(false)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')




    const fetchGetNotificationsByPortal = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/lk/get_portals_levels`
        } else {
            url = `${RavshanHttp}/api/lk/get_portals_levels`
        }

        const currentHeaders = {
            ...headers,
            'language': 'ru',
            'portal-id': '4',
        }

        fetch(url, { method: 'GET', headers: currentHeaders })
            .then(res => res.json())
            .then(json => {
                setListNotificationsPortals(json)
            })
            .catch(err => console.error(err))
    }

    const fetchArticleByParents = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/lk/get_articles_by_parents`
        } else {
            url = `${RavshanHttp}/api/lk/get_articles_by_parents`
        }


        const body = JSON.stringify({
            language: "rus",
            filters: "string",
            parent_id: id,
            limit: 10,
            order_param: "0JTQsNGC0LAg0L/Rg9Cx0LvQuNC60LDRhtC40Lg=",
            order_param_direction: direction,
            page: page,
            status: 'all',
            active: true,
            read_status: currentStatusRead,
            // search: ''
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                const { data, labels, total, sort, column_size } = json
                if (sort) setSort(sort)
                if (total || total === 0) {
                    setNotifListCounter(total)
                    setIsSearchLastPage(total <= page * 10)
                }
                const data_message = data?.map((el, ind) => {
                    return {
                        id: el[0],
                        lk_client_id: el[1],
                        portal_id: el[2],
                        active: el[3],
                        status_read: el[4],
                        img: el[5],
                        name: el[6],
                        annotation: el[7],
                        date: el[8],
                        reading_time: [9],
                        count_view: el[10],
                        status: el[11]
                    }
                })
                if (page > 1) {
                    setFetchedData(prev => [...prev, ...data_message])
                } else {
                    setFetchedData(data_message ? data_message : [])
                }
            })
            .catch(err => console.error(err))
    }

    const fetchArticleSearch = (id) => {
        setFetchedData([])
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/search`
        } else {
            url = `${RavshanHttp}/search`
        }

        const body = JSON.stringify({
            query: search,
            'level_id': id,
            'portal_id': 4,
            'language': 'ru',
            'limit': 10,
            'page': page,
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                const { data, labels, total, sort, column_size } = json
                if (sort) setSort(sort)
                if (total || total === 0) {
                    setNotifListCounter(total)
                    setIsSearchLastPage(total <= page * 10)
                }
                const data_message = data?.map((el, ind) => {
                    return {
                        id: el[0],
                        //  lk_client_id: el[1],
                        portal_id: el[1],
                        active: el[2],
                        img: el[3],
                        name: el[4],
                        annotation: el[5],
                        date: el[7],
                        author: el[8],
                        reading_time: [9],
                        count_view: el[10],
                        // status_read: el[4],
                        // status: el[11]
                    }
                })
                if (page > 1) {
                    setFetchedData(prev => [...prev, ...data_message])
                } else {
                    setFetchedData(data_message ? data_message : [])
                }
            })
            .catch(err => console.error(err))
    }

    const handleSearch = useDebounce(fetchArticleSearch, 1000)

    const fetchGeArticleByPortal = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/lk/get_article`
        } else {
            url = `${RavshanHttp}/api/lk/get_article`
        }

        const currentHeaders = {
            ...headers,
            'article-id': id,
            'active': true,
        }

        fetch(url, { method: 'GET', headers: currentHeaders })
            .then(res => res.json())
            .then(json => {
                setLoading(true)
                setApiData(json)
            })
            .catch(err => console.error(err))
            .finally(setLoading(false))
    }

    const fetchChangeStatusRead = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/lk/change_read_status`
        } else {
            url = `${RavshanHttp}/api/lk/change_read_status`
        }

        const body = JSON.stringify({
            id: id,
            status: true
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                setUpdateStatusRead(true)
            })
            .catch(err => console.error(err))
    }

    const onScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current
        const bottomHeight = scrollHeight - clientHeight
        if (bottomHeight) {
            setPageHeight(bottomHeight)
        }
        setScrollTop(scrollTop)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            const currentPageHeight = pageHeight
            if (!isSearchLastPage && (Math.ceil(scrollTop) >= currentPageHeight) && (fetchedData?.length > 0)) {
                setPage(prev => prev + 1)
                return
            }
        }, 500)
        return () => clearTimeout(timeout)
    }, [scrollTop, pageHeight, isSearchLastPage])


    useEffect(() => {
        setDirection(selectedSort.value)
    }, [selectedSort]);

    useEffect(() => {
        fetchGetNotificationsByPortal()
    }, [selectedSpace])

    useEffect(() => {
        if (!searchParams.get('articleId')) {
            setActiveChat(null)
            setApiData(null)
        }
    }, [activeContent]);

    useEffect(() => {
        const articleId = searchParams.get('articleId')
        const portalId = searchParams.get('portalId')

        if (articleId && portalId) {
            fetchGeArticleByPortal(+articleId)
            setActiveContent(+portalId)
            setCurrentArticle(+articleId)
            setActiveChat({ id: +articleId })
            fetchChangeStatusRead(+articleId)
        }
    }, [searchParams]);

    const resetScroll = () => {
        setPage(1)
        setPageHeight(0)
        setScrollTop(-1)
        setIsSearchLastPage(false)
    }

    useEffect(() => {
        resetScroll()
        setUpdateStatusRead(false)
        fetchGeArticleByPortal(activeChat?.id)
    }, [activeChat]);

    useEffect(() => {
        resetScroll()
        if (search?.length) {
            handleSearch(activeContent)
        } else fetchArticleByParents(activeContent)
    }, [selectedSpace, direction, currentStatusRead, activeContent]);

    useEffect(() => {
        if (page > 1) {
            if (search?.length) {
                handleSearch(activeContent)
            } else fetchArticleByParents(activeContent)
        }
    }, [page, selectedSpace])

    useEffect(() => {
        setPage(1)
        setActiveChat(null)
    }, [search]);

    useEffect(() => {
        if (search?.length) {
            handleSearch(activeContent)
        } else {
            setCurrentArticle(null)
            fetchArticleByParents(activeContent)
        }
    }, [search, selectedSpace]);

    return (
        <div className='notifications-ver2'>
            <nav className='navigation-panel'>
                <ul>
                    {Array.isArray(listNotificationsPortals) && listNotificationsPortals?.map(({ label, value, message }) => {
                        return <li key={label}
                            className={activeContent == value ? 'navigation-panel__item_active' : 'navigation-panel__item'}
                            onClick={(e) => {
                                setPage(1)
                                setSearch('')
                                setActiveContent(value)
                                setCurrentArticle(null)
                                setActiveChat(null)
                                fetchArticleByParents(value)
                            }}
                        >
                            <div className='img-box'>
                                {value === 50 && <Help style={activeContent === value ? { fill: '#46A355' } : {}} />}
                                {value === 51 && <Service style={activeContent === value ? { fill: '#46A355' } : {}} />}
                                {value === 52 && <News style={activeContent === value ? { fill: '#46A355' } : {}} />}
                                {value === 53 && <Partners style={activeContent === value ? { fill: '#46A355' } : {}} />}

                            </div>
                            <p className='text'>{label}</p>
                            {/* логика на кружочек */}
                            {message > 0 && <span className='notification-round'>{message}</span>}
                        </li>
                    }
                    )}
                </ul>
            </nav>

            <section className='content-messages'>
                <div className='content-messages__header'>
                    <div className='header-top-box'>
                        <p className='text_chat'>{`${notifListCounter} чатов`}</p>

                        <DropDownSelector
                            isClearable={false}
                            options_prop={SORTING_OPTIONS}
                            state={selectedSort}
                            setState={(e) => setSelectedSort(e)}
                            defaultValue={selectedSort}
                            style={{ maxWidth: '250px' }}
                        />
                    </div>

                    <div className='header-bottom-box'>
                        <InputSearch
                            onChange={(e) => setSearch(e)}
                        />

                        <div className='checkbox-content'>
                            <p className='text'>Скрыть прочитанные</p>
                            <label className="checkbox-status">
                                <Checkbox
                                    checked={!read}
                                    onChange={(e) => {
                                        setCurrentStatusRead(currentStatusRead == 'all' ? 'false' : 'all')
                                        setRead(!read)
                                    }}
                                />
                            </label>
                        </div>
                    </div>

                </div>

                {fetchedData?.length > 0 ? <div className='messages-box' ref={listRef} onScroll={e => onScroll(e)} >
                    <ul className='messages-list'>
                        {fetchedData.map(({...props }) => {
                            return <NotificationListItem 
                                {...props}
                                activeChat={activeChat}
                                setActiveChat={e => setActiveChat(e)}
                                fetchGetNotificationsByPortal={e => fetchGetNotificationsByPortal()}
                            />
                        }
                        )}
                    </ul>
                </div>
                    :
                    ((activeContent && fetchedData?.length == 0) ? <div className='selected-message-plumb'>Выберите портал</div>
                        :
                        <div className='selected-message-plumb'>У данного портала нет сообщений</div>)
                }

            </section>

            <section className='message-item-content'>
                <div className='message-item-content__header'>
                    <div className='header-content'>
                        <div className='user-info'>
                            <div className='icon-box'>
                                <img src={user_light_grey} />
                            </div>
                            <h4 className='title'>{apiData?.author ? apiData?.author : ''}</h4>
                        </div>
                        <p className='text_online'>{apiData?.date ? <>Дата: <span>{apiData?.date}</span></> : ''}</p>
                    </div>
                </div>

                {(activeContent !== 'Поддержка Ecomlab') &&
                    (apiData?.content?.length > 0 ?
                        <div className='message-box'>
                            <ArticleView
                                articleTitle={apiData?.name}
                                readonly
                                apiData={apiData}
                                contentData={contentData}
                                setContentData={setContentDataCallback}
                                setListOfContent={setContentLinks}
                                showTitle={activeContent == 50 ? false : true}
                                hideInfoPanel
                                hideRecommendation
                            />
                        </div>
                        : (activeChat ?
                            (loading ? <div className='empty-article-plumb'>Что-то пошло не так</div>
                                :
                                <div className='center' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <MoonLoader
                                        size={40}
                                        color="#7b7b7b"
                                        speedMultiplier={0.5}
                                    />
                                </div>)
                            :
                            <div className='selected-message-plumb'>Выберите сообщение</div>))
                }

                {activeContent == 'Поддержка Ecomlab' &&
                    <>
                        <div className='support__content'>

                            <div className='date-box'>21.01.2023</div>

                            <div className='support-message'>
                                <div className='message-box'>
                                    <p className='text_grey'>Оператор техподдержки</p>
                                    <p className='text'>Напоминаем что у вас есть товары без фото, которые не отображаются на сайте. Для начала продаж необходимо добавить фото. Для повышения конверсии карточки мы рекомендуем воспользоваться инструментами Мультифото Напоминаем что у вас есть товары без фоте. Для начала продаж необходимо добавить фото. Для повышения конверсии карточки мы рекомендуем воспользоваться инструментами </p>
                                    <p className='text_time'>14:25</p>
                                </div>
                            </div>

                            <div className='unread-line'>
                                <p className='text'>Непрочитанные</p>
                            </div>

                            <div className='user-message'>
                                <div className='message-box'>
                                    <p className='text_grey'>Оператор техподдержки</p>
                                    <p className='text'>Напоминаем что у вас есть товары без фото, которые не отображаются на сайте. Для начала продаж необходимо добавить фото. Для повышения конверсии карточки мы рекомендуем воспользоваться инструментами Мультифото Напоминаем что у вас есть товары без фоте. Для начала продаж необходимо добавить фото. Для повышения конверсии карточки мы рекомендуем воспользоваться инструментами </p>
                                    <p className='text_time'>14:25</p>
                                </div>
                            </div>

                            <div className='support-message'>
                                <div className='message-box'>
                                    <p className='text_grey'>Оператор техподдержки</p>
                                    <p className='text'>Напоминаем что у вас есть товары без фото, которые не отображаются на сайте. Для начала продаж необходимо добавить фото. Для повышения конверсии карточки мы рекомендуем воспользоваться инструментами Мультифото Напоминаем что у вас есть товары без фоте. Для начала продаж необходимо добавить фото. Для повышения конверсии карточки мы рекомендуем воспользоваться инструментами </p>
                                    <p className='text_time'>14:25</p>
                                </div>
                            </div>
                        </div>

                        <SendMessage
                            disabled
                            frequentAnswers
                            isEdit={isEdit}
                            setIsEdit={(e) => setIsEdit(e)}
                            message={answer}
                            value={answerValue}
                            onChange={e => setAnswerValue(e)}
                            onSendAction={e => (e)} />
                    </>

                }
            </section>
        </div >
    )
}

export { NotificationsVer2 }