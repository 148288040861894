import customHeaders, {getSpace} from '../../common/headers';
import Cookies from 'universal-cookie';
import analyticRoutes from '../../pages/AnalyticsPage/routes'
import { LogoBox } from '../Logo/Logo';
import { ModalNotifications, DropDownSelector, InputDynamicWidth } from "@lk-gtcom/ecomlab-components";
import { ProfileSettings } from '../ProfileSettings/ProfileSettings';
import { NotificationList } from '../NotificationList/NotificationList';
import { NotificationContext } from '../../App'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { ButtonDrop } from '../ButtonDrop/ButtonDrop';
import { ArtemBilling2Https, ArtemBilling2Http, RavshanHttps, RavshanHttp } from '../../fetchUrls';
import { ModalUnboardingForSpace } from '../Modal/ModalUnboardingForSpace/ModalUnboardingForSpace';
import info_blue from './img/info-blue.svg'
import user_icon from './img/bx-user.svg.svg'
import user_circle_icon from './img/bx-user-circle.svg.svg'
import useGeneralStore from '../../store/general';
import { useShallow } from 'zustand/react/shallow';

import './HeaderMain.scss'

const HeaderMain = ({
    activeContent,
    setActiveItems,
    setActiveSubItems,
    setIsModalKnowledgeBase,
    setIsModalCreatingNewSpace,
    setIsModalActive,
    spaceList,
    isModalUnboardingForSpace, 
    setIsModalUnboardingForSpace
}) => {
    const { showNotificationBox, setShowNotificationBox } = useContext(NotificationContext)

    const [profileName, setProfileName] = useState('')
    const [showProfileSettings, setProfileSettings] = useState(false)
    const [optionList, setOptionsList] = useState([])
    const [balance, setBalance] = useState(0)
    const [currency, setCurrency] = useState('₽')
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    const cookies = new Cookies()
    const client_id = localStorage.getItem('client_id');
    const [localSelectedSpace, setLocalSelectedSpace] = useState({})
    
    const {
        selectedSpace,
        setSelectedSpace,
        notifCount, setNotifCount
    } = useGeneralStore(useShallow(state => ({
        selectedSpace: state.selectedSpace,
        setSelectedSpace: state.setSelectedSpace,
        notifCount: state.notificationCounter,
        setNotifCount: state.setNotificationCounter
    })))
    
    const headers = {...customHeaders, ...getSpace(selectedSpace)}

    useEffect(() => {     
        setOptionsList(
            spaceList?.map(({ id, name, author }) => {
                return {
                    label: <p className='option-space'><img src={client_id == author?.id ? user_circle_icon : user_icon}/>{name}</p>,
                    labelValue: name,
                    value: id,
                    author: author
                }
            }))
    }, [spaceList]);

    useEffect(() => {
        optionList?.forEach((el) => {
            const {value} = el
            if(+selectedSpace?.value === +value){
                setLocalSelectedSpace(el)
            }
        })
    }, [optionList])

    const list_btn = [
        {
            label: 'Добавить пространство',
            func: () => {
                navigate('/settings/space')
                setIsModalCreatingNewSpace(true)
            },
        },
        {
            label: 'Добавить подключение',
            func: () => {
                navigate('/settings/connections')
                setIsModalActive(true)
            },
        },
        {
            label: 'Добавить сотрудника',
            func: () => {
                navigate('/settings/employees')
                setIsModalCreatingNewSpace(true)
            },
        }
    ]

    const onLogOutHandler = () => {
        cookies.remove('auth', { path: '/' })
        cookies.remove('refresh', { path: '/' })
        localStorage.removeItem('not_one_account')
        localStorage.removeItem('client_id')
        localStorage.removeItem('email')
        if (localStorage.getItem('auth')) localStorage.removeItem('auth')
        if (localStorage.getItem('refresh')) localStorage.removeItem('refresh')
        navigate('../auth')
    }

    const FETCH_INTERVAL_MS = 30000

    const fetchNotificationsCount = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/get_count_unread_msg`
        } else {
            url = `${RavshanHttp}/get_count_unread_msg`
        }

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ count }) => {
                setNotifCount(count)
            })
            .catch(err => console.error(err))
    }

    const fetchBalanceCount = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemBilling2Https}/balance`
        } else {
            url = `${ArtemBilling2Http}/balance`
        }

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ balance, currency }) => {
                setBalance(balance?.toLocaleString())
                setCurrency(currency === 'RUB' ? '₽' : currency)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        let profile = localStorage.getItem('email')
        setProfileName(profile)

        let intervalId;

        fetchNotificationsCount()
        fetchBalanceCount()

        intervalId = setInterval(() => {
            fetchNotificationsCount()
            fetchBalanceCount()
        }, FETCH_INTERVAL_MS)

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const closeHeaderItems = (e) => {
        let el = e.target
        if (!el.closest('.profile-box__btn') && !el.closest('.profile-box__user-name')) {
            setProfileSettings(false)
        }

        if (el.closest('.notifications__btn') || el.closest('.notification-list')) {
            if (el.closest('.notification-item') || el.closest('.link-blue')) {
                setShowNotificationBox(false)
            } else {
                setShowNotificationBox(true)
            }
        } else {
            setShowNotificationBox(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeHeaderItems)
        return () => {
            document.addEventListener('click', closeHeaderItems)
        }
    })


    return (
        <>
            {isModalUnboardingForSpace && <ModalUnboardingForSpace
                setIsModal={(e) => setIsModalUnboardingForSpace(e)}
            />}

            <div className='main-sidebar'>
                <nav className='main-sidebar__nav'>
                    <div className='logo-and-search'>
                        <Link to='/landing' onClick={(e) => {
                            setActiveItems(null)
                            setActiveSubItems(null)
                        }}>
                            <LogoBox />
                        </Link>
                        {/* <InputDynamicWidth /> */}
                    </div>

                    <div className='main-sidebar__space-content'>
                        <DropDownSelector
                            state={localSelectedSpace}
                            options_prop={optionList}
                            setState={e => {     
                                if(Object.keys(e)?.length > 0){
                                    let obj = Object.assign({}, e)
                                    delete obj?.label                                
                                    setSelectedSpace(obj)
                                    setLocalSelectedSpace(e)
                                }
                            }}
                            placeholder='Выберите пространство'
                            isClearable={false}
                        />

                        <button className='main-sidebar__btn-info'
                            onClick={(e) => setIsModalUnboardingForSpace(true)}
                        >
                            <img src={info_blue} />
                        </button>

                        <ButtonDrop
                            list_btn={list_btn}
                            style={{ height: '100%' }}
                        />
                    </div>


                    <div className='main-sidebar__right-box'>
                        {activeContent !== 'Admin' ? <div className='main-sidebar__group-btn' >

                            <div className='tooltip-container'>
                                <Link className='upload__btn' to='/settings/download-history'></Link>
                                <div className='tooltip_light-blue'>Загрузки</div>
                            </div>
                            <div className='tooltip-container'>
                                <a className='message__btn notifications' onClick={(e) => {
                                    e.preventDefault()
                                    setIsModalKnowledgeBase(true)
                                }}
                                ></a>
                                <div className='tooltip_light-blue'>Обучение</div>
                            </div>
                            <div className='tooltip-container'>
                                <Link className='notifications__btn notifications'></Link>
                                <div className='tooltip_light-blue'>Уведомления</div>
                                {showNotificationBox && <ModalNotifications
                                    notifCount={notifCount}
                                    isOpen={showNotificationBox}
                                    setIsOpen={setShowNotificationBox}
                                    portal_id={4}
                                    language={'ru'}
                                    fetchNotificationsCount={e => fetchNotificationsCount()} />}

                                {notifCount > 0 && <span className='notification-count'>{notifCount}</span>}
                            </div>
                        </div> : null}

                        <div className='balance-box-num' onClick={(e) => navigate('/settings/payment')}>
                            {balance} <span>{currency}</span>
                        </div>

                        < div className='profile-box'>
                            <p className='profile-box__user-name'>{profileName}</p>
                            <div className='profile-list__content'
                                onClick={(e) => {
                                    setProfileSettings(!showProfileSettings)
                                }}
                            >
                                <button className='profile-box__btn'>{profileName?.length > 0 && profileName[0]?.toUpperCase()}</button>

                                {showProfileSettings && <ProfileSettings onLogOutHandler={e => onLogOutHandler(e)} />}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export { HeaderMain }