import Cookies from 'universal-cookie'
import add_icon from './img/add_icon.svg'
import close_icon from './img/close.svg'
import { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { KonstantinAddImgHttps } from '../../fetchUrls';
import "./DropZoneProductImg.scss"

const DropZoneProductImg = ({ ind, key, arrImg, setArrImg, notMainPhoto = true, handleChangeItemPlace, setSelectedItem }) => {
    const cookies = new Cookies()
    const token = cookies.get('auth')
    const [save, setSave] = useState(null)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(false)

    const fetchPostImg = (file) => {
        setErr(false)

        const fd = new FormData()
        fd.append('images', file)
        const url = `${KonstantinAddImgHttps}/clients/images`

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    setErr(true)
                    throw new Error('bad response')
                }
            })
            .then(json => {
                setLoading(true)
                if (json.images) {
                    setSave(json.images[0].url)
                } else setErr(true)
                setLoading(false)
            })
            .catch(err => {
                setErr(true)
                setLoading(false)
                console.error(err)
            })
    }

    const handleFileInput = (e) => {
        if (e.target.files.length === 0) {
            e.preventDefault()
            return
        }
        if (e.target.files[0]) {
            fetchPostImg(e.target.files[0])
        }
    }

    return (
        <div
            key={key}
            className={(ind == 0 && notMainPhoto) ? 'file-box_big' : 'file-box'} 
            onMouseEnter={e => e.currentTarget.setAttribute('hover', true)} 
            onMouseLeave={e => e.currentTarget.setAttribute('hover', false)}
            onDragStart={e => setSelectedItem(ind)}
            onDrop={e => handleChangeItemPlace(ind)}
            onDragOver={e => e.preventDefault()}
            draggable
        >
            {
                <>
                    {
                        loading ?
                            <div className='center' style={{ width: '100%', height: '86px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MoonLoader
                                    size={20}
                                    color="#7b7b7b"
                                    speedMultiplier={0.5}
                                />
                            </div>
                            :
                            ((save || arrImg[ind]) ?
                                <img className='image' src={save ? save : arrImg[ind]} />
                                :
                                <img className='icon-add' src={add_icon} />
                            )
                    }

                    <input
                        type='file'
                        accept='image/png, image/jpg, image/jpeg'
                        multiple={true}
                        className='input-file'
                        onChange={(e) => {
                            setLoading(true)
                            handleFileInput(e)
                        }}
                    />

                    {(save || arrImg[ind]) && <div className='close-box'>
                        <button className='close-btn'
                            onClick={(e) => {
                                setSave(null)
                                e.stopPropagation()
                                let current = arrImg
                                current.splice(ind, 1)
                                setSave(arrImg[ind])
                                console.log(current);
                                setArrImg(current)
                            }}
                        ><img src={close_icon} /></button>
                    </div>}
                </>
            }
        </div>
    )
}

export { DropZoneProductImg }