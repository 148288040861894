import React from 'react';
import './ChangesContentLanding.scss';

const ChangesContentLanding = ({ title, text, icon, img, activeClass }) => {


    return (
        <div className={activeClass}>
            <div className='landing__img-content'>
                <div className='landing__img-box'>
                    <object className='landing__icon' type='image/svg+xml' data={icon}/>
                </div>
                <div className='landing__img-box'>
                    <object className='landing__img-changes' type='image/svg+xml' data={img}/>
                </div>
            </div>
            <div className='landing__description-box'>
                <h3 className='title'>{title}</h3>
                <p className='text'>{text}</p>
            </div>
        </div>
    );
};

export { ChangesContentLanding };
