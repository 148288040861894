import { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import iconBlue from './img/icon_blue.png'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const DoughnutChart = ({
  nameChart = false,
  chart_name,
  chart_unit = null,
  chart_size = 'small',
  chart_icons = [],
  display_legend,
  labels_prop = [],
  datasets_prop = [],
  multiAxis_prop = false,
  title_icon = false,
  colorArr = [],
  data_name = [],
  colorStart,
  unit = '',
  maxHeight = false
}) => {

  const colors = ['#014E94', '#D9009C', '#07D2A2', '#B90159', '#A53100', '#CFEE8D', '#57118E', '#88306D', '#389C08', '#FA9C0F']
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([{
    label: 'My First Dataset',
    data: [300, 50, 100],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }])
  const [data, setData] = useState({ labels, datasets })
  const [options, setOptions] = useState({
    type: 'doughnut',
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += context.parsed?.toLocaleString();
            }

            return label;
          }
        }
      },
      legend: {
        display: display_legend,
        position: 'bottom',
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          usePointStyle: true
        },
      },
      title: {
        display: false,
        text: '',
      },
    },
  })

  const [lineChart, setLineChart] = useState(<></>)

  const multiAxisOptions = (colorArr) => {
    const [first_color, second_color] = colorArr
    return {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          display: true,
          drawOnChartArea: false,
          color: first_color,
          borderColor: first_color,
        },
        ticks: {
          callback: (value) => `${value?.toLocaleString()} ${unit}`,
          color: first_color
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
          color: second_color,
          borderColor: second_color,
        },
        ticks: {
          callback: (value) => value?.toLocaleString(),
          color: second_color
        },
      }
    }
  }


  useEffect(() => {
    if (labels_prop.length > 0) {
      setLabels([...labels_prop])
    }

    if (datasets_prop.length > 0) {
      setDatasets([
        ...datasets_prop.map((el, ind) => ({
          ...datasets[0],
          data: el,
          label: data_name[ind] ? data_name[ind] : 'Не указано',
          borderColor: colors,
          backgroundColor: colors,
          yAxisID: multiAxis_prop & ind > 0 ? `y${ind}` : 'y'
        }))
      ])
    }

    if (multiAxis_prop) {
      setOptions(({
        ...options,
        type: 'doughnut',
        scales: multiAxisOptions(colorArr.length > 0 ? colorArr : [colors[0], colors[1]])
      }))
    }


  }, [labels_prop, datasets_prop, multiAxis_prop,])

  useEffect(() => {
    setData({
      labels,
      datasets
    })
  }, [datasets])

  useEffect(() => {
    setLineChart(
      <Doughnut
        type='doughnut'
        options={options}
        data={data}

      />
    )
  }, [data, options])

  return (
    <div className={`line_chart line_chart_${chart_size}`} >
      {nameChart && <h3 className='title'>{nameChart}</h3>}
      <div className='chart-box'  style={{maxHeight: maxHeight ? maxHeight : '', minHeight: maxHeight ? maxHeight : ''}}>
        {lineChart}
      </div>
    </div>
  )
}

export { DoughnutChart }
