import { Link } from 'react-router-dom';
import banner from  './img/banner.svg'
import "./NotActive.scss"

const NotActive = () => {

    return (
        <div className='not-active'>
            <h2 className='title'>Сейчас этот раздел находится в разработке, пожалуйста загляните сюда попозже</h2>
            <embed type='image/svg+xml' src={banner} />
       </div>
    )
}

export { NotActive }