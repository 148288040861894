import info_icon from './img/info_icon.svg'
import "./HintsNotifications.scss"

const HintsNotifications = ({ text, style }) => {

    return (
        <div className='hints-notifications' style={{...style}}>
            <img src={info_icon} />
            {text && <p className='hints-notifications__text'>{text}</p>}
        </div>
    )
}

export { HintsNotifications }