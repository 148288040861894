import parse from 'html-react-parser'
import card_img from './img/card_img.svg'
import { useState, } from 'react'
import { SendMessage } from '../../SendMessage/SendMessage'
import { CarouselSlick } from '../../CarouselSlick/CarouselSlick'
import './ModalPostChat.scss'

const ModalPostChat = ({ setModal, selectedPostContent, comment_list, setSelectedPost}) => {
    const [answerValue, setAnswerValue] = useState('')
    const [answer, setAnswer] = useState('')
    const [isEdit, setIsEdit] = useState(false)


  return (
    <div 
        className="blackout" 
        onClick={e => {
            setModal(false)
            setSelectedPost(-1)
        }}
    >
        <div className="post modal_post">
            <div className='chat-content'>
                <div className='comment-list'>
                    <div className='comment-list__header'>
                        <h2 className='title'>Комментарии</h2>
                    </div>
                    <div className='comment-list__content'>
                        <div className='comment-content'>
                            {comment_list?.length > 0 && comment_list?.map(({ author, comment, date_create, avatar, subComment }, ind) =>
                                <div className='comment-item' key={ind + comment}>
                                    <div className='img-box'>
                                        <img src={avatar} />
                                    </div>
                                    <div className='comment-item__content'>
                                        <h4 className='title'>{author}</h4>
                                        <p className='text'>{comment}</p>
                                        <div className='info-content'>
                                            <p className='text_date-create'>{date_create}</p>
                                            <button className='btn-response'>Ответить</button>
                                        </div>
                                        {subComment?.length > 0 &&
                                            subComment.map(({ author, comment, date_create, avatar, subComment }, ind) =>
                                                <div className='comment-item' key={ind + comment}>
                                                    <div className='img-box'>
                                                        <img src={avatar} />
                                                    </div>
                                                    <div className='comment-item__content'>
                                                        <h4 className='title'>{author}</h4>
                                                        <p className='text'>{comment}</p>
                                                        <div className='info-content'>
                                                            <p className='text_date-create'>{date_create}</p>
                                                            <button className='btn-response'>Ответить</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )}
                            {comment_list?.length == 0 && <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            >Нет данных</div>}
                        </div>
                        <SendMessage
                            frequentAnswers
                            isEdit={isEdit}
                            setIsEdit={(e) => setIsEdit(e)}
                            message={answer}
                            value={answerValue}
                            onChange={e => setAnswerValue(e)}
                            onSendAction={e => (e)} />
                    </div>
                </div>

                <div className='post-item'>
                    {
                        selectedPostContent?.date ?
                            <>
                                <div className='post-item__header'>
                                    <h2 className='title'>{selectedPostContent?.title}</h2>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                        <p className='text_date-create'>{new Date(selectedPostContent?.date)?.toLocaleDateString('ru-RU')}</p>
                                        <img style={{width: 20, height: 20}} src={selectedPostContent?.account?.icon_link} alt='account icon' />
                                    </div>
                                </div>
                                <div className='post-item__content'>
                                    <p className='text'>{parse(selectedPostContent?.text)}</p>

                                    {
                                        selectedPostContent.media?.length > 0 &&
                                        <CarouselSlick imgArr={selectedPostContent.media?.map(el => el?.link)} />
                                    }
                                </div>
                            </>
                        :
                            <>
                                <div className='post-item__header'>
                                    <h2 className='title'>Пост не выбран</h2>
                                    {/* <p className='text_date-create'>{new Date(selectedPostContent?.date)?.toLocaleDateString('ru-RU')}</p> */}
                                </div>
                                <div className='post-item__content'>
                                    <p className='text'>Выберите пост, чтобы просмотреть его данные</p>

                                    <CarouselSlick imgArr={[card_img]} />
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default ModalPostChat