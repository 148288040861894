import customHeaders, { getSpace } from '../../../common/headers';
import { useState, useEffect, memo, useMemo } from 'react';
import {
  DropDownSelector,
  ButtonBasic,
  InputDinamycPlaceholder,
  TextareaDynamicPlaceholder,
  CheckboxToggle,
  CheckboxToggleSmile,
} from '@lk-gtcom/ecomlab-components';
import { FilterContainer } from '../../FilterContainer/FilterContainer';
import {
  fetchApiReviewsFilter,
  fetchFeedbacksBrandFilter,
  fetchFeedbacksCategoryFilter,
} from '../../../common/filtersFunc';
import {
  KonstantinFeedbackHttps,
  KonstantinFeedbackHttp,
} from './../../../fetchUrls';
import edit_icon from './img/bx-edit.svg.svg';
import close_icon from './img/bx-x.svg.svg';
import useGeneralStore from '../../../store/general';
import './ModalAddingReadyScenario.scss';

const mpData = [
  {
    label: 'Ozon',
    value: 1,
    id: 1,
  },
  {
    label: 'Wildberries',
    value: 2,
    id: 2,
  },
  {
    label: 'Yandex',
    value: 3,
    id: 3,
  },
];

const answerTemplate2 = [
  {
    label: 'Ответ по шаблону',
    value: true,
  },
  {
    label: 'Ответ с помощью ИИ',
    value: false,
  },
];

const ModalAddingReadyScenario = ({
  setIsEdit,
  isEdit,
  setIsModal,
  setIsModalScenarioSettings,
  selectedCategoryProperties,
  setSelectedCategoryProperties,
  fetchRuleTable,
  scenarioData,
  setScenarioData,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [pageValue, setPageValue] = useState();

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState({
    label: 'Ozon',
    value: 1,
  });
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [titleValue, setTitleValue] = useState('');
  const [title, setTitle] = useState('');

  const [description, setDescription] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');

  const [isCheck, setIsCheck] = useState(false);
  const [ruleId, setRuleId] = useState(
    scenarioData?.id ? scenarioData?.id : -1
  );
  const [categoriesList, setCategoriesList] = useState([]);

  const [groupList, setGroupList] = useState([]);
  const [categoriesListMemo, setCategoriesListMemo] = useState(<></>);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isCreateCategory, setIsCreateCategory] = useState(false);

  const createRule = () => {
    if (title?.length < 1) {
      return;
    }
    let url;
    if (!window.location.hostname.match('localhost')) {
      if (isEdit) {
        url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}`;
      } else {
        url = `${KonstantinFeedbackHttps}/feedbacks/category-rules`;
      }
    } else {
      if (isEdit) {
        url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}`;
      } else {
        url = `${KonstantinFeedbackHttp}/feedbacks/category-rules`;
      }
    }

    const body = JSON.stringify({
      active: isCheck,
      name: title,
      description: description,
      mp_id: selectedMarketplace?.value,
      api_id_list: Array.isArray(selectedApi) ? selectedApi : [selectedApi],
      category_id_list: Array.isArray(selectedCategory)
        ? selectedCategory
        : [selectedCategory],
      brand_id_list: Array.isArray(selectedBrand)
        ? selectedBrand
        : [selectedBrand],
    });

    fetch(url, { body, method: isEdit ? 'PUT' : 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setRuleId(json?.id);
        setIsEdit(true);
        // setTimeout(() => {
        //     fetchRuleTable()
        // }, 600)
        // setScenarioData({})
        // setIsModal(false)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchRule = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const {
          id,
          name,
          mp_id,
          active,
          api_id_list,
          category_id_list,
          brand_id_list,
          description,
        } = json;
        setRuleId(id);
        setIsCheck(active);
        setTitleValue(name);
        setDescriptionValue(description);
        setSelectedMarketplace(...mpData?.filter((el) => el?.value === mp_id));
        setSelectedApi(api_id_list);
        setSelectedCategory(category_id_list);
        setSelectedBrand(brand_id_list);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchRuleCat = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setCategoriesList(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchGroupList = (page, searchInput) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups?limit=100&offset=${
        page ? page : 0
      }${searchInput?.length > 0 ? `&search_param=${searchInput}` : ''}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups?limit=100&offset=${
        page ? page : 0
      }${searchInput?.length > 0 ? `&search_param=${searchInput}` : ''}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setGroupList(
          json?.map(({ group_name, id }) => ({ label: group_name, value: id }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const CategoryItem = (el, ind) => {
    if (!el) throw new Error('No el');
    const {
      name,
      id,
      groupList,
      groupsCallback,
      answer_type,
      positive,
      answer_delay,
      answer_group_id,
      stop_brand_id_list,
      stop_word_list,
      stop_category_id_list,
    } = el;

    let currentAnswerType = answerTemplate2.filter(
      (el) => el.label == answer_type
    );
    const [selectedAnswerTemplate, setSelectedAnswerTemplate] =
      useState(currentAnswerType);
    const [selectedGroup, setSelectedGroup] = useState(
      answer_group_id?.value ? answer_group_id : null
    );

    const [groupsPage, setGroupsPage] = useState(0);
    const [groupsSearch, setGroupsSearch] = useState('');

    const [isEditName, setIsEditname] = useState(false);
    const [isPositive, setIsPositive] = useState(positive ? positive : false);
    const [responseDelay, setResponseDelay] = useState(
      answer_delay ? new String(answer_delay) : ''
    );
    const [valuename, setValueName] = useState(name);

    const createRuleCategory = () => {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories`;
      } else {
        url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories`;
      }

      const body = JSON.stringify({
        name: valuename,
        positive: isPositive,
        // active: ,
        answer_type: 'Ответ с помощью ИИ',
        answer_group_id: selectedGroup,
        // add_customer_name: false,
        // rotate_answer_for_same_product_and_rating: true,
        answer_delay: responseDelay,
        // stop_category_id_list: [],
        // stop_brand_id_list: [],
        // stop_word_list: []
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
          fetchRuleCat();
          setIsAddCategory(false);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const changeRuleCat = () => {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories/${id}`;
      } else {
        url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories/${id}`;
      }

      const body = JSON.stringify({
        name: valuename,
        positive: isPositive,
        answer_type: 'Ответ с помощью ИИ',
        answer_group_id: selectedGroup ? selectedGroup : answer_group_id,
        answer_delay:
          responseDelay || responseDelay == 0
            ? responseDelay === ''
              ? null
              : responseDelay
            : answer_delay,
        stop_category_id_list: stop_category_id_list,
        stop_brand_id_list: stop_brand_id_list,
        stop_word_list: stop_word_list,
      });

      fetch(url, { body, method: 'PUT', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw Error(JSON.stringify(err));
          }
        })
        .then((json) => {})
        .catch((err) => {
          console.error(err);
        });
    };

    useEffect(() => {
      if (isCreateCategory) {
        createRuleCategory();
      }
      setIsCreateCategory(false);
    }, [isCreateCategory]);

    useEffect(() => {
      if (!id) {
        createRuleCategory();
      } else {
        changeRuleCat();
      }
    }, [
      valuename,
      selectedGroup,
      selectedAnswerTemplate,
      isPositive,
      responseDelay,
    ]);

    console.log(selectedAnswerTemplate.value);

    try {
      return (
        <div className="row-item" key={id}>
          <div className="category-item">
            {!isEditName ? (
              name
            ) : (
              <input
                autoFocus={true}
                className="category-item__input"
                onChange={(e) => setValueName(e.target.value)}
                value={valuename}
              />
            )}
            <img src={edit_icon} onClick={(e) => setIsEditname(true)} />
          </div>
          <DropDownSelector
            options_prop={answerTemplate2}
            state={selectedAnswerTemplate}
            setState={(e) => setSelectedAnswerTemplate(e)}
            placeholder="Как отвечать?"
          />
          <DropDownSelector
            options_prop={groupList ? groupList : []}
            state={selectedGroup}
            setState={(e) => setSelectedGroup(e)}
            placeholder="Выберите группу"
            fetchCallback={(e) => setGroupsPage(e)}
            setInputChange={(e) => setGroupsSearch(e)}
            disabled={
              selectedAnswerTemplate[0]?.value == false ||
              selectedAnswerTemplate?.value == false
            }
          />

          <input
            type="num"
            value={responseDelay}
            onChange={(e) => setResponseDelay(e.target.value)}
            style={{
              width: 210,
              minWidth: 210,
              height: '40px',
              outline: 'none',
              border: '1px solid lightgrey',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          />
          <div className="row-item__container">
            <CheckboxToggleSmile
              value={isPositive}
              onChange={(e) => {
                setIsPositive(e);
              }}
            />

            <button
              className="btn-setting"
              onClick={(e) => {
                setSelectedCategoryProperties({ ...el, ruleId });
                setIsModalScenarioSettings(true);
              }}
            ></button>
          </div>
        </div>
      );
    } catch (error) {
      return 'Что-то пошло не так';
    }
  };

  useEffect(() => {
    if (isEdit) {
      if (ruleId) {
        fetchRule();
        fetchRuleCat();
      }
    } else {
      setCategoriesList([]);
    }
  }, [isEdit, scenarioData, ruleId]);

  useEffect(() => {
    fetchApiReviewsFilter({
      selectedSpace: selectedSpace?.value,
      selectedMarketplace: selectedMarketplace,
      withAll: false,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: 'wb',
    });
  }, [apiPage, apiQuery, selectedMarketplace]);

  useEffect(() => {
    fetchFeedbacksCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedCategory,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    pageValue,
    selectedBrand,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchFeedbacksBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedApi,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    pageValue,
    selectedBrand,
    brandPage,
    brandQuery,
  ]);

  useEffect(() => {
    fetchGroupList();
  }, []);

  useMemo(() => {
    setCategoriesListMemo(
      categoriesList.map((el, ind) => (
        <CategoryItem
          {...el}
          groupList={groupList}
          groupsCallback={fetchGroupList}
        />
      ))
    );
  }, [categoriesList, groupList]);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsEdit(false);
          setRuleId(-1);
          setIsModal(false);
          fetchRuleTable();
        }
      }}
    >
      <div className="modal-adding-ready-scenario">
        <div className="modal-adding-ready-scenario__header">
          <h2 className="modal-adding-ready-scenario__title">
            {titleValue
              ? `Редактирование сценария ${titleValue}`
              : 'Добавление готового сценария'}
          </h2>

          <InputDinamycPlaceholder
            position="left"
            maxWidth="100%"
            isOpen={true}
            onChange={(value) => setTitle(value)}
            changeValue={titleValue}
            placeholder="Введите название сценария "
            size="s"
          />

          <label className="modal-adding-ready-scenario__label">
            <span>{isCheck ? 'Активен' : 'Не активен'}</span>
            <CheckboxToggle
              value={isCheck}
              onChange={(e) => setIsCheck(!isCheck)}
            />
          </label>

          <button
            className="modal-adding-ready-scenario__btn-close"
            onClick={(e) => {
              setRuleId(-1);
              setIsModal(false);
            }}
          >
            <img src={close_icon} />
          </button>
        </div>

        <div className="modal-adding-ready-scenario__main-content">
          <div className="modal-adding-ready-scenario__group-btn">
            <ButtonBasic
              green
              width="164px"
              text={<div className="content-btn">Сохранить сценарий</div>}
              size="40px"
              onClick={(e) => createRule()}
            />
            <ButtonBasic
              whiteGreen
              text={<div className="content-btn">Создать свою категорию</div>}
              width="194px"
              size="40px"
              onClick={(e) => {
                setIsAddCategory(e);
              }}
              disabled={ruleId < 0}
            />
          </div>

          <div className="modal-adding-ready-scenario__toolbar-top">
            <div className="filters-container">
              <DropDownSelector
                state={selectedMarketplace}
                options_prop={mpData}
                setState={(e) => setSelectedMarketplace(e)}
                placeholder="Площадка"
              />
              <DropDownSelector
                state={selectedApi}
                options_prop={apiData}
                setState={(e) => setSelectedApi(e)}
                placeholder="Магазин"
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
              />
              <DropDownSelector
                state={selectedCategory}
                options_prop={categoryData}
                setState={(e) => setSelectedCategory(e)}
                placeholder="Категория"
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
              />
              <DropDownSelector
                state={selectedBrand}
                options_prop={brandData}
                setState={(e) => setSelectedBrand(e)}
                placeholder="Бренд"
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
              />
            </div>
            <TextareaDynamicPlaceholder
              width="100%"
              changeValue={descriptionValue}
              placeholder="Описание"
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>

          <div className="prepared_scenarios">
            <header className="prepared_scenarios__header">
              <h2 className="title_category">Категория отзывов </h2>
              <h2 className="title_col">Написание ответа</h2>
              <h2 className="title_col">Выбор группы шаблонов</h2>
              <h2 className="title_col">Задержка ответа</h2>
              <h2 className="title_col">Выбор настроения</h2>
            </header>
            <div className="prepared_scenarios__content">
              {categoriesListMemo}
              {isAddCategory && (
                <CategoryItem
                  groupList={groupList}
                  groupsCallback={fetchGroupList}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalAddingReadyScenario };
