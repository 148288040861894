const Flag = ({ color }) => {

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bxs-flag-alt.svg">
                <path id="Vector" d="M10.0347 4.00065L8.03468 2.66732H4.49935V1.33398H3.16602V14.6673H4.49935V9.33398H7.63068L9.63068 10.6673H13.8327V4.00065H10.0347Z" fill={color} />
            </g>
        </svg>
    )
}

export { Flag }