import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { AndreyHttps, AndreyHttp } from '../../fetchUrls'
import './ListLinksProductRelated.scss'
import { ModalAddLinks } from '../Modal/ModalsCompetitors/ModalsCompetitors'
import Cookies from 'universal-cookie'


const ListLinksProductRelated = ({ modalsPortalId }) => {
  const [textAreaValue, setTextAreaValue] = useState('')
  const [linksArr, setLinksArr] = useState([])
  const [fetchedData, setFetchedData] = useState([])
  const [clientLink, setClientLink] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [isLinks, setIsLinks] = useState(true)
  const [status, setStatus] = useState(0)
  const auth = new Cookies().get('auth')

  const postLinks = (linksArr) => {
    if (linksArr.length > 0) {
      let url
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreyHttps}/parselinks/`
      } else {
        url = `${AndreyHttp}/parselinks/`
      }

      const client_id = localStorage.getItem('client_id')

      const body = JSON.stringify([{
        client_id,
        client_link: clientLink,
        links: linksArr,
        map: true,
        type: 2
      }])

      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`
      }

      fetch(url, { body, method: 'POST', headers })
        .then(res => {
          setStatus(res.status)
          return res.json()
        })
        .then(json => {
          setShowModal(true)
       })
        .catch(err => {
          console.error(err)
          setStatus(0)
          setShowModal(true)
        })
    }
  }

  const checkIsLinks = (linksArr) => {
    setIsLinks(linksArr.length > 0)
  }

  return (
    <div className='list-links-product-related'>
      {showModal && createPortal(<ModalAddLinks setShowModal={(e) => { setShowModal(e) }} status={status} />, document.getElementById(modalsPortalId))}

      <div className='tools__header'>
        <div className='tools__link-box'>
          <p className='text'>
            Ссылка на ваш товар
          </p>
          <input
            className='client_link_input'
            type='text'
            placeholder='https://www.ozon.ru/product/pizhama-hola-623692499/?avtc=1&avte=2&avts=1670129180&sh=GqJaTY8PIA'
            value={clientLink}
            onChange={e => setClientLink(e.target.value)}
          />
        </div>
      </div>

      <div className='list-links-product-related__content'>
        <div className='competitor_link_main_wrapper'>
          <span className='competitor_link_main_span'>
            Ссылки на товары конкурентов
          </span>
          <button
            className="btn__green"
            style={{ maxWidth: '160px' }}
            onClick={e => {
              const linksArr = textAreaValue.split('\n').filter(el => el !== '')
              checkIsLinks(linksArr)
              postLinks(linksArr)
            }}
          >
            Отправить ссылки
          </button>

        </div>

        <div className='textarea-box'>
          <textarea
            style={isLinks ? {} : { outline: '1px solid red' }}
            value={textAreaValue}
            autoFocus={true}
            onChange={e => setTextAreaValue(e.target.value)}
            className='competitors_textarea'
            placeholder='https://www.ozon.ru/product/protsessor-intel-core-i9-13900k-oem-bez-kulera-790289147/?asb2=unKsgnW-6UYq--amUfhJ4bT_o8e1ctB9Y9cTDc_ja3lfX2xLewp-STrm0pOND5jw&avtc=1&avte=2&avts=1670939665&keywords=intel+core+i9+13900k&sh=j735KRJPsw'
          />
        </div>
      </div>
    </div>
  )
}

export { ListLinksProductRelated }