import customHeaders, {getSpace} from "../../../common/headers";
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { useState, useEffect } from 'react';
import { KonstantinFeedbackHttps, KonstantinFeedbackHttp } from '../../../fetchUrls';
import useGeneralStore from "../../../store/general";
import './ModalConfirmationUnloading.scss'

const ModalConfirmationUnloadingl = ({ setIsModal }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const downloadAllTemplates = () => {
        const url = `${KonstantinFeedbackHttps}/feedbacks/answers/templates-report`

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if(res.ok){
                    return res.json()
                } else {
                    const err = await res?.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ filename, url }) => {
                window.open(url, '_blank')
                setIsModal(false)
            })
            .catch(err => console.error(err))
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-confirmation-unloading'>
                <h2 className='modal-confirmation-unloading__title'>Вы действительно хотите выгрузить все шаблоны</h2>
                <ButtonBasic
                    green
                    text='Да, хочу выгрузить'
                    width='225px'
                    size='40px'
                    onClick={(e) => {
                        downloadAllTemplates()
                    }}
                />
                <ButtonBasic
                    grey
                    text='Нет, я передумал'
                    width='225px'
                    size='40px'
                    onClick={(e) => setIsModal(false)}
                />
            </div>
        </div>
    )
}

export { ModalConfirmationUnloadingl }