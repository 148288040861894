import { useEffect } from 'react'
import { useParams } from 'react-router-dom'


export const useResetOnBaseURL = (baseURL, resetCallback) => {
    const params = useParams()
    const path = params['*']

    useEffect(() => {
        if (path === baseURL) resetCallback()
    }, [params])
}