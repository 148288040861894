import customHeaders, {getSpace} from '../../../common/headers'
import { TableBox } from '@lk-gtcom/ecomlab-components';
import { useDataTable } from '../../../common/hooks'
import { useState, useEffect, useRef } from 'react'
import useGeneralStore from '../../../store/general';
import './ModalWithTable.scss'

const ModalWithTable = ({ ...props }) => {
    const {
        setIsModal,
        _url,
        body,
        setCheckedProducts,
        onAction,
        actionInfo = false
    } = props
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const paginatorRef = useRef()
    const [ setTable, tableProps, setTableFuncs ] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading } = setTableFuncs


    const fetchTableData = (params) => {
        let sort, filters

        if (params) {
            [sort, filters] = params
        }
        const url = _url

        const currentBody = JSON.stringify({
            ...body,
            filters,
            sort_name: sort ? sort[0] : 'Сред. запросов в неделю',
            sort_order: sort ? sort[1] : 'DESC',
            limit: paginatorRef.current.limit,
            page: paginatorRef.current.page,
        })

        fetch(url, { method: 'POST', headers, body: currentBody })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setTable(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    useEffect(() => {
        setTable({})
        if(_url && body){
            setLoading(true)
            fetchTableData()
        }
    }, [_url, body, selectedSpace])


    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-creating-an-advertising-campaign-v2'>

                <header className='modal-creating-an-advertising-campaign-v2__header'>
                    <button className='btn-close'
                        onClick={(e) => {
                            setIsModal(false)
                        }}
                    ></button>
                </header>

                <div className='modal-creating-an-advertising-campaign-v2__content'>
                    <div className='table-content'>
                        <TableBox
                            {...tableProps}
                            tableUrl={_url}
                            tableBody={body}
                            paginator={true}
                            ref={paginatorRef}
                            onAction={onAction}
                            onActionInfo={actionInfo}
                            fetchCallback={fetchTableData}
                            onCheck={e => setCheckedProducts(e)}
                        />
                    </div>

                </div>
            </div >
        </div >
    );
}

export { ModalWithTable };