import { FileDropZone } from 'react-file-uploadify'
import 'react-file-uploadify/dist/index.css'
import Cookies from 'universal-cookie'
import React, { useState, useEffect } from "react";
import { KonstantinAddImgHttps } from '../../fetchUrls';
import myClassNames from './myClassNames.module.scss'


const DownloadComponent = ({ setFilesArr, filesdata }) => {
    const cookies = new Cookies()
    const token = cookies.get('auth')
    const [files, setFiles] = useState([])

    const updateFiles = (incomingFiles) => {
        setFiles(incomingFiles);
    };

    const [save, setSave] = useState([])

    const fetchPostVideo = (file) => {

        const fd = new FormData()
        fd.append('videos', file)
        const url = `${KonstantinAddImgHttps}/clients/videos?file_group=task`

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                if (json.videos) {
                    setSave((prev) => [...prev, json.videos[0].url])
                }
            })
            .catch(err => {
                console.error(err)
            })

    }

    const fetchPostImg = (file) => {
        const fd = new FormData()
        fd.append('images', file)
        const url = `${KonstantinAddImgHttps}/clients/images?file_group=task`

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                if (json.images) {
                    setSave((prev) => [...prev, json.images[0].url])
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const fetchPostFile = (file) => {
        const fd = new FormData()
        fd.append('files', file)
        const url = `${KonstantinAddImgHttps}/clients/files?file_group=task`

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                if (json.files) {
                    setSave((prev) => [...prev, json.files[0].url])
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        if (filesdata?.length > 0) {
            let arr = filesdata.map((el) => el.value)
            setFilesArr([...arr, ...save])
        } else {
            setFilesArr(save)
        }
    }, [save]);
    

    return (
        <div className={myClassNames.container}>

            <FileDropZone
                onChange={(e) => updateFiles(e)}
                maxFilesSizeInMb={20}
                 acceptTypes={[                  
                    '.png',
                    '.jpg',
                    '.jpeg',
                    '.gif',
                    '.bmp',
                    '.tiff',
                    '.txt',
                    '.pdf',
                    '.doc',
                    '.docx',
                    '.xls',
                    '.xlsx',
                    '.xml',
                    '.swf',
                    '.avi',
                    '.mpg',
                    '.mpeg',
                    '.mpv',
                    '.mp4',
                    '.m4e',
                    '.m4v',
                    '.mp3',
                    '.m3u',
                    '.wma',
                    '.wmv',
                    '.js',
                    '.jsx',
                    '.css',
                    '.htm',]}

                haveFileList={true}
                multiple={true}
                // minFiles={2}
                maxFiles={10}
                lang={"ru"}
                classNames={{
                    fileIcon: myClassNames.myFileIconClass,
                    fileBox: myClassNames.box,
                    fileBoxButton: myClassNames.myFileButton,
                    fileName: myClassNames.myFileName,
                    dropZoneBox: myClassNames.drop_zone,
                    button: myClassNames.btn,
                }}
            >
            </FileDropZone>
            <button className='btn-basic grey-btn'
                style={{ marginLeft: 'auto', minWidth: 'auto', width: 'auto' }}
                onClick={(e) => {
                    setSave([])
                    files.map((el) => {
                        if (el?.type == 'image/jpeg') {
                            fetchPostImg(el)
                        }
                        if (el?.type == 'video/mp4') {
                            fetchPostVideo(el)
                        } else {
                            fetchPostFile(el)
                        }    
                    })
                }}
            >Сохранить</button>
        </div>
    );
}
export { DownloadComponent };