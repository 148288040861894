import { useState } from 'react';
import "./ModalPasswordRecovery.scss"
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"

const ModalPasswordRecovery = ({ setIsModal }) => {

    const [isModalMail, setIsModalMail] = useState(true)

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='password-recovery'>
                <h2 className='title'
                    onClick={(e) => setIsModal(false)}
                >Восстановление пароля</h2>
                <InputDinamycPlaceholder
                    onChange={e => (e)}
                    type="e-mail"
                    classname='autorization__input-field'
                    placeholder='E-mail'
                    err={''}
                    textErr="Неверный e-mail формат"
                />

                <button
                    className='btn__blue'
                >Восстановить пароль</button>
            </div>
        </div>
    )
}

export { ModalPasswordRecovery }