import { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import err_icon from './img/err_icon.svg'
import './ModalDelete.scss'

const ModalDelete = ({ title, setIsModal }) => {

    return (
        <div className='modal-delete'>
            <img src={err_icon} />
            <h2 className='title'>{title}</h2>
            <div className='btn-group'>
                <ButtonBasic
                    grey
                    text='Отмена'
                    size='56px'
                    width='calc(50% - 6px)'
                    onClick={(e) => {
                        setIsModal(false)
                    }}
                />
                <ButtonBasic
                    red
                    text='Удалить'
                    size='56px'
                    width='calc(50% - 6px)'
                    onClick={(e) => setIsModal(false)}
                />
            </div>
        </div>
    )
}

export { ModalDelete }