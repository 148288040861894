import vk from './img/socialNetworks/vk.svg'
import facebook from './img/socialNetworks/facebook.svg'
import tg from './img/socialNetworks/tg.svg'
import insta from './img/socialNetworks/insta.svg'

export const mapFiltersResponse = array => array.map((el) => 
  ({ label: el.name ? el.name : el.id, value: el.id })).filter(el => el.name !== null)

export const tableNumberRowToFixed = (row) => 
  Object.fromEntries(Object.entries(row).map(([key, value]) => {
    value = typeof value === 'number' && value !== 0 ? (+value?.toFixed(2))?.toLocaleString() : value
    return [key, value]
}))

export const splitByRedLine = (array) => array.split('\n').map(el => el.trim()).filter(el => el !== '')

// social networks

const socialNetworkNames = {
    tg: 'Телеграм',
    vk: 'Вконтакте'
}

const socialNetworkImagesMap = new Map([
  [ socialNetworkNames.tg, tg],
  [ socialNetworkNames.vk, vk]
])

export const mapSocialNetworkNameToSvg = name => socialNetworkImagesMap.get(name)

export const parseGroupsResponse = response => {
  return response
}

export const parseAccountsResponse = response => {
  return response
}

// miscellaneous 

export const toSnakeCase = object => {
  const keys = Object.keys(object)
  const values = Object.values(object)
  const result = {}
  const regex = new RegExp(/[A-Z]/)
  keys.forEach((k, i) => {
    let newKey = ""
    for (const char of k) {
      newKey += regex.test(char) ? `_${char.toLowerCase()}` : char
    }
    result[newKey] = values[i]
  })
  return result
}

export const fromSnakeCase = object => {
  const keys = Object.keys(object)
  const values = Object.values(object)
  const result = {}
  keys.forEach((k, i) => {
    let newKey = ""
    for (let index = 0; index < k.length; index++) {
      const char = k[index]
      if (char === '_') {
        newKey += k[index + 1].toUpperCase()
        index++
        continue
      } else {
        newKey += char
      }
    }
    result[newKey] = values[i]
  })
  return result
}