import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import err_icon from './img/err_icon.svg'
import './ModalDeleteProduct.scss'

const ModalDeleteProduct = ({ title, setIsModal }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-delete-product'>
                <img src={err_icon} />
                <h2 className='title'>Вы хотите удалить товар?</h2>
                <div className='btn-group'>
                    <ButtonBasic
                        text='Отмена'
                        lilac
                        size='56px'
                        width='calc(50% - 6px)'
                        onClick={(e) => setIsModal(false)}
                    />

                    <ButtonBasic
                        text='Удалить'
                        red
                        size='56px'
                        width='calc(50% - 6px)'
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalDeleteProduct }