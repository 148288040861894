import Cookies from 'universal-cookie';
import { ModalAddEmployee } from '../Modal/ModalAddEmployee/ModalAddEmployee';
import { TableBox, ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { useState, useEffect, useRef } from 'react';
import { ModalSuccessfullyAddingAnEmployee } from '../Modal/ModalSuccessfullyAddingAnEmployee/ModalSuccessfullyAddingAnEmployee';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import "./Employees.scss"

const Employees = ({ spaceList }) => {

    const [loading, setLoading] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [showModalEmployeeAdded, setShowModalEmployeeAdded] = useState(false)
    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
    const [employeeData, setEmployeeData] = useState([])
    const [employeeHeaders, setEmployeeHeaders] = useState([])
    const [employeeSort, setEmployeeSort] = useState([])
    const [employeeColumnSize, setEmployeeColumnSize] = useState([])
    const employeeRef = useRef()

    const auth = new Cookies().get('auth')

    const fetchGetEmployees = (params) => {
        let sort;
        let filtersParam;

        if (params) {
            [sort, filtersParam] = params
        }
        setLoading(true)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/web/users/employees/list${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
        } else {
            url = `${KonstantionAuthHttp}/web/users/employees/list${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        const body = JSON.stringify({
            limit: 50,
            offset: 1,
            filters: filtersParam
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const { data, labels, sort, column_size, total } = json
                if (data) setEmployeeData(data)
                if (labels) setEmployeeHeaders(labels)
                if (sort) setEmployeeSort(sort)
                if (column_size) setEmployeeColumnSize(column_size)
                if (total) employeeRef.current.setTotal(total)
                setTableUrl(url)
                setTableBody(body)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    const onDeleteEmployees = (id) => {

        if (id) {
            setLoading(true)
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${KonstantionAuthHttps}/web/users/employees/${id}`
            } else {
                url = `${KonstantionAuthHttp}/web/users/employees/${id}`
            }

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            }

            fetch(url, { method: 'DELETE', headers })
                .then(res => res.json())
                .then(json => {
                    fetchGetEmployees()
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        fetchGetEmployees()
    }, [])

    const contextMenuArr = [
        {
            label: 'Удалить',
            onClick: ({ id }) => {
                onDeleteEmployees(id)
            }
        }
    ]

    return (
        <>
            {showModalEmployeeAdded && <ModalSuccessfullyAddingAnEmployee setIsModal={(e) => setShowModalEmployeeAdded(e)} />}
            {showModal ? <ModalAddEmployee
                spaceList={spaceList}
                setShowModal={(e) => setShowModal(e)}
                fetchGetEmployees={e => fetchGetEmployees(e)} /> : null}
            <div className='employees'>
                <h3 className='title__main'>Сотрудники</h3>

                <TableBox
                    loading={loading}
                    fetchedData={employeeData}
                    headers={employeeHeaders}
                    child={
                        <ButtonBasic
                            green
                            text='Пригласить сотрудника +'
                            onClick={(e) => setShowModal(true)}
                            size='32px'
                            width='211px'
                        />
                    }
                    // onDelete={(e) => onDeleteEmployees(e)}
                    fetchCallback={e => fetchGetEmployees(e)}
                    ref={employeeRef}
                    paginator={true}
                    sort={employeeSort}
                    columnSize={employeeColumnSize}
                    hideExport
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    contextMenuArr={contextMenuArr}
                />

                {/* {tableContent === 'Сотрудники' ?
                    !loading ?
                        <div className='employees-list'>
                            {employees.map(({ email, name, id }) =>
                                <div key={id} className='employees-list__item'>
                                    <div className='name-box'>
                                        <p>{email}</p>
                                        <p>{name}</p>
                                    </div>
                                    <div className='btn-box'>
                                        <button onClick={(e) => {
                                            onDeleteEmployees(id)
                                        }} className='btn__delete-light'></button>
                                    </div>
                                </div>
                            )}
                            {employees.length === 0 && <p style={{ marginTop: '24px' }}>Нет сотрудников</p>}
                        </div>
                        :
                        <p>Загрузка...</p>

                    :
                    <p></p>
                } */}
            </div>
        </>

    )
}

export { Employees }