import product from './img/product.svg'
import not_img from './img/not-img.svg'
import photo_icon from './img/photo_icon.svg'
import video_icon from './img/video_icon.svg'
import close_icon from './img/close.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './AddMediaProduct.scss'

const AddMediaProduct = ({ onOpenMediaModal, arrImg, arrVideo, productTemporary, setArrImg, setArrVideo }) => {

    const currentArrVideo = () => {
        const res = productTemporary?.attributes?.videos?.value
        if (res) {
            return arrVideo
        } else {
            return arrVideo
        }
    }
    
    return (
        <div className='add-media-product'>
            <p className='text_sum-photo'>Фото {arrImg?.length ? arrImg?.length : 0}/<span>15</span></p>
            <div className='img-content'>
                {arrImg[0] && <div className='img-group'>
                    {<div className={'img-box_big'} onClick={e => onOpenMediaModal([true, 0])}>
                        <img src={arrImg[0] ? arrImg[0] : not_img} onError={e => e.target.src = product} />

                        <div className='close-box' >
                            <button className='close-btn'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    let current = arrImg.splice(0, 1)
                                    setArrImg(current)
                                }}
                            ><img src={close_icon} /></button>
                        </div>
                    </div>}

                    <div className='img-group'>
                        {arrImg.length > 0 &&
                            arrImg?.map((el, ind) => {
                                const src = el
                                return <div
                                    className={ind == 0 ? 'img-box' : 'img-box'}
                                    key={el + ind}
                                    onClick={e => onOpenMediaModal([true, ind + 1])}>
                                    <img src={el ? el : product} onError={e => e.target.src = product} />

                                    {src && <div className='close-box'>
                                        <button className='close-btn'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                let current = arrImg
                                                current.splice(ind, 1)
                                                setArrImg(current)
                                            }}
                                        ><img src={close_icon} /></button>
                                    </div>}
                                </div>
                            }).splice(1, 15)
                            }
                    </div>
                </div>}
            </div>

            <ButtonBasic
                grey
                text={<div className='content-photo-btn'><img src={photo_icon} />Добавить фото</div>}
                width='100%'
                onClick={(e) => onOpenMediaModal([true])}
            />

            <p className='text_sum-photo'>Видео {currentArrVideo()?.length ? currentArrVideo()?.length : 0}/<span>5</span></p>
            <div className='video-content'>
                {currentArrVideo().length > 0 && <div className='video-group'>
                    {currentArrVideo()?.map((el, ind) => {
                            const src = el
                            return <div className={'img-box'} key={el + ind} onClick={e => onOpenMediaModal([true, ind + 1])}>
                                <video controls src={src ? src : product} onError={e => e.target.src = product} />
                                {src && <div className='close-box'>
                                    <button className='close-btn'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            let current = currentArrVideo()
                                            current.splice(ind, 1)
                                            setArrVideo(current)
                                        }}
                                    ><img src={close_icon} /></button>
                                    
                                </div>}
                            </div>
                        })}
                </div>}
            </div>
            <ButtonBasic
                grey
                text={<div className='content-photo-btn'><img src={video_icon} />Добавить видео</div>}
                width='100%'
                onClick={(e) => onOpenMediaModal([true])}
            />
        </div>
    )
}

export { AddMediaProduct }