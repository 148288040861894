import './ModalInfoEvent.scss'

const ModalInfoEvent = ({ setIsModalInfo, eventInfo }) => {

    const {text, description, dateFrom, dateTo} = eventInfo

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModalInfo(false)
            }
        }}>
            <div className='modal-info-event'>
                <button className='close' onClick={() => setIsModalInfo(false)}></button>
                <h2 className='title'>{text}</h2>
                <p className='text'>{description}</p>
                <p className='text'>{dateFrom}</p>
                <p className='text'>{dateTo}</p>
            </div>
        </div>

    )
}

export default ModalInfoEvent