import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useState, useEffect, useRef } from 'react';
import { TableBox, ButtonBasic, DropDownSelector, SearchComponent, Tabs } from "@lk-gtcom/ecomlab-components";
import { TabsGreen } from '../../components/TabsGreen/TabsGreen';
import './ViewCompetitors.scss'

const btn_tab = [
    {
        label: 'Все',
        value: 'all'
    },
    {
        label: 'Черновики',
        value: 'drafts'
    },
    {
        label: 'Ошибки',
        value: 'errors'
    },
    {
        label: 'Архив',
        value: 'archive'
    },
    {
        label: 'Недавно удаленные',
        value: 'recently_deleted'
    }
]


const ViewCompetitors = ({ }) => {

    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    const [categoryPage, setCategoryPage] = useState(1)
    const [brandPage, setBrandPage] = useState(1)

    const [categoryQuery, setCategoryQuery] = useState('')
    const [brandQuery, setBrandQuery] = useState('')

    const [categoryData, setCategoryData] = useState([])
    const [brandData, setBrandData] = useState([])

    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])

    const [searchData, setSearchData] = useState([])

    const [searchValue, setSearchValue] = useState('')
    const activeTab = useParams()['*']

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])


    return (

        <div className='view-competitors'>

            <h1 className='view-competitors__title-main'>Посмотреть конкурентов</h1>

            <div className='view-competitors__toolbar-top'>
                <FilterContainer
                    onReset={(e) => {
                        setSelectedCategory([])
                        setSelectedBrand([])
                    }}
                >
                    <DropDownSelector
                        options_prop={categoryData}
                        state={selectedCategory}
                        setState={e => setSelectedCategory(e)}
                        placeholder='Категория'
                        multi={true}
                        fetchCallback={(e) => setCategoryPage(e)}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                    />

                    <DropDownSelector
                        options_prop={brandData}
                        state={selectedBrand}
                        setState={e => setSelectedBrand(e)}
                        placeholder='Бренд'
                        multi={true}
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                    />
                    <SearchComponent
                        searchValue={''}
                        errSearch={''}
                        placeholder='Поиск...'
                        setErrSearch={(e) => (e)}
                        setSearchValue={(e) => (e)}
                        data={[]}
                    />
                </FilterContainer>

                <ButtonBasic
                    green
                    width='150px'
                    text='Создать товар'
                />
            </div>

            <Tabs
                tabs={btn_tab}
                pageValue={pageValue} />

            <TableBox
                loading={loading}
                fetchedData={[]}
                headers={[]}
            />
        </div>
    )
}

export { ViewCompetitors }