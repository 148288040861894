const {format: formatDate} = require('date-fns')

export const getFormatDate = (dateString = Date.now(), UTC = false, dateFormat = false) => {
    const date = new Date(dateString);
  
    const UtcFormatDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );

    return formatDate(UTC ? UtcFormatDate : dateString, dateFormat ? dateFormat : 'yyyy-MM-dd')
  };