import customHeaders, {getSpace} from '../../common/headers';
import { Post } from '../../components/Post/Post';
import { Page404 } from '../Page404/Page404';
import { NotActive } from '../../components/NotActive/NotActive';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { RavshanHttps } from '../../fetchUrls';
import { NetworkGroups } from '../../components/NetworkGroups/NetworkGroups';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import useGeneralStore from '../../store/general';
import './SocialNetwork.scss'

const SocialNetwork = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const path = useParams()
    const navigate = useNavigate()
    const [sidebarList, setSidebarList] = useState([])
    const [isActive, setIsActive] = useState('Группы сетей')
    const [isActiveItems, setIsActiveItems] = useState('')

    const [platforms, setPlatforms] = useState([])
    const [statuses, setStatuses] = useState([])
    


    const [hideMenu, setHideMenu] = useState(true)

    const [sidebarItems, setSidebarItems] = useState(
        [
            {
                label: 'Социальные сети',
                subItems: [
                    {
                        label: 'Группы сетей',
                        path: 'network-groups',
                        black: true
                    },
                    {
                        label: 'Посты',
                        black: true,
                        path: 'post',
                    },
                    {
                        label: 'Аналитика',
                        black: true,
                        path: ''
                    },
                ],
            },
            {
                label: 'Уведомления',
                subItems: [
                    {
                        label: 'Настроить уведомления',
                        black: true
                    }
                ],
            },
        ]
    )

    const fetchFilters = (signal) => {
        const url = `${RavshanHttps}/api/social/filters_group`

        fetch(url, { method: 'GET', headers, signal })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then((json) =>{
                const {statuses, platforms} = json[0]
                setPlatforms(platforms)
                setStatuses(statuses.map((({label, value}) => ({label: String(label), value}))))
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        if (path['*'] === '') {
            navigate('network-groups')
        }

        setSidebarList([
            ...sidebarItems.map((el, idx, { label }) => <SidebarMenu isActiveItems={isActiveItems} setIsActiveItems={e =>setIsActiveItems(e)} key={label + '' + idx} {...el} setActive={e => setIsActive(e)} active={isActive} />)
        ])

        const abortController = new AbortController()
        fetchFilters(abortController.signal)
        return () => abortController.abort()
    }, [isActive, selectedSpace])

    return (
        <div className='settings__content'>

            <div className='flex'>
                <div className={hideMenu ? 'sidebar-inside' : 'sidebar-inside_active'}>
                    <nav className='sidebar-inside__nav'>
                        {sidebarItems.map((el, idx, { label }) => <SidebarMenu {...el} isActiveItems={isActiveItems} setIsActiveItems={e =>setIsActiveItems(e)} setActive={e => setIsActive(e)} active={isActive} key={label + '' + idx} />)}
                    </nav>
                </div>
                <div className='settings__main-box'>
                    <Routes>
                       <Route path='network-groups/*' element={<NetworkGroups 
                       fetchFilters={(e) => fetchFilters(e)} 
                       platforms={platforms} 
                       statuses={statuses} />}/>
                       <Route path='post' element={<Post 
                        platforms={platforms} 
                        statuses={statuses}
                       />}/>
                       <Route path='not-active' element={<NotActive/>}/>
                       <Route path='*' element={< Page404 />}/>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export { SocialNetwork }