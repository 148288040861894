import customHeaders, {getSpace} from '../../common/headers'
import brand_tab from './img/brand_tab.svg'
import seller_tab from './img/seller_tab.svg'
import trend_icon from './img/trend_icon.svg'
import product_tab from './img/product_tab.svg'
import by_days_tab from './img/by_days_tab.svg'
import summary_tab from './img/summary_tab.svg'
import category_tab from './img/category_tab.svg'
import requests_tab from './img/requests_tab.svg'
import statistic_icon from './img/statistic_icon.svg'
import price_segment_tab from './img/price_segment_tab.svg'
import shopping_basket_icon from './img/shopping_basket_icon.svg'
import { SliderRange } from '../Slider/Slider'
import { useChartData } from '../../common/hooks'
import { useDataTable } from '../../common/hooks'
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { Andrey2Https, Andrey2Http, AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls'
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabs, TableBox, SearchComponent, CardStatistics, RangeBox, CheckboxToggleBlue, LineChart, DropContainer, Checkbox } from "@lk-gtcom/ecomlab-components"
import useGeneralStore from '../../store/general'
import "./AnalysisSearchQuery.scss"

const btn_tab = [
    {
        label: 'Топ',
        value: 'top_keywords',
        icon: summary_tab
    },
    {
        label: 'Сводка',
        value: 'summary',
        icon: summary_tab
    },
    {
        label: 'Запросы',
        value: 'queries',
        icon: requests_tab
    },
    {
        label: 'Товары',
        value: 'goods',
        icon: product_tab
    },
    {
        label: 'Категории',
        value: 'category',
        icon: category_tab
    },
    {
        label: 'Бренды ',
        value: 'brands',
        icon: brand_tab
    },
    {
        label: 'Продавцы',
        value: 'sellers',
        icon: seller_tab
    },
    {
        label: 'По датам',
        value: 'days',
        icon: by_days_tab
    },
    {
        label: 'Ценовые сегменты',
        value: 'price_segment',
        icon: price_segment_tab
    }
]

const options_period = [
    {
        label: 'дням',
        value: 'day'
    },
    {
        label: 'неделям',
        value: 'week'
    },

    {
        label: 'месяцам',
        value: 'month'
    }
]

const AnalysisSearchQuery = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const activeTab = useParams()['*']
    const [searchParams, setSearchParams] = useSearchParams()

    const [searchValue, setSearchValue] = useState('')
    const [search, setSearch] = useState('')
    const [searchData, setSearchData] = useState([])
    const [isSearchLastPage, setIsSearchLastPage] = useState(false)

    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])

    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(20000)
    const [price, setPrice] = useState([minValue, maxValue])
    const [segments, setSegments] = useState(0)
    const [minMaxRange, setMinMaxRange] = useState([0, 0])
    const [priceRange, setPriceRange] = useState([0, 0])
    const [checkFbs, setCheckFbs] = useState(false)

    const [saleMetrics, setSaleMetrics] = useState([])
    const [goodsMetrics, setGoodsMetrics] = useState([])
    const [statMetrics, setStatMetrics] = useState([])
    const [metricsLoading, setMetricsLoading] = useState(false)
    const [showDropBox, setShowDropBox] = useState(true)
    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
    const [selectedItems, setSelectedItems] = useState([])
    const [choisePrice, setChoisePrice] = useState(false)


    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [selectedPeriod, setSelectedPeriod] = useState(options_period[0])

    const [tableMetrics, setTableMetrics] = useState([])
    const [tableMetric, setTableMetric] = useState('IDC')
    const [showDropBox2, setShowDropBox2] = useState(false)

    const [setChartData2, chartData2, setChartFuncs2] = useChartData()
    const { setChartLoading2, resetChart2 } = setChartFuncs2

    const [setChartData, chartData, setChartFuncs] = useChartData()
    const { setChartLoading, resetChart } = setChartFuncs

    const [isReverse, setIsReverse] = useState(false)



    const onHandleSliderChange = (minMax) => {
        setPrice(minMax)
    }

    const resetTable = () => {
        paginatorRef.current?.reset()
        paginatorRef.current?.setTotal(0)
        // setCheckedProducts([])
        setFetchedData([])
        setHeaders([])
        setSort({})
        setFilters([])
    }

    const fetchTableData = (params, abortController = new AbortController()) => {
        if (pageValue && (searchValue?.length >= 3 || pageValue === 'top_keywords')) {
            let sort
            let filtersParam

            if (params) {
                [sort, filtersParam] = params
            }


            setLoading(true)


            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/ecomru/keywords/search_requests/${pageValue}/table/`
            } else {
                url = `${Andrey2Http}/api/v1/ecomru/keywords/search_requests/${pageValue}/table/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]


            const priceData = pageValue == 'price_segment'
                ?
                {
                    min_price: price[0],
                    max_price: price[1],
                    number_of_segments: segments
                }
                : {}


            const body = JSON.stringify({
                date_from,
                date_to,
                page: paginatorRef.current?.page,
                limit: paginatorRef.current?.limit,
                filters: filtersParam,
                fbofbs_filter: checkFbs,
                sort_name: sort ? sort[0] : 'Сред. запросов в неделю',
                sort_order: sort ? sort[1] : 'DESC',
                search_request_filter: searchValue,
                reverse: isReverse,
                ...priceData
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    if (!Array.isArray(json)) {
                        setTable(json)
                        setTableUrl(url)
                        setTableBody(body)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchChartData = (abortController = new AbortController()) => {

        if (pageValue ) {
            setLoading(true)

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/search_requests/${pageValue}/graph/`
            } else {
                url = `${AndreySkuHttp}/api/v1/ecomru/keywords/search_requests/${pageValue}/graph/`
            }

            let [date_from, date_to] = date;
            date_from = new Date(date_from + 86401000)
                ?.toISOString()
                ?.split('T')?.[0];
            date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

            let categoryBody = pageValue === 'queries' ? '' : { query: [searchValue] }
            let categoryBodyDays = pageValue === 'days' ? { search_request_filter: [searchValue] } : ''
            
            const metricName = { metric_name: tableMetric }
            let searchId

            const id_arr = selectedItems?.map(el => {
                if (pageValue === 'queries' && el?.['Поисковой запрос']) {
                    if (Array.isArray(el?.['Поисковой запрос'])) {
                        const [img, name] = el?.['Поисковой запрос']
                        return el?.['Поисковой запрос'][0]
                    }
                }
                if (pageValue === 'goods') {
                    const [img, name, category, brand, nm_id] = el?.['Товар']
                    return nm_id
                }
                if (pageValue === 'brands') {
                    return el?.brand_id
                }
                if (pageValue === 'sellers') {
                    return el?.seller_id
                }
                if (pageValue === 'category') {
                    return el?.category_id
                }
            })



            if (pageValue === 'goods') {
                searchId = { nm_id: id_arr }
            }
            if (pageValue === 'brands') {
                searchId = { brand_id: id_arr }
            }
            if (pageValue === 'sellers') {
                searchId = { seller_id: id_arr }
            }
            if (pageValue === 'queries') {
                searchId = { query: id_arr }
            }
            if (pageValue === 'category') {
                searchId = { category_id: id_arr }
            }

            const priceData = pageValue == 'price_segment'
            ?
            {
                min_price: choisePrice[0],
                max_price: choisePrice[1],
            }
            : {}
            
            const body = JSON.stringify({
                ...categoryBody,
                ...categoryBodyDays,
                date_from,
                date_to,
                fbofbs_filter: checkFbs,
                // page: paginatorRef.current.page,
                // limit: paginatorRef.current.limit,
                ...metricName,
                // initial: !(chartData?.selectedLegend?.length > 0),
                metrics: [],
                ...searchId,
                ...priceData
            })

            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setChartData2(json)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchChartMetrics = (abortController = new AbortController()) => {
        if (pageValue) {
            setMetricsLoading(true)

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/search_requests/${pageValue}/metric_list/`
            } else {
                url = `${AndreySkuHttp}/api/v1/ecomru/keywords/search_requests/${pageValue}/metric_list/`
            }


            fetch(url, { method: 'GET', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setTableMetrics(json)
                    setTableMetric(json[0]?.value)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setMetricsLoading(false)
                })
        }
    }


    const fetchMetrics = (abortController = new AbortController()) => {
        if (pageValue && (searchValue?.length >= 3)) {
            setMetricsLoading(true)

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/ecomru/keywords/search_requests/summary/metrics_block/`
            } else {
                url = `${Andrey2Http}/api/v1/ecomru/keywords/search_requests/summary/metrics_block/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]


            const body = JSON.stringify({
                date_from,
                date_to,
                search_request_filter: searchValue,
                fbofbs_filter: checkFbs,
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    if (Array.isArray(json)) {
                        const [sale, stat, good] = json
                        setSaleMetrics(sale)
                        setStatMetrics(stat)
                        setGoodsMetrics(good)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setMetricsLoading(false)
                })
        }
    }

    const charts_arr = ['general_metrics', 'top30', 'popularity']

    const fetchChart = (name) => {
        if (pageValue && (searchValue?.length >= 3)) {
            setChartLoading(true)

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/ecomru/keywords/search_requests/summary/metrics_block/graph/`
            } else {
                url = `${Andrey2Http}/api/v1/ecomru/keywords/search_requests/summary/metrics_block/graph/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]


            const body = JSON.stringify({
                search_request_filter: searchValue,
                fbofbs_filter: checkFbs,
                date_from,
                date_to,
                limit: 10,
                page: 1,
                initial: !(chartData?.legend?.length > 0),
                metrics: chartData?.selectedLegend,
            })


            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setChartData(json)
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setChartLoading(false)
                })
        }
    }


    const fetchSearchValue = ([search, page = 1]) => {
        if (search?.length >= 3) {
            setLoading(true)


            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/ecomru/keywords/search_requests/search_string/`
            } else {
                url = `${Andrey2Http}/api/v1/ecomru/keywords/search_requests/search_string/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                page,
                limit: 15,
                search_filter: search
            })


            fetch(url, { body, method: 'POST', headers })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    let result = []
                    if (json?.Search_request) {
                        result = json?.Search_request.map(({ search_request_name }, ind) => {
                            return ({ label: search_request_name, value: search_request_name })
                        }
                        )
                    }
                    setSearchData(result);
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => setLoading(false))
        }
    }

    const fetchMinMaxPrice = () => {
        if (searchValue.length >= 3) {

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/ecomru/keywords/search_requests/price_range/`
            } else {
                url = `${Andrey2Http}/api/v1/ecomru/keywords/search_requests/price_range/`
            }

            let [date_from, date_to] = date
            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                search_request_filter: searchValue,
                fbofbs_filter: checkFbs,
                date_from,
                date_to,
            })

            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    if (json) {
                        let { min_price, max_price } = json
                        setMinMaxRange([min_price, max_price])
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const onActionHandler = (el) => {
        setChoisePrice([el[0].min_price, el[0].max_price])
        const [dataObj, targetData, targetColumn] = el

        if (targetColumn === 'Поисковой запрос') {
            window.open(`/analysis-search-query/summary?query=${targetData}`, '_blank')
        }

        if (targetColumn === 'Категория') {
            window.open(`/analysis-by-category/products?query=${targetData}`, '_blank')
        }

        if (targetColumn === 'Артикул') {
            window.open(`/analysis-by-SKU/position?query=${targetData}`, '_blank')
        }

        if (targetColumn === 'Бренд') {
            window.open(`/analysis-by-brand/requests?query=${targetData}`, '_blank')
        }

        if (targetColumn === 'Продавец') {
            window.open(`/analysis-by-sellers/queries?query=${targetData}`, '_blank')
        }
    }

    useEffect(() => {
        setPageValue(activeTab)
        const query = searchParams.get('query')
        if (query) {
            setSearch({ label: query, value: query })
        }
    }, [activeTab])

    useEffect(() => {
        resetTable()
        setChartData([])
        setChartData2([])
        setSelectedItems([])
        const abortController = new AbortController()
        if (pageValue !== 'price_segment') {
            fetchTableData([], abortController)
        }

        if (pageValue !== 'summary' & pageValue !== 'top_keywords') {
            fetchChartData()
            fetchChartMetrics()
        }

        if (pageValue === 'summary') {
            // charts_arr.map((name) => fetchChart(name, abortController))
            fetchMetrics(abortController)
        }

        if (pageValue === 'price_segment') {
            fetchMinMaxPrice()
        }
        return () => abortController.abort()
    }, [pageValue, date, searchValue, checkFbs, isReverse, selectedSpace])

    useEffect(() => {
        if (pageValue == 'goods'
            || pageValue == 'category'
            || pageValue == 'brands'
            || pageValue == 'sellers'
            || pageValue == 'queries'
        ) {
            if (selectedItems?.length > 0) {
                fetchChartData()
            }
        }

        if (pageValue == 'days') {
            fetchChartData()
        }

        if (pageValue == 'price_segment') {
            if (choisePrice?.length > 0) {
                fetchChartData()
            }
        }
    }, [selectedItems, date, pageValue, search, tableMetric, choisePrice, selectedSpace])

    useEffect(() => {
        resetChart()
    }, [pageValue]);

    return (
        <div className='analytics-search-query'>
            <div className='content-1320'>
                <h1 className='title_main'>По поисковому запросу</h1>
                <div className='toolbar-top'>
                    <SearchComponent
                        fetchData={e => {
                            // const abortController = new AbortController()

                            // resetTable()
                            // fetchTableData(e, abortController)

                            // return () => abortController.abort()
                        }}
                        setSearchValue={setSearchValue}
                        fetchCallback={fetchSearchValue}
                        value={search}
                        data={searchData}
                        searchValue={searchValue}
                        isSearchLastPage={isSearchLastPage}
                        errText='Неверный запрос или слово'
                        placeholder='Введите поисковой запрос или слово'
                    />
                    <div className='range-group-btn-and-range-box'>
                        <TooltipLightBlue
                            text={<p className='text_tooltip'>FBO <span>(продажи со склада площадки)</span> по умолчанию.
                                <br /><br />
                                Нажмите на переключатель чтобы увидеть FBS <span>(продажи со склада продавца)</span>
                            </p>}
                            top='32px'
                            left='0px'
                            maxWidth='110px'
                            child={
                                <label className='checkbox-container'>
                                    <CheckboxToggleBlue
                                        value={checkFbs}
                                        onChange={(e) => setCheckFbs(!checkFbs)}
                                    />
                                    <p className='text_label'>FBS</p>
                                </label>
                            }
                        />

                        <RangeBox setDate={e => setDate(e)} />
                    </div>
                </div>

                <Tabs tabs={btn_tab} pageValue={pageValue} />

                {pageValue == 'summary' &&
                    <div className='summary-content'>
                        <div className='card-content'>
                            <div className='card-content__item'>
                                <div className='card-content__header'>
                                    <img src={trend_icon} />
                                    <p className='text'>Продажи, выручка</p>
                                </div>
                                <div className='card-content__content'>
                                    {saleMetrics?.map((e, i) => {
                                        const title = e?.labels?.title
                                        const data = e?.data
                                        const unit = e?.unit
                                        return <CardStatistics
                                            key={i + title}
                                            title={title}
                                            value={data}
                                            units={unit}
                                        />
                                    })}
                                </div>
                            </div>

                            <div className='card-content__item'>
                                <div className='card-content__header'>
                                    <img src={shopping_basket_icon} />
                                    <p className='text'>Товары, бренды, продавцы</p>
                                </div>
                                <div className='card-content__content'>
                                    {goodsMetrics?.map((e, i) => {
                                        const title = e?.labels?.title
                                        const data = e?.data
                                        const unit = e?.unit
                                        return <CardStatistics
                                            key={i + title}
                                            title={title}
                                            value={data}
                                            units={unit}
                                        />
                                    })}
                                </div>
                            </div>

                            <div className='card-content__item'>
                                <div className='card-content__header'>
                                    <img src={statistic_icon} />
                                    <p className='text'>Статистика</p>
                                </div>
                                <div className='card-content__content'>
                                    {statMetrics?.map((e, i) => {
                                        const title = e?.labels?.title
                                        const data = e?.data
                                        const unit = e?.unit
                                        return <CardStatistics
                                            key={i + title}
                                            title={title}
                                            value={data}
                                            units={unit}
                                        />
                                    })}
                                </div>
                            </div>

                            {/* {
                                <DropContainer
                                    lang='en'
                                    showDropBox={showDropBox}
                                    setShowDropBox={(e) => setShowDropBox(e)}
                                >
                                    <div className='charts-container'>
                                        <LineChart
                                            legendPosition='top'
                                            {...chartData}
                                        />
                                    </div>
                                </DropContainer>
                            } */}

                        </div>

                        {pageValue === 'summary' &&
                            <div className='card-main-container'>
                                <div className='card-group'>
                                    <div className='card-group__content'>
                                        {/* {metrics?.map((dataObj, i) => {
                                            try {
                                                const { data, labels: { header, title } } = dataObj
                                                return <CardStatistics
                                                    key={i}
                                                    title={header}
                                                    value={data}
                                                    loading={metricsLoading}
                                                />
                                            } catch (error) {

                                            }
                                        })} */}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

            </div>

            {/* <Wordclouds /> */}

            {pageValue == 'price_segment' && <SliderRange
                minValue={minMaxRange[0]}
                maxValue={minMaxRange[1]}
                sliderRange={priceRange}
                setSegments={e => setSegments(e)}
                segments={segments}
                range={priceRange}
                minMax
                setState={e => onHandleSliderChange(e)}
                callbackHandler={e => {
                    fetchTableData()
                }}
            />}

            {
                (pageValue !== 'summary' & pageValue !== 'top_keywords') ?
                    <DropContainer
                        lang='en'
                        showDropBox={showDropBox2}
                        setShowDropBox={(e) => setShowDropBox2(e)}
                    >
                        <div className='charts-container'>
                            <LineChart
                                legendPosition='top'
                                {...chartData2}
                            />
                        </div>
                    </DropContainer>
                    :
                    null
            }

            {pageValue != 'summary' &&
                <TableBox
                    onActionInfo
                    {...tableProps}
                    paginator={true}
                    ref={paginatorRef}
                    onAction={onActionHandler}
                    onCheck={e => setSelectedItems(e)}
                    onUncheckAllDependence={[pageValue, search]}
                    fetchCallback={e => fetchTableData(e)}
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    child={
                        <div style={{ display: 'flex', gap: '.5em', flexDirection: 'column', boxSizing: 'border-box', padding: '.5em 0 1em' }}>
                            {
                                pageValue === 'days' &&
                                <div>
                                    <p style={{ display: 'flex', gap: '.5em' }}>
                                        Перевернуть
                                        <Checkbox value={isReverse} onChange={checked => setIsReverse(checked)} />
                                    </p>
                                </div>
                            }
                            {
                                (pageValue !== 'summary' & pageValue !== 'top_keywords') ?
                                    <div style={{ width: '90%', overflow: 'overlay', height: '100%', display: 'flex', flexWrap: 'wrap', gap: '.5em', justifyContent: 'space-between' }}>
                                        {tableMetrics.map(({ label, value }) =>
                                            <div
                                                style={{
                                                    width: '200px', border: '1px solid lightgrey', borderRadius: '10px', boxSizing: 'border-box',
                                                    padding: '1em', backgroundColor: tableMetric === value ? '#d5d5f9' : '', cursor: 'pointer',
                                                    fontSize: '12px'
                                                }}
                                                onClick={e => setTableMetric(value)}
                                            >
                                                {label}</div>)}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    }
                />}
        </div>
    )
}

export { AnalysisSearchQuery }