import React from 'react'
import { ChatMessagePage } from '../../../pages/ChatMessage/ChatMessagePage'

const ChatModal = ({setIsModal}) => {

  return (
    <div className='blackout' 
      onClick={e => setIsModal(false)}
    >
        <ChatMessagePage setIsModal={e => setIsModal(e)} />
    </div>
  )
}

export default ChatModal