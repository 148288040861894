import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { ExecutorPlaceholder } from "../../components/OptionsExecutor/OptionsExecutor";
import { ModalCreatingNewSpace } from "../../components/Modal/ModalCreatingNewSpace/ModalCreatingNewSpace";
import customHeaders, { getSpace } from "../../common/headers";
import { RavshanHttps, RavshanHttp } from "../../fetchUrls";
import useGeneralStore from "../../store/general";
import { useState, useEffect } from "react";
import user_icon from './img/bx-user.svg.svg'
import user_circle_icon from './img/bx-user-circle.svg.svg'
import { TabsGreen } from "../../components/TabsGreen/TabsGreen";
import './SpacePage.scss';

const executors_arr = [
    {
        "id": 309,
        "name": "Vladimir Ignatov",
        "color": "rgb(121, 243, 110)",
        "login": "v.ignatov@gtcom.io"
    },
    {
        "id": 512,
        "name": "Roman",
        "color": "rgb(90, 92, 153)",
        "login": "r.shamyan@gtcom.io"
    },
    {
        "id": 517,
        "name": "Ravshan Sherzanov",
        "color": "rgb(121, 243, 110)",
        "login": "r.sherzanov@gtcom.io"
    },
    {
        "id": 543,
        "name": "o.makarov@gtcom.io",
        "color": "rgb(247, 113, 51)",
        "login": "o.makarov@gtcom.io"
    },
    {
        "id": 438,
        "name": "Елена",
        "color": "rgb(224, 173, 46)",
        "login": "e.naloeva@gtcom.io"
    },
    {
        "id": 518,
        "name": "Evgenii Trusov",
        "color": "rgb(103, 242, 211)",
        "login": "e.trusov@gtcom.io"
    },
    {
        "id": 221,
        "name": "KN",
        "color": "rgb(219, 58, 250)",
        "login": "info1@gtcom.io"
    },
    {
        "id": 263,
        "name": "Андрей",
        "color": "rgb(174, 175, 95)",
        "login": "a.chebunin@gtcom.io"
    },
    {
        "id": 258,
        "name": "Evgenii Shirokov",
        "color": "rgb(122, 49, 98)",
        "login": "e.shirokov@gtcom.io"
    },
    {
        "id": 559,
        "name": "Evgenii Ryabinin",
        "color": "rgb(43, 105, 121)",
        "login": "e.ryabinin@gtcom.io"
    },
    {
        "id": 541,
        "name": "Nikita Zhvalik",
        "color": "rgb(122, 50, 251)",
        "login": "n.zhvalik@gtcom.io"
    },
    {
        "id": 530,
        "name": "i.matov@gtcom.io",
        "color": "rgb(214, 90, 90)",
        "login": "i.matov@gtcom.io"
    },
    {
        "id": 547,
        "name": "j.mirokhin@gtcom.io",
        "color": "rgb(239, 131, 229)",
        "login": "j.mirokhin@gtcom.io"
    },
    {
        "id": 419,
        "name": "e.anurova@gtcom.io",
        "color": "rgb(239, 131, 229)",
        "login": "e.anurova@gtcom.io"
    },
    {
        "id": 253,
        "name": "Artem Nazarov",
        "color": "rgb(45, 106, 44)",
        "login": "a.nazarov@gtcom.io"
    },
    {
        "id": 540,
        "name": "Александр",
        "color": "rgb(97, 72, 209)",
        "login": "a.morozov@gtcom.io"
    },
    {
        "id": 197,
        "name": "Konstantin Nosov",
        "color": "rgb(97, 72, 209)",
        "login": "info@gtcom.io"
    },
    {
        "id": 563,
        "name": "Andrey Dyakonov",
        "color": "rgb(103, 242, 211)",
        "login": "a.dyakonov@gtcom.io"
    },
    {
        "id": 231,
        "name": "Konstantin",
        "color": "rgb(101, 43, 45)",
        "login": "k.lobov@gtcom.io"
    },
    {
        "id": 135,
        "name": "ES",
        "color": "rgb(168, 119, 140)",
        "login": "abc@gmail.com"
    },
    {
        "id": 566,
        "name": "Nikita Malinin",
        "color": "rgb(122, 50, 251)",
        "login": "n.malinin@gtcom.io"
    },
    {
        "id": 546,
        "name": "a.kuzmin@gtcom.io",
        "color": "rgb(83, 86, 83)",
        "login": "a.kuzmin@gtcom.io"
    },
    {
        "id": 564,
        "name": "Roman Safin",
        "color": "rgb(48, 50, 177)",
        "login": "r.safin@gtcom.io"
    },
    {
        "id": 582,
        "name": "a.lenov@gtcom.io",
        "color": "rgb(173, 44, 215)",
        "login": "a.lenov@gtcom.io"
    }
]

const list_space = [
    {
        label: 'Название 1 ffffff fffff fffffffff ffffffffffff ffffffffff fffffffffff',
        executors: executors_arr
    },
    {
        label: 'Название 2',
        executors: executors_arr
    },
    {
        label: 'Название 3',
        executors: executors_arr
    },
    {
        label: 'Название 4',
        executors: executors_arr
    },
    {
        label: 'Название 5',
        executors: executors_arr
    }
]

const btns = [
    { label: 'Все', count: 2, value: 'all', icon: user_icon },
    { label: 'Админ', count: 4, value: 'admin', icon: user_circle_icon },
    { label: 'Сотрудник', count: 4, value: 'employee', icon: user_icon }
]

const arrNames = (arr_names) => {
    return arr_names?.map(({ name, login, color, id }, ind) => {
        return <ExecutorPlaceholder
            key={name + id + '' + color + login + ind}
            email={login}
            name={name}
            color={color}
        />
    })
}

const client_id = localStorage.getItem('client_id');

const CardSpase = ({
    spaceData,
    allExecutors,
    setIsEditSpace,
    setIsModal,
    deleteFunc,
    refreshFetch,
    setCurrentSpace
}, ind) => {

    const is_admin = spaceData?.is_admin

    return (
        <div className='card-space' key={spaceData.name + ind}
            onClick={(e) => {
                setCurrentSpace(spaceData)
                setIsEditSpace(true)
                setIsModal(true)
            }}
        >
            <div className='card-space__header'>
                <img src={is_admin ? user_circle_icon : user_icon} />
                <h3 className='card-space__title'>{spaceData.name}</h3>
            </div>
            <div className='card-space__content'>
                {arrNames(spaceData.employees, '')?.splice(0, 6)}
                {(spaceData.employees?.length > 6) && <p className='sum_executor'>{'+' + (spaceData.employees?.length - 6)}</p>}
            </div>
            {
                is_admin &&
                <ButtonBasic
                    red
                    width='100px'
                    size='16px'
                    text='Удалить'
                    style={{
                        margin: '8px',
                        marginTop: 'auto'

                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteFunc(spaceData.id)
                        setTimeout(() => refreshFetch(), 500)
                    }}
                />
            }
        </div>
    )
}

const SpacePage = ({
    setIsModalCreatingNewSpace,
    setIsEditSpace,
    isModalCreatingNewSpace,
    fetchGetSpace, spaceList,
    setCurrentSpace,
    currentSpace }) => {

    const [isModal, setIsModal] = useState(false)
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}    
    const [status, setStatus] = useState(btns?.[0]?.value)

    // Удаление пространства
    const fetchDeleteSpace = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/space/`
        } else {
            url = `${RavshanHttps}/api/space/`
        }

        const body = JSON.stringify({
            id: id
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => res.json)
            .then((json) => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    const currentSpaceList = () => {
        let current_list

        if (status == 'admin') {
            current_list = spaceList.filter(({ author }) => {
                console.log(author?.id, client_id)
                return author?.id == client_id
            })
            return current_list
        }

        if (status == 'employee') {
            current_list = spaceList.filter(({ author }) => author?.id != client_id)
            return current_list
        }

        if (status == 'all') {
            return spaceList
        }

    }
    
    useEffect(() => {
        fetchGetSpace()
    }, [selectedSpace]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchGetSpace()
        }, 500)

        return () => clearTimeout(timeoutId);
    }, [isModalCreatingNewSpace]);

    return (
        <>
            <div className='space-page'>
                <header className='space-page__header'>
                    <h1 className='space-page__title'>Пространства</h1>
                    <ButtonBasic
                        green
                        text='Добавить пространство'
                        width='210px'
                        onClick={(e) => setIsModalCreatingNewSpace(true)}
                    />
                </header>

                <TabsGreen
                    btns={btns}
                    currentBtn={status}
                    setCurrentBtn={(e) => setStatus(e)}
                    checkValue={true}
                />

                <div className='space-page__content'>
                    {currentSpaceList()?.map((el) => {                        
                        return <CardSpase
                            spaceData={el}
                            allExecutors={[]}
                            setCurrentSpace={(e) => setCurrentSpace(e)}
                            setIsEditSpace={(e) => setIsEditSpace(e)}
                            setIsModal={(e) => setIsModalCreatingNewSpace(e)}
                            deleteFunc={(e) => fetchDeleteSpace(e)}
                            refreshFetch={(e) => fetchGetSpace(e)}
                        />
                    })}
                </div>
            </div>
        </>

    )
}

export { SpacePage }