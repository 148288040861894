import customHeaders, {getSpace} from "../../../common/headers";
import { KonstantinMasterHttps, KonstantinMasterHttp } from "../../../fetchUrls";
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { useState, useEffect, useRef } from 'react';
import useGeneralStore from "../../../store/general";
import './ModalUnansweredFeedback.scss'

const ModalUnansweredFeedback = ({ 
    setIsModal, setIsAllUnanswered,  
    tableBody, totalCounter = {feedbacks: 0, questions: 0},
    date
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [loading, setLoading] = useState(false)
    const [sort, setSort] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    const [column_size, setColumnSize] = useState([])
    const [fetchedData, setFetchedData] = useState([])
    const paginatorRef = useRef()
   const [tableUrl, setTableUrl] = useState('')
    const [tableBody1, setTableBody1] = useState(null)

    const fetchTableData = (params, resetPage = false, allUnswered = true) => {
        let sort;
        let filtersParam = [];

        if (params) {
            [sort, filtersParam] = params
        }

        setFetchedData([])
        let url
        if (!window.location.hostname.match('localhost')) {
            if(allUnswered){
                url = `${KonstantinMasterHttps}/feedbacks-and-questions/tables/unanswered-feedbacks-questions${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
            }
        } else {
            if(allUnswered){
                url = `${KonstantinMasterHttp}/feedbacks-and-questions/tables/unanswered-feedbacks-questions${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
            }
        }

        const [date_from, date_to] = date
        const body = JSON.stringify({
            page: paginatorRef.current?.page,
            limit: paginatorRef.current?.limit,
            filters: filtersParam,
            api_id_list: ['all'],
            category_id_list: ['all'],
            brand_id_list: ['all'],
            product_valuation_list: ['all'],
            status: 'Все',
            date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
            date_to: new Date(date_to)?.toISOString().split('T')?.[0]
        })

 
        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                const { data, labels, sort, column_size, total } = json
                if (sort) {
                    setSort(sort)
                }
                if (labels) setTableHeaders(labels)
                if (column_size) setColumnSize(column_size)
                if (total) paginatorRef.current?.setTotal(total)
                if (data) {
                    if (data?.length === 0) setFetchedData([])
                    setFetchedData(data)
                }
                setTableUrl(url) 
                setTableBody1(body)
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
    }


    useEffect(() => {
        fetchTableData([], true, true)
    }, [date, tableBody, selectedSpace])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
                setIsAllUnanswered(false)
            }
        }}>
            <div className='modal-unanswered-Feedbackmodal'>
                <h1 className='title'>Всего неотвеченных отзывов <span>{totalCounter?.feedbacks}</span> и вопросов <span>{totalCounter?.questions}</span></h1>
                <TableBox
                    loading={loading}
                    fetchedData={fetchedData}
                    headers={tableHeaders}
                    columnSize={column_size}
                    sort={sort}
                    ref={paginatorRef}
                    fetchCallback={e => fetchTableData(e)}
                    maxHeight='350px'
                    hideHeader
                    hideFilters
                    tableUrl={tableUrl}
                    tableBody1={tableBody1}
                />
            </div>
        </div>
    )
}

export { ModalUnansweredFeedback }