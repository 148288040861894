import { useEffect, useState } from 'react'
import mock_img from './img/mock_icon.svg'
import { OlegHttps, OlegPricesAndStocksHttps } from '../../../../fetchUrls'
import { fromSnakeCase } from '../../../../common/mappers'
import customHeaders, {getSpace} from '../../../../common/headers'
import eye from './img/eye_icon.svg'
import drr from './img/drr_icon.svg'
import max from './img/max_icon.svg'
import arrows from './img/arrows_icon.svg'
import traffic from './img/traffic_icon.svg'
import useGeneralStore from '../../../../store/general'
import './ModalChoiceOfStrategy.scss'

const ModalChoiceOfStrategy = ({ setIsModal, setIsModalStrategyParameters, activate }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [strategies, setStrategies] = useState([])
    const [selectedStrategy, setSelectedStrategy] = useState(null)
    const [loading, setLoading] = useState(false)
    const arrIcons = [eye, traffic, max, drr, arrows]

    const fetchStrategies = () => {
        setLoading(true)
        const url = `${OlegHttps}/api/v1/tools/stock_management/strategy/all`
        fetch(url, { headers })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error('bad strategies response')
                }
            })
            .then(json => {
                const camelCased = json.map(s => fromSnakeCase(s))
                const active = camelCased.find(strategy => strategy.isActive)
                if (active) {
                    activate(active)
                    setSelectedStrategy(active)
                }
                setStrategies(camelCased)
            })
            .finally(() => setLoading(false))
    }

    const handleChooseStrategy = () => {
        setLoading(true)
        // if (selectedStrategy && selectedStrategy.strategyName !== 'Максимум трафика' && setIsModalStrategyParameters) {
        //     setIsModalStrategyParameters(true)
        // }
        const url = `${OlegPricesAndStocksHttps}/stock/ecom/strategy/activate`
        const body = JSON.stringify({
            strategy: selectedStrategy.strategyId
        })
        fetch(url, { method: 'POST', body, headers })
            .then(response => {
                if (response.status === 200) {
                    activate(selectedStrategy)
                    setIsModal(false)
                } else {
                    throw new Error('bad activate strategy request')
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchStrategies()
    }, [selectedSpace])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-choice-of-strategy'>
                <h2 className='title'>Выберите стратегию</h2>
                <div className='modal-choice-of-strategy__content'>
                    {
                        strategies.map((strategy, ind) => {
                            const { description, strategyId } = strategy
                            return (
                                <div
                                className={selectedStrategy?.strategyId === strategyId ? 'strategy-item_active' : 'strategy-item'} key={strategyId}
                                    onClick={(e) => setSelectedStrategy(strategy)}
                                >
                                    <img src={arrIcons[ind]} />
                                    <p className='text'>{description}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <button disabled={loading || !selectedStrategy} className='btn__blue'onClick={(e) => handleChooseStrategy()}>Выбрать</button>
            </div>
        </div>
    );
}

export { ModalChoiceOfStrategy };