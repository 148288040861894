import customHeaders, {getSpace} from '../../common/headers';
import { useNavigate } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import { Andrey2Http, Andrey2Https } from '../../fetchUrls';
import { useState, useEffect, useRef, useMemo } from 'react'
import { RangeBox, TableBox, SearchGlobalInput, DropDownSelector, CheckboxToggleBlue, InputDinamycPlaceholder, ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import filter_icon from './img/filter_icon.svg'
import { ModalFilters } from '../Modal/ModalFilters/ModalFilters';
import { ReactComponent as FixIcon } from './img/fix_icon.svg'
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack'
import { ModalNiche } from '../Modal/ModalNiche/ModalNiche';
import close_icon from './img/close_icon.svg'
import info_icon from './img/info_icon.svg'
import useGeneralStore from '../../store/general';
import "./NicheSelection.scss"

const sales_type = [
    { value: 5, label: 'Все' },
    { value: 1, label: 'FBO' },
    { value: 2, label: 'FBS' },
    { value: 3, label: 'Из-за рубежа' },
    { value: 4, label: 'RFBS' },
]


export const FilterFromTo = ({ label, value, unit, fixbtn = false, setState, state }) => {

    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)


    useEffect(() => {
        if (typeof setState === 'function') {
            setState(prev => ({
                ...prev,
                [value?.[0]]: from,
                [value?.[1]]: to,
            }))
        }
    }, [from, to])

    useEffect(() => {
        if (from !== state?.[value?.[0]]) {
            setFrom(state?.[value?.[0]])
        }
        if (to !== state?.[value?.[1]]) {
            setTo(state?.[value?.[1]])
        }
    }, [state])

    return <div className='filter-item' key={label + unit}>
        <div className='filter-item__content'>
            <p className='filter-item__placeholder'>{label}</p>
            <div className='input-group'>
                <InputDinamycPlaceholder
                    onChange={(e) => setFrom(e)}
                    // setValue={(e) => setFrom(e)}
                    changeValue={state?.[value?.[0]]}
                    placeholder='from'
                    value={from}
                    size='s'
                    style={{
                        maxWidth: '325px'
                    }}
                    showClearIndicator
                />

                <InputDinamycPlaceholder
                    onChange={(e) => setTo(e)}
                    placeholder='to'
                    changeValue={state?.[value?.[1]]}
                    value={to}
                    size='s'
                    // setValue={(e) => setTo(e)}
                    showClearIndicator
                />

                {unit && <p className='text'>{unit}</p>}
            </div>
        </div>

        {fixbtn && <TooltipBlack
            child={<button className='fix-btn'>
                <FixIcon />
            </button>}
            text='Подсказка'
            top='140%'
            right='0'
        />}
    </div>
}

const NicheSelection = ({ setIsModalKnowledgeBase }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
 
    const [search, setSearch] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [searchData, setSearchData] = useState([])

    const [filterList, setFilterList] = useState([])
    const [filterListObj, setFilterListObj] = useState({})

    const [isModal, setIsModal] = useState(false)

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [checkFbs, setCheckFbs] = useState(false)
    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
    const [query, setQuery] = useState(null)
    const [mp, setMp] = useState('ozon')
    const [selectedSalesType, setSelectedSalesType] = useState(sales_type?.at(0))
    const [selectedFilters, setSelectedFilters] = useState([])

    const [isModalAnalisys, setIsModalAnalisys] = useState(false)

    const fetchSearch = ([search, page = 1], abortController = new AbortController()) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'}/niche_search/category/search_string/`
        } else {
            url = `${Andrey2Https}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'}/niche_search/category/search_string/`
        }

        const body = JSON.stringify({
            page: page,
            limit: 10,
            search_text: search
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setSearchData({ category: Object?.values(json)?.[0] })
            })
            .catch(err => {
                console.error(err)
            })
    }

    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort
        let filtersParam

        if (params) {
            [sort, filtersParam] = params
        }
        setLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'}/niche_search/category/table/`
        } else {
            url = `${Andrey2Http}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'}/niche_search/category/table/`
        }

        let [date_from, date_to] = date
        date_from = new Date(date_from + 86401000)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        let filterObj = {}
        Object.keys(filterListObj)?.forEach(el => {
            if (filterListObj?.[el]) {
                filterObj[el] = +filterListObj?.[el]
            }
        })


        const params_common = mp === 'wb' ?
            {
                category_id_filter: search,
                fbofbs_filter: checkFbs,
            }
            :
            {
                working_scheme_filter: selectedSalesType?.value,
                category_name_filter: search,
            }

        const body = JSON.stringify({
            limit: paginatorRef.current?.limit,
            page: paginatorRef.current?.page,
            // date_from,
            // date_to,
            ...params_common,
            sort_name: sort?.[0],
            sort_order: sort?.[1],
            ...filterObj
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw Error(JSON.stringify(err))
                }
            })
            .then(json => {
                if (!Array.isArray(json)) {
                    setTable(json)
                    setTableUrl(url)
                    setTableBody(body)
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    const fetchFilters = (abortController = new AbortController()) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v1/ecomru/competitors/platform_analysis/niche_search/category/metric_list/`
        } else {
            url = `${Andrey2Http}/api/v1/ecomru/competitors/platform_analysis/niche_search/category/metric_list/`
        }

        fetch(url, { method: 'GET', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setFilterList(json)
                setSelectedFilters(json.slice(0, 4))
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    useEffect(() => {
        const params = new URL(document.location).searchParams
        let query = params.get("mp")
        setQuery(query)
        setMp(query)

    }, [new URL(document.location)]);

    useEffect(() => {
        const abortController = new AbortController()
        fetchTableData([], abortController)
        return () => abortController.abort()
    }, [date, checkFbs, search, selectedSalesType, selectedSpace])

    useEffect(() => {
        const abortController = new AbortController()
        fetchFilters(abortController)
        return () => abortController.abort()
    }, [selectedSpace])

    const onRedirect = e => {
        const [__, link] = e
        window.open(`/niche-search/dashboard?query=${link}`, '_blank')
    }

    const resetTable = () => {
        paginatorRef.current.reset()
        paginatorRef.current.setTotal(0)
        setSort({})
        setHeaders([])
        setFetchedData([])
    }

    const filtersArr = useMemo(() => {
        const arr_filters = selectedFilters.map((el) => el.label)

        const all_filters = filterList?.map(({ label, value, unit }) => {
            if (arr_filters.includes(label)) {
                return <FilterFromTo
                    key={label + value}
                    label={label}
                    unit={unit}
                    value={value}
                    setState={setFilterListObj}
                    state={filterListObj}
                />
            }
        })

        return all_filters

    }, [filterList, selectedFilters])

    const handleModal = () => {
        fetchTableData()
        setFilterList([...filterList])
        setIsModal(false)
    }

    const handleClear = () => {
        let obj = {}
        const keys = Object.keys(filterListObj)
        keys?.map(el => obj[el] = '')
        setFilterListObj({ ...obj })
        setFetchedData([])
        setTimeout(() => {
            setFilterList([...filterList])
        }, 100)
    }

    const [currentCategory, setCurrentCategory] = useState()

    const onAction = (e) => {
        if (mp == 'ozon') setCurrentCategory(e?.category_name_filter)
        if (mp == 'wb') setCurrentCategory(e?.category_id)
    }

    useEffect(() => {
        setSearch('')
        setSearchValue('')
        setSearchData([])
        resetTable()
    }, [mp]);

 

    // useEffect(() => {
    //     navigate('summary' + `?mp=${mp}`)
    // }, [isModalAnalisys]);

    return (
        <>
            {isModalAnalisys && <ModalNiche
                mp={mp}
                setMp={(e) => setMp(e)}
                checkFbs={checkFbs}
                date={date}
                currentCategory={currentCategory}
                selectedSalesType={selectedSalesType}
                setIsModal={(e) => setIsModalAnalisys(e)}
            />}

            {isModal &&
                <ModalFilters
                    filterList={filterList} state={filterListObj} setState={e => setFilterListObj(e)} setIsModal={() => handleModal()} onClick={() => handleModal()}
                />
            }

            <div className='niche-selection'>
                <div className='niche-selection__content'>
                    <div className='niche-selection__header'>
                        <h1 className='title_main'>Поиск ниши</h1>
                        <button className='btn_info'>Как это работает? <img src={info_icon} /></button>
                    </div>

                    <div className='toolbar-top'>
                        <SearchGlobalInput
                            {...searchData}
                            fetchSearch={e => {
                                const abortController = new AbortController()
                                fetchSearch(e, abortController)
                                return () => abortController.abort()
                            }}
                            setSearch={({ category, extra }) => {
                                if (extra) {
                                    setSearch(extra)
                                } else {
                                    setSearch(category)
                                }
                            }}
                            value={searchValue}
                            setSearchData={e => setSearchData(e)}
                        />

                        {mp == 'wb' &&
                            <TooltipLightBlue
                                text={<p className='text_tooltip'>FBO <span>(продажи со склада площадки)</span> по умолчанию.
                                    <br /><br />
                                    Нажмите на переключатель чтобы увидеть FBS <span>(продажи со склада продавца)</span>
                                </p>}
                                top='32px'
                                left='0px'
                                maxWidth='110px'
                                child={
                                    <label className='checkbox-container'>
                                        <CheckboxToggleBlue
                                            value={checkFbs}
                                            onChange={(e) => setCheckFbs(!checkFbs)}
                                        />
                                        <p className='text_label'>FBS</p>
                                    </label>
                                }
                            />}

                        {mp == 'ozon' &&
                            <DropDownSelector
                                options_prop={sales_type}
                                state={selectedSalesType}
                                setState={e => setSelectedSalesType(e)}
                                placeholder='Тип продаж'
                            />
                        }

                        {
                            mp == 'wb' &&
                            <RangeBox setDate={e => setDate(e)} />
                        }
                    </div>

                    <div className='toolbar-medium'>
                        <div className='toolbar-medium__header'>
                            <h2 className='toolbar-medium__title'>Фильтры</h2>
                            <DropDownSelector
                                multi
                                options_prop={filterList}
                                state={selectedFilters}
                                setState={e => setSelectedFilters(e)}
                                placeholder='Все фильтры'
                            />
                            <button className='niche-selection__btn-reset'>
                                Очистить все параметры <img src={close_icon} />
                            </button>

                            <ButtonBasic
                                green
                                onClick={(e) => fetchTableData()}
                                text='Применить'
                                width='120px'
                            />
                        </div>

                        <h2 className='toolbar-medium__items'>

                            {filtersArr}
                        </h2>
                    </div>
                    {/* <div className='btn-group_bottom'>
                        <button
                            className='btn-clear'
                            onClick={e => handleClear()}
                        >
                            Очистить все значения <img src={close_icon} />
                        </button>

                        <div className='btn-toolbar'>
                            <ButtonBasic
                                grey
                                width='fit-content'
                                text={<p className='content-btn'> <img src={filter_icon} />Все фильтры</p>}
                                onClick={(e) => setIsModal(true)}
                            />
                            <ButtonBasic
                                green
                                width='fit-content'
                                text='Применить'
                                onClick={e => {
                                    const abortController = new AbortController()
                                    fetchTableData([], abortController)
                                    return () => abortController.abort()
                                }}
                            />
                        </div>

                    </div> */}
                </div>

                <TableBox
                    {...tableProps}
                    paginator={true}
                    ref={paginatorRef}
                    fetchCallback={e => fetchTableData(e)}
                    tableUrl={tableUrl}
                    onAction={(e) => {
                        onAction(e)
                        if (currentCategory) {
                            setIsModalAnalisys(true)
                        }
                    }}
                    tableBody={tableBody}
                    onRedirect={onRedirect}
                />
            </div >
        </>
    )
}

export { NicheSelection }