import { DropDownSelector, ButtonBasic } from "@lk-gtcom/ecomlab-components";
import done_icon from './img/done_icon.svg'
import box_icon from './img/box_icon.svg'
import './ModalContentTransfer.scss'

const ModalContentTransfer = ({
    state,
    setShowModal,
    targetApiData,
    setSelectTargetApi,
    transferApi,
    checkedProducts,
    filterValue,
    setIsModalActive,
    setShowModalDone }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setShowModal(false)
            }
        }}>
            <div className='modal-content-transfer'>
                <img className="img" src={done_icon} />
                <h2 className='title'>Выберите магазин куда хотите перенести товары</h2>
                <DropDownSelector
                    state={state}
                    options_prop={targetApiData}
                    setState={e => setSelectTargetApi(e)}
                    placeholder='Список ваших магазинов'
                />

                <div className='counter-product'>
                    <img src={box_icon} />
                    <p className='text'>{`${checkedProducts?.length ? checkedProducts?.length : filterValue?.length ? filterValue?.length : 0} товара`}</p>
                </div>

                <div className='btn-group'>
                    <ButtonBasic
                        lilac
                        text='Подключить аккаунт'
                        size='56px'
                        width='calc(50% - 6px)'
                        onClick={e => setIsModalActive(true)}
                    />
                    <ButtonBasic
                        violet
                        text='Перенести'
                        size='56px'
                        width='calc(50% - 6px)'
                        onClick={e => {
                            transferApi()
                            setShowModal(false)
                            setShowModalDone(true)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalContentTransfer }