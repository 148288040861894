import idea_icon from './img/idea.svg'
import "./Steps.scss"

const Steps = ({ steps }) => {

    return (
        <div className='steps-component'>
            <div className="img-box">
                <img src={idea_icon} />
            </div>
            <div className='steps-component__content'>
                {steps?.map(({ label, description }, ind) =>
                    <div className="step-item" key={label + ind}>
                        <div className='num-step'>{ind + 1}</div>
                        <div className="step-item__content">
                            <p className="text">{label}</p>
                            <p className="text_grey">{description}</p>
                        </div>
                        <div></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export { Steps }