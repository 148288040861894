import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie'
import { KonstantinAddImgHttps, KonstantinAddImgHttp } from '../../fetchUrls';
import add_icon from './img/add_icon.svg'
import close_icon from './img/close.svg'
import { MoonLoader } from 'react-spinners';
import "./DropZoneProductVideo.scss"

const DropZoneProductVideo = ({ ind, key, arrVideo, setArrVideo }) => {
    const cookies = new Cookies()
    const token = cookies.get('auth')
    const [save, setSave] = useState(null)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(false)

    const fetchPostVideo = (file) => {
        setErr(false)

        const fd = new FormData()
        fd.append('videos', file)
        const url = `${KonstantinAddImgHttps}/clients/videos`

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    setErr(true)
                    throw new Error('bad response')
                }
            })
            .then(json => {
                if (json.videos) {
                    setSave(json.videos[0].url)
                } else setErr(true)
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                setErr(true)
                setLoading(false)
            })

    }


    const handleFileInput = (e) => {
        if (e.target.files.length === 0) {
            e.preventDefault()
            return
        }
        if (e.target.files[0]) {
            fetchPostVideo(e.target.files[0])
        }
    }

    return (
        <div
            key={key}
            className={err ? 'file-box_err' : 'file-box'} onMouseEnter={e => e.currentTarget.setAttribute('hover', true)} onMouseLeave={e => e.currentTarget.setAttribute('hover', false)}>
            {
                <>
                    {
                        loading ?
                            <div className='center' style={{ width: '100%', height: '86px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MoonLoader
                                    size={20}
                                    color="#7b7b7b"
                                    speedMultiplier={0.5}
                                />
                            </div>
                            :
                            ((save || arrVideo[ind]) ?
                                <video className='video' src={save ? save : arrVideo[ind]} />
                                :
                                <img className='icon-add' src={add_icon} />
                            )
                    }
                    <input
                        type='file'
                        // accept='image/png, image/jpg, image/jpeg'
                        multiple={true}
                        className='input-file'
                        onChange={(e) => {
                            setLoading(true)
                            handleFileInput(e)
                        }}
                    />

                    {(save || arrVideo[ind]) && <div className='close-box'>
                        <button className='close-btn'
                            onClick={(e) => {
                                setSave(null)
                                e.stopPropagation()
                                let current = arrVideo
                                current.splice(ind, 1)
                                setArrVideo(current)
                            }}
                        ><img src={close_icon} /></button>
                    </div>}
                </>
            }
        </div>
    )
}

export { DropZoneProductVideo }