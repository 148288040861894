import customHeaders, {getSpace} from '../../common/headers'
import { useState, useEffect } from 'react';
import { Tabs, ButtonBasic } from "@lk-gtcom/ecomlab-components"
import spakler_icon from './img/sparkler_icon.svg'
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { ModalProductImprovement } from '../../components/Modal/ModalProductImprovement/ModalProductImprovement';
import { ProductImprovement_EnhanceVideo } from '../ProductImprovement_EnhanceVideo/ProductImprovement_EnhanceVideo';
import { ProductImprovement_EnhancePhoto } from '../ProductImprovement_EnhancePhoto/ProductImprovement_EnhancePhoto';
import { ProductImprovement_CreateRICHContent } from '../ProductImprovement_CreateRICHContent/ProductImprovement_CreateRICHContent';
import { ProductImprovement_EmojiAddition } from '../ProductImprovement_EmojiAddition/ProductImprovement_EmojiAddition';
import useGeneralStore from '../../store/general';
import "./ProductCardExtended_ProductImprovement.scss"

const btn_tab = [
    {
        label: 'Эмоджи',
        value: 'emoji',
    },
    {
        label: 'Улучшить фото',
        value: 'enhance_photo',
    },
    {
        label: 'Улучшить видео',
        value: 'enhance_video',
    },
    {
        label: 'Создать RICH content',
        value: 'create_RICH_content',
    }
]

const ProductCardExtended_ProductImprovement = ({ }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [isModal, setIsModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [nmid, setNmid] = useState(false)
    const [productId, setProductId] = useState('')

    const [oldDesc, setOldDesc] = useState('')
    const [newDesc, setNewDesc] = useState('')

    const [oldImages, setOldImages] = useState([])
    const [newImages, setNewImages] = useState([])
    const [oldVideo, setOldVideo] = useState([])
    const [newVideo, setNewVideo] = useState([])


    useEffect(() => {
        const nmid_query = searchParams.get('nmid')
        const product_id_filter = searchParams.get('product_id')
        if(nmid_query){
            setNmid(nmid_query)
        } else {
            if(nmid){
                setSearchParams({nmid, productId})
            }
        }
        if (product_id_filter) {
            setProductId(product_id_filter)
        } else {
            if(productId){
                setSearchParams({nmid, productId})
            }
        }
    }, [searchParams])

    const getOldDesc = () => {
        if(pageValue === btn_tab[0].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/get_old_product_description`
    
            const body = JSON.stringify({
                product_id: productId
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setOldDesc(json);
                })
                .catch(err => console.error(err))
        }
    }

    const getNewDesc = () => {
        if(pageValue === btn_tab[0].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/get_new_product_description`
    
            const body = JSON.stringify({
                product_id: productId
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setNewDesc(json);
                })
                .catch(err => console.error(err))
        }
    }

    const saveNewDesc = () => {
        if(pageValue === btn_tab[0].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/save_new_description`
    
            const body = JSON.stringify({
                product_id: productId,
                new_description: newDesc
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    console.log(json);
                })
                .catch(err => console.error(err))
        }
    }

    const getOldImages = () => {
        if(pageValue === btn_tab[1].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/get_product_images`
    
            const body = JSON.stringify({
                product_id: productId
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setOldImages(json);
                })
                .catch(err => console.error(err))
        }
    }

    const getNewImages = () => {
        if(pageValue === btn_tab[1].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/get_new_product_images`
    
            const body = JSON.stringify({
                product_id: productId
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setNewImages(json);
                })
                .catch(err => console.error(err))
        }
    }

    const saveNewImages = () => {
        if(pageValue === btn_tab[1].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/save_new_photo_product`
    
            const body = JSON.stringify({
                product_id: productId,
                new_description: newImages
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    console.log(json);
                })
                .catch(err => console.error(err))
        }
    }

    const getOldVideo = () => {
        if(pageValue === btn_tab[2].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/get_old_video_product`
    
            const body = JSON.stringify({
                product_id: productId
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setOldVideo(json);
                })
                .catch(err => console.error(err))
        }
    }

    const getNewVideo = () => {
        if(pageValue === btn_tab[2].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/get_new_video_product`
    
            const body = JSON.stringify({
                product_id: productId
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setNewVideo(json);
                })
                .catch(err => console.error(err))
        }
    }

    const saveNewVideo= () => {
        if(pageValue === btn_tab[2].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/save_new_video_product`
    
            const body = JSON.stringify({
                product_id: productId,
                new_description: newVideo
            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    console.log(json);
                })
                .catch(err => console.error(err))
        }
    }

    const createNewVideo = () => {
        if(pageValue === btn_tab[2].value){

            const url = `https://apps0.ecomru.ru:4447/api/products/generate_new_product_video`
    
            const body = JSON.stringify({
                product_id_list: [productId],
                // attribute_list: selectedAttributes,
                // emoji_code: selectedEmoji.join(''),

            })
    
            fetch(url, {body, method: 'POST', headers})
                .then(async res => {
                    if(res.ok){
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    console.log(json);
                    // const { key } = json
                    // sessionStorage.setItem(productId, key)
                    // setIsModal(false)
                })
                .catch(err => console.error(err))
        }
    }


    useEffect(() => {
        if(productId){
            getOldDesc()
            getNewDesc()
            getOldImages()
            getNewImages()
            getOldVideo()
            getNewVideo()
        }
    }, [ productId, pageValue ])

    useEffect(() => {
        if(!isModal){
            getNewDesc()
            getNewImages()
        }
    }, [isModal])

    return (
        <>
            {isModal && <ModalProductImprovement
                page={pageValue}
                setIsModal={setIsModal} 
                productId={productId}
            />}

            <section className='product-improvement-inside-content'>
                <div className='toolbar-top'>
                    <Tabs tabs={btn_tab} pageValue={pageValue} setPageValue={e => setPageValue(e)} />
                    {pageValue == 'emoji' && <div className='btn-group'>
                        <ButtonBasic
                            width='300px'
                            green
                            text='Сохранить'
                            onClick={e => saveNewDesc()}
                        />
                        <ButtonBasic
                            width='300px'
                            green
                            onClick={(e) => setIsModal(true)}
                            text={<p className='content-btn'><img src={spakler_icon} />Изменить параметры</p>}
                        />
                    </div>}

                    {pageValue == 'enhance_photo' && <div className='btn-group'>
                        <ButtonBasic
                            width='300px'
                            green
                            text='Сохранить'
                            onClick={e => saveNewImages()}
                        />
                        <ButtonBasic
                            width='300px'
                            green
                            onClick={(e) => setIsModal(true)}
                            text={<p className='content-btn'><img src={spakler_icon} />Улучшить фото</p>}
                        />
                    </div>}

                    {pageValue == 'enhance_video' && <div className='btn-group'>
                        <ButtonBasic
                            width='300px'
                            green
                            text='Сохранить'
                            onClick={e => saveNewVideo(e)}
                        />
                        <ButtonBasic
                            width='300px'
                            green
                            onClick={(e) => createNewVideo()}
                            text={<p className='content-btn'><img src={spakler_icon} />Улучшить видео</p>}
                        />
                    </div>}
                </div>

                <div className='product-improvement-inside-content__changed-content'>
                    <Routes>
                        {/* Добавление эмоджи */}
                        <Route path='/emoji' element={<ProductImprovement_EmojiAddition oldDesc={oldDesc} newDesc={newDesc} />} />
                        {/* Создать RICH content */}
                        <Route path='/create_RICH_content' element={<ProductImprovement_CreateRICHContent
                            product_id={productId}
                            setIsModal={(e) => setIsModal(e)}
                        />} />
                        {/* Улучшить фото */}
                        <Route path='/enhance_photo' element={<ProductImprovement_EnhancePhoto oldImages={oldImages} newImages={newImages} />} />
                        {/* Улучшить видео */}
                        <Route path='/enhance_video' element={<ProductImprovement_EnhanceVideo oldVideo={oldVideo} newVideo={newVideo} />} />
                    </Routes>
                </div>
            </section>
        </>


    )
}

export { ProductCardExtended_ProductImprovement }