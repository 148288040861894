import plus_icon from './img/plus_icon.svg'
import drop_icon from './img/drop_icon.svg'
import { useState, useEffect } from 'react'
import "./ButtonDrop.scss"

const ButtonDrop = ({ list_btn, style }) => {

    const [isDrop, setIsDrop] = useState(false)

    // Обработчик на клик вне элемента,для закрытия блока с options
    useEffect(() => {
        const hideMenu = (e) => {
            let el = e.target
            if (!el.closest('.btn-drop')) {
                setIsDrop(false)
            }
        }
        document.addEventListener('click', hideMenu)

        return () => {
            document.addEventListener('click', hideMenu)
        }
    }, [])

    return (
        <div className='btn-drop' style={style ? style : {}} onClick={(e) => {
            setIsDrop(true)
        }}>
            <button className='btn-drop__button'><img src={plus_icon} /></button>
            <img src={drop_icon} />

            {isDrop && <div className='btn-drop__drop-container'>
                {list_btn.map(({ label, func }, ind) => {
                    return <button className='btn-drop__btn-drop'
                        key={label + ind}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (func) {
                                func(true)
                            }
                            setIsDrop(false)
                        }}
                    >{label}</button>
                })}
            </div>}
        </div>
    )
}

export { ButtonDrop }