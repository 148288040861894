import icon_tab from './img/icon_tab.svg'
import customHeaders, {getSpace} from '../../common/headers';
import { ModalLoader } from '../Modal/ModalLoader/ModalLoader';
import { SliderRange } from '../Slider/Slider';
import { useChartData, useDataTable } from '../../common/hooks';
import { ModalWithTable } from '../Modal/ModalWithTable/ModalWithTable';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AndreyHttps, AndreyHttp, AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { Tabs, TableBox, SearchGlobalInput, RangeBox, CheckboxToggleBlue, Checkbox, DropContainer, LineChart } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from '../../store/general';
import "./AnalysisBySellers.scss"

const btn_tab = [
    {
        label: 'Запросы',
        value: 'queries',
        icon: icon_tab
    },
    // {
    //     label: 'Товары',
    //     value: 'goods',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Бренды',
    //     value: 'brands',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Категории',
    //     value: 'category',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Ценовые сегменты',
    //     value: 'price_segments',
    //     icon: icon_tab
    // },
    {
        label: 'По датам',
        value: 'days',
        icon: icon_tab
    }
]


const AnalysisBySellers = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const activeTab = useParams()['*']
    const [searchParams, setSearchParams] = useSearchParams()

    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [modalLoader, setModalLoader] = useState(false)
    const [dynamic, setDynamic] = useState(0)
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])

    const [searchValue, setSearchValue] = useState('')
    const [search, setSearch] = useState('')
    const [searchData, setSearchData] = useState([])

    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(20000)
    const [price, setPrice] = useState([minValue, maxValue])
    const [segments, setSegments] = useState(0)

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const [selectedItems, setSelectedItems] = useState([])
    const [checkFbs, setCheckFbs] = useState(false)

    const [queryNameFilter, setQueryNameFilter] = useState('')
    const [actionColumn, setActionColumn] = useState('')
    const [isModalAction, setIsModalAction] = useState(false)
    const [isReverse, setIsReverse] = useState(false)

    const [tableMetrics, setTableMetrics] = useState([])
    const [tableMetric, setTableMetric] = useState('IDC')
    const [showDropBox, setShowDropBox] = useState(false)

    const [setChartData, chartData, setChartFuncs] = useChartData()
    const { setChartLoading, resetChart } = setChartFuncs

    const resetTable = () => {
        paginatorRef.current.reset()
        paginatorRef.current.setTotal(0)
        // setCheckedProducts([])
        setFetchedData([])
        setHeaders([])
        setSort({})
        setFilters([])
    }

    const onHandleSliderChange = (minMax) => {
        setPrice(minMax)
    }

    const getModalTableData = () => {
        const _url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sellers/${actionColumn}/table/`
        let [date_from, date_to] = date

        date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        const body = {
            fbofbs_filter: checkFbs,
            date_from,
            date_to,
            seller_id_filter: [search],
            query_name_filter: [queryNameFilter]
        }

        return { _url, body }
    }

    const fetchTableData = (params, abortController = new AbortController()) => {
        if (!!pageValue & search?.length >= 3) {
            let sort
            let filtersParam

            if (params) {
                [sort, filtersParam] = params
            }

            setLoading(true)

            let current_pageValue = pageValue == 'by_days' ? 'queries' : pageValue

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sellers/${current_pageValue}/table/`
            } else {
                url = `${AndreySkuHttp}/api/v1/ecomru/keywords/sellers/${current_pageValue}/table/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]


            const priceData = pageValue == 'price_segments'
                ?
                {
                    price_from: price[0],
                    price_to: price[1],
                    price_segments: segments
                }
                : {}

            const metricName = pageValue === 'days' ? { metric_name: tableMetric } : {}



            const body = JSON.stringify({
                date_from,
                date_to,
                page: paginatorRef.current.page,
                limit: paginatorRef.current.limit,
                fbofbs_filter: checkFbs,
                filters: filtersParam,
                seller_id_filter: [search],
                sort_name: sort && sort[0],
                sort_order: sort && sort[1],
                reverse: isReverse,
                ...priceData,
                ...metricName
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setTable(json)
                    setTableUrl(url)
                    setTableBody(body)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchChartData = (abortController = new AbortController()) => {
        setLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sellers/${pageValue}/graph/`
        } else {
            url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sellers/${pageValue}/graph/`
        }

        let searchId;
        let [date_from, date_to] = date
        date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0]
        date_to = new Date(date_to)?.toISOString()?.split('T')?.[0]

        const id_arr = selectedItems?.map(el => {
            if (el) {
                if (pageValue === 'queries' && el?.['Поисковой запрос']) {
                    if (Array.isArray(el?.['Поисковой запрос'])) {
                        const [img, name] = el?.['Поисковой запрос']
                        return el?.['Поисковой запрос'][0]
                    }
                }

                if (pageValue === 'days' && el?.['Поисковый запрос']) {
                    if (Array.isArray(el?.['Поисковый запрос'])) {
                        const [img, name] = el?.['Поисковый запрос']
                        return el?.['Поисковый запрос'][0]
                    }
                }
            }
        })
        searchId = { query: id_arr }

        const selller_id = { seller_id_filter: [search] }

        const body = JSON.stringify({
            date_from,
            date_to,
            fbofbs_filter: checkFbs,
            // page: paginatorRef.current.page,
            // limit: paginatorRef.current.limit,
            metric_name: tableMetric,
            // initial: !(chartData?.selectedLegend?.length > 0),
            metrics: chartData?.selectedLegend,
            ...selller_id,
            ...searchId
        })

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setChartData(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })

    }

    const fetchSearchValue = ([search, page]) => {
        if (search?.length >= 3) {
            setLoading(true)


            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sellers/search_string/`
            } else {
                url = `${AndreySkuHttp}/api/v1/ecomru/keywords/sellers/search_string/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                date_from,
                date_to,
                page: 1,
                limit: 15,
                seller: search
            })


            fetch(url, { body, method: 'POST', headers })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setSearchData({ seller: json?.Seller })
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => setLoading(false))
        }
    }

    const fetchMinMaxPrice = () => {
        if (searchValue.length >= 3) {

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreyHttps}/ecomru/keywords/sellers/${pageValue}/price_range/`
            } else {
                url = `${AndreyHttp}/ecomru/keywords/sellers/${pageValue}/price_range/`
            }

            let [date_from, date_to] = date
            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                brands_name: searchValue,
                fbs: checkFbs,
                date_from,
                date_to,
            })

            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    const { min_price, max_price } = json
                    setMinValue(min_price)
                    setMaxValue(max_price)
                    setPrice([min_price, max_price])
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const onActionHandler = (el) => {
        const [dataObj, targetData, targetColumn] = el
        const query_name_filter = dataObj?.['Поисковой запрос']
        setQueryNameFilter(String(query_name_filter)?.replace(',', ''))

        if (targetColumn === 'Подкатегорий, шт') {
            setActionColumn('category')
        }

        if (targetColumn === 'Товаров, шт') {
            setActionColumn('goods')
        }

        if (targetColumn === 'Брендов, шт') {
            setActionColumn('brands')
        }

        setIsModalAction(true)
    }

    const fetchMetricsList = () => {
        if (pageValue === 'days' || pageValue === 'queries') {
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sellers/${pageValue}/metric_list/`
            } else {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sellers/${pageValue}/metric_list/`
            }


            fetch(url, { method: 'GET', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setTableMetrics(json);
                    setTableMetric(json[0]?.value)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const handleCheck = (data) => {
        setSelectedItems(data)
    }

    useEffect(() => {
        setDynamic(100)
    }, []);

    useEffect(() => {
        setPageValue(activeTab)
        const query = searchParams.get('query')
        if (query) {
            setSearch(query)
            setSearchValue(query)
        }
    }, [activeTab])

    useEffect(() => {
        resetTable()
        const abortController = new AbortController()
        let defaultSort
        if (!sort?.length > 0) {
            defaultSort = ['Сред. запросов в неделю', 'DESC']
            setSort(defaultSort)
        } else {
            defaultSort = sort
        }

        if (pageValue !== 'price_segments') {
            fetchTableData([defaultSort, filters], abortController)
        }

        if (pageValue === 'price_segments') {
            fetchMinMaxPrice()
        }

        return () => abortController.abort()


    }, [selectedSpace, pageValue, date, search, checkFbs, isReverse])

    useEffect(() => {
        const abortController = new AbortController()

        if (selectedItems?.length > 0 && search?.length > 0) {
            fetchChartData(abortController)
        }

        return () => abortController.abort()
    }, [selectedSpace, chartData?.selectedLegend, selectedItems, date, pageValue, search, checkFbs, tableMetric])

    useEffect(() => {
        if (pageValue === 'days' || pageValue === 'queries') {
            fetchMetricsList()
        } else {
            setTableMetrics([])
            setTableMetric(-1)
        }
    }, [pageValue, selectedSpace])

    useEffect(() => {
        resetChart()
    }, [pageValue])

    return (
        <>
            {modalLoader &&
                <ModalLoader
                    setIsModal={(e) => setModalLoader(e)}
                    dynamic={dynamic}
                    title='Формируется отчет'
                    text='Не закрывайте страницу, загрузка может занять до 2 минут'
                />}

            {
                isModalAction &&
                <ModalWithTable
                    {...getModalTableData()}
                    setIsModal={setIsModalAction}
                />
            }


            <div className='analysis-by-sellers'>
                <div className='content-1320'>
                    <h1 className='title'>По продавцу</h1>

                    <div className='tooltip-top'>
                        <div className='tooltip-top'>
                            <SearchGlobalInput
                                {...searchData}
                                fetchSearch={e => {
                                    const abortController = new AbortController()
                                    fetchSearchValue(e, abortController)
                                    return () => abortController.abort()
                                }}
                                setSearch={({ extra }) => setSearch(extra)}
                                value={searchValue}
                                setSearchData={e => setSearchData(e)}
                            />
                        </div>
                    </div>

                    <div className='toolbar-medium'>
                        <Tabs tabs={btn_tab} disables={loading} pageValue={pageValue} />
                        <div className='range-group-btn-and-range-box'>
                            <TooltipLightBlue
                                text={<p className='text_tooltip'>FBO <span>(продажи со склада площадки)</span> по умолчанию.
                                    <br /><br />
                                    Нажмите на переключатель чтобы увидеть FBS <span>(продажи со склада продавца)</span>
                                </p>}
                                top='32px'
                                left='0px'
                                maxWidth='110px'
                                child={
                                    <label className='checkbox-container'>
                                        <CheckboxToggleBlue
                                            value={checkFbs}
                                            onChange={(e) => setCheckFbs(!checkFbs)}
                                        />
                                        <p className='text_label'>FBS</p>
                                    </label>
                                }
                            />

                            <RangeBox setDate={e => setDate(e)} />
                        </div>
                    </div>

                    {pageValue == 'price_segments' && <SliderRange
                        minValue={minValue} maxValue={maxValue}
                        setSegments={e => setSegments(e)}
                        setState={e => onHandleSliderChange(e)}
                        callbackHandler={e => {
                            fetchTableData()
                            //    fetchChartData()
                        }}
                    />}
                </div>
                <DropContainer
                    showDropBox={showDropBox}
                    setShowDropBox={(e) => setShowDropBox(e)}
                >
                    <LineChart
                        {...chartData}
                    />
                </DropContainer >

                <TableBox
                    onActionInfo
                    newTable={true}
                    {...tableProps}
                    paginator={true}
                    ref={paginatorRef}
                    onAction={onActionHandler}
                    onCheck={e => handleCheck(e)}
                    onUncheckAllDependence={[pageValue, search]}
                    fetchCallback={fetchTableData}
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    child={
                        <div style={{ display: 'flex', gap: '.5em', flexDirection: 'column', boxSizing: 'border-box', padding: '.5em 0 1em' }}>
                            {
                                pageValue === 'days' &&
                                <div>
                                    <p style={{ display: 'flex', gap: '.5em' }}>
                                        Перевернуть
                                        <Checkbox value={isReverse} onChange={checked => setIsReverse(checked)} />
                                    </p>
                                </div>
                            }
                            {
                                (pageValue !== 'summary' & pageValue !== 'top_keywords') ?
                                    <div style={{ width: '90%', overflow: 'overlay', height: '100%', display: 'flex', flexWrap: 'wrap', gap: '.5em', justifyContent: 'space-between' }}>
                                        {tableMetrics.map(({ label, value }) =>
                                            <div
                                                style={{
                                                    width: '200px', border: '1px solid lightgrey', borderRadius: '10px', boxSizing: 'border-box',
                                                    padding: '1em', backgroundColor: tableMetric === value ? '#d5d5f9' : '', cursor: 'pointer',
                                                    fontSize: '12px'
                                                }}
                                                onClick={e => setTableMetric(value)}
                                            >
                                                {label}</div>)}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    }
                />
            </div >
        </>

    )
}

export { AnalysisBySellers }