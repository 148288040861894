// validation email

export const validationEmail = (email) => {

    // const regexEmail = /^((([0-9A-Za-z]{1}[-0-9A-z\.]{0,30}[0-9A-Za-z]?)|([0-9А-Яа-я]{1}[-0-9А-я\.]{0,30}[0-9А-Яа-я]?))@([-A-Za-z]{1,}\.){1,}[-A-Za-z]{2,})$/;
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const resultValidation = regexEmail.test(email)

    return resultValidation
}

// export const stringBreake = (string) => {
//     const regex = /\n/
//     const result = regex.test(string)

//     console.log(string.split(/\n/))
//     return(string.split(/\n/))
// }

// stringBreake(`hello`)