import { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import { TableBox } from "@lk-gtcom/ecomlab-components"
import { ModalExitConfirmations } from "../Modal/ModalCreatingAnAdvertisingCampaign/ModalExitConfirmations/ModalExitConfirmations"
import { ModalChoiceOfStrategyAnalyticalCollections } from "../Modal/ModalCreatingAnAdvertisingCampaign/ModalChoiceOfStrategyAnalyticalCollections/ModalChoiceOfStrategyAnalyticalCollections"
import { ModalStrategyParameters } from "../Modal/ModalCreatingAnAdvertisingCampaign/ModalStrategyParameters/ModalStrategyParameters"
import { RangeBoxSingle } from "../RangeBoxSingle/RangeBoxSingle"
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import arrow_left from './img/arrow_left.svg'
import done_active from './img/done_active.svg'
import done_disactive from './img/done_disactive.svg'
import done_current from './img/done_current.svg'
import stop_icon from './img/stop_icon.svg'
import "./CreatingAnAdvertisingCampaign.scss"
import { ModalAddProduct } from "../Modal/ModalAddProduct/ModalAddProduct"

const statistic = [
    {
        label: 'Вы выбрали',
        sum: '5 товаров'
    }
]

const CreatingAnAdvertisingCampaign = ({ setShowTooltipLauncAdvertisingCampaign }) => {

    const form_filling_stage = [
        {
            label: 'Выбор товаров',
            description: 'Выбор товаров'
        },
        {
            label: 'Создание рекламной кампании',
            description: 'Заполните основные данные о кампании'
        },
        {
            label: 'Выбор стратегии',
            description: 'Выберите подходящую стратегию для товаров'
        },
        {
            label: 'Параметры',
            description: 'Заполните параметры стратегии'
        },
        {
            label: 'Запуск рекламной кампании',
            description: 'Запустите продвижение. Готово!'
        },
    ]

    const [campaignName, setCampaignName] = useState('')
    const [errCampaignName, setErrCampaignName] = useState(false)
    const [budget, setBudget] = useState('')
    const [errBudget, setErrBudget] = useState(false)
    const [currentStep, setCurrentStep] = useState(form_filling_stage[1].label)
    const [activeStep, setActiveStep] = useState(new Set([form_filling_stage[0].label]))
    const [isModalChoiceOfStrategy, setIsModalChoiceOfStrategy] = useState(false)
    const [isModalStrategyParameters, setIsModalStrategyParameters] = useState(false)
    const [isModalExit, setIsModalExit] = useState(false)
    const [isModalAddProduct, setIsModalAddProduct] = useState(false)
    const [currentStrategy, setCurrentStrategy] = useState(null)
    const [isAdvertisingLaunched, setIsAdvertisingLaunched] = useState(false)
    const [date, setDate] = useState([new Date().getTime()])
    const path = useParams()

    useEffect(() => {
        const newState = new Set(activeStep);
        if (currentStrategy) {
            newState.add(form_filling_stage[1].label);
            setCurrentStep(form_filling_stage[2].label)
            setActiveStep(newState)
        } else {
            newState.delete(form_filling_stage[1].label);
            setCurrentStep(form_filling_stage[1].label)
            setActiveStep(newState)
        }
    }, [currentStrategy]);

    return (
        <>
            {isModalStrategyParameters && <ModalStrategyParameters
                setIsModal={(e) => setIsModalStrategyParameters(e)}
                currentStrategy={currentStrategy}
                setCurrentStrategy={(e) => setCurrentStrategy(e)}
                setIsModalChoiceOfStrategy={(e) => setIsModalChoiceOfStrategy(e)}
            />}

            {isModalChoiceOfStrategy && <ModalChoiceOfStrategyAnalyticalCollections
                setIsModal={(e) => setIsModalChoiceOfStrategy(e)}
                setIsModalStrategyParameters={(e) => setIsModalStrategyParameters(e)}
                currentStrategy={currentStrategy}
                setCurrentStrategy={(e) => setCurrentStrategy(e)}
            />}

            {isModalExit && <ModalExitConfirmations
                setIsModal={(e) => setIsModalExit(e)}
            />}

            {isModalAddProduct && <ModalAddProduct
                setIsModal={(e) => setIsModalAddProduct(e)}
            />}

            <div className='creating-an-advertising-campaign'>
                <Link to='../list-of-advertising-campaigns' className="link-breadcrumbs"><img src={arrow_left}></img>К списку рекламных кампаний</Link>
                <div className='creating-an-advertising-campaign__main-content'>
                    <div className='creating-an-advertising-campaign__content'>
                        <h2 className="title">Создание рекламной кампании</h2>
                        <div className="input-field-group">
                            <InputDinamycPlaceholder
                                onChange={(e) => setCampaignName(e)}
                                err={errCampaignName}
                                textErr={'Неверное название'}
                                classname='autorization__input-field'
                                placeholder='Название кампании'
                                autoComplete={false}
                            />
                            <div className="double-group">
                                <div className="input-field-item">
                                    <RangeBoxSingle minMaxDate={[new Date(), false]} setDate={e => setDate(e)} />
                                </div>
                                <div className="input-field-item">
                                    <InputDinamycPlaceholder
                                        onChange={(e) => setBudget(e)}
                                        err={errBudget}
                                        textErr={'Неверное значение'}
                                        classname='autorization__input-field'
                                        placeholder='Дневной бюджет'
                                        autoComplete={false}
                                        info='Минимальный бюджет - 500 руб.'
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="chosen-strategy">
                            <p className="text-grey">Выбранная стратегия</p>
                            {currentStrategy ? <p className="text">{currentStrategy}</p>
                                :
                                <ButtonBasic
                                   blue
                                    text='Выбрать стратегию'
                                    width='176px'
                                    size='56px'
                                    onClick={(e) => {
                                        setIsModalChoiceOfStrategy(true)
                                    }}
                                />
                            }
                           
                        </div>
                        {currentStrategy == 'Целевой % ДРР' && <div className="btn-group">
                            <ButtonBasic
                               white
                                text='Изменить параметры стратегии'
                                width='266px'
                                size='56px'
                                onClick={(e) => setIsModalStrategyParameters(true)}
                            />

                            {!isAdvertisingLaunched &&
                                <ButtonBasic
                                   green
                                    text={<div className="content-btn-start"><img src={stop_icon} />Запустить рекламную кампанию</div>}
                                    width='266px'
                                    size='56px'
                                    onClick={(e) => {
                                        setShowTooltipLauncAdvertisingCampaign(true)
                                        setIsAdvertisingLaunched(true)
                                    }}
                                />}

                            {isAdvertisingLaunched && <ButtonBasic
                               red
                                text={<div className="content-btn-stop"><img src={stop_icon} />STOP</div>}
                                width='218px'
                                size='56px'
                                onClick={(e) => {
                                    setShowTooltipLauncAdvertisingCampaign(false)
                                    setIsAdvertisingLaunched(false)
                                }}
                            />}
                        </div>}
                    </div>
                    <div className='creating-an-advertising-campaign__side-panel'>
                        {form_filling_stage.map(({ label, description }, ind) =>
                            <div className="step-item" key={label + ind}>
                                <div className="img-container">
                                    <img src={currentStep == label ? done_current : (activeStep.has(label) ? done_active : done_disactive)} />
                                </div>
                                <div className="step-item__content">
                                    <p className="text">{label}</p>
                                    <p className="text_grey">{description}</p>
                                </div>
                                <div></div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="table-container">
                    <TableBox
                        loading={null}
                        fetchedData={[]}
                        headers={[]}
                        paginator={true}
                        hideExport
                        hideColumn
                        statistic={statistic}
                        child={
                            <div style={{ display: "flex", gap: '12px' }}>
                                <ButtonBasic
                                   white
                                    text='Добавить товар'
                                    onClick={(e) => setIsModalAddProduct(true)}
                                    minWidth='123px'
                                    width='123px'
                                    size='32px'
                                />

                                <ButtonBasic
                                   white
                                    text='Удалить товар'
                                    minWidth='123px'
                                    width='123px'
                                    size='32px'
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    )
}

export { CreatingAnAdvertisingCampaign }