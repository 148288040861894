import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../../common/components/Loader/Loader';
import './NoDataContent.scss'

const NoDataContent = ({ title, description, child = false, img = false }) => {

    return (
        <div className='no-data-content'>
            {child}
            {title && <h2 className='title'>{title}</h2>}
            {description && <p className='text'>{description}</p>}
            {img && <img src={img} />}
        </div>
    )
}

export { NoDataContent }