import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalDeleteTemplate.scss'

const ModalDeleteTemplate = ({ setIsModal, deleteTemplateInGroup }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-delete-template'>
                <h2 className='modal-delete-template__title'>Вы действительно хотите удалить шаблон?</h2>
                <ButtonBasic
                    red
                    text='Удалить'
                    width='225px'
                    size='40px'
                    onClick={(e) => {
                        deleteTemplateInGroup()
                        setIsModal(false)
                    }}
                />
                <ButtonBasic
                    grey
                    text='Отменить'
                    width='225px'
                    size='40px'
                    onClick={(e) => setIsModal(false)}
                />
            </div>
        </div>
    )
}

export { ModalDeleteTemplate }