import { useState, useEffect } from 'react';
import { InputDinamycPlaceholder, ButtonBasic } from "@lk-gtcom/ecomlab-components"
import './ModalMiltilevelSidebarAdd.scss'

const ModalMiltilevelSidebarAdd = ({ setIsModal }) => {

    const [namePoint, setNamePoint] = useState('')

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-miltilevel-sidebar-add'>
                <h2 className='modal-miltilevel-sidebar-add__title'>Добавить новый пункт меню</h2>
                <InputDinamycPlaceholder
                    onChange={(e) => setNamePoint(e)}
                    placeholder='Введите название'
                />
                <ButtonBasic
                    green
                    width='100%'
                    text='Создать'
                    size='56px'
                />
            </div>
        </div>
    )
}

export { ModalMiltilevelSidebarAdd }