import successIcon from './img/successIcon.svg'
import { useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { useNavigate } from 'react-router-dom';
import done_icom from './img/done_icon.svg'
import "./ModalFirstConnection.scss"

const CLOSE_EVENT_TYPE = 'modal first connection close'

const ModalFirstConnection = ({
    setIsModalActive,
    setConnectedModal,
    setIsServiceConnected,
    setIsServiceSelected
}) => {
    const navigate = useNavigate()
    const closeEvent = new Event(CLOSE_EVENT_TYPE)

    useEffect(() => {
        return () => {
            document.dispatchEvent(closeEvent)
            
        }
    }, [])

    return (
        <div className='modal-first-connection'>
            <button className="btn-close" onClick={e => {
                setIsModalActive(false)
                setConnectedModal(false)
            }}></button>
            <img 
                src={successIcon} 
                alt="Successful account connection" 
                style={{
                    height: '70px',
                    width: '70px',
                    objectFit: 'contain',
                    marginBottom: '2.5em'
                }}
            />
            <h1 className='title'>Подключение создано</h1>
            <p className='text'>Сбор и обработка информации займет <b>до 24 часов.</b></p>
            <p className='text'>Пока мы настраиваем подключение, вы можете ознакомиться с  
                <a className='link-blue' 
                    style={{display: 'block', color: '#1890FF', cursor: 'pointer', userSelect: 'none'}}
                    onClick={e => {window.open("https://wiki.ecomru.ru/main/article/Instruktsija_po_razdelu_%E2%80%9COtzyvy_i_voprosy%E2%80%9D_na_Ecomru.ru/735", '_blank')}}
                >
                    инструкцией по разделу.
                </a>
            </p>

            <div className='btn-group'>
                <ButtonBasic
                    lilac  
                    text='К списку подключений'
                    onClick={e => {
                        setIsModalActive(false)
                        navigate('../settings/connections')
                    }}
                    width='calc(50% - 6px)'
                    size='56px'
                />
                <ButtonBasic 
                    text='Новое подключение'
                    green
                    width='calc(50% - 6px)'
                    size='56px'
                    onClick={e => {
                        setIsServiceConnected(false)
                        setIsServiceSelected(false)
                    }}
                />
            </div>
        </div>
    )
}

export { ModalFirstConnection, CLOSE_EVENT_TYPE }