import { MoonLoader } from 'react-spinners'
import { CardExecutor } from '../../CardExecutor/CardExecutor'
import { useState, useEffect } from 'react'
import { useRef } from 'react'
import { InputDinamycPlaceholder, Tabs, ButtonBasic, TableBox } from "@lk-gtcom/ecomlab-components"
import useGeneralStore from '../../../store/general'
import { RavshanHttps } from '../../../fetchUrls'
import { OlegHttps, OlegHttp } from '../../../fetchUrls'
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../../fetchUrls'
import customHeaders, {getSpace} from '../../../common/headers'
import './ModalCreatingNewSpace.scss'

const btn_tab_1 = [
    {
        label: 'Сотрудники',
        value: 'employee'
    },
    {
        label: 'Подключения',
        value: 'settings'
    }
]

const btn_tab_2 = [
    {
        label: 'Добавить',
        value: 'true'
    },
    {
        label: 'Добавленные',
        value: 'false'
    }
]

const ModalCreatingNewSpace = ({ setIsModal, isEdit = false, setIsEditSpace, currentSpace, setCurrentSpace }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const {
        spaceList,
        // currentSpace,
        // setCurrentSpace,
        accounList,
        setAccountList,
        employeesList,
        setEmployeesList
    } = useGeneralStore()

    const [nameSpace, setNameSpace] = useState([])
    const [load, setLoad] = useState(false)
    const [pageValue1, setPageValue1] = useState(btn_tab_1[0].value)
    const [pageValue2, setPageValue2] = useState(btn_tab_2[1].value)

    // Добавления пространства
    const fetchAddSpace = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/space/`
        } else {
            url = `${RavshanHttps}/api/space/`
        }

        const body = JSON.stringify({
            name: nameSpace,
            // "author": "111"
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Изменить пространство
    const fetchChangeSpace = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/space/`
        } else {
            url = `${RavshanHttps}/api/space/`
        }

        const body = JSON.stringify({
            name: nameSpace,
            id: currentSpace?.id
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Получение аккаунтов из пространства
    const fetchGetAccountSpace = () => {
        
        if (!currentSpace?.id && pageValue2 == 'false') return

        const abortController = new AbortController()
        setLoad(true)
        const url = `${RavshanHttps}/api/space/account/${currentSpace?.id ? currentSpace?.id : -1}?all=${pageValue2}`

        fetch(url, { headers, signal: abortController.signal })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then(json => {
                if (json) {
                    setAccountList(json)
                }
            })
            .catch(err => console.error(err))
            .finally((e) => {
                setLoad(false)
            })
        return abortController

    }

    // Получение аккаунтов из пространства
    const fetchGetClientSpace = () => {

        if (!currentSpace?.id && pageValue2 == 'false') return
        const abortController = new AbortController()

        const url = `${RavshanHttps}/api/space/client/${currentSpace?.id ? currentSpace?.id : -1}?all=${pageValue2}`

        setLoad(true)

        fetch(url, { headers, signal: abortController.signal })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then(json => {
                if (json) {
                    setEmployeesList(json)
                }
            })
            .catch(err => console.error(err))
            .finally((e) => {
                setLoad(false)
            })
        return abortController

    }

    // Добавление аккаунта в пространство
    const fetchAddAccountInSpace = (acc_id) => {
        const url = `${RavshanHttps}/api/space/account/add`

        const body = JSON.stringify({
            id: currentSpace?.id,
            accounts: [acc_id]
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Добавление аккаунта в пространство
    const fetchAddClientInSpace = (client_id) => {
        const url = `${RavshanHttps}/api/space/client/add`

        const body = JSON.stringify({
            id: currentSpace?.id,
            clients: client_id
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Удаление аккаунта в пространство
    const fetchDeleteAccountInSpace = (acc_id) => {
        const url = `${RavshanHttps}/api/space/account/delete`

        const body = JSON.stringify({
            id: currentSpace?.id,
            accounts: [acc_id]
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    // Удаление сотрудника из пространство
    const fetchDeleteClientInSpace = (client_id) => {
        const url = `${RavshanHttps}/api/space/client/delete`

        const body = JSON.stringify({
            id: currentSpace?.id,
            clients: client_id
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }
    

    useEffect(() => {
        fetchGetAccountSpace()
        fetchGetClientSpace()
    }, [currentSpace, pageValue1, pageValue2, selectedSpace]);


    const [selectedArrExecutor, setSelectedArrExecutor] = useState([])
    const [selectedArrAccount, setSelectedArrAccount] = useState([])


    const currentListExecutor = () => {
        if (pageValue2 == 'true') {
            const list = employeesList.filter((el) => {
                return !selectedArrExecutor.includes(el.id)
            })
            return list
        } else {
            const list = employeesList.filter((el) => {
                return selectedArrExecutor.includes(el.id)
            })
            return list
        }
    }

    const currentListAccount = () => {
        if (pageValue2 == 'true') {
            const list = accounList.filter((el) => {
                return !selectedArrAccount.includes(el.id)
            })
            return list
        } else {
            const list = accounList.filter((el) => {
                return selectedArrAccount.includes(el.id)
            })
            return list
        }
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsEditSpace(false)
                setCurrentSpace(null)
                setAccountList([])
                setEmployeesList([])
                setIsModal(false)
            }
        }}>
            <div className='modal-creating-new-space'>
                <div className='modal-creating-new-space__header'>
                    <h1 className='title'>{isEdit ? 'Настройка пространства' : 'Создание нового пространства'}</h1>
                </div>
                <div className='toolbar-medium'>
                    <InputDinamycPlaceholder
                        changeValue={currentSpace?.name}
                        placeholder='Название'
                        onChange={e => setNameSpace(e)} />
                </div>
                <div className='tabs_big'>
                    <Tabs tabs={btn_tab_1} stopRedirect pageValue={pageValue1} setPageValue={(e) => setPageValue1(e)} />
                </div>

                {
                    currentSpace?.is_admin &&
                    <Tabs tabs={btn_tab_2} stopRedirect pageValue={pageValue2} setPageValue={(e) => setPageValue2(e)} />
                }

                {load ?
                    <div className='center' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MoonLoader
                            size={40}
                            color="#7b7b7b"
                            speedMultiplier={0.5}
                        />
                    </div>
                    :
                    <div className='modal-creating-new-space__content'>

                        {
                            (pageValue1 == 'employee')
                            && (currentSpace?.id ? employeesList : currentListExecutor()).map((el) => {
                                return <CardExecutor
                                    id={el.id}
                                    isAdmin={currentSpace?.is_admin}
                                    login={el.login}
                                    name={el.name}
                                    color={el.color}
                                    status={currentSpace?.id ? pageValue2 == 'false' : pageValue2 == 'false'}
                                    addNewMember={(e) => {
                                        if (currentSpace?.id) {
                                            if (pageValue2 == 'false') {
                                                fetchDeleteClientInSpace(e)
                                            } else {
                                                fetchAddClientInSpace(e)
                                            }
                                            setTimeout(() => fetchGetClientSpace(), 1000)
                                        } else {
                                            if (pageValue2 == 'false') {
                                                let list_executor = selectedArrExecutor
                                                let current_list_executor = list_executor.filter((item) => item.id != el.id)
                                                setSelectedArrExecutor(current_list_executor)
                                            } else {
                                                setSelectedArrExecutor(prev => [...prev, el.id])
                                            }
                                        }
                                    }}
                                />
                            })
                        }

                        {(pageValue1 != 'employee')
                            &&
                            (currentSpace?.id ? accounList : currentListAccount())?.map((el) => {
                                return <div className={`${(false ? 'notification-item_active' : 'notification-item')}`}
                                    key={el}
                                    onClick={e => {
                                    }}
                                >
                                    <img src={el.group_by_portal[0]}
                                        onLoad={e => {
                                            e.target.style.backgroundImage = 'none'
                                        }}
                                    />
                                    <div className='changing-content'>
                                        <p className='text'>{el.name}</p>
                                        <p className='text_grey'>{el.mp_name}</p>
                                        <p className='text_grey'>{el.mp_group}</p>
                                        {/* <p className='text_mp'><img src={el.group_by_portal[0]} /> {el.group_by_portal[1]}</p> */}
                                    </div>
                                    {
                                        currentSpace?.is_admin ?
                                            pageValue2 === 'false' ?
                                                <ButtonBasic
                                                    red
                                                    width='100%'
                                                    size='32px'
                                                    onClick={(e) => {
                                                        if (currentSpace?.id) {
                                                            fetchDeleteAccountInSpace(el.id)
                                                            setTimeout(() => fetchGetAccountSpace(), 1000)
                                                        } else {
                                                            let list_executor = selectedArrAccount
                                                            let current_list_executor = list_executor.filter((item) => item.id != el.id)
                                                            setSelectedArrAccount(current_list_executor)
                                                        }

                                                    }}
                                                    text={'Убрать'}
                                                /> :
                                                <ButtonBasic
                                                    green
                                                    width='100%'
                                                    size='32px'
                                                    onClick={(e) => {
                                                        if (currentSpace?.id) {
                                                            fetchAddAccountInSpace(el.id)
                                                            setTimeout(() => fetchGetAccountSpace(), 1000)
                                                        } else {
                                                            setSelectedArrAccount(prev => [...prev, el.id])
                                                        }
                                                    }}
                                                    text={'Добавить'}
                                                />
                                        :
                                            null
                                    }
                                </div>
                            })
                        }
                    </div>
                }
                <div className='modal-creating-new-space__btn-box'>
                    <ButtonBasic
                        green
                        onClick={(e) => {
                            if (isEdit) {
                                fetchChangeSpace()
                            } else {
                                fetchAddSpace()
                            }
                            setCurrentSpace(null)
                            setAccountList([])
                            setEmployeesList([])
                            setIsModal(false)
                        }}
                        text={isEdit ? 'Изменить' : 'Создать'}
                        size='56px'
                        width='260px'
                    />
                </div>
            </div>
        </div >
    )
}

export { ModalCreatingNewSpace }