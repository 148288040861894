import customHeaders, { getSpace } from '../../common/headers';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { rich_description } from '../../common/svg_img';
import { ModalRichNotification } from '../Modal/ModalRichNotification/ModalRichNotification';
import { useState, useEffect, useRef } from 'react';
import {
  fetchMasterCategoryFilter,
  fetchMasterBrandFilter,
} from '../../common/filtersFunc';
import {
  DropDownSelector,
  TableBox,
  ButtonBasic,
  SearchComponent,
} from '@lk-gtcom/ecomlab-components';
import {
  ArtemPhotoHttps,
  KonstantinMasterHttps,
  KonstantinMasterHttp,
} from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import './AutoRichLanding.scss';

const DescriptionImprovementLanding = ({}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [showModal, setShowModal] = useState(false);
  const [contentTable, setContentTable] = useState(false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [offerIdPage, setOfferIdPage] = useState(1);

  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [offerIdQuery, setOfferIdQuery] = useState('');

  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [offerIdData, setOfferIdData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState([]);

  const [searchData, setSearchData] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;

  const resetTable = () => {
    paginatorRef.current?.reset();
    paginatorRef.current?.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchMasterCategoryFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
    return () => abortController.abort();
  }, [
    selectedSpace,
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery,
    selectedBrand,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchMasterBrandFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
    return () => abortController.abort();
  }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery]);

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`;
    }

    let brand_id_list;
    let category_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setSearchData(
          json.map(({ id, name, image }) => ({
            label: name,
            extra: id,
            value: id,
            image,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();

    resetTable();
    fetchTableData([], abortController);

    return () => abortController.abort();
  }, [selectedSpace, selectedBrand, selectedCategory, selectedOfferId]);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/tables/product-list${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/tables/product-list${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    }

    let brand_id_list;
    let category_id_list;
    let offer_id_list;
    let offer_id_name_search = searchValue;

    if (selectedOfferId) {
      offer_id_list = selectedOfferId?.map((el) => el?.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      // offer_id_list,
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
      filters: filtersParam,
      offer_id_name_search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const handleProductsRich = (all = false) => {
    const url = `${ArtemPhotoHttps}/desc/improve`;

    const body = {
      // owner_api_id: selectedApi.value,
      prod_ids: all ? ['all'] : checkedProducts.map((el) => el.product_id),
    };

    // не ждём ответ. После того как изменится обработка на бэке, можно завязываться на ответ и показать попап с уведомлением
    fetch(url, { body: JSON.stringify(body), method: 'POST', headers });
    setShowModal(true);
  };

  return (
    <div className="description-improvement-landing">
      {showModal && <ModalRichNotification setShowModal={setShowModal} />}
      <div className="toolbar-top">
        <FilterContainer
          onReset={(e) => {
            setSelectedCategory([]);
            setSelectedBrand([]);
          }}
        >
          <DropDownSelector
            options_prop={categoryData}
            state={selectedCategory}
            setState={(e) => setSelectedCategory(e)}
            placeholder="Категория"
            multi={false}
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
            // defaultValue={[{ label: 'Все', value: 'all' }]}
          />

          <DropDownSelector
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            placeholder="Бренд"
            multi={true}
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
          />
          <SearchComponent
            searchValue={searchValue}
            errSearch={false}
            placeholder="Поиск по артукулу или названию"
            errText="Неверный артикул или название"
            setSearchValue={setSearchValue}
            fetchData={fetchTableData}
            fetchCallback={fetchSearch}
            data={searchData}
          />
        </FilterContainer>
        <ButtonBasic
          text={'Выбрать'}
          onClick={async (e) => {
            await setContentTable(true);
            setCheckedProducts([]);
            fetchTableData();
          }}
        />
      </div>

      {!contentTable ? (
        <div className="auto-rich-landing">
          <div className="rich__img">
            <object type="image/svg+xml" data={rich_description} />
          </div>

          <div className="rich__information-box">
            <h3 className="title">УЛУЧШЕНИЕ ОПИСАНИЯ КАРТОЧКИ</h3>

            <div className="rich__list-item">
              <p className="text">Что делаем:</p>
              <ul className="landing__list">
                <li className="landing__list-item">
                  Добавляем маркированный список
                </li>
                <li className="landing__list-item">Добавляем эмоджи</li>
              </ul>
            </div>

            <div className="rich__list-item">
              <p className="text">На что влияет:</p>
              <ul className="landing__list">
                <li className="landing__list-item">
                  Улучшает восприятие текстового восприятия
                </li>
                <li className="landing__list-item">
                  Повышает конверсию карточки товара
                </li>
                <li className="leanding__list-item">
                  Повышение конверсии карточки
                </li>
              </ul>
            </div>

            <div className="rich__list-item">
              <p className="text">Что вы получаете::</p>
              <ul className="landing__list">
                <li className="landing__list-item">
                  Быстрое улучшение контента
                </li>
                <li className="landing__list-item">
                  Способ выделиться среди конкурентов
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <>
          <TableBox
            {...tableProps}
            paginator={true}
            ref={paginatorRef}
            onCheck={(e) => setCheckedProducts(e)}
            fetchCallback={fetchTableData}
            tableUrl={tableUrl}
            tableBody={tableBody}
            child={
              <div className="child-content">
                <ButtonBasic
                  action
                  disabled={!(checkedProducts?.length > 0)}
                  width="auto"
                  text="Обогатить выбранное"
                  size="32px"
                  onClick={(e) => handleProductsRich()}
                />

                <ButtonBasic
                  action
                  width="auto"
                  text="Обогатить все"
                  disabled={!(checkedProducts?.length > 0)}
                  size="32px"
                  onClick={(e) => handleProductsRich(true)}
                />
              </div>
            }
          />
        </>
      )}
    </div>
  );
};

export { DescriptionImprovementLanding };
