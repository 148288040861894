import customHeaders, { getSpace } from '../../common/headers';
import icon_tab from './img/icon_tab.svg';
import LineChart from '../../charts/LineChart';
import { useDataTable } from '../../common/hooks';
import { TooltipYellow } from '../TooltipYellow/TooltipYellow';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { OlegHttps, RavshanHttps } from '../../fetchUrls';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { ModalCreatingAnAdvertisingCampaignV2 } from '../Modal/ModalCreatingAnAdvertisingCampaignV2/ModalCreatingAnAdvertisingCampaignV2';
import { ModalChoiceOfStrategyAnalyticalCollections } from '../Modal/ModalCreatingAnAdvertisingCampaign/ModalChoiceOfStrategyAnalyticalCollections/ModalChoiceOfStrategyAnalyticalCollections';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
} from '../../common/filtersFunc';
import {
  MetricsItem,
  DropDownSelector,
  Tabs,
  RangeBox,
  DropContainer,
  TableBox,
  ButtonBasic,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './AnalyticalCollectionsOzon.scss';

const btn_tab = [
  {
    label: 'Все товары',
    value: 'all_product',
    icon: icon_tab,
  },
  {
    label: 'С низкой оборачиваемостью',
    value: 'low_turnover_rate',
    tooltip: (
      <p>
        Рассчитали медианное значение оборачиваемости товаров в днях по каждой
        категории площадки. В подборку добавлены товары с показателем выше
        медианного.
      </p>
    ),
    icon: icon_tab,
  },
  {
    label: 'Высокая конверсия',
    value: 'high_conversion_rate',
    tooltip: (
      <p>
        Медианное значение конверсии из показа в заказ{' '}
        <span style={{ color: '#F97316' }}>10%</span> по выбранной площадке. В
        подборку добавлены товары с показателем выше медианного.
      </p>
    ),
    icon: icon_tab,
  },
  {
    label: 'Недостаток трафика',
    value: 'insufficient_traffic',
    tooltip: (
      <p>
        Медианное значение количества показов на товар{' '}
        <span style={{ color: '#F97316' }}>10 шт.</span> по выбранной площадке.
        В подборку добавлены товары с показателем ниже медианного.
      </p>
    ),
    icon: icon_tab,
  },
  // {
  //   label: 'Высокая маржинальность',
  //   value: 'high_margin',
  //   tooltip: '',
  //   icon: icon_tab,
  // },
  // {
  //   label: 'Цена ниже конкурентов',
  //   value: 'price_lower_than_competitors',
  //   tooltip: (
  //     <p>
  //       Рассчитали индекс цены по каждому товару. В подборку добавлены товары с
  //       индексом Отличный и Хороший. Отличный - предложение самое выгодное на
  //       площадке и других маркетплейсах. Хороший - стоимость более чем на{' '}
  //       <span style={{ color: '#F97316' }}>1%</span> превышает цены конкурентов
  //       на Ozon и других площадках.
  //     </p>
  //   ),
  //   icon: icon_tab,
  // },
  {
    label: 'Товары без продаж',
    value: 'without_sales',
    tooltip: (
      <p>
        Медианное значение количество дней без продаж{' '}
        <span style={{ color: '#F97316' }}>10 </span> по выбранной площадке. В
        подборку добавлены товары с показателем выше медианного.'
      </p>
    ),
    icon: icon_tab,
  },
  // {
  //   label: 'Позиция в поиске',
  //   value: 'search_position',
  //   tooltip: (
  //     <p>
  //       Медианное значение по параметру позиция в поиске по каталогу{' '}
  //       <span style={{ color: '#F97316' }}>10 </span> по выбранной площадке. В
  //       подборку добавлены товары с показателем выше медианного.
  //     </p>
  //   ),
  //   icon: icon_tab,
  // },
  // {
  //   label: 'Нужно продвижение',
  //   value: 'need_promotion',
  //   tooltip: (
  //     <p>
  //       Медианное значение количества уникальных посетителей на карточку товара{' '}
  //       <span style={{ color: '#F97316' }}>10 шт.</span> по выбранной площадке.В
  //       подборку добавлены товары с показателем ниже медианного
  //     </p>
  //   ),
  //   icon: icon_tab,
  // },
];

const options_prop = [
  {
    label: 'Ozon',
    value: { name: 'ozon', id: 1 },
  },
  {
    label: 'Wildberries',
    value: { name: 'wb', id: 3 },
  },
  {
    label: 'Yandex',
    value: { name: 'yandex', id: 2 },
  },
];

const metrics_current = {
  all_product: [
    'all_product_orders_rub',
    'all_product_orders_item',
    'all_product_views',
    'all_product_advertising_expenses',
  ],

  low_turnover_rate: [
    'low_turnover_rate_orders',
    'low_turnover_rate_views',
    'low_turnover_rate_ads_less_than_0_5',
    'low_turnover_rate_idc_more_than_30',
  ],

  high_conversion_rate: [
    'high_conversion_rate_amount_goods_3_group_without_advertising',
    'high_conversion_rate_amount_goods_3_group',
    'high_conversion_rate_amount_goods_4_group_without_advertising',
    'high_conversion_rate_amount_goods_4_group',
  ],
  insufficient_traffic: [
    'insufficient_traffic_impression_share',
    'insufficient_traffic_orders',
    'insufficient_traffic_amount_goods',
    'insufficient_traffic_conversion_order_to_impression',
  ],

  high_margin: ['', '', '', ''],

  price_lower_than_competitors: [
    'price_lower_than_competitors_order_share',
    'price_lower_than_competitors_view_share',
    'price_lower_than_competitors_conversion_impression_to_order',
    'price_lower_than_competitors_amount_goods',
  ],

  without_sales: [
    'without_sales_view_share',
    'without_sales_amount_goods',
    'without_sales_fbo',
    'without_sales_fbs',
  ],

  search_position: [
    'search_position_search_place_more_100',
    'search_position_search_place_between_101_200',
    'search_position_search_place_more_200',
    'search_position_search_place_average',
  ],

  need_promotion: [
    'need_promotion_high_conversion_low_views',
    'need_promotion_product_price_lower_than_competitors',
    'need_promotion_ads_lower_than_0_5',
    'need_promotion_idc_more_than_30',
  ],
};

const AnalyticalCollectionsOzon = ({
  setShowTooltipParametr,
  setShowTooltipLauncAdvertisingCampaignInfo,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const location = useLocation();
  const path = location?.pathname;

  const navigate = useNavigate();
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [date, setDate] = useState([
    new Date().getTime() - 604800000 + 86400000 * 30,
    new Date().getTime() + 604800000 + 86400000 * 30,
  ]);
  const [dateFrom, dateTo] = date;
  const [range, setRange] = useState(0);
  const [showDropBox, setShowDropBox] = useState(false);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [apiErr, setApiErr] = useState(false);
  const [marketplaceErr, setMarketplaceErr] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [chartDataName, setChartDataName] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [tooltip, setTooltip] = useState(true);

  const [currentStrategy, setCurrentStrategy] = useState(null);
  const [currentStrategyName, setCurrentStrategyName] = useState(null);
  const [currentStrategyNameForPortal, setCurrentStrategyNameForPortal] =
    useState(null);

  const [
    isModalCreatingAnAdvertisingCampaign,
    setIsModalCreatingAnAdvertisingCampaign,
  ] = useState(false);

  const [currentMetricsItem, setCurrentMetricsItem] = useState(null);
  const [isModalChoiceOfStrategy, setIsModalChoiceOfStrategy] = useState(false);

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [tableFetchParam, setTableFetchParam] = useState({});
  const [checkedErr, setCheckedErr] = useState(false);
  const [checkedStateEmpty, setCheckedStateEmpty] = useState(false);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  // Выбор карточки метрики
  const [currentMetrics, setCurrentMetrics] = useState([]);
  const [loadingMetrics, setLoadingMetrics] = useState(false);

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setCheckedProducts([]);
    setCheckedStateEmpty(true);
    setFetchedData([]);
    setHeaders([]);
    setSort({});
  };

  const fetchTableData = (params) => {
    if (!pageValue) return;
    if (
      !selectedApi.value ||
      !selectedCategory.length ||
      !selectedBrand.length ||
      !selectedMarketplace
    )
      return;

    setLoading(true);

    let sort, filtersParam;
    if (params) {
      [sort, filtersParam] = params;
    }

    // const url = `${OlegHttps}/api/v2/marketing/table/${pageValue}?marketplace_param=${
    //   selectedMarketplace?.value?.id
    // }${
    //   sort?.length > 0
    //     ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1]
    //     : ''
    // }`;

    const url = `${OlegHttps}/api/v2/api/v2/marketing/table/${pageValue}${
      sort?.length > 0
        ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1]
        : ''
    }`;

    let api_id_list;
    let brand_id_list;
    let category_id_list;

    if (selectedApi?.value) {
      api_id_list = [String(selectedApi?.value)];
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const [date_from, date_to] = date;

    const body = JSON.stringify({
      filters: filtersParam,
      category_id_list,
      brand_id_list,
      api_id_list,
      date_from,
      date_to,
      offer_id_list: ['all'],
      order_param: sort?.[0],
      order_param_direction: sort?.[1],
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
    });

    // {
    //   "filters": [
    //     [
    //       "string"
    //     ]
    //   ],
    //   "page": 0,
    //   "limit": 0,
    //   "offer_id_list": [
    //     "all"
    //   ],
    //   "date_from": "2024-09-03",
    //   "date_to": "2024-09-03",
    //   "category_id_list": [
    //     "string"
    //   ],
    //   "brand_id_list": [
    //     "string"
    //   ],
    //   "api_id_list": [
    //     "string"
    //   ]
    // }

    setTableFetchParam(JSON.parse(body));

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error('bad table response');
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const fetchChartData = () => {
    if (!pageValue) return;
    if (
      !selectedApi.length ||
      !selectedCategory.length ||
      !selectedBrand.length
    )
      return;

    setChartData([]);
    setChartLoading(true);

    const url = `${OlegHttps}/api/v1/marketing/graph/date/${pageValue}/${'day'}`; ///////day нужно будет спросить

    let api_id_list;
    let brand_id_list;
    let category_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => String(el.value));
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const [date_from, date_to] = date;

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      api_id_list,
      date_from,
      date_to,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => res.json())
      .then((json) => {
        const {
          datasets: { data, label },
          labels,
        } = json;
        if (data) setChartData(data);
        if (label) setChartDataName(label);
        if (labels) setLabels(labels);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setChartLoading(false));
  };

  const fetchMetricsData = (name, setState, setLoading) => {
    if (!pageValue) return;
    if (
      !selectedApi.length ||
      !selectedCategory.length ||
      !selectedBrand.length
    )
      return;

    setLoading(true);

    const url = `${OlegHttps}/api/v1/marketing/block/${name}`;

    let api_id_list;
    let brand_id_list;
    let category_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => String(el.value));
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const [date_from, date_to] = date;

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      api_id_list,
      date_from,
      date_to,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => res.json())
      .then((json) => {
        const {
          data,
          labels: { header },
        } = json;
        setState((prev) => [...prev, { data, header }]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const returnTooltip = () => {
    const ind = btn_tab?.findIndex((el) => el?.value == pageValue);
    if (ind) {
      return btn_tab[ind]?.tooltip || '';
    }
  };

  useEffect(() => {
    setPageValue(btn_tab[0].value);
  }, []);

  useEffect(() => {
    setShowTooltipParametr(true);
    setShowTooltipLauncAdvertisingCampaignInfo(true);
    return () => {
      setShowTooltipParametr(false);
      setShowTooltipLauncAdvertisingCampaignInfo(false);
    };
  }, []);

  useEffect(() => {
    if (pageValue) {
      const currentTab = path?.split('/').at(-1);
      setPageValue(currentTab);
    }
  }, [path]);

  useEffect(() => {
    const rangeDate = (dateTo - dateFrom) / 86400000 + 1;
    setRange(rangeDate?.toString()?.split(',')?.slice(0, 1)?.join(''));
  }, [dateFrom, dateTo]);

  useEffect(() => {
    resetTable();
    if (
      selectedMarketplace?.label &&
      selectedApi.value &&
      selectedBrand.length > 0 &&
      selectedCategory.length > 0
    ) {
      fetchTableData();
      fetchChartData();
      setCurrentMetrics([]);
      metrics_current[pageValue]?.map((el) =>
        fetchMetricsData(el, setCurrentMetrics, setLoadingMetrics)
      );
    }
  }, [
    selectedSpace,
    selectedMarketplace,
    selectedApi,
    selectedBrand,
    selectedCategory,
    pageValue,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: selectedMarketplace?.value?.name,
      selectedShop: [
        {
          label: selectedMarketplace?.value?.name,
          value: selectedMarketplace?.value?.id,
        },
      ],
    });
  }, [selectedSpace, apiPage, apiQuery, selectedMarketplace]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [selectedSpace, apiData, selectedApi, categoryPage, categoryQuery]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    brandPage,
    brandQuery,
  ]);

  useEffect(() => {
    setSelectedApi([]);
  }, [selectedSpace, selectedMarketplace]);

  const createTemp = () => {
    setChartData([]);
    setChartLoading(true);

    const url = `${RavshanHttps}/api/campaign/tmp/table/create`;

    let marketplace_param = String(selectedMarketplace?.value?.id);
    let table_name = pageValue;
    let param = tableFetchParam;
    let session_id = sessionStorage.getItem('session_id');
    // let product_id = checkedProducts?.map(({ SKU }) => SKU);
    let product_id = checkedProducts?.map(({ product_id }) => product_id);

    const body = JSON.stringify({
      table_name,
      marketplace_param,
      param,
      session_id,
      product_id,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setChartLoading(false));
  };

  const onCreateAddHandler = (all = false) => {
    if (!selectedApi?.value) {
      setApiErr(true);
      setTimeout(() => {
        setApiErr(false);
      }, 1000);
      return;
    }

    if (!selectedMarketplace?.value) {
      setMarketplaceErr(true);
      setTimeout(() => {
        setMarketplaceErr(false);
      }, 1000);
      return;
    }

    if (!all) {
      if (!checkedProducts?.length > 0) {
        setCheckedErr(true);
        setTimeout(() => {
          setCheckedErr(false);
        }, 1000);
        return;
      }
    }

    createTemp();
    setIsModalCreatingAnAdvertisingCampaign(true);
  };

  useEffect(() => {
    if (!isModalCreatingAnAdvertisingCampaign) {
      if (!isModalChoiceOfStrategy) {
        localStorage.removeItem('campaignName');
        localStorage.removeItem('budget');
        localStorage.removeItem('date');
      }
    }
  }, [isModalCreatingAnAdvertisingCampaign]);

  return (
    <>
      {isModalChoiceOfStrategy && (
        <ModalChoiceOfStrategyAnalyticalCollections
          setIsModal={(e) => setIsModalChoiceOfStrategy(e)}
          currentStrategy={currentStrategy}
          setCurrentStrategy={(e) => setCurrentStrategy(e)}
          setCurrentStrategyName={(e) => setCurrentStrategyName(e)}
          setIsModalCreatingAnAdvertisingCampaign={(e) =>
            setIsModalCreatingAnAdvertisingCampaign(e)
          }
          setCurrentStrategyNameForPortal={(e) =>
            setCurrentStrategyNameForPortal(e)
          }
        />
      )}

      {isModalCreatingAnAdvertisingCampaign && (
        <ModalCreatingAnAdvertisingCampaignV2
          dataTable={checkedProducts}
          selectedApi={selectedApi}
          selectedMarketplace={selectedMarketplace}
          selectedBrand={selectedBrand}
          selectedCategory={selectedCategory}
          currentStrategy={currentStrategy}
          currentStrategyName={currentStrategyName}
          currentStrategyNameForPortal={currentStrategyNameForPortal}
          setIsModalChoiceOfStrategy={(e) => setIsModalChoiceOfStrategy(e)}
          isModalChoiceOfStrategy={isModalChoiceOfStrategy}
          setIsModal={(e) => setIsModalCreatingAnAdvertisingCampaign(e)}
          tableDate={date}
          setDataTable={(e) => setCheckedProducts(e)}
          tablePageValue={pageValue}
        />
      )}

      <div className="analytical-collections">
        <h1 className="title_main-page">Аналитические подборки</h1>

        <div className="toolbar-top">
          <FilterContainer
            onReset={(e) => {
              setSelectedMarketplace([]);
              setSelectedApi([]);
              setSelectedCategory([]);
              setSelectedBrand([]);
            }}
          >
            <DropDownSelector
              err={marketplaceErr}
              options_prop={options_prop}
              state={selectedMarketplace}
              setState={(e) => {
                setSelectedMarketplace(e);
              }}
              className="connections_page_selector"
              placeholder="Маркетплейс"
              defaultValue={options_prop[0]}
            />

            <DropDownSelector
              err={apiErr}
              state={selectedApi}
              options_prop={apiData}
              setState={(e) => setSelectedApi(e)}
              className="connections_page_selector"
              placeholder="Площадка"
              fetchCallback={(e) => setApiPage(e)}
              setInputChange={(e) => setApiQuery(e)}
            />
            <DropDownSelector
              state={selectedCategory}
              options_prop={categoryData}
              setState={(e) => setSelectedCategory(e)}
              className="connections_page_selector"
              placeholder="Категория"
              defaultValue={[{ label: 'Все', value: 'all' }]}
              fetchCallback={(e) => setCategoryPage(e)}
              setInputChange={(e) => setCategoryQuery(e)}
              multi
            />
            <DropDownSelector
              state={selectedBrand}
              options_prop={brandData}
              setState={(e) => setSelectedBrand(e)}
              className="connections_page_selector"
              placeholder="Бренд"
              defaultValue={[{ label: 'Все', value: 'all' }]}
              fetchCallback={(e) => setBrandPage(e)}
              setInputChange={(e) => setBrandQuery(e)}
              multi
            />
          </FilterContainer>

          <RangeBox setDate={(e) => setDate(e)} btn_period={false} />
        </div>

        <Tabs tabs={btn_tab} pageValue={pageValue} />

        <DropContainer
          showDropBox={showDropBox}
          setShowDropBox={(e) => setShowDropBox(e)}
        >
          <div className="charts-and-metrics">
            <LineChart
              datasets_prop={chartData}
              labels_prop={labels}
              data_name={chartDataName}
              loading={chartLoading}
              scaleLabel={['abc', 'cba']}
            />

            <div className="metrics-box">
              {currentMetrics?.length < 1 ? (
                <>
                  <MetricsItem
                    key={0 + '-' + labels}
                    data={[]}
                    header={''}
                    period={range}
                    loading={loadingMetrics}
                    currentMetrics={currentMetricsItem}
                    setCurrentMetrics={(e) => setCurrentMetricsItem(e)}
                    long
                  />
                  <MetricsItem
                    key={1 + '-' + labels}
                    data={[]}
                    header={''}
                    period={range}
                    loading={loadingMetrics}
                    currentMetrics={currentMetricsItem}
                    setCurrentMetrics={(e) => setCurrentMetricsItem(e)}
                    long
                  />
                  <MetricsItem
                    key={2 + '-' + labels}
                    data={[]}
                    header={''}
                    period={range}
                    loading={loadingMetrics}
                    currentMetrics={currentMetricsItem}
                    setCurrentMetrics={(e) => setCurrentMetricsItem(e)}
                    long
                  />
                  <MetricsItem
                    key={3 + '-' + labels}
                    data={[]}
                    header={''}
                    period={range}
                    loading={loadingMetrics}
                    currentMetrics={currentMetricsItem}
                    setCurrentMetrics={(e) => setCurrentMetricsItem(e)}
                    long
                  />
                </>
              ) : (
                currentMetrics.map((el, ind) => {
                  const { data, header } = el;
                  return (
                    <MetricsItem
                      key={ind + data + labels}
                      data={data}
                      title={header}
                      period={range}
                      loading={loadingMetrics}
                      currentMetrics={currentMetricsItem}
                      setCurrentMetrics={(e) => setCurrentMetricsItem(e)}
                      long
                    />
                  );
                })
              )}
            </div>
          </div>
        </DropContainer>

        {tooltip && returnTooltip() && (
          <TooltipYellow
            staticTooltip
            showTooltip={tooltip}
            setShowTooltip={(e) => setTooltip(e)}
            text={returnTooltip()}
          />
        )}

        <TableBox
          onCheck={(e) => setCheckedProducts(e)}
          ref={paginatorRef}
          paginator={true}
          fetchCallback={fetchTableData}
          setCheckedStateEmpty={
            checkedStateEmpty ? (e) => setCheckedStateEmpty(e) : false
          }
          noDataText={
            pageValue == 'high_margin' ? (
              <p>
                Для загрузки данных таблицы добавьте себестоимость по товарам в
                разделе{' '}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      '/price-management/set-price-and-margin/by-your-products'
                    );
                  }}
                  href="#"
                  style={{ color: '#1890FF' }}
                >
                  Управление ценами
                </a>
              </p>
            ) : null
          }
          {...tableProps}
          tableUrl={tableUrl}
          tableBody={tableBody}
          child={
            <>
              <div className="btn-group-panel">
                <ButtonBasic
                  green
                  text={`Создать кампанию со всеми товарами - ${tableProps?.total}`}
                  // onClick={(e) => onCreateAddHandler(true)}
                  width="370px"
                  size="32px"
                />

                <ButtonBasic
                  lilac
                  disabled={!checkedProducts?.length > 0}
                  text={`Создать кампанию с выбранными товарами - ${
                    checkedProducts?.length > 0 ? checkedProducts?.length : '0'
                  }`}
                  onClick={(e) => onCreateAddHandler()}
                  width="370px"
                  size="32px"
                />
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

export { AnalyticalCollectionsOzon };
