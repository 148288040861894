import { useState } from 'react'
import "./ProductImprovement_EmojiAddition.scss"
import parse from 'html-react-parser'

const ProductImprovement_EmojiAddition = ({oldDesc, newDesc}) => {

    return (
        <section className='emoji-addition-inside-content'>
            <div className='old-description'>
                <h2 className='title'>Старое описание</h2>
                <p className='text'>{parse(oldDesc ? oldDesc : 'Описание отсутствует')}</p>
            </div>
            <div className='new-description'>
                <h2 className='title'>Новое описание</h2>
                <p className='text'>{parse(newDesc ? newDesc : 'Описание отсутствует')}</p>
            </div>
        </section>
    )
}

export { ProductImprovement_EmojiAddition }