import React from 'react'
import './CardDiscount.scss'

const CardDiscount = ({ key, header, data, title, loading }) => {

    return (
        <div className='card-discount' key={key}>
            <h3 className='title_card'>Скидка</h3>

            <div className='card-discount__content'>
                {Array.isArray(header) ?
                    <p className='text_header'>{header[0] + ' - '}
                        <span>{Array.isArray(data) && data[0]}</span>
                    </p>
                    :
                    <p className='text_header'>-</p>
                }

                {Array.isArray(header) ?
                    <p className='text_header'>{header[1] + ' - '}
                        <span>{Array.isArray(data) && data[1]}</span>
                    </p>
                    :
                    <p className='text_header'>-</p>
                }
            </div>

            <div className='discount-content'>
                <p className='text'>{title ? title.split('%')[0] : '-'}<span>{'%'}</span></p> 
                <span className='text_min'>{title ? title.split('%')[1] : ''}</span>
            </div>
        </div>
    )
}

export { CardDiscount }