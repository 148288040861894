import scan_icon from './img/scan_icon.svg';
import print_icon from './img/print_icon.svg';
import arhive_icon from './img/archive_icon.svg';
import { CardOrder } from '../CardOrder/CardOrder';
import { useParams } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { fetchOrdersFilter } from '../../common/filtersFunc';
import { useState, useEffect, useRef } from 'react';
import { ModalGeneralOrderInformation } from '../Modal/ModalGeneralOrderInformation/ModalGeneralOrderInformation';
import { ArtemOrdersHttps, ArtemOrdersHttp } from '../../fetchUrls';
import {
  DropDownSelector,
  TableBox,
  Tabs,
  RangeBox,
  ButtonBasic,
} from '@lk-gtcom/ecomlab-components';
import './Deliveries.scss';
import useGeneralStore from '../../store/general';

const marketplaceOption = [
  {
    label: 'Ozon',
    value: 'ozon',
    id: 1,
  },
  {
    label: 'Wildberries',
    value: 'wb',
    id: 2,
  },
  {
    label: 'Yandex',
    value: 'yandex',
    id: 3,
  },
];

const btn_tab = [
  {
    label: 'Ожидает упаковки',
    value: 'awaiting_packaging',
    counter: 1,
  },
  {
    label: 'Список поставок',
    value: 'supply_list',
  },
  {
    label: 'Ожидает доставки',
    value: 'awaiting_delivery',
  },
];

const Deliveries = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const activeTab = useParams()['*'];
  const [pageValue, setPageValue] = useState(btn_tab[0]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [warehousePage, setWarehousePage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [warehouseQuery, setWarehouseQuery] = useState('');

  const [apiData, setApiData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [currentRange, setCurrentRange] = useState({});
  const [tabs, setTabs] = useState([]);
  const [dateList, setDateList] = useState([]);

  const [isModalGeneralOrderInformation, setIsModalGeneralOrderInformation] =
    useState(false);
  const [postingNumber, setPostingNumber] = useState(null);
  const [currentCard, setCurrentCard] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilter({
      selectedSpace: selectedSpace?.value,
      target: 'api_id',
      type: selectedMarketplace?.value,
      page: apiPage,
      setData: (e) => setApiData(e),
      searchParam: apiQuery,
      categoryData,
      selectedCategory,
      brandData,
      selectedBrand,
      warehouseData,
      selectedWarehouse,
      dateFrom,
      dateTo,
      withAll: false,
      abortController,
    });

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedCategory,
    selectedBrand,
    selectedWarehouse,
    selectedMarketplace,
    apiPage,
    apiQuery,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilter({
      selectedSpace: selectedSpace?.value,
      target: 'category',
      type: selectedMarketplace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      setData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
      apiData,
      selectedApi,
      brandData,
      selectedBrand,
      warehouseData,
      selectedWarehouse,
      abortController,
    });

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedApi,
    selectedBrand,
    selectedWarehouse,
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery,
    selectedMarketplace,
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilter({
      selectedSpace: selectedSpace?.value,
      target: 'brand',
      type: selectedMarketplace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setData: (e) => setBrandData(e),
      apiData,
      selectedApi,
      categoryData,
      selectedCategory,
      warehouseData,
      selectedWarehouse,
      abortController,
    });

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedApi,
    selectedCategory,
    selectedWarehouse,
    selectedMarketplace,
    brandPage,
    brandQuery,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilter({
      selectedSpace: selectedSpace?.value,
      target: 'warehouse',
      type: selectedMarketplace?.value,
      page: warehousePage,
      apiData,
      warehouseData,
      selectedApi,
      selectedWarehouse,
      searchParam: warehouseQuery,
      setData: (e) => setWarehouseData(e),
      apiData,
      selectedApi,
      categoryData,
      selectedCategory,
      brandData,
      selectedBrand,
      abortController,
    });

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedApi,
    selectedCategory,
    selectedBrand,
    selectedMarketplace,
    warehousePage,
    warehouseQuery,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  return (
    <>
      {isModalGeneralOrderInformation && (
        <ModalGeneralOrderInformation
          apiData={apiData}
          postingNumber={postingNumber}
          setIsModal={(e) => setIsModalGeneralOrderInformation(e)}
        />
      )}

      <div className="deliveries">
        <div className="content-1440">
          <h1 className="title_main">Поставки</h1>

          <div className="rich__select-box">
            <FilterContainer
              onReset={(e) => {
                setSelectedApi([]);
                setSelectedCategory([]);
                setSelectedBrand([]);
                setSelectedWarehouse([]);
              }}
            >
              <DropDownSelector
                options_prop={marketplaceOption}
                state={selectedMarketplace}
                setState={(e) => setSelectedMarketplace(e)}
                placeholder="Площадка"
                defaultValue={marketplaceOption[0]}
              />

              <DropDownSelector
                options_prop={apiData}
                state={selectedApi}
                setState={(e) => setSelectedApi(e)}
                placeholder="Магазин"
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
              />

              <DropDownSelector
                options_prop={warehouseData}
                state={selectedWarehouse}
                setState={(e) => setSelectedWarehouse(e)}
                placeholder="Склад"
                fetchCallback={(e) => setWarehousePage(e)}
                setInputChange={(e) => setWarehouseQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
              />

              <DropDownSelector
                options_prop={categoryData}
                state={selectedCategory}
                setState={(e) => setSelectedCategory(e)}
                placeholder="Категория"
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
              />

              <DropDownSelector
                options_prop={brandData}
                state={selectedBrand}
                setState={(e) => setSelectedBrand(e)}
                placeholder="Бренд"
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
              />
            </FilterContainer>
          </div>

          <Tabs tabs={btn_tab} pageValue={pageValue} />
        </div>

        <TableBox
          ref={paginatorRef}
          {...tableProps}
          paginator={true}
          child={
            <div className="btn-group">
              <div className="group-left">
                <TooltipLightBlue
                  text="Сканировать"
                  top="calc(100% + 4px)"
                  left="0"
                  child={
                    <button className="btn-icon">
                      <img src={scan_icon} />
                    </button>
                  }
                />

                <TooltipLightBlue
                  text="Выгрузить заказы"
                  top="calc(100% + 4px)"
                  left="0"
                  child={
                    <button className="btn-icon">
                      <img src={arhive_icon} />
                    </button>
                  }
                />

                <TooltipLightBlue
                  text="Распечатать лист подбора"
                  top="calc(100% + 4px)"
                  left="0"
                  child={
                    <button className="btn-icon">
                      <img src={print_icon} />
                    </button>
                  }
                />
              </div>

              <div className="group-right">
                <ButtonBasic
                  action
                  size="32px"
                  text="Создать новую поставку"
                  width="fit-content"
                  onClick={(e) => e}
                />

                <ButtonBasic
                  grey
                  size="32px"
                  text="Добавить в поставку"
                  width="fit-content"
                  onClick={(e) => e}
                />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};

export { Deliveries };
