import { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalDeleteGroup.scss'

const ModalDeleteGroup = ({ setIsModal, id, setId, onDeleteHandler }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-delete-group'>
                <h2 className='title'>Вы точно хотите группу?</h2>
                <div className='group-btn'>
                    <ButtonBasic
                        grey
                        size='56px'
                        onClick={(e) => setIsModal(false)}
                        width='calc(50% - 6px)'
                        text='Нет'
                    />

                    <ButtonBasic
                        violet
                        size='56px'
                        onClick={(e) => {
                            onDeleteHandler(id)
                            setId(null)
                            setIsModal(false)
                        }}
                        width='calc(50% - 6px)'
                        text='Да'
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalDeleteGroup }