import { useEffect, useState, useRef } from 'react';
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { OlegHttps, OlegHttp } from '../../fetchUrls';
import customHeaders, {getSpace} from '../../common/headers';
import useGeneralStore from '../../store/general';
import "./PaymentHistory.scss"

const PaymentHistory = () => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = {...customHeaders, ...getSpace(selectedSpace)}
  const [fetchedData, setFetchedData] = useState([])
  const [tableHeaders, setTableHeaders] = useState([])
  const [sort, setSort] = useState({})
  const [columnSize, setColumnSize] = useState([])
  const paginatorRef = useRef()
 const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchPaymentTable = (params) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params
    }
    setLoading(true)

    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/payment/info/table/increase${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
    } else {
      url = `${OlegHttp}/api/v1/payment/info/table/increase${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
    }

    const body = JSON.stringify({
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam
    })

    fetch(url, { body, method: 'POST', headers })
      .then(res => res.json())
      .then(json => {
        const { data, labels, total, sort, column_size } = json
        if (total) paginatorRef.current.setTotal(total)
        if (sort) setSort(sort)
        if (labels) setTableHeaders(labels)
        if (column_size) setColumnSize(column_size)
        setFetchedData(data.length > 0 ? data : [[]])
        setLoading(false)
        setTableUrl(url) 
                setTableBody(body)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })

  }
  const onAction = () => {
    console.log('your action is click');
  }

  useEffect(() => {
    fetchPaymentTable()
  }, [selectedSpace])

  return (
    <div className='payment-history'>
      <TableBox
        tableUrl={tableUrl}
                    tableBody={tableBody}
        loading={loading}
        fetchedData={fetchedData}
        headers={tableHeaders}
        paginator={true}
        onAction={e => onAction(e)}
        fetchCallback={e => fetchPaymentTable(e)}
        sortingFunc={e => fetchPaymentTable(e)}
        columnSize={columnSize}
        sort={sort}
        ref={paginatorRef}
      />
    </div>
  )
}

export { PaymentHistory }