import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ModalSearchByInn from '../Modal/ModalSearchByInn/ModalSearchByInn'
import { InputDinamycPlaceholder, ButtonBasic } from "@lk-gtcom/ecomlab-components"
import { ModalChangeOfLegalEntities } from '../Modal/ModalChangeOfLegalEntities/ModalChangeOfLegalEntities';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful';
import { TooltipMui } from '../TooltipMui/TooltipMui';
import { ButtonCopy } from '../ButtonCopy/ButtonCopy';
import "./InformationForBilling.scss"
import { ButtonBase } from '@mui/material';

const InformationForBilling = () => {

    const [showModal, setShowModal] = useState(false)
    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [showModalLegalEntities, setShowModalLegalEntities] = useState(false)
    const [editable, setEditable] = useState(false)

    const [listField, setListField] = useState([
        {
            label: 'Страна организации',
            value: 'РФ',
            copy: false
        },
        {
            label: 'Название организации',
            value: 'ООО Компания',
            copy: true
        }
    ])


    return (
        <>
            {showModal && <ModalChangeOfLegalEntities setShowModal={(e) => setShowModal(e)} />}
            {showModalSuccess && <ModalSuccessful title='Ваш запрос успешно отправлен' setIsModal={(e) => setShowModalSuccess(e)} />}
            <div className='information-for-billing'>
                <h3 className='title__main'>Информация для выставления счетов и актов</h3>
                <ButtonBasic
                    lilac
                    width='302px'
                    text='Редактировать юридические данные'
                    size='56px'
                    onClick={(e) => setEditable(true)}
                />
                <div className='information-for-billing__content'>
                    <h3 className='title'>Юридическое лицо</h3>
                    {editable ?
                        <>
                            <InputDinamycPlaceholder
                                onChange={(e) => (e)}
                                type="text"
                                classname='autorization__input-field'
                                placeholder='Введите ИНН организации*'
                                autoComplete={false}
                            />

                            <button className='btn__blue'>Найти по ИНН</button>
                        </>
                        :
                        <>
                            <div className='non-editable-fields'>
                                {listField.map(({ label, value, copy }) =>
                                    <div className='non-editable-fields__item'>
                                        <div className='val-box'>
                                            <span className='placeholder'>{label}</span>
                                            <p className='text'>{value}</p>
                                            {copy && <ButtonCopy value={value} />}
                                        </div>
                                    </div>
                                )}

                                <div className='non-editable-fields-group'>
                                    <div className='non-editable-fields__item'>
                                        <div className='val-box'>
                                            <span className='placeholder'>ИНН организации</span>
                                            <p className='text'>1234597543</p>
                                            <ButtonCopy value={'1234597543'} />
                                        </div>
                                    </div>
                                    <div className='non-editable-fields__item'>
                                        <div className='val-box'>
                                            <span className='placeholder'>КПП организации</span>
                                            <p className='text'>1234597544</p>
                                            <ButtonCopy value={'1234597544'} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {listField.map(({ label, value, copy }) =>
                                <div className='non-editable-fields__item'>
                                    <div className='val-box'>
                                        <span className='placeholder'>{label}</span>
                                        <p className='text'>{value}</p>
                                        {copy && <ButtonCopy value={value} />}
                                    </div>
                                </div>
                            )}
                        </>

                    }
                </div>

            </div>

            {editable && <div className='information-for-billing'>

                <InputDinamycPlaceholder
                    type="text"
                    classname='autorization__input-field'
                    placeholder='Страна организации*'
                    autoComplete={false}
                />

                <InputDinamycPlaceholder
                    type="text"
                    classname='autorization__input-field'
                    placeholder='Название организации*'
                    autoComplete={false}
                />

                <div className='input-group'>
                    <InputDinamycPlaceholder
                        type="text"
                        classname='autorization__input-field'
                        placeholder='ИНН организации*'
                        autoComplete={false}
                    />

                    <InputDinamycPlaceholder
                        type="text"
                        classname='autorization__input-field'
                        placeholder='К*'
                        autoComplete={false}
                    />
                </div>

                <InputDinamycPlaceholder
                    type="text"
                    classname='autorization__input-field'
                    placeholder='Юридический адрес*'
                    autoComplete={false}
                />

                <InputDinamycPlaceholder
                    type="text"
                    classname='autorization__input-field'
                    placeholder='Почтовый адрес*'
                    autoComplete={false}
                />

                <InputDinamycPlaceholder
                    type="text"
                    classname='autorization__input-field'
                    placeholder='Телефон'
                    autoComplete={false}
                />

                <InputDinamycPlaceholder
                    type="text"
                    classname='autorization__input-field'
                    placeholder='E-mail бухгалтера'
                    autoComplete={false}
                />

                <div className='group-btn'>
                    <button className='btn__white'>Сохранить черновик</button>
                    <button className='btn__blue'
                        onClick={(e) => setEditable(false)}
                    >Сохранить данные</button>
                </div>
            </div>}
        </>
    )
}

export { InformationForBilling }
