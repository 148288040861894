import { useEffect, useState } from 'react'
import mock_img from './img/mock_icon.svg'
import eye from './img/eye_icon.svg'
import drr from './img/drr_icon.svg'
import max from './img/max_icon.svg'
import arrows from './img/arrows_icon.svg'
import traffic from './img/traffic_icon.svg'
import { OlegHttps, OlegCreateAddCampaingHttps } from '../../../../fetchUrls'
import { fromSnakeCase } from '../../../../common/mappers'
import customHeaders, {getSpace} from '../../../../common/headers'
import './ModalChoiceOfStrategyAnalyticalCollections.scss'
import { TooltipBlack } from '../../../Tooltip/TooltipBlack/TooltipBlack'
import useGeneralStore from '../../../../store/general'

const steps = [
    { label: 'Параметры кампании' },
    { label: 'Выбор стратегии' },
    { label: 'Запуск' }
]



const ModalChoiceOfStrategyAnalyticalCollections = ({ setIsModal, setIsModalCreatingAnAdvertisingCampaign, currentStrategy, setCurrentStrategy, setCurrentStrategyName, setCurrentStrategyNameForPortal, setIsModalStrategyParameters }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const arrIcons = [eye, traffic, max, drr, arrows]
    const [strategy_item, setStrategyItem] = useState([])
    const [activeStep, setActiveStep] = useState(new Set(['Параметры кампании']))

    const fetchStrategies = () => {
        const url = `${OlegCreateAddCampaingHttps}/api/v1/strategy/all`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error('bad table response')
                }
            })
            .then(json => {
                setStrategyItem(json)
            })
            .catch(e => console.error(e))
    }

    useEffect(() => {
        fetchStrategies()
    }, [selectedSpace])


    useEffect(() => {
        let newState
        if(currentStrategy) {
            newState = activeStep.add('Выбор стратегии')
        } else {
            newState = activeStep.delete('Выбор стратегии')       
        }
        setActiveStep(activeStep)
    }, [currentStrategy]);

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el?.classList?.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-choice-of-strategy' style={{height: '70vh', maxWidth: '60%', position: 'relative'}}>
                <h2 className='title'>Выберите стратегию</h2>

                <div className='steps-items'>
                    {steps?.map(({ label }, ind) => {
                        return <div key={ind+label} className={activeStep.has(label) ? 'step-item_active' : 'step-item'}>
                            <div className='step-num'>{ind + 1}</div>
                            <div className='step-text'>{label}</div>
                        </div>
                    }
                    )}
                </div>

                <div className='modal-choice-of-strategy__content' style={{maxWidth: '90%', maxHeight: 'unset', height: '60%'}}>
                    {strategy_item?.map(({ id, description, name_for_portal, strategy_name }, ind) =>
                     <div className={currentStrategy == id ? 'strategy-item_active' : 'strategy-item'} key={id + name_for_portal + strategy_name}
                        onClick={(e) => {
                            setCurrentStrategy(id)
                            setCurrentStrategyName(strategy_name)
                            setCurrentStrategyNameForPortal(name_for_portal)
                        }}
                        style={{position: 'relative'}}
                    >
                        <img src={arrIcons[ind]} />
                        <p className='text'>{name_for_portal}</p>
                        <TooltipBlack
                            child={
                                <p>Подробнее</p>
                            }
                            top={'-1%'}
                            position={'absolute'}
                            text={description}
                        />
                    </div>
                      
                    )}
                </div>
                <button className='btn__blue'
                    onClick={(e) => {
                        setIsModalCreatingAnAdvertisingCampaign(true)
                        setIsModal(false)
                    }}
                >Выбрать</button>
            </div>
        </div>
    );
}

export { ModalChoiceOfStrategyAnalyticalCollections };