import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { BarLoader } from 'react-spinners'
import { banner_loader } from '../../common/svg_img'
import { refresh } from '../../common/authHelper'


const ServiceAuth = () => {
    const path = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    const checkToken = (refresh_token) => {
        setIsLoading(true)
        refresh({
            token: refresh_token,
            onError: err => {
                console.error(err)
                setIsError(true)
                setTimeout(() => {
                    navigate('../auth')
                }, 3000)
            },
            onCleanup: () => setIsLoading(false)
        })
    }

    useEffect(() => {
        let refresh = path['*']
        checkToken(refresh)
    }, [])

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {
                isLoading ?
                    <>
                        <div className="main-loader-box">
                            <div className='main-loader-box__content'>
                                <embed type='image/svg+xml' src={banner_loader} />
                                <div className='progress'>
                                    < BarLoader
                                        color="#27BC62"
                                        height='24'
                                        width='480'
                                        speedMultiplier={1} />
                                </div>
                                <h1 className='title'>Загружаем данные</h1>
                            </div>
                        </div>
                    </>
                    :
                    <h1 style={{ textAlign: 'center', color: isError ? 'red' : 'black', fontSize: '31px', margin: 0 }} >{isError ?
                        'Что-то пошло не так,' : 'Операция прошла успешно,'}
                        <br /> скоро вы будете перенаправлены
                    </h1>
            }
        </div>
    )
}

export { ServiceAuth }