import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useState, useEffect, useRef } from 'react';
import { TableBox, ButtonBasic, DropDownSelector, SearchComponent, Tabs } from "@lk-gtcom/ecomlab-components";
import x_icon from './img/X.png'
import y_icon from './img/Y.png'
import setting_icon from './img/setting.png'
import './SummaryMatrices.scss'

const btns =  [
    {
        label: 'Товары',
        icon: x_icon
    },
    {
        label: 'Категории',
        icon: y_icon
    },
    {
        label: 'Мин. цена конкурентов',
        icon: setting_icon
    }
]

const SummaryMatrices = ({ }) => {

    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])

    return (
        <div className='summary-matrices'>
            <h1 className='summary-matrices__title-main'>Сводные матрицы</h1>

            <div className='summary-matrices__toolbar-top'>
                <FilterContainer
                    onReset={(e) => {
                        setSelectedCategory([])
                        setSelectedBrand([])
                    }}
                >
                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Ось Y'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Ось X'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={e => (e)}
                        placeholder='Анализируемый показатель'
                    />

                </FilterContainer>

                <ButtonBasic
                    green
                    width='150px'
                    text='Создать товар'
                />
            </div>

            <TableBox
                loading={loading}
                fetchedData={[]}
                headers={[]}
                child={
                    <>
                    {btns.map(({label, icon}) => {
                        return <button className='summary-matrices__btn-table'>
                            <img src={icon}/>
                            {label}
                        </button>
                    })}
                    </>
                }
            />
        </div>
    )
}

export { SummaryMatrices }