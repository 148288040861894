import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import feedback_img from './img/feedback_img.svg'
import { InputDinamycPlaceholder, TextareaDynamicPlaceholder, ButtonBasic } from "@lk-gtcom/ecomlab-components"
import { Textarea } from '../../Textarea/Textarea'
import './ModalFeedback.scss'

const ModalFeedback = ({ setIsModal, setIsModalFeedbackDone }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [question, setQuestion] = useState('')

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-feedback'>
                <h2 className='title'>Обратная связь</h2>
                <p className='text_grey-blue'>Если у Вас возникли вопросы, напишите нам в форме ниже, в самое ближайшее время мы свяжемся с Вами</p>
                <img src={feedback_img} />

                <div className='modal-feedback__content'>
                    <TextareaDynamicPlaceholder
                        width='100%'
                        changeValue={question}
                        placeholder="Вопрос или комментарий*"
                        onChange={(e) => setQuestion(e)}
                    />

                    <InputDinamycPlaceholder
                        onChange={(e) => setName(e)}
                        type="text"
                        classname='autorization__input-field'
                        placeholder="Ваше имя*"
                        autoComplete={false}
                    />

                    <InputDinamycPlaceholder
                        onChange={(e) => setEmail(e)}
                        type="email"
                        classname='autorization__input-field'
                        placeholder="E-mail почта*"
                        autoComplete={false}
                    />


                </div>

                <div className='group-btn'>
                    <ButtonBasic
                        grey
                        size='56px'
                        text='Отмена'
                        width='calc(50% - 6px)'
                        onClick={(e) => setIsModal(false)}
                    />

                    <ButtonBasic
                        violet
                        size='56px'
                        text='Отправить'
                        width='calc(50% - 6px)'
                        onClick={(e) => {
                            setIsModalFeedbackDone(true)
                            setIsModal(false)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalFeedback }