import customHeaders, { getSpace } from '../../common/headers';
import scan_icon from './img/scan_icon.svg';
import print_icon from './img/print_icon.svg';
import arhive_icon from './img/archive_icon.svg';
import { CardOrder } from '../CardOrder/CardOrder';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { fetchOrdersFilterNew } from '../../common/filtersFunc';
import { ModalAboutTheOrder } from '../Modal/ModalAboutTheOrder/ModalAboutTheOrder';
import { ModalDeliveryCreation } from '../Modal/ModalDeliveryCreation/ModalDeliveryCreation';
import { useState, useEffect, useRef } from 'react';
import { ModalGeneralOrderInformation } from '../Modal/ModalGeneralOrderInformation/ModalGeneralOrderInformation';
import { ArtemOrdersHttps, ArtemOrdersHttp } from '../../fetchUrls';
import {
  DropDownSelector,
  TableBox,
  Tabs,
  RangeBox,
  ButtonBasic,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './OrderManagement.scss';

const marketplaceOption = [
  {
    label: 'Ozon',
    value: 'ozon',
    id: 1,
  },
  {
    label: 'Wildberries',
    value: 'wb',
    id: 2,
  },
  {
    label: 'Yandex',
    value: 'yandex',
    id: 3,
  },
];

const formateDate = (date) => {
  const timeZone = (new Date().getTimezoneOffset() / 60) * -3600000;
  const formate_date = new Date(
    new Date(date)?.getTime() + timeZone
  )?.toUTCString();
  return new Date(formate_date);
};

const dayMs = 86400000;
const defaultValue = [
  new Date().setHours(0, 0, 0, 0) - dayMs * 14,
  new Date().setHours(23, 59, 59, 999) - dayMs,
];

const OrderManagement = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [date, setDate] = useState([
    new Date().setHours(0, 0, 0, 0) - dayMs * 14,
    new Date().setHours(23, 59, 59, 999) - dayMs,
  ]);
  const [dateFrom, dateTo] = date;
  const navigate = useNavigate();

  const activeTab = useParams()['*'];
  const [pageValue, setPageValue] = useState(null);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [warehousePage, setWarehousePage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [warehouseQuery, setWarehouseQuery] = useState('');

  const [apiData, setApiData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState(
    marketplaceOption?.[0]
  );
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const [currentRange, setCurrentRange] = useState({});
  const [tabs, setTabs] = useState([]);
  const [dateList, setDateList] = useState([]);

  const [isModalGeneralOrderInformation, setIsModalGeneralOrderInformation] =
    useState(false);
  const [isModalAboutOrder, setIsModalAboutOrder] = useState(false);
  const [isModalDeliveryCreation, setIsModalDeliveryCreation] = useState(false);
  const [postingNumber, setPostingNumber] = useState(null);
  const [currentCard, setCurrentCard] = useState('all');
  const [contextMenuArr, setContextMenuArr] = useState([]);

  // Отменить заказы.

  useEffect(() => {
    setCurrentCard(null);
  }, [date]);

  const fetchCancelPostings = (order_id) => {
    let api_id = selectedApi?.value;
    //let api_id = "wb_unek_1"

    if (!api_id) {
      alert('Выберите магазин');
      return;
    }

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/wb-supplies/cancel-order?api_id=${api_id}`;
    } else {
      url = `${ArtemOrdersHttp}/wb-supplies/cancel-order?api_id=${api_id}`;
    }

    const body = JSON.stringify({
      // api_id: api_id,
      // posting_numbers: [''], //order_id из таблицы
      // reason_id: 352, // Код причины отмены
      // reason_message: "Товар закончился"
      order_id: order_id,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
      })
      .catch((err) => console.error(err));
  };

  const onDeleteAccountHandler = (e) => {
    fetchСancelOrders(e['Номер отправления']);
    const abortController = new AbortController();
    fetchTableData([], abortController);
  };

  const fetchTableData = (params, abortController = new AbortController()) => {
    if (!activeTab) return;
    if (!pageValue) return;

    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }
    setLoading(true);
    setFetchedData([]);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/orders/get-order-table/${activeTab}?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/orders/get-order-table/${activeTab}?filter_type=${selectedMarketplace?.value}`;
    }

    let api_id;
    let category;
    let brand;
    let warehouse;
    let date_from;
    let date_to;

    if (selectedApi?.value) {
      api_id = selectedApi?.value;
    }

    if (selectedCategory?.value) {
      category = selectedCategory?.value;
    } else {
      category = 'all';
    }

    if (selectedBrand?.value) {
      brand = selectedBrand?.value;
    } else {
      brand = 'all';
    }

    if (selectedWarehouse?.value) {
      warehouse = selectedWarehouse.value;
    } else {
      warehouse = 'all';
    }

    if (currentCard && currentCard == 'all') {
      date_from = null;
      date_to = null;
    } else if (currentCard && currentCard != 'all') {
      date_from = new Date(new Date(currentCard).getTime())
        ?.toISOString()
        .split('T')?.[0];
      date_to = new Date(currentCard)?.toISOString().split('T')?.[0];
    } else {
      if (Array.isArray(date) && date?.length > 0) {
        date_from = new Date(formateDate(dateFrom))
          ?.toISOString()
          .split('T')?.[0];
        date_to = new Date(formateDate(dateTo))?.toISOString().split('T')?.[0];
      }
    }

    const body = JSON.stringify({
      api_id,
      brand,
      category,
      warehouse,
      filters,
      sort,
      date_from,
      date_to,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      // order_status: activeTab,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('err');
        }
      })
      .then(({ table }) => {
        const { data, labels, total, sort, styles, contextMenuArr } = table;
        if (total) paginatorRef.current?.setTotal(total);
        if (contextMenuArr?.length > 0) {
          contextMenuArr[0].onClick = onDeleteAccountHandler;
          setContextMenuArr(contextMenuArr);
        }
        if (sort) setSort(sort);
        if (labels) setHeaders({ ...labels, styles });
        if (data) setFetchedData(data.length > 0 ? data : [[]]);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
    if (selectedApi?.value) {
    }
  };

  const fetchTabs = (abortController = new AbortController()) => {
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/orders/get-status-tabs?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/orders/get-status-tabs?filter_type=${selectedMarketplace?.value}`;
    }

    let api_id;
    let category;
    let brand;
    let warehouse;
    let date_from;
    let date_to;

    if (selectedApi?.value) {
      api_id = selectedApi?.value;
    }

    if (selectedCategory?.value) {
      category = selectedCategory?.value;
    } else {
      category = 'all';
    }

    if (selectedBrand?.value) {
      brand = selectedBrand?.value;
    } else {
      brand = 'all';
    }

    if (selectedWarehouse?.value) {
      warehouse = selectedWarehouse.value;
    } else {
      warehouse = 'all';
    }

    if (currentCard && currentCard == 'all') {
      date_from = null;
      date_to = null;
    } else if (currentCard && currentCard != 'all') {
      date_from = new Date(new Date(currentCard).getTime())
        ?.toISOString()
        .split('T')?.[0];
      date_to = new Date(currentCard)?.toISOString().split('T')?.[0];
    } else {
      if (Array.isArray(date) && date?.length > 0) {
        date_from = new Date(formateDate(dateFrom))
          ?.toISOString()
          .split('T')?.[0];
        date_to = new Date(formateDate(dateTo))?.toISOString().split('T')?.[0];
      }
    }

    const body = JSON.stringify({
      api_id,
      brand,
      category,
      warehouse,
      date_from,
      date_to,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ tabs }) => {
        const tabs_list = tabs.map(({ name, id, count }) => ({
          label: name,
          value: name,
          counter: count,
        }));
        setTabs(tabs_list);
        navigate(tabs_list[0]?.value);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController?.signal?.aborted) return;
        setLoading(false);
      });
  };

  const fetchTabsCounters = (abortController = new AbortController()) => {
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/get-postings/status-tabs?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/get-postings/status-tabs?filter_type=${selectedMarketplace?.value}`;
    }

    fetch(url, { method: 'POST', headers, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ options }) => {
        setTabs(options.map(({ name, id }) => ({ label: name, value: id })));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const fetchDates = (abortController = new AbortController()) => {
    if (!activeTab) return;
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/orders/get-date-options/${activeTab}?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/orders/get-date-options/${activeTab}?filter_type=${selectedMarketplace?.value}`;
    }

    let api_id;
    let category;
    let brand;
    let warehouse;

    if (selectedApi?.value) {
      api_id = selectedApi?.value;
    }

    if (selectedCategory?.value) {
      category = selectedCategory?.value;
    } else {
      category = 'all';
    }

    if (selectedBrand?.value) {
      brand = selectedBrand?.value;
    } else {
      brand = 'all';
    }

    if (selectedWarehouse?.value) {
      warehouse = selectedWarehouse.value;
    } else {
      warehouse = 'all';
    }

    const body = JSON.stringify({
      api_id,
      brand,
      category,
      warehouse,
      // page: 1,
      // limit: 10,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ options }) => {
        setDateList(options);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const onCancelHandler = (numberPosting = false, apiId, mpId) => {
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/cancel-postings?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/cancel-postings?filter_type=${selectedMarketplace?.value}`;
    }

    let api_id;

    if (selectedApi?.value) {
      api_id = selectedApi?.value;
    }

    const body = JSON.stringify({
      api_id,
      posting_numbers: numberPosting
        ? [numberPosting]
        : checkedProducts.map((el) => el?.['Номер отправления']),
      reason_id: 352,
      reason_message: 'Товар закончился',
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ options }) => {
        fetchTableData();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const onPostingLabels = (
    abortController = new AbortController(),
    numberPosting,
    apiId,
    mpId
  ) => {
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/get-posting-labels?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/get-posting-labels?filter_type=${selectedMarketplace?.value}`;
    }

    const body = JSON.stringify({
      postings: numberPosting
        ? numberPosting
        : checkedProducts.map((el) => el?.['Номер отправления']),
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ options }) => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const onPostingAsPacked = (numberPosting = false, apiId, mpId) => {
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/mark-postings-as-packed?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/mark-postings-as-packed?filter_type=${selectedMarketplace?.value}`;
    }

    const body = JSON.stringify({
      postings: numberPosting
        ? numberPosting
        : checkedProducts.map((el) => el?.['Номер отправления']),
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ options }) => {
        fetchTableData();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  // Упаковать заказы
  // Работает
  const fetchPackOrders = (order_ids) => {
    if (selectedApi?.value == 'all') {
      alert('Выберите магзин');
      return;
    }
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/orders/pack-orders?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/orders/pack-orders?filter_type=${selectedMarketplace?.value}`;
    }

    let api_id;

    if (selectedApi?.value) {
      api_id = selectedApi?.value;
    }

    const body = JSON.stringify({
      api_id,
      order_ids: order_ids,
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ options }) => {
        fetchTabs();
        fetchTableData();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  // Отменить заказы
  // Работает
  // куда попадают отмененные заказы
  // причины отмены как выводить
  const fetchСancelOrders = (order_ids) => {
    if (selectedApi?.value == 'all') {
      alert('Выберите магзин');
      return;
    }
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/orders/cancel-orders?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/orders/cancel-orders?filter_type=${selectedMarketplace?.value}`;
    }

    let api_id;

    if (selectedApi?.value) {
      api_id = selectedApi?.value;
    }

    const body = JSON.stringify({
      api_id: api_id,
      msg: 'Товар закончился',
      order_ids: order_ids,
      reason_id: 352,
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ options }) => {
        fetchTabs();
        fetchTableData();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  // Наклейки
  const fetchLabels = (order_ids) => {
    if (selectedApi?.value == 'all') {
      alert('Выберите магзин');
      return;
    }
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemOrdersHttps}/orders/get-order-labels?filter_type=${selectedMarketplace?.value}`;
    } else {
      url = `${ArtemOrdersHttp}/orders/get-order-labels?filter_type=${selectedMarketplace?.value}`;
    }

    let api_id;

    if (selectedApi?.value) {
      api_id = selectedApi?.value;
    }

    const body = JSON.stringify({
      api_id: api_id,
      order_ids: order_ids,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.arrayBuffer())
      .then((body) => {
        // setLoaderExcel(false)
        const date = new Date();
        const fileName = `orders ${date.toLocaleDateString('ru-RU', {
          dateStyle: 'short',
        })} ${date.toLocaleTimeString('ru-RU', { timeStyle: 'short' })}.pdf`;
        const blob = new Blob([body]);

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const onActionHandler = (e) => {
    const numberPosting = e?.['Номер отправления'];
    const apiId = e?.api_id;
    const mpId = e?.mp_id;
    if (e?.['Распечатать']) {
      fetchLabels(numberPosting);
    }
    if (e?.['Упаковать']) {
      fetchPackOrders(numberPosting);
    }
    // if (activeTab === 'awaiting_packaging') {
    //     onPostingAsPacked(numberPosting, apiId, mpId)
    // } else {
    //     onCancelHandler(numberPosting, apiId, mpId)
    // }
  };

  useEffect(() => {
    setCurrentCard(dateList[0]?.date);
  }, [dateList]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilterNew({
      selectedSpace: selectedSpace?.value,
      target: 'api_id',
      type: selectedMarketplace?.value,
      page: apiPage,
      setData: (e) => setApiData(e),
      searchParam: apiQuery,
      categoryData,
      selectedCategory,
      brandData,
      selectedBrand,
      warehouseData,
      selectedWarehouse,
      dateFrom,
      dateTo,
      withAll: false,
      abortController,
    });

    return () => abortController.abort();
  }, [selectedSpace, selectedMarketplace, apiPage, apiQuery, dateFrom, dateTo]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilterNew({
      selectedSpace: selectedSpace?.value,
      target: 'warehouse',
      type: selectedMarketplace?.value,
      page: warehousePage,
      apiData,
      warehouseData,
      selectedApi,
      selectedWarehouse,
      searchParam: warehouseQuery,
      setData: (e) => setWarehouseData(e),
      apiData,
      selectedApi,
      categoryData,
      selectedCategory,
      brandData,
      selectedBrand,
      abortController,
    });

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedApi,
    selectedMarketplace,
    warehousePage,
    warehouseQuery,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilterNew({
      selectedSpace: selectedSpace?.value,
      target: 'category',
      type: selectedMarketplace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      warehouseData,
      selectedWarehouse,
      setData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
      apiData,
      selectedApi,
      brandData,
      selectedBrand,
      warehouseData,
      selectedWarehouse,
      abortController,
    });

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedApi,
    selectedBrand,
    selectedWarehouse,
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery,
    selectedMarketplace,
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchOrdersFilterNew({
      selectedSpace: selectedSpace?.value,
      target: 'brand',
      type: selectedMarketplace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setData: (e) => setBrandData(e),
      apiData,
      selectedApi,
      categoryData,
      selectedCategory,
      warehouseData,
      selectedWarehouse,
      abortController,
    });

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedApi,
    selectedCategory,
    selectedWarehouse,
    selectedMarketplace,
    brandPage,
    brandQuery,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  useEffect(() => {
    const abortController = new AbortController();

    if (pageValue) {
      fetchTableData([], abortController);
      fetchDates([], abortController);
    }

    return () => abortController.abort();
  }, [
    selectedSpace,
    pageValue,
    selectedMarketplace,
    selectedCategory,
    selectedApi,
    selectedBrand,
    selectedWarehouse,
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchTableData([], abortController);

    return () => abortController.abort();
  }, [selectedSpace, date, currentCard]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchTabs([], abortController);

    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedApi,
    selectedBrand,
    selectedCategory,
    selectedWarehouse,
    selectedMarketplace,
  ]);

  useEffect(() => {
    const range = currentRange?.value;
    if (range) {
      setDate([range, range]);
    }
  }, [currentRange]);

  return (
    <>
      {isModalGeneralOrderInformation && (
        <ModalGeneralOrderInformation
          apiData={apiData}
          postingNumber={postingNumber}
          setIsModal={(e) => setIsModalGeneralOrderInformation(e)}
        />
      )}

      {/* нет логики на открытие модалки */}
      {isModalAboutOrder && (
        <ModalAboutTheOrder setIsModal={(e) => setIsModalAboutOrder(e)} />
      )}

      {isModalDeliveryCreation && (
        <ModalDeliveryCreation
          api_id={selectedApi?.value}
          //api_id={"wb_unek_1"}
          setIsModal={(e) => setIsModalDeliveryCreation(e)}
        />
      )}

      <div className="order-management">
        <div className="content-1440">
          <h1 className="title_main">Заказы</h1>

          <div className="rich__select-box">
            <FilterContainer
              onReset={(e) => {
                setSelectedApi([]);
                setSelectedCategory([]);
                setSelectedBrand([]);
                setSelectedWarehouse([]);
              }}
            >
              <DropDownSelector
                options_prop={marketplaceOption}
                state={selectedMarketplace}
                setState={(e) => setSelectedMarketplace(e)}
                placeholder="Площадка"
                defaultValue={marketplaceOption[0]}
              />

              <DropDownSelector
                options_prop={apiData}
                state={selectedApi}
                setState={(e) => setSelectedApi(e)}
                placeholder="Магазин"
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
                defaultValue={{ label: 'Все', value: 'all' }}
              />

              <DropDownSelector
                options_prop={warehouseData}
                state={selectedWarehouse}
                setState={(e) => setSelectedWarehouse(e)}
                placeholder="Склад"
                fetchCallback={(e) => setWarehousePage(e)}
                setInputChange={(e) => setWarehouseQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
              />

              <DropDownSelector
                options_prop={categoryData}
                state={selectedCategory}
                setState={(e) => setSelectedCategory(e)}
                placeholder="Категория"
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
              />

              <DropDownSelector
                options_prop={brandData}
                state={selectedBrand}
                setState={(e) => setSelectedBrand(e)}
                placeholder="Бренд"
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
              />
            </FilterContainer>
          </div>

          <Tabs tabs={tabs} pageValue={pageValue} />

          <div className="toolbar-medium">
            <div className="card-group">
              {dateList?.length > 0
                ? dateList?.map((props, ind) => {
                    const { date, count, less12, expire } = props;
                    return (
                      <CardOrder
                        {...props}
                        key={JSON.stringify(props) + '-' + ind}
                        currentCard={currentCard}
                        setCurrentCard={(e) => setCurrentCard(e)}
                      />
                    );
                  })
                : [...Array(4)].map((el, ind) => {
                    return (
                      <CardOrder
                        date={false}
                        count={0}
                        key={ind}
                        less12={0}
                        expire={0}
                        currentCard={currentCard}
                        setCurrentCard={(e) => setCurrentCard(e)}
                      />
                    );
                  })}
            </div>

            <RangeBox
              date={date}
              defaultValue={defaultValue}
              setDate={(e) => setDate(e)}
              btn_period={true}
            />
          </div>
        </div>

        <TableBox
          ref={paginatorRef}
          {...tableProps}
          contextMenuArr={contextMenuArr}
          paginator={true}
          onCheck={(e) => setCheckedProducts(e)}
          fetchCallback={(e) => fetchTableData(e)}
          onAction={(e) => onActionHandler(e)}
          tableUrl={tableUrl}
          tableBody={tableBody}
          child={
            <div className="btn-group-toolbar">
              <div className="group-left">
                <TooltipLightBlue
                  text="Сканировать"
                  top="calc(100% + 4px)"
                  left="0"
                  child={
                    <button className="btn-icon">
                      <img src={scan_icon} />
                    </button>
                  }
                />

                <TooltipLightBlue
                  text="Выгрузить заказы"
                  top="calc(100% + 4px)"
                  left="0"
                  child={
                    <button className="btn-icon">
                      <img src={arhive_icon} />
                    </button>
                  }
                />

                <TooltipLightBlue
                  text="Распечатать лист подбора"
                  top="calc(100% + 4px)"
                  left="0"
                  child={
                    <button className="btn-icon">
                      <img src={print_icon} />
                    </button>
                  }
                />
              </div>

              <div className="group-right">
                {selectedMarketplace?.value == 'wb' && (
                  <ButtonBasic
                    action
                    size="32px"
                    text="Создать новую поставку"
                    disabled={!selectedApi?.value}
                    width="fit-content"
                    onClick={(e) => {
                      if (!selectedApi || selectedApi.length == 0) {
                        alert('Выберите магазин');
                      } else setIsModalDeliveryCreation(true);
                    }}
                  />
                )}

                {/* <ButtonBasic
                                    grey
                                    size='32px'
                                    text='Добавить в поставку'
                                    width='fit-content'
                                    onClick={e => e}
                                /> */}

                {activeTab === 'awaiting_deliver' && (
                  <ButtonBasic
                    grey
                    size="32px"
                    text={'Создать штрихкод'}
                    width="fit-content"
                    onClick={(e) => {
                      onPostingLabels();
                    }}
                  />
                )}
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};

export { OrderManagement };
