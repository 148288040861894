import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { ButtonBasic, Checkbox } from "@lk-gtcom/ecomlab-components";
import settings_icon from './Img/settings.svg'
import "./BtnDropCheckbox.scss"

const BtnDropCheckbox = ({ list, checkboxValues, setCheckboxValues }) => {

    const [isDrop, setIsDrop] = useState(false)

    // Обработчик на клик вне элемента,для закрытия блока с options
    useEffect(() => {
        const hideMenu = (e) => {
            let el = e.target
            if (!el.closest('.btn-drop-checkbox')) {
                setIsDrop(false)
            }
        }
        document.addEventListener('click', hideMenu)

        return () => {
            document.addEventListener('click', hideMenu)
        }
    }, [])

    return (
        <div className='btn-drop-checkbox'>
            <ButtonBasic
                grey
                className={'btn-drop-checkbox__btn'}
                text={<img src={settings_icon} />}
                minWidth='40px'
                width='40px'
                size='40px'
                onClick={(e) => setIsDrop(!isDrop)}
            />

            {isDrop && <div className='btn-drop-checkbox__drop-box'>
                {list.map(({ label }, ind) => <button
                    key={label + ind}
                    className='btn-drop-checkbox__drop-btn'>
                    {label}

                    <Checkbox
                        value={checkboxValues.includes(label)}
                        onChange={e => {
                            let index = checkboxValues.indexOf(label)
                            if (index === -1) {
                                setCheckboxValues([...checkboxValues, label])
                            } else {
                                let new_values = checkboxValues.filter((el) => el != label)
                                setCheckboxValues(new_values)
                            }
                        }}
                    />
                </button>)}
            </div>}
        </div>
    )
}

export { BtnDropCheckbox }