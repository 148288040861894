import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic, TableBox } from "@lk-gtcom/ecomlab-components";
import add_icon from './img/add.svg'
import update_icon from './img/update.svg'
import icon_btn from './img/icon-btn.svg'
import close from './img/close.svg'
import './ModalAddProdutsSide.scss'

const ModalAddProdutsSide = ({ setIsModal, title, description = false }) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-add-produts-side'>
                <header className='modal-add-produts-side__header'>
                    <h1 className='modal-add-produts-side__title'>Добавление товаров</h1>
                    <button className='modal-add-produts-side__btn-close'
                    onClick={(e) => setIsModal(e)}
                    ><img src={close} /></button>
                </header>

                <h2 className='modal-add-produts-side__title-product'>Название товара</h2>

                {description && <p className='text'>{description}</p>}
                <TableBox
                    hideColumn
                    hideExport
                    loading={null}
                    fetchedData={[]}
                    headers={[]}
                    newTable={true}
                    child={
                        <>
                            <ButtonBasic
                                whiteGreen
                                text={<div className='modal-add-produts-side__content-btn'><img src={icon_btn} />Импорт ссылок</div>}
                                width={'calc(50%  - 32px)'}
                                size='32px'
                            />

                            <ButtonBasic
                                whiteGreen
                                text={<div className='modal-add-produts-side__content-btn'><img src={add_icon} />Добавить ссылки</div>}
                                width={'calc(50%  - 32px)'}
                                size='32px'
                            />

                            <ButtonBasic
                                text={<div className='modal-add-produts-side__content-btn'><img src={update_icon} /></div>}
                                whiteGreen
                                minWidth={'38px'}
                                width={'38px'}
                                size='32px'
                            />
                        </>
                    }
                />
            </div>
        </div>
    )
}

export { ModalAddProdutsSide }