import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import done_active from './img/done_active.svg'
import done_disactive from './img/done_disactive.svg'
import done_current from './img/done_current.svg'
import on_off from './img/on_off.svg'
import add_icon from './img/add_icon.svg'
import { ModalChoiceOfStrategy } from "../Modal/ModalCreatingAnAdvertisingCampaign/ModalChoiceOfStrategy/ModalChoiceOfStrategy"
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import Ozon from './img/Ozon.svg'
import { Steps } from '../Steps/Steps';
import "./TransferOfPrices.scss"

const TransferOfPrices = ({ setShowTooltipTransferOfPrice, setShowTooltipSuccessfulUpdate }) => {

    const steps = [
        {
            label: 'Выберите нужный склад',
            description: ''
        },
        {
            label: 'Перетащите его в поле справа',
            description: ''
        },
        {
            label: 'Выбор стратегии',
        },
        {
            label: 'Запустите обновление остатков',
        }
    ]

    const available_warehouses_list = [
        {
            marketplace: 'Длинное название маркетплейса, длинное название маркетплейса',
            icon: Ozon,
            warehouse_name: 'Название склада'
        },
        {
            marketplace: 'OZON',
            icon: Ozon,
            warehouse_name: 'Название склада очень длинное, Название склада очень длинное'
        },
        {
            marketplace: 'Длинное название маркетплейса, длинное название маркетплейса',
            icon: Ozon,
            warehouse_name: 'Название склада'
        },
        {
            marketplace: 'OZON',
            icon: Ozon,
            warehouse_name: 'Название склада очень длинное, Название склада очень длинное'
        },
        {
            marketplace: 'Длинное название маркетплейса, длинное название маркетплейса',
            icon: Ozon,
            warehouse_name: 'Название склада'
        },
        {
            marketplace: 'OZON',
            icon: Ozon,
            warehouse_name: 'Название склада очень длинное, Название склада очень длинное'
        },
    ]

    const upgradable_warehouses_list = [
        {
            marketplace: 'OZON',
            icon: Ozon,
            warehouse_name: 'Название склада'
        },
        {
            marketplace: 'Длинное название маркетплейса, длинное название маркетплейса',
            icon: Ozon,
            warehouse_name: 'Название склада'
        },
        {
            marketplace: 'OZON',
            icon: Ozon,
            warehouse_name: 'Название склада очень длинное, Название склада очень длинное'
        },
        {
            marketplace: 'Длинное название маркетплейса, длинное название маркетплейса',
            icon: Ozon,
            warehouse_name: 'Название склада'
        },
        {
            marketplace: 'OZON',
            icon: Ozon,
            warehouse_name: 'Название склада очень длинное, Название склада очень длинное'
        },
        {
            marketplace: 'Длинное название маркетплейса, длинное название маркетплейса',
            icon: Ozon,
            warehouse_name: 'Название склада'
        },
        {
            marketplace: 'OZON',
            icon: Ozon,
            warehouse_name: 'Название склада очень длинное, Название склада очень длинное'
        },
    ]

    const [currentStrategy, setCurrentStrategy] = useState(null)
    const [isModalChoiceOfStrategy, setIsModalChoiceOfStrategy] = useState(false)
    const [isLaunched, setIsLaunched] = useState(false)

    useEffect(() => {
        setShowTooltipTransferOfPrice(true)
        setShowTooltipSuccessfulUpdate(true)
        return () => {
            setShowTooltipTransferOfPrice(false)
            setShowTooltipSuccessfulUpdate(false)
        }
    }, [])

    return (
        <>
            {isModalChoiceOfStrategy && <ModalChoiceOfStrategy
                currentStrategy={currentStrategy}
                setCurrentStrategy={(e) => setCurrentStrategy(e)}
                setIsModal={(e) => setIsModalChoiceOfStrategy(e)}
            />}
            <div className='transfer-of-prices'>
                <section className='transfer-of-prices__content'>
                    <header className='transfer-of-prices__header'>
                        {!currentStrategy?.strategyName ?
                            <ButtonBasic
                               blue
                                text='Выбрать стратегию'
                                size='40px'
                                onClick={(e) => {
                                    setIsModalChoiceOfStrategy(true)
                                }}
                            />
                            :
                            <>
                                <div className='name-container'>
                                    <h2 className='title_blue'>{currentStrategy?.strategyName}</h2>
                                    <button className='btn-edit'
                                        onClick={(e) => {
                                            setIsModalChoiceOfStrategy(true)
                                        }}
                                    ></button>
                                </div>

                                {currentStrategy &&
                                    (!isLaunched ? <ButtonBasic
                                   green
                                        onClick={(e) => setIsLaunched(true)}
                                        text={<div className='content-btn'><img src={on_off} />Запустить стратегию</div>}
                                        width='calc(50% - 12px)'
                                        size='40px'
                                    />
                                        :
                                        <ButtonBasic
                                           red
                                            onClick={(e) => setIsLaunched(false)}
                                            text={<div className='content-btn'><img src={on_off} />Остановить стратегию</div>}
                                            width='calc(50% - 12px)'
                                            size='40px'
                                        />
                                    )
                                }
                            </>
                        }
                    </header>

                    <div className='column-group'>
                        <div className='available-warehouses'>
                            <div className='warehouses-list'>
                                {available_warehouses_list.map(({ marketplace, icon, warehouse_name }, ind) =>
                                    <div className='card-warehouses' key={warehouse_name + ind}>
                                        <div className='marketplace-info'>
                                            <img src={icon ? icon : Ozon} />
                                            <p className='text'>{marketplace + ' : ' + warehouse_name}</p>
                                        </div>
                                    </div>
                                )}
                                {available_warehouses_list?.length < 1 && <div className='no-list'>
                                    <img src={add_icon} />
                                    <p className='text'>Добавьте склад</p>
                                </div>}
                            </div>
                            <div className='static-info'>{`${available_warehouses_list?.length || '0'} доступно`}</div>
                        </div>
                        <div className='upgradable-warehouses'>
                            <div className='warehouses-list'>
                                {upgradable_warehouses_list.map(({ marketplace, icon, warehouse_name }, ind) =>
                                    <div className='card-warehouses' key={warehouse_name + ind}>
                                        <div className='marketplace-info'>
                                            <img src={icon ? icon : Ozon} />
                                            <p className='text'>{marketplace + ' : ' + warehouse_name}</p>
                                        </div>
                                    </div>
                                )}
                                {upgradable_warehouses_list?.length < 1 && <div className='no-list'>
                                    <img src={add_icon} />
                                    <p className='text'>Добавьте склад</p>
                                </div>}
                            </div>
                            <div className='static-info'>{`${upgradable_warehouses_list?.length || '0'} перенесено`}</div>
                        </div>
                    </div>

                </section>

                <section className='transfer-of-residues__side-panel'>
                    <Steps
                        steps={steps}
                    />
                </section>
            </div>
        </>

    )
}

export { TransferOfPrices }