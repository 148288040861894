import { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import dropBlue from './img/drop-blue.svg';
import { TooltipYellow } from '../../components/TooltipYellow/TooltipYellow';
import { TooltipGreen } from '../../components/TooltipGreen/TooltipGreen';
import { NotActive } from '../../components/NotActive/NotActive';
import { Page404 } from '../Page404/Page404';
import { TransferOfPrices } from '../../components/TransferOfPrices/TransferOfPrices';
// import { SetPriceAndMarginVer2 } from '../../components/SetPriceAndMarginVer2/SetPriceAndMarginVer2';
import { AnalyticsPage } from '../AnalyticsPage/AnalyticsPage';
import "./PriceManagement.scss"


const PriceManagement = () => {
    const pathMain = useParams()
    const navigate = useNavigate()

    const [isActive, setIsActive] = useState('Установить цену и маржуУправление ценами')
    const [isActiveItems, setIsActiveItems] = useState('')
    const [sidebarItems, setSidebarItems] = useState(
        [
            {
                label: 'Управление ценами',
                subItems: [
                    {
                        label: 'Установить цену и маржу',
                        path: 'set-price-and-margin/by-your-products',
                        black: true
                    },
                    {
                        label: 'Посмотреть цены на площадке',
                        path: 'prices/date',
                        black: true
                    },
                    {
                        label: 'Перенос цен',
                        black: true,
                        path: 'price-transfer'
                    },
                    // {
                    //     label: 'Обновление цен',
                    //     black: true,
                    //     path: ''
                    // },
                ],
            },
            {
                label: 'Уведомления',
                subItems: [
                    {
                        label: 'Настройки уведомлений',
                        black: true
                    }
                ],
            },
        ]
    )
    const [sidebarList, setSidebarList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [hideMenu, setHideMenu] = useState(true)

    const [showTooltipTransferOfPrice, setShowTooltipTransferOfPrice] = useState(false)
    const [showTooltipSuccessfulUpdate, setShowTooltipSuccessfulUpdate] = useState(false)

    const dropClass = hideMenu ? 'icon-drop' : 'icon-drop_active'

    useEffect(() => {
        // После перезагрузки страницы, посвечиваем корректные пункты меню

        sidebarItems?.forEach(({ label, subItems, path }) => {
            const title = label
            const comparePaths = path && (pathMain['*'].split('/')[0] == path.split('/')[0])
            if (comparePaths) {
                setIsActive(null)
                setIsActiveItems(label)
            } else {
                subItems.forEach(({ label, path }) => {
                    if (comparePaths) {
                        setIsActive(title + label)
                        setIsActiveItems(null)
                    }
                })
            }
        })

        if (pathMain['*'].split('/')[0] == 'not-active') {
            navigate('set-price-and-margin/by-your-products')
            setIsActive('Установить цену и маржуУправление ценам')
            setIsActiveItems('')
        }
    }, [])

    useEffect(() => {
        setSidebarList([
            ...sidebarItems.map((el, idx, { label }) => <SidebarMenu {...el} isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} setActive={e => setIsActive(e)} active={isActive} key={label + '' + idx} />)
        ])
    }, [isActive])


    return (
        <div className='settings__content'>
            {/* <div className='tooltip-group-fixed'>
                {showTooltipTransferOfPrice && <TooltipYellow title={'Обновляйте склады, для этого следуйте инструкции в правом поле'}
                    showTooltip={showTooltipTransferOfPrice}
                    setShowTooltip={(e) => setShowTooltipTransferOfPrice(e)} />}

                {showTooltipSuccessfulUpdate && <TooltipGreen title={'Обновление прошло успешно'}
                    text='Вы можете остановить стратегию в любой момент'
                    showTooltip={showTooltipSuccessfulUpdate}
                    setShowTooltip={(e) => setShowTooltipSuccessfulUpdate(e)} />}
            </div> */}
            <div className='flex'>
                <div className={hideMenu ? 'sidebar-inside' : 'sidebar-inside_active'}>
                    <nav className='sidebar-inside__nav'>
                        {sidebarList}
                    </nav>
                </div>
                <div className='settings__main-box'>
                    <Routes>
                        {/* <Route path='set-price-and-margin' element={<SetPriceAndMargin />} />
                        <Route path='see-prices-on-site' element={<SeePricesOnSite />} />
                        <Route path='price-transfer' element={<PriceTransfer />} />
                         */}
                        <Route path='price-transfer' element={<TransferOfPrices
                            setShowTooltipTransferOfPrice={(e) => setShowTooltipTransferOfPrice(e)}
                            setShowTooltipSuccessfulUpdate={(e) => setShowTooltipSuccessfulUpdate(e)}
                        />} />   
                        <Route path='prices/*' element={<AnalyticsPage route={'prices'} />} />
                        {/* <Route path='set-price-and-margin/*' element={<SetPriceAndMarginVer2 />} /> */}
                        <Route path='not-active' element={<NotActive />} />
                        <Route path='*' element={< Page404 />} />
                    </Routes>
                </div>
            </div>

        </div>
    )
}

export { PriceManagement }