import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useState, useEffect, useCallback, useRef } from 'react';
import { BtnDropCheckbox } from '../../components/BtnDropCheckbox/BtnDropCheckbox';
import { useParams } from 'react-router-dom';
import {
    DropDownSelector,
    Tabs,
    TableBox,
    Checkbox,
    ButtonBasic,
    RangeBox
} from '@lk-gtcom/ecomlab-components';
import './CheckingOVH.scss'

const list_select = [
    {
        label: 'Площадка',
    },
    {
        label: 'Магазин',
    },
    {
        label: 'Бренд',
    },
    {
        label: 'Категория',
    },
];

const btn_tab = [
    {
        label: 'Все товары',
        value: 'all',
    },
    {
        label: 'Товары с отклонением по периметру > 100%',
        value: 'deviated_perimete_100',
    },
    {
        label: 'Один из параметров по отклонению габарита меньше -15%  или больше 5%',
        value: 'dimensional_deviation_less_than_15%_or_more_than_5',
    },
    {
        label: 'Отклонение объемного веса от среднего значения объемного веса ',
        value: 'deviation_of_volumetric_weight_from_the_average_value_of_volumetric_weight ',
    }
];

const CheckingOVH = () => {
    const [pageValue, setPageValue] = useState('product');
    const activeTab = useParams()['*']
    const [checkboxValues, setCheckboxValues] = useState([
        'Площадка',
        'Категория',
        'Бренд',
        'Магазин',
    ]);
    const [productData, setProductData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [brandData, setBrandData] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [checkedProducts, setCheckedProducts] = useState([]);

    const [productPage, setProductPage] = useState(1);
    const [categoryPage, setCategoryPage] = useState(1);
    const [brandPage, setBrandPage] = useState(1);
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])

    const [productQuery, setProductQuery] = useState('');
    const [categoryQuery, setCategoryQuery] = useState('');
    const [brandQuery, setBrandQuery] = useState('');
    const [tableUrl, setTableUrl] = useState('');
    const [tableBody, setTableBody] = useState(null);
    const paginatorRef = useRef();
    const [setTable, tableProps, setTableFuncs] = useDataTable(
        paginatorRef.current?.setTotal,
        paginatorRef
    );
    const [checkZero, setCheckZero] = useState(false)

    useEffect(() => {
        setPageValue(activeTab);
    }, [activeTab]);

    return (
        <>
            <div className="checking-OVH">
                <h1 className="title_main">Проверка ОВХ</h1>

                <div className="checking-OVH__toolbar-top">
                    <FilterContainer
                        onReset={(e) => {
                            setSelectedProduct([]);
                            setSelectedCategory([]);
                            setSelectedBrand([]);
                        }}
                    >
                        {checkboxValues.includes('Площадка') && <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={(e) => (e)}
                            placeholder="Площадка"
                        />}

                        {checkboxValues.includes("Магазин") &&
                            <DropDownSelector
                                options_prop={[]}
                                state={[]}
                                setState={(e) => (e)}
                                placeholder="Магазин"
                            />
                        }

                        {checkboxValues.includes("Категория") &&
                            <DropDownSelector
                                options_prop={categoryData}
                                state={selectedCategory}
                                setState={(e) => setSelectedCategory(e)}
                                placeholder="Категория"
                                multi={true}
                                fetchCallback={(e) => setCategoryPage(e)}
                                setInputChange={(e) => setCategoryQuery(e)}
                                defaultValue={[{ label: 'Все', value: 'all' }]}
                            />
                        }

                        {checkboxValues.includes("Бренд") &&
                            <DropDownSelector
                                options_prop={brandData}
                                state={selectedBrand}
                                setState={(e) => setSelectedBrand(e)}
                                placeholder="Бренд"
                                multi={true}
                                fetchCallback={(e) => setBrandPage(e)}
                                setInputChange={(e) => setBrandQuery(e)}
                                defaultValue={[{ label: 'Все', value: 'all' }]}
                            />
                        }


                        <Checkbox
                            value={checkZero}
                            onChange={(e) => setCheckZero(e)}
                        >
                            Без нулевого остатка
                        </Checkbox>
                    </FilterContainer>

                    <div className='checking-OVH__toolbar-top_right'>
                        <BtnDropCheckbox
                            checkboxValues={checkboxValues}
                            setCheckboxValues={(e) => setCheckboxValues(e)}
                            list={list_select}
                        />
                        <RangeBox setDate={e => setDate(e)} hideBtnGroup />
                    </div>

                </div>

                <div className="toolbar-medium">
                    <Tabs tabs={btn_tab} pageValue={pageValue} />
                </div>

                <TableBox
                    {...tableProps}
                    paginator={true}
                    ref={paginatorRef}
                    onCheck={(e) => setCheckedProducts(e)}
                    fetchCallback={(e) => (e)}
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    child={
                        <>
                            <ButtonBasic
                                green
                                text='action1'
                                size='32px'
                                width='auto'
                            />

                            <ButtonBasic
                                grey
                                text='action2'
                                size='32px'
                                width='auto'
                            />
                        </>
                    }
                />
            </div>
        </>
    );
};

export { CheckingOVH };
