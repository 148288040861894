import "./EventElement.scss"

const EventElement = ({id, title, dateTo, dateFrom, style, fetchEventData, setReadOnly}) => {
    
    return (
        <div 
            className='event-item' style={style} 
            onClick={(e) => {
                fetchEventData(id)
                setReadOnly(true)
            }}
        >
            <p>{title}, {dateFrom?.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'})}</p>
        </div>
    )
}

export { EventElement }