import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import RangeDatePicker from '../RangeDatePicker';
import { DropDownSelector } from '@lk-gtcom/ecomlab-components';
import { AndreyHttps, AndreyHttp, OlegHttps, OlegHttp } from '../../fetchUrls';
import './ListLinksProductsInCabinet.scss';
import { ModalAddLinks } from '../Modal/ModalsCompetitors/ModalsCompetitors';
import {
  fetchApiFilter,
  fetchCategoryFilter,
  fetchBrandFilter,
  fetchProductFilter,
} from '../../common/filtersFunc';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import Cookies from 'universal-cookie';
import useGeneralStore from '../../store/general';

const LinksListProductsInCabinet = ({ modalsPortalId }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);

  const [textAreaValue, setTextAreaValue] = useState('');
  const [fetchedData, setFetchedData] = useState([]);

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [productPage, setProductPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [productQuery, setProductQuery] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [isLinks, setIsLinks] = useState(true);
  const [status, setStatus] = useState(0);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [rangeOfView] = useState('7');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const client_id = localStorage.getItem('client_id');
  const auth = new Cookies().get('auth');

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      client_id,
    });
  }, [selectedSpace, apiPage, apiQuery, client_id]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
      client_id,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
    client_id,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
      client_id,
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    brandPage,
    brandQuery,
    client_id,
  ]);

  useEffect(() => {
    fetchProductFilter({
      selectedSpace: selectedSpace?.value,
      page: productPage,
      apiData,
      categoryData,
      selectedCategory,
      selectedBrand,
      brandData,
      selectedApi,
      searchParam: productQuery,
      setProductsData: (e) => setProductsData(e),
      client_id,
    });
  }, [
    selectedSpace,
    selectedBrand,
    selectedApi,
    selectedCategory,
    apiData,
    brandData,
    categoryData,
    productPage,
    productQuery,
    client_id,
  ]);

  const postLinks = (linksArr) => {
    if (linksArr.length > 0) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreyHttps}/parselinks/`;
      } else {
        url = `${AndreyHttp}/parselinks/`;
      }

      const body = JSON.stringify([
        {
          client_id,
          links: linksArr,
          map: true,
          master_sku: +selectedProduct.value,
          type: 1,
        },
      ]);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth}`,
      };

      fetch(url, { body, method: 'POST', headers })
        .then((res) => {
          setStatus(res.status);
          return res.json();
        })
        .then((json) => {
          setShowModal(true);
          console.log(json);
        })
        .catch((err) => {
          setStatus(0);
          setShowModal(true);
        });
    }
  };

  const checkIsLinks = (linksArr) => {
    setIsLinks(linksArr.length > 0);
  };

  return (
    <div className="links-list-products-in-cabinet">
      {showModal &&
        createPortal(
          <ModalAddLinks
            setShowModal={(e) => {
              setShowModal(e);
            }}
            status={status}
          />,
          document.getElementById(modalsPortalId)
        )}
      <div className="rich__select-box">
        <FilterContainer
          onReset={(e) => {
            setSelectedApi([]);
            setSelectedCategory([]);
            setSelectedBrand([]);
            setSelectedProduct([]);
          }}
        >
          <DropDownSelector
            options_prop={apiData}
            state={selectedApi}
            setState={(e) => setSelectedApi(e)}
            placeholder="Магазин"
            fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setApiQuery(e)}
          />

          <DropDownSelector
            options_prop={categoryData}
            state={selectedBrand}
            setState={(e) => setSelectedCategory(e)}
            placeholder="Категория"
            multi={true}
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
          />

          <DropDownSelector
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            placeholder="Бренд"
            multi={true}
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
          />
          <DropDownSelector
            options_prop={productsData}
            state={selectedProduct}
            setState={(e) => setSelectedProduct(e)}
            placeholder="Товар"
            multi={true}
            fetchCallback={(e) => setProductPage(e)}
            setInputChange={(e) => setProductQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
          />
        </FilterContainer>

        <button className="btn__green" style={{ maxWidth: '112px' }}>
          Показать
        </button>
      </div>

      <div className="links-list-products-in-cabinet__content">
        <div className="competitor_link_main_wrapper">
          <span className="competitor_link_main_span">
            Ссылки на товары конкурентов
          </span>
          <button
            className="btn__green"
            style={{ maxWidth: '160px' }}
            onClick={(e) => {
              const linksArr = textAreaValue
                .split('\n')
                .filter((el) => el !== '');
              checkIsLinks(linksArr);
              postLinks(linksArr);
            }}
          >
            Отправить ссылки
          </button>
        </div>

        <div className="textarea-box">
          <textarea
            style={isLinks ? {} : { outline: '1px solid red' }}
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            className="competitors_textarea"
            autoFocus={true}
            placeholder="https://www.ozon.ru/product/protsessor-intel-core-i9-13900k-oem-bez-kulera-790289147/?asb2=unKsgnW-6UYq--amUfhJ4bT_o8e1ctB9Y9cTDc_ja3lfX2xLewp-STrm0pOND5jw&avtc=1&avte=2&avts=1670939665&keywords=intel+core+i9+13900k&sh=j735KRJPsw"
          />
        </div>
      </div>
    </div>
  );
};

export { LinksListProductsInCabinet };
