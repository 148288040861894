import { useState, useEffect } from 'react';
import { ButtonBasic, DropDownSelector } from "@lk-gtcom/ecomlab-components";
import './ModalAddToGroup.scss'

const ModalAddToGroup = ({
    optionsGroup,
    setIsModal,
    setArrCheck,
    addToGroupTemplate,
    selectedGroupId
}) => {

    const [selectedGroup, setSelectedGroup] = useState({})
    const current_prop = optionsGroup.filter(({label, value}) => selectedGroupId != value) 

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-confirmation-unloading'>
                <h2 className='modal-confirmation-unloading__title'>Добавить в:</h2>
                <DropDownSelector
                    width='100%'
                    options_prop={current_prop}
                    state={selectedGroup}
                    setState={e => setSelectedGroup(e)}
                    placeholder='Выберите группу'
                />
                <ButtonBasic
                    green
                    text='Переместить'
                    width='225px'
                    size='40px'
                    onClick={(e) => {
                        addToGroupTemplate(selectedGroup?.value)
                        setArrCheck([])
                        setIsModal(false)
                    }}
                />
            </div>
        </div>
    )
}

export { ModalAddToGroup }