import customHeaders, {getSpace} from '../../common/headers';
import Cookies from 'universal-cookie';
import user_icon from './img/user.svg'
import { Link } from 'react-router-dom';
import delete_icon from './img/trash_icon.svg'
import edit_icon from './img/edit_icon.svg'
import { TabsGreyBtn } from '../../components/TabsGreyBtn/TabsGreyBtn';
import { SendMessage } from '../../components/SendMessage/SendMessage';
import { ModalCreateGroup } from '../../components/Modal/ModalCreateGroup/ModalCreateGroup';
import { useEffect, useRef, useState } from 'react';
import { ExecutorPlaceholder } from '../../components/OptionsExecutor/OptionsExecutor';
import { ButtonBasic, InputSearch } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from '../../store/general';
import "./ChatMessagePage.scss"

const btns = [
    { label: 'Чаты', count: 2, value: 'Все' },
    { label: 'Группы', count: 4, value: 'Группы' }
]


const MessagePreview = (props, ind) => {

    const [data, setData] = useState(props)

    const {
        chat_id,
        name,
        // message,
        // sum_message,
        // date,
        currentMessage,
        setCurrentMessage,
        unread_messages_count
    } = data

    useEffect(() => {
        setData(props)
    }, [props])

    return <div
        className={currentMessage.chat_id == chat_id ? 'message_preview_v1_active' : 'message_preview_v1'}
        key={name + chat_id}
        onClick={(e) => setCurrentMessage(props)}
    >
        <img src={user_icon} />
        <div className='message_preview_v1__content'>
            <div className='message_preview_v1__top'>
                <h3 className='message_preview_v1__title'>{name}</h3>
                {/* <p className='message_preview_v1__date'>{date}</p> */}
                {
                    unread_messages_count > 0 &&
                    <span className='unread_count'>{unread_messages_count}</span>
                }
            </div>
            <div className='btn-group'>
                {/* <button className='btn-edit'
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsModalCreateGroup(true)
                    }}
                >
                    <img style={{ width: 18, height: 18 }} src={edit_icon} />
                </button> */}
                <button className='btn-delete'
                    onClick={e => {
                        e.stopPropagation()
                        // deleteGroup(id)
                    }}
                >
                    <img src={delete_icon} />
                </button>
            </div>
        </div>
    </div>
}

const GroupPreview = (props, ind) => {

    const [data, setData] = useState(props)

    const {
        chat_id,
        name,
        // message,
        // sum_message,
        // date,
        currentMessage,
        setCurrentMessage,
        unread_messages_count,
        setIsModalCreateGroup,
        setIsEditGroup
    } = data

    useEffect(() => {
        setData(props)
    }, [props])

    const user_list = [
        {
            id: 540,
            name: "Александр",
            color: "rgb(97, 72, 209)",
            login: "a.morozov@gtcom.io"
        },
        {
            id: 546,
            name: "a.kuzmin@gtcom.io",
            color: "rgb(83, 86, 83)",
            login: "a.kuzmin@gtcom.io"
        },
        {
            id: 559,
            name: "Evgenii Ryabinin",
            color: "rgb(43, 105, 121)",
            login: "e.ryabinin@gtcom.io"
        },
    ]

    return <div
        className={currentMessage.chat_id == chat_id ? 'message_preview_v1_active' : 'message_preview_v1'}
        key={name + chat_id}
        onClick={(e) => setCurrentMessage(props)}
    >
        <img src={user_icon} />
        <div className='message_preview_v1__content'>
            <div className='message_preview_v1__top'>
                <h3 className='message_preview_v1__title'>{name}</h3>
                {/* <p className='message_preview_v1__date'>{date}</p> */}
                {
                    unread_messages_count > 0 &&
                    <span className='unread_count'>{unread_messages_count}</span>
                }
            </div>
            {/* <div className='message_preview_v1__bottom'>
                <p className='message_preview_v1__message'>{message}</p>
                {sum_message ? <p className='message_preview_v1__sum_message'>{sum_message}</p> : null}
            </div> */}

            <div className='employees-container'>
                {user_list.map(({ name, color, login }) => <ExecutorPlaceholder
                    key={name + color}
                    // author={login}
                    email={login}
                    name={name}
                    color={color}
                />).splice(0, 2)}

                {(user_list?.length > 2) && <p className='sum_executor'>+<span>{user_list?.length - 2}</span></p>}

            </div>

            <div className='btn-group'>
                <button className='btn-edit'
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsEditGroup(true)
                        setIsModalCreateGroup(true)
                    }}
                >
                    <img style={{ width: 18, height: 18 }} src={edit_icon} />
                </button>
                <button className='btn-delete'
                    onClick={e => {
                        e.stopPropagation()
                        // deleteGroup(id)
                    }}
                >
                    <img src={delete_icon} />
                </button>
            </div>


        </div>
    </div>
}

const MessageItem = (props, ind) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const {
        data,
        client,
        created_at,
        message_id,
        is_read,
        chat_id, project_id,
        updateUnreadCount
    } = props
    const client_id = +localStorage.getItem('client_id')


    const readMessageStatus = () => {
        let url = `https://apps1.ecomru.ru:4444/project/${project_id}/chat/${chat_id}/message/read`

        const body = JSON.stringify({
            message_id_list: [message_id]
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                updateUnreadCount()
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (project_id >= 0 && chat_id >= 0) {
            if (!is_read && client?.id !== client_id) {
                readMessageStatus()
            }
        }
    }, [])

    return <div className={client_id === client?.id ? 'message_item_user_v1' : 'message_item_v1'} key={message_id}>
        {client?.name && <h3 className='message_item_v1__title'>{client?.name}</h3>}
        {data && <p className='message_item_v1__text' target='_blank'>{data}</p>}
        {/* {img && <img src={img} className='message_item_v1__img' />} */}
        {/* {link && <a href={link} className='message_item_v1__link' target='_blank'>{link}</a>} */}
        {created_at && <p className='message_item_v1__date'>{new Date(created_at)?.toLocaleDateString('ru-RU')} {new Date(created_at)?.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}</p>}
    </div>
}

const ChatMessagePage = ({ setIsModal }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const cookie = new Cookies()
    const auth = cookie?.get('auth')

    const [answerValue, setAnswerValue] = useState('')
    const [answer, setAnswer] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [isEditGroup, setIsEditGroup] = useState(false)
    const [isModalCreateGroup, setIsModalCreateGroup] = useState(false)
    const [messageList, setMessageList] = useState([])

    const [projectList, setProjectList] = useState([])
    const [chatList, setChatList] = useState([])

    const [usersList, setUsersList] = useState([])
    const [chatId, setChatId] = useState(-1)

    const [status, setStatus] = useState(btns?.[0]?.value)
    const [currentProject, setCurrentProject] = useState(-1)
    const [currentMessage, setCurrentMessage] = useState({})
    const [isCreateChat, setIsCreateChat] = useState(false)
    const socket = useRef()
    const chatListHeight = document.querySelector('.chat_message_page__content_message')

    const onSendMessage = () => {
        socket.current.send(answerValue)
        setAnswerValue('')
    }

    useEffect(() => {
        if (typeof socket.current !== 'undefined') {
            console.log('Close request');
            socket.current.close(1000, 'Request for a new connection')
        } 
        
        if (currentMessage?.id && currentMessage?.project_id) {

            socket.current = new WebSocket(`wss://apps1.ecomru.ru:4444/socket/project/${currentMessage?.project_id}/chat/${currentMessage?.id}/ws?authorization=Bearer ${auth}`)
        }

        if (currentMessage?.chat_id && currentProject?.project_id) {
            socket.current = new WebSocket(`wss://apps1.ecomru.ru:4444/socket/project/${currentProject?.project_id}/chat/${currentMessage?.chat_id}/ws?authorization=Bearer ${auth}`)
        }
               
        if(socket.current){
            socket.current.onopen = () => {
                console.log('Соединение установлено');      
            }
    
            socket.current.onmessage = e => {
                const data = JSON.parse(e.data)
                
                if (!Array.isArray(data)) {
                    setMessageList(prev => [...prev, data])
                } else {
                    setMessageList(data)
                }
            }
    
            socket.current.onclose = (e) => {
                console.log(`Соединение закрыто: ${e}`);
            }
    
            socket.current.onerror = error => {
                console.log(`Ошибка ${error.message}`);
                console.log(error);
                setMessageList([])
            }
        }
        
    }, [currentMessage, currentProject])

    const fetchProjects = () => {
        let url = 'https://apps1.ecomru.ru:4444/project/'

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setProjectList(json)
                setCurrentProject(json?.[0])
            })
            .catch(err => console.error(err))
    }

    const fetchChatList = (id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${id}/chats`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setChatList(json.map(el => ({ ...el, name: el?.chat_name?.name })))
                setCurrentMessage(json?.[0])
            })
            .catch(err => console.error(err))
    }

    const fetchGroup = (id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${id}/group`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setChatList(json)
                setCurrentMessage(json?.[0])
            })
            .catch(err => console.error(err))
    }

    const fetchUsers = (id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/users`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setUsersList(json)
            })
            .catch(err => console.error(err))
    }

    const fetchChatUsers = (id, chat_id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${id}/chats/${chat_id}/users`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setUsersList(json)
            })
            .catch(err => console.error(err))
    }

    const createChat = (id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/chat`

        const body = JSON.stringify({
            client_ids: [id]
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                if (isCreateChat) {
                    fetchChatList(currentProject?.project_id)
                } else {
                    fetchGroup(currentProject?.project_id)
                }
            })
            .catch(err => console.error(err))
    }

    const createGroup = (ids, group_name) => {
        setIsModalCreateGroup(false)
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/group`

        const body = JSON.stringify({
            client_ids: ids,
            group_name
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                if (isCreateChat) {
                    fetchChatList(currentProject?.project_id)
                } else {
                    fetchGroup(currentProject?.project_id)
                }
            })
            .catch(err => console.error(err))
    }

    const getUnreadCount = async () => {
        setIsModalCreateGroup(false)
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/chat/${currentMessage?.chat_id}/message/unread`


        const res = await fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(({ unread_messages }) => {
                return unread_messages
            })
            .catch(err => console.error(err))
        return res
    }

    const updateUnreadCount = () => {
        const count = getUnreadCount()
        const chat_id = currentMessage?.chat_id ? currentMessage?.chat_id : currentMessage?.id ? currentMessage?.id : -1
        setChatList(prev => prev?.map(el => el?.chat_id === chat_id ? { ...el, unread_messages_count: count } : el))
    }

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }

    useEffect(() => {
        fetchProjects()
    }, [selectedSpace])

    useEffect(() => {
        if (status === btns?.[0]?.value) {
            fetchChatList(currentProject?.project_id)
        }
        if (status === btns?.[1]?.value) {
            fetchGroup(currentProject?.project_id)
        }
        fetchUsers()
    }, [currentProject, status, selectedSpace])

    useEffect(() => {
        const y = chatListHeight?.scrollHeight;
        chatListHeight?.scrollTo(0, y)
    }, [chatListHeight?.scrollHeight, messageList])

    return (
        <>
            {isModalCreateGroup && <ModalCreateGroup
                setIsModal={(e) => {
                    setIsModalCreateGroup(e)
                }}
                isEdit={isEditGroup}
                setIsEdit={(e) => setIsEditGroup(e)}
                createGroup={createGroup}
                createChat={e => createChat(e)}
                type={isCreateChat}
                usersList={usersList}
                currentMessage={currentMessage}
            />}

            <div className='chat_message_page' onClick={e => e.stopPropagation()}>
                <aside className='chat_message_page__side_panel'>
                    <h2 className='chat_message_page__title'>Проекты</h2>
                    <div className='chat_message_page__project_list'>
                        {projectList?.map((el) =>
                            <div
                                className={currentProject.project_id == el.project_id ? 'chat_message_page__project_item_active' : 'chat_message_page__project_item'}
                                key={el.project_id}
                                onClick={(e) => setCurrentProject(el)}
                            >
                                {el.project_name}
                                <span className='unread_count'>{el?.unread_messages > 0 ? el?.unread_messages : ''}</span>
                            </div>
                        )}
                    </div>
                </aside>

                <div className='chat_message_page__list_message'>
                    <header className='chat_message_page__header'>
                        <div className='chat_message_page__btn_group'>
                            <ButtonBasic
                                grey
                                width='auto'
                                text='+ Новая группа'
                                onClick={(e) => {
                                    setIsModalCreateGroup(true)
                                    setIsCreateChat(false)

                                }}
                            />

                            <ButtonBasic
                                grey
                                width='auto'
                                text='+ Новый чат'
                                onClick={e => {
                                    setIsModalCreateGroup(true)
                                    setIsCreateChat(true)
                                }}
                            />
                        </div>

                        <InputSearch />

                        <TabsGreyBtn
                            btns={btns}
                            currentBtn={status}
                            setCurrentBtn={(e) => setStatus(e)}
                            checkValue={true}
                        />
                    </header>

                    <div className='chat_message_page__container_list_message' >
                        {chatList?.map((props) => {
                            if (status == 'Все') {
                                return <MessagePreview
                                    {...props}
                                    currentMessage={currentMessage}
                                    setCurrentMessage={(e) => setCurrentMessage(e)}
                                />
                            } else {
                                return <GroupPreview
                                    {...props}
                                    setIsEditGroup={(e) => setIsEditGroup(e)}
                                    setIsModalCreateGroup={(e) => setIsModalCreateGroup(e)}
                                    currentMessage={currentMessage}
                                    setCurrentMessage={(e) => setCurrentMessage(e)}
                                />
                            }
                        }
                        )}
                    </div>
                </div>

                <div className='chat_message_page__current_chat'>
                    <header className='chat_message_page__header_message'>
                        <img className='chat_message_page__user_icon' src={user_icon} />
                        <h2 className='chat_message_page__name_chat'>{currentMessage?.name ? currentMessage?.name : currentMessage?.chat_name?.name}</h2>
                        {
                            setIsModal &&
                            <div className="chat_message_page_close_btn" onClick={e => setIsModal(false)}>X</div>
                        }
                    </header>
                    <div className='chat_message_page__content_message_send'>
                        <div className='chat_message_page__content_message' style={{ height: '500px' }}>
                            {
                                messageList?.length < 1 ?
                                    <div
                                        className='text_grey'
                                        style={{ margin: '0 auto', height: '100%', display: 'flex', alignItems: 'center', fontSize: 14 }}
                                    >
                                        В этом чате еще нет сообщений
                                    </div>
                                    :
                                    messageList?.map((props) =>
                                        <MessageItem
                                            {...props}
                                            chat_id={currentMessage?.chat_id}
                                            project_id={currentProject?.project_id}
                                            updateUnreadCount={e => updateUnreadCount()}
                                        />
                                    )
                            }
                        </div>
                        <SendMessage
                            frequentAnswers
                            isEdit={isEdit}
                            setIsEdit={(e) => setIsEdit(e)}
                            message={answer}
                            placeholder={'Напишите Ваше сообщение здесь'}
                            value={answerValue}
                            onChange={e => setAnswerValue(e)}
                            onSendAction={e => onSendMessage(e)} />
                    </div>
                </div>
            </div>
        </>
    )
}

export { ChatMessagePage }