import { useEffect, useState } from 'react';
import { InputDinamycPlaceholder, ButtonBasic } from "@lk-gtcom/ecomlab-components";
import "./ModalEditingRules.scss"

const ModalEditingRules = ({ 
    setIsModal, strategyParams, setStrategyParamValue, strategyParamValue, strategyInfo,
    onSaveHandler, setStrategyInfo 
}) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
                setStrategyParamValue([])
                setStrategyInfo({})
            }
        }}>
            <div className='modal-editing-rules' onClick={(e) => {
                let el = e.target
                if (!el.closest('.promotion-rules-parametr')) {
                }
            }}>
                <div className='modal-editing-rules__header'>
                    <h1 className='title'>{strategyInfo?.name ? strategyInfo?.name : 'Название стратегии'}</h1>
                    
                </div>

                <div className='modal-editing-rules__content'>
                    <div className='promotion-rules-parametr__content'>
                        {
                            strategyParams?.map(({ portal_name, type, description, id, value = '' }) => {
                                return (
                                    <InputDinamycPlaceholder
                                        onChange={(e) => {
                                            if (e) {
                                                setStrategyParamValue(prev => {
                                                    let value
                                                    if(type === 'integer'){
                                                        value = Number(e)
                                                    }
                                                    if(type === 'string'){
                                                        value = String(e)
                                                    }
                                                    return { ...prev, [+id]: {type, value} }
                                                })
                                            }
                                        }}
                                        placeholder={portal_name}
                                        autoComplete={false}
                                        changeValue={value}
                                        size='s'
                                    />
                                )
                            })
                        }
                        
                    </div>
                </div>
                    <ButtonBasic
                        // disabled
                        green
                        width='190px'
                        minWidth='190px'
                        maxWidth='190px'
                        text={strategyParamValue?.length < 1 ? 'ОК' : 'Сохранить'}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsModal(false)
                            
                            if(Object.keys(strategyParamValue)?.length > 0){
                                onSaveHandler()
                            }
                            // onChangeStrategy()
                        }}
                    />
            </div>
        </div>
    )
}

export { ModalEditingRules }