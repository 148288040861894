import { Routes, Route } from "react-router-dom"
import { Main } from "../Main/Main"

const Wiki = ({
    activeLang,
    pathBread,
    currentArticle,
    setCurrentArticle,
    pathsWebName,
    setPathsWebName,
    description,
    setDescription,
    setPaths,
    title,
    setTitle,
    paths
}) => {

    return (<>
        <Routes>
            <Route path="/*" element={
                <Main
                    activeLang={activeLang} pathBread={pathBread}
                    currentArticle={currentArticle}
                    setCurrentArticle={setCurrentArticle}
                    pathsWebName={pathsWebName}
                    setPathsWebName={e => setPathsWebName(e)}
                    description={description}
                    setDescription={e => setDescription(e)}
                    setPaths={e => setPaths(e)}
                    paths={paths}
                    title={title}
                    setTitle={e => setTitle(e)}
                />
            }
            />
        </Routes >
    </>)
}

export default Wiki