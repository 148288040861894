import customHeaders, { getSpace } from '../../../common/headers'
import close from './img/close.svg'
import copy_icon from './img/copy_icon.svg'
import trash_icon from './img/trash_icon.svg'
import low_status from './img/low_status.svg'
import hight_status from './img/hight_status.svg'
import medium_status from './img/medium_status.svg'
import info_icon from './img/info_icon.svg'
import info_icon_2 from './img/bx-info-circle.svg'
import arrow_left_icon from './img/arrow-left_icon.svg'
import papper_clip from './img/paperclip.png'
import { Editor } from '../../Editor/Editor'
import { DateBox } from '../../DateBox/DateBox'
import { Comment } from '../../Comment/Comment'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { CommentItem } from '../../CommentItem/CommentItem'
import { TASK_EVENT_TYPE } from '../../../pages/TaskList/TaskList'
import { useState, useEffect } from 'react'
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls'
import { ButtonBasic, DropDownSelector, Checkbox, SelectExecutor, TitleContentEditable } from "@lk-gtcom/ecomlab-components";
import { OptionsExecutor, ExecutorPlaceholder } from '../../OptionsExecutor/OptionsExecutor'
import { TooltipBlack } from '../../Tooltip/TooltipBlack/TooltipBlack'
import { DownloadComponent } from '../../DownloadComponent/DownloadComponent'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useGeneralStore from '../../../store/general'
import user_icon from './img/bx-user.svg'
import info_circle_icon from './img/bx-info-circle.svg'
import file_icon from './img/bx-file-blank.svg'
import './ModalAddTask2.scss'
import { TabsGreen } from '../../TabsGreen/TabsGreen'
import download_icon from './img/bx-download.svg'

const btns = [
    { label: 'Исполнитель', value: 'еxecutor', icon: user_icon },
    { label: 'История', value: 'history', icon: info_circle_icon },
]

const btns_2 = [
    { label: 'Файлы', value: 'file' },
    { label: 'Изображения', value: 'img' },
    { label: 'Ссылки', value: 'links' },
    { label: 'Таск-Лист', value: 'task_list' },
]

const OPTIONS_PRIORITY = [
    {
        label: <p className='option-status'>Высокий <img src={hight_status} /></p>,
        value: 'Высокий'
    },
    {
        label: <p className='option-status'>Средний <img src={medium_status} /></p>,
        value: 'Средний'
    },
    {
        label: <p className='option-status'>Низкий <img src={low_status} /></p>,
        value: 'Низкий'
    },
    {
        label: 'Не указано',
        value: 'Не назначено'
    }
]

export const PRIORITY_TO_COLOR_MAP = {
    'Низкий': 'green',
    'Средний': 'yellow',
    'Высокий': 'red',
    'Не назначено': 'blue'
}

const btn_tab = [
    {
        label: 'Описание',
        value: `task-info/description`
    },
    {
        label: 'Комментарии',
        value: `task-info/comments`
    }
]

const ModalAddTask2 = ({
    setIsModal,
    infoTask = false,
    attendees,
    setInfoTask,
    setTasks,
    selectedColumn,
    allColumns,
    fetchDeleteTask,
    taskId,
    query,
    isLoadInfoTask
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const email = localStorage.getItem('email')
    const currentUserColor = localStorage.getItem('currentUserColor')
    const currentUserName = localStorage.getItem('currentUserName')

    const [endDate, setEndDate] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date())

    const [endDate2, setEndDate2] = useState(new Date())
    const navigate = useNavigate()

    const [executorSearchValue, setExecutorSearchValue] = useState('')
    const [executors, setExecutors] = useState([])
    const [coExecutors, setCoExecutors] = useState([])
    const [executorOptions, setExecutorOptions] = useState(attendees)

    const [title, setTitle] = useState('')
    const [defaultTitle, setDefaultTitle] = useState(false)
    const [description, setDescription] = useState('')
    const [authorName, setAuthorName] = useState(currentUserName)
    const [authorColor, setAuthorColor] = useState(currentUserColor)
    const [authorEmail, setAuthorEmail] = useState(email)
    const [addTaskId, setAddTaskId] = useState()
    const [comments, setComments] = useState([])
    const [statusTask, setStatusTask] = useState([])
    const [selectedStatusTask, setSelectedStatusTask] = useState({})
    const [selectedProject, setSelectedProject] = useState(null)
    const [selectedPriority, setSelectedPriority] = useState({ label: 'Не указано', value: 'Не назначено' })
    const [isDropMenu, setIstDropMenu] = useState(false)
    const [check, setCheck] = useState(false)
    const [check2, setCheck2] = useState(false)
    const [logsArr, setLogsArr] = useState([])
    const [logsErr, setLogsErr] = useState(false)
    const [files, setFiles] = useState([])
    const [filesdata, setFilesData] = useState([])

    const [isShowHistoryTask, setIsShowHistoryTask] = useState(false)

    const [showOption, setShowOptions] = useState(false)
    const [showOption2, setShowOptions2] = useState(false)
    const [eventId, setEventId] = useState()

    const activeTab = useParams()['*']
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [currentTab, setCurrentTab] = useState(btns?.[0]?.value)
    const [currentTab2, setCurrentTab2] = useState(btns_2?.[0]?.value)


    const formateDate = (date) => {
        const timeZone = new Date().getTimezoneOffset() / 60 * -3600000
        const formate_date = new Date(new Date(date)?.getTime() + timeZone).toUTCString()
        return new Date(formate_date)
    }


    const getBodyAndHeaders = (withId) => {
        console.log(coExecutors.map(({ id }) => id));

        const contentData = JSON.stringify({
            event_id: withId ? eventId : undefined,
            name: title,
            language: 'ru',
            start_time: formateDate(startDate),
            end_time: formateDate(endDate),
            employee_time: check2 ? formateDate(new Date()) : endDate2 ? formateDate(endDate2) : endDate ? formateDate(endDate) : undefined,
            event_type: TASK_EVENT_TYPE,
            task_id: addTaskId ? addTaskId : taskId,
            task_type: selectedStatusTask.value,
            executor: executors[0]?.id,
            priority: selectedPriority.value,
            content: description ? replaceString('</p>', ' </p>', description) : description,
            co_executors: coExecutors?.length > 0 ? coExecutors.map(({ id }) => id) : [],
            show_in_calendar: false,
            files: files?.length ? files : filesdata.map((el) => el.value)
        })


        // const formData = new FormData()
        // formData.append('content_data', new Blob([contentData], { type: 'application/json' }))

        const _headers = Object.assign({}, headers)
        // delete _headers['Content-Type']

        return { body: contentData, headers: _headers }
    }

    const fetchAddNewTask = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/post_event`
        } else {
            url = `${RavshanHttp}/api/tasks/post_event`
        }

        const { body, headers } = getBodyAndHeaders()

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => setIsModal(false))
            .catch(err => console.error(err))
    }

    const fetchUpdateTask = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/put_event`
        } else {
            url = `${RavshanHttp}/api/tasks/put_event`
        }

        const { body, headers } = getBodyAndHeaders(true)


        const _headers = Object.assign({}, headers)
        // delete _headers['Content-Type']

        fetch(url, { body: body, method: 'PUT', headers: _headers })
            .then(res => res.json())
            .then(json => setIsModal(false))
            .catch(err => console.error(err))
    }

    const fetchTaskLog = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v2/tasks/event/log`
        } else {
            url = `${RavshanHttp}/api/v2/tasks/event/log`
        }

        const { headers } = getBodyAndHeaders(true)
        const body = JSON.stringify({
            event_id: eventId
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
            })
            .then(json => {
                if (Array.isArray(json)) {
                    setLogsArr(json)
                    setIsShowHistoryTask(true)
                } else {
                    setLogsErr(true)
                    setTimeout(() => {
                        setLogsErr(false)
                    }, 3000)
                }
            })
            .catch(err => console.error(err))
    }

    const fetchPossibleAttendees = () => {
        const url = `${RavshanHttps}/api/tasks/project_client_team`
        const newHeaders = {
            ...headers,
            'project-id': addTaskId
        }
        fetch(url, { headers: newHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .then(json => {
                setExecutorOptions(json)
            })
            .catch((e) => console.error(e))
    }

    const [isLoadingComments, setIsLoadingComments] = useState(false)

    const fetchComments = () => {
        if (eventId) {
            setIsLoadingComments(true)
            const url = `${RavshanHttps}/api/tasks/comment`
            const newHeaders = {
                ...headers,
                'event-id': eventId
            }
            fetch(url, { headers: newHeaders })
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        throw new Error('bad comments response')
                    }
                })
                .then(json => {
                    setComments(json)
                })
                .catch((e) => console.error(e))
                .finally(() => setIsLoadingComments(false))
        }
    }

    const handleMainButtonClick = (update) => {
        if (update) { fetchUpdateTask() }
        else { fetchAddNewTask() }
        setTasks([])
        sessionStorage.removeItem('eventId')
        navigate(`/tasks/task-list/${sessionStorage.getItem('taskProjectId')}`)
        setInfoTask(null)
    }

    const fetchAllColumns = (delayMs, taskId) => {
        if (!taskId & !selectedColumn?.typeId) return
        const url = `${RavshanHttps}/api/tasks/get_task_type`
        const getHeaders = {
            ...headers,
            "project-id": taskId ? taskId : selectedColumn?.typeId,
            // "executor": [],
            // "author": [],
            // "co_executor": [],
            // "task_type": "string"
        }

        const fetchCallback = () => fetch(url, { headers: getHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad columns response')
                }
            })
            .then(json => {
                if (!Array.isArray(json)) return
                const statusArr = json.sort((a, b) => a.place - b.place).map(c => ({ label: c?.type_name, value: c?.type_id }))
                setStatusTask(statusArr)
            })

        if (delayMs) setTimeout(fetchCallback, delayMs)
        else fetchCallback()
    }

    useEffect(() => {
        if (!infoTask) return

        const {
            id, taskId, eventName, endTime, priority, taskType, author, startTime, executor, coExecutors, content,
            employee_time, files
        } = infoTask

        if (eventName) {
            setTitle(eventName)
            setDefaultTitle(eventName)
        }
        if (priority) {
            const priorityToDropdown = OPTIONS_PRIORITY.find(p => p.value === priority)
            setSelectedPriority(priorityToDropdown ?? OPTIONS_PRIORITY.at(-1))
        }
        if (taskType) {
            const { id, name } = taskType
            setSelectedStatusTask({ label: name, value: id })
        }
        if (endTime) {
            setStartDate(new Date(endTime))
            setEndDate(new Date(endTime))
        }
        if (startTime) {
            setStartDate(new Date(startTime))
        }
        if (employee_time) {
            setEndDate2(new Date(employee_time))
        } else {
            setEndDate2(new Date(endTime))
        }
        if (author) {
            const { name, color, login } = author
            setAuthorName(name)
            setAuthorColor(color)
            setAuthorEmail(login)
        }
        if (Array.isArray(content)) {
            setDescription(content[0]?.text) // тут падает
        }
        if ((executor && executor?.id)) {
            setExecutors([executor])
        }
        if ((Array.isArray(coExecutors) && coExecutors.length > 0) && coExecutors[0]?.id) {
            const new_coExecutors = coExecutors.map((el) => {
                let elem = el
                elem.email = elem?.login
                return elem
            }
            )

            setCoExecutors(new_coExecutors)
        }

        if (id) {
            setEventId(id)
        }
        if (taskId) {
            fetchAllColumns(0, taskId)
            setAddTaskId(taskId)
            const selectedProject = allColumns.filter(({ value: { id } }) => id === taskId)?.[0]
            setSelectedProject(selectedProject)
        }
        if (files) {
            console.log(files)
            setFilesData(files)
        }
    }, [infoTask])

    useEffect(() => {
        fetchPossibleAttendees()
        setPageValue(btn_tab[0].value)

        const hideOptionsBox = (e) => {
            if (!e.target.closest('.search-box-executor')) {
                setShowOptions(false)
            }
            setExecutorSearchValue('')
        }
        document.addEventListener('click', (e) => hideOptionsBox(e))
        return () => document.removeEventListener('click', (e) => hideOptionsBox(e));


    }, [addTaskId, selectedSpace])

    useEffect(() => {
        fetchComments()
    }, [eventId])

    useEffect(() => {
        if ((activeTab != btn_tab[0].value) && (activeTab != btn_tab[1].value)) {
            setPageValue(btn_tab[0].value)
        } else setPageValue(activeTab)
    }, [activeTab])

    useEffect(() => {
        const selProjId = selectedProject?.value?.id
        if (!infoTask?.id) {
            if (+taskId === -1) {
                const selCol = allColumns?.filter(({ label }) => label === selectedColumn?.typeName)?.[0]
                setAddTaskId(selProjId ? selProjId : selCol?.value?.id)
                fetchAllColumns(0, selProjId ? selProjId : selCol?.value?.id)
                setSelectedProject(selCol)
            } else {
                const selCol = allColumns?.filter(({ value: { id } }) => id === +taskId)?.[0]
                setAddTaskId(taskId)
                fetchAllColumns(0, taskId)
                setSelectedProject(selCol)
                setSelectedStatusTask({ label: selectedColumn?.typeName, value: selectedColumn?.typeId })
            }
        } else {
            setAddTaskId(selProjId)
        }
    }, [selectedProject, selectedColumn, allColumns, selectedSpace])

    useEffect(() => {
        if (currentTab == 'history') {
            fetchTaskLog()
        }
    }, [currentTab, selectedSpace]);

    function replaceString(oldS, newS, fullS) {
                return fullS.split(oldS).join(newS);
            }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
                sessionStorage.removeItem('eventId')
                navigate(`/tasks/task-list/${taskId}${query ? `/filter=${query}` : ''}`)
                setInfoTask(null)
            }
            if (!e.target.className.includes('search-box-executor')) setShowOptions2(false)
            // if (!el.classList.closest('autor-box')) {
            //     setShowOptions2(false)
            // }
        }}><div className='modal-add-task'>
                <div className='modal-add-task__chat'>
                    <div className='modal-add-task__header modal-add-task__header-comment'>
                        <h2 className='modal-add-task__title-comment'>Комментарии</h2>
                    </div>
                    <div className='modal-add-task__comment-container'>
                        <div className='modal-add-task__list-message'>
                            {isLoadingComments ?
                                <div className='sceleton-chat'>
                                    <Stack className='sceleton-chat__content'>
                                        <div className='sceleton-chat__message'>
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                            <Skeleton variant="rectangular" width={280} height={150} >
                                                <Skeleton variant="circular" width={32} height={32} />
                                            </Skeleton>
                                        </div>

                                        <div className='sceleton-chat__message'>
                                            <Skeleton variant="rectangular" width={280} height={130} />
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                        </div>

                                        <div className='sceleton-chat__message'>
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                            <Skeleton variant="rectangular" width={280} height={80} >
                                                <Skeleton variant="circular" width={32} height={32} />
                                            </Skeleton>
                                        </div>

                                        <div className='sceleton-chat__message'>
                                            <Skeleton variant="rectangular" width={280} height={40} />
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                        </div>

                                    </Stack>
                                </div>
                                :
                                (comments.length > 0 ? comments?.map((props) => {
                                    const { id } = props
                                    return <CommentItem
                                        disabled={props.author.login !== email}
                                        key={id}
                                        {...props}
                                        event_id={eventId}
                                        fetchComments={e => fetchComments(e)} />
                                })
                                    :
                                    <p className='modal-add-task__no-data-message'>Нет комментариев</p>

                                )
                            }
                        </div>

                        <Comment
                            disabled={!infoTask}
                            name={currentUserName}
                            email={email}
                            color={currentUserColor}
                            event_id={eventId}
                            fetchUpdateComms={() => fetchComments()} />
                    </div>
                </div>

                <div className='modal-add-task__right-content'>
                    <div className='modal-add-task__header'>
                        <div className='modal-add-task__title-btn'>
                            <TooltipBlack
                                top='6px'
                                right='none'
                                left='calc(100% + 4px)'
                                text={'Ссылка скопирована'}
                                child={
                                    <button className='btn-copy-link'
                                        onClick={(e) => e}
                                    ><img src={copy_icon} /></button>
                                }
                            />
                            <h1 className='title_task'>{infoTask ? <>Редактирование задачи - <span>{`ID${eventId}`}</span></> : 'Новая задача'}</h1>
                        </div>

                        {/* Добавить логику для этого элемента */}
                        <DropDownSelector
                            options_prop={allColumns}
                            placeholder='Проект'
                            state={selectedProject}
                            setState={(e) => setSelectedProject(e)}
                            disabled={infoTask?.id}
                        />

                        <div className='group-btn'>

                            {/* <button className='btn-info'
                            disabled={isLoadInfoTask}
                            onClick={(e) => {
                                fetchTaskLog()
                            }}
                        ><img src={info_icon_2} /></button> */}

                            {(email == authorEmail) && <button className='btn-delete'
                                disabled={isLoadInfoTask}
                                onClick={(e) => {
                                    fetchDeleteTask(eventId)
                                    setTasks([])
                                    setIsModal(false)
                                    setInfoTask(null)
                                    setIstDropMenu(false)
                                }}
                            ><img src={trash_icon} /></button>}
                        </div>
                    </div>

                    <div className='modal-add-task__header'>
                        {!isLoadInfoTask ?
                            <>
                                <div className='executor'>
                                    <div className='executor-content'>
                                        <ExecutorPlaceholder hideTooltip name={authorName} color={authorColor} email={authorEmail} />
                                        <p className='text_name'>{authorName}</p>
                                    </div>
                                </div>
                                <DropDownSelector
                                    options_prop={OPTIONS_PRIORITY}
                                    placeholder='Приоритет'
                                    isClearable={false}
                                    value={selectedPriority}
                                    defaultValue={selectedPriority}
                                    state={selectedPriority}
                                    setState={(e) => setSelectedPriority(e)}
                                />

                                <DropDownSelector
                                    options_prop={statusTask}
                                    placeholder='Статус'
                                    isClearable={false}
                                    value={selectedStatusTask}
                                    defaultValue={selectedStatusTask}
                                    state={selectedStatusTask}
                                    setState={(e) => setSelectedStatusTask(e)}
                                />

                                <div className='select-group'>
                                    <DateBox
                                        disabled={check}
                                        startDate={endDate}
                                        description={'Крайний день выполнения'}
                                        setStartDate={(e) => setEndDate(e)}
                                    />

                                    <label className='checkbox-content'>
                                        <Checkbox
                                            value={check}
                                            onChange={(e) => setCheck(e)}
                                        />
                                        <p className='text'>Без срока</p>
                                    </label>
                                </div>
                            </>
                            :
                            <div className='sceleton-header'>
                                <Stack className='sceleton-header__content'>
                                    {/* For variant="text", adjust the height via font-size */}
                                    <Skeleton variant="circular" width={40} height={40} />
                                    {[...Array(3)].map((el) => {
                                        return <>
                                            <Skeleton variant="rectangular" width={210} height={40} />
                                        </>
                                    })}
                                </Stack>
                            </div>
                        }

                    </div>

                    <div className='modal-add-task__content'>
                        <div className='main-info' >
                            {!isLoadInfoTask ?
                                <>
                                    <TitleContentEditable
                                        disabled={authorEmail !== email}
                                        autoFocus
                                        placeholder='Добавьте название'
                                        title={title}
                                        setTitle={(e) => setTitle(e)}
                                    />

                                    <Editor
                                        disabled={authorEmail !== email}
                                        bounds='.main-info'
                                        title='Описание'
                                        placeholder='Редактировать описание задачи'
                                        description={description}
                                        setDescription={e => setDescription(e)} />
                                </>
                                :
                                <div className='sceleton-main-info'>
                                    <Stack className='sceleton-main-info__content'>
                                        <Skeleton variant="rectangular" height={48} />
                                        <Skeleton variant="rectangular" width={100} height={16} />
                                        <Skeleton variant="rectangular" height={200} />

                                        {/* <div className='sceleton-main-info__item'>
                                            <Skeleton variant="rectangular" width={260} height={32} />
                                            <Skeleton variant="rectangular" width={32} height={32} />
                                        </div> */}

                                        {/* <div className='sceleton-main-info__comment'>
                                            {[...Array(3)].map((el, ind) => {
                                                return <div key={el} className='sceleton-main-info__item'>
                                                    <Skeleton variant="rectangular" width={ind % 2 == 0 ? 300 : 150} height={18} />
                                                    <Skeleton variant="rectangular" width={144} height={18} />
                                                </div>
                                            })}
                                        </div> */}
                                    </Stack>
                                </div>
                            }
                        </div>



                        <div className='additional-information'>

                            <div className='additional-information__info'>
                                {/* <button className='btn-more-task'
                                onClick={(e) => {
                                    fetchTaskLog()
                                }}
                                style={logsErr ? { color: 'red' } : {}}
                            ><img src={info_icon} />Подробнее о задаче</button> */}

                                <div className='select-group'>
                                    <TabsGreen
                                        btns={btns}
                                        currentBtn={currentTab}
                                        setCurrentBtn={(e) => setCurrentTab(e)}
                                        checkValue={true}
                                    />

                                    {!isLoadInfoTask ?
                                        (currentTab == 'history' ?
                                            <div className={isShowHistoryTask ? 'history-actions' : 'history-actions_hide'}>
                                                <div className='history-actions__header'>
                                                    <h3 className='title'>ИНФОРМАЦИЯ о задаче</h3>
                                                </div>

                                                <div className='history-actions__content'>
                                                    {
                                                        logsArr?.length > 0 ?
                                                            logsArr?.map((el, i) => {
                                                                const { author, editor, comment, timestamp } = el
                                                                return (
                                                                    <div className='history-item'>
                                                                        <div className='round'></div>
                                                                        <div className='history-item__info'>
                                                                            {author?.login && <p className='text_label'>{author?.login}</p>}
                                                                            {editor?.login && <p className='text_label'>{editor?.login}</p>}
                                                                            {Array.isArray(comment) ?
                                                                                comment?.map(el => <p className='text_value'>{el}</p>)
                                                                                : <p className='text_value'>{comment}</p>
                                                                            }
                                                                            {timestamp && <p className='text_value'>{new Date(timestamp).toLocaleDateString('ru-RU')}</p>}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            'Ничего не найдено'
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <SelectExecutor
                                                    executors={executors}
                                                    setExecutors={(e) => setExecutors(e)}
                                                    executorOptions={executorOptions}
                                                    title='Исполнитель:'
                                                    placeholder='Назначить'
                                                />

                                                <div className='select-group__date-box'>
                                                    <label className='checkbox-content'>
                                                        <Checkbox
                                                            value={new Date(endDate2)?.toLocaleDateString('ru-RU') === new Date()?.toLocaleDateString('ru-RU')}
                                                            onChange={(e) => setEndDate2(new Date())}
                                                            disabled={new Date(endDate2)?.toLocaleDateString('ru-RU') === new Date()?.toLocaleDateString('ru-RU')}
                                                        />
                                                        <p className='text'>Выполню сегодня</p>
                                                    </label>

                                                    <DateBox
                                                        startDate={endDate2}
                                                        description={'Срок выполнения'}
                                                        setStartDate={(e) => setEndDate2(e)}
                                                    />
                                                </div>

                                                {executors[0]?.id && <SelectExecutor
                                                    executors={coExecutors}
                                                    setExecutors={(e) => setCoExecutors(e)}
                                                    executorOptions={executorOptions?.filter(({ id }) => {
                                                        if (executors[0]?.id) {
                                                            return id != executors[0].id
                                                        }
                                                    })}
                                                    title='Cоисполнители:'
                                                    placeholder='Назначить'
                                                    hideTooltip={false}
                                                    multi
                                                />}
                                            </>)

                                        :
                                        <div className='sceleton-select-group'>
                                            <Stack className='sceleton-select-group__content'>

                                                <Skeleton variant="rectangular" width={100} height={16} />

                                                <div className='sceleton-select-group__user'>
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <Skeleton variant="rectangular" width={97} height={40} />
                                                </div>

                                                <Skeleton variant="rectangular" width={100} height={16} />
                                                <Skeleton variant="rectangular" width={200} height={32} />
                                                <Skeleton variant="rectangular" width={100} height={16} />

                                                <div className='sceleton-select-group__user'>
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <Skeleton variant="rectangular" width={97} height={40} />
                                                </div>
                                                <div className='sceleton-select-group__user'>
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <Skeleton variant="rectangular" width={97} height={40} />
                                                </div>
                                            </Stack>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Нижний контент с дроп зоной */}

                    <div className='modal-add-task__bottom-content'>
                        <div className='modal-add-task__tab-and-content'>
                            <TabsGreen
                                btns={btns_2}
                                currentBtn={currentTab2}
                                setCurrentBtn={(e) => setCurrentTab2(e)}
                                checkValue={true}
                            />

                            {isLoadInfoTask ?

                                <Stack className='sceleton-file__content'>
                                    <Skeleton variant="rectangular" height={20} />

                                    <div className='sceleton-file__item'>
                                        <Skeleton variant="rectangular" width={350} height={20} />
                                        <Skeleton variant="rectangular" width={200} height={20} />
                                    </div>

                                    <div className='sceleton-file__item'>
                                        <Skeleton variant="rectangular" width={250} height={20} />
                                        <Skeleton variant="rectangular" width={200} height={20} />
                                    </div>

                                    <div className='sceleton-file__item'>
                                        <Skeleton variant="rectangular" width={350} height={20} />
                                        <Skeleton variant="rectangular" width={200} height={20} />
                                    </div>

                                    <div className='sceleton-file__item'>
                                        <Skeleton variant="rectangular" width={250} height={20} />
                                        <Skeleton variant="rectangular" width={200} height={20} />
                                    </div>
                                </Stack>
                                :

                                <div className='modal-add-task__content-with-drop-zone'>
                                    <div className='modal-add-task__link-list'>

                                        {currentTab2 == 'file' && filesdata.map(({ label, value }, ind) => {
                                            return <div className='modal-add-task__link'>
                                                <p className='modal-add-task__link-file'>
                                                    <img src={file_icon} />
                                                    {label}

                                                    {(email == authorEmail) && <img className='modal-add-task__icon'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            let indx = ind
                                                            setFilesData(filesdata.filter((el) => el.label !== label))
                                                        }}
                                                        src={close} />}
                                                </p>

                                                <a className='modal-add-task__link-btn'
                                                    target='_blank'
                                                    href={value}
                                                    download={'download'}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <img src={download_icon} /> Скачать файл
                                                </a>
                                                <div className='modal-add-task__link-info'>
                                                    <span>23 МБ</span>
                                                    <span>02.03.2016 16:33</span>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>}
                        </div>

                        {(email == authorEmail) && <DownloadComponent
                            filesdata={filesdata}
                            setFilesArr={(e) => setFiles(e)}
                        />}
                    </div>



                    <div className='modal-add-task__btn-group'>
                        <ButtonBasic
                            disabled={isLoadInfoTask}
                            green
                            size='56px'
                            width='260px'
                            text={infoTask ? 'Сохранить' : 'Создать'}
                            onClick={() => infoTask ? handleMainButtonClick(true) : handleMainButtonClick(false)}
                        />
                    </div>
                </div>
            </div>

        </div >
    )
}

export { ModalAddTask2 }