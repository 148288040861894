import './ModalError.scss'

const ModalError = ({ headerText, messageText, setShowModal }) => {

    return (
        <div className='modal-box modal-error'>
            <div className='modal-add-links'>
                <div style={{paddingLeft: '40px', paddingRight: '40px', paddingBottom: '52px'}}>
                    <h2 className='title'>{headerText}</h2>
                    <p className='text'>{messageText}</p>
                </div>

                {/* <img src={done} className='icon_done-round'></img> */}

                <button className='btn__blue-modal' onClick={() => setShowModal(false)}>X</button>
            </div>
        </div>

    )
}

export default ModalError