import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useState, useEffect, useRef } from 'react';
import { TableBox, ButtonBasic, DropDownSelector, SearchComponent, Tabs } from "@lk-gtcom/ecomlab-components";
import { TabsGreen } from '../../components/TabsGreen/TabsGreen';
import xlc from './img/xlc.svg'
import './SettingCostPrice.scss'

const btn_tab = [
    {
        label: 'Установка себестоимости',
        value: 'setting'
    },
    {
        label: 'Параметры себестоимости',
        value: 'parameters'
    }
]

const btns = [
    { label: 'Общее для всех', count: 2, value: 'common' },
    { label: 'По товарам', count: 4, value: 'product' }
]

const SettingCostPrice = ({ }) => {

    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [status, setStatus] = useState(btns?.[0]?.value)

    const [categoryPage, setCategoryPage] = useState(1)
    const [brandPage, setBrandPage] = useState(1)

    const [categoryQuery, setCategoryQuery] = useState('')
    const [brandQuery, setBrandQuery] = useState('')

    const [categoryData, setCategoryData] = useState([])
    const [brandData, setBrandData] = useState([])

    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])

    const [searchData, setSearchData] = useState([])

    const [searchValue, setSearchValue] = useState('')
    const activeTab = useParams()['*']

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])


    return (

        <div className='setting-cost-price'>

            <h1 className='setting-cost-price__title-main'>Установка себестоимости</h1>

            <div className='setting-cost-price__toolbar-top'>
                <FilterContainer
                    onReset={(e) => {
                        setSelectedCategory([])
                        setSelectedBrand([])
                    }}
                >
                    <DropDownSelector
                        options_prop={categoryData}
                        state={selectedCategory}
                        setState={e => setSelectedCategory(e)}
                        placeholder='Категория'
                        multi={true}
                        fetchCallback={(e) => setCategoryPage(e)}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                    />

                    <DropDownSelector
                        options_prop={brandData}
                        state={selectedBrand}
                        setState={e => setSelectedBrand(e)}
                        placeholder='Бренд'
                        multi={true}
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                    />
                    <SearchComponent
                        searchValue={''}
                        errSearch={''}
                        placeholder='Поиск...'
                        setErrSearch={(e) => (e)}
                        setSearchValue={(e) => (e)}
                        data={[]}
                    />
                </FilterContainer>

                <ButtonBasic
                    green
                    width='150px'
                    text='Показать товар'
                />
            </div>

            <div className='setting-cost-price__toolbar-medium'>
                <Tabs
                    tabs={btn_tab}
                    pageValue={pageValue} />

                <button className='setting-cost-price__btn-load'><img src={xlc} />Загрузить из Excel</button>
            </div>

            <TableBox
                loading={loading}
                fetchedData={[]}
                headers={[]}
                child={
                    <>
                        {pageValue == 'parameters' && <div className='setting-cost-price__table-panel'>
                            <TabsGreen
                                btns={btns}
                                currentBtn={status}
                                setCurrentBtn={(e) => setStatus(e)}
                                checkValue={true}
                            />
                            <button className='setting-cost-price__btn-new-field'>+ Новое поле</button>
                        </div>}
                    </>
                }
            />
        </div>
    )
}

export { SettingCostPrice }