// import { useState, useEffect } from 'react';
// import React from 'react';
// import trash_icon from './img/trash_icon.svg';
// import edit_icon from './img/edit_icon.svg';
// import drop_icon from './img/drop_icon.svg';
// import customHeaders, { getSpace } from '../../../common/headers';
// import { RavshanHttps } from '../../../fetchUrls';
// import { handleApiResponseOrThrow } from '../../../common/utils';
// import {
//   ButtonBasic,
//   InputDinamycPlaceholder,
// } from '@lk-gtcom/ecomlab-components';
// import { Loader } from '../../../common/components/Loader/Loader';
// import useGeneralStore from '../../../store/general';
// import './ModalMiltilevelSidebarSettings.scss';

// const DropZone = ({
//   dragElement,
//   setDragElement,
//   level,
//   level_id,
//   parent_id,
//   place,
//   fetchChangeLevel,
//   refresh,
//   value,
//   isOpenMenu,
//   fetchArticlesByLevel,
// }) => {
//   return (
//     <div
//       className={dragElement ? 'drop-placeholder' : 'drop-placeholder_hide'}
//       onDragOver={(e) => e.preventDefault()}
//       onDrop={(e) => {
//         fetchChangeLevel(
//           dragElement?.name,
//           parent_id,
//           level_id,
//           dragElement?.id
//         );
//         refresh();

//         const arr = Array.from(isOpenMenu);
//         arr.map((el, ind) => {
//           setTimeout(fetchArticlesByLevel(el), 200 + 1 * ind);
//         });
//         // setTimeout(() => fetchArticlesByLevel(value), 600)
//       }}
//       onDragEnter={(e) => e.target.classList.add('hover')}
//       onDragLeave={(e) => e.target.classList.remove('hover')}
//     ></div>
//   );
// };

// const Menu = ({
//   fetchAddLevel,
//   items,
//   label,
//   value,
//   // isOpenMenu,
//   setParrentValue,
//   // setIsOpenMenu,
//   setDragElement,
//   dragElement,
//   isContentLabel,
//   parrentValue,
//   setIsContentLabel,
//   parrentLabel,
//   setParrentLabel,
//   parrent,
//   ind,
//   isInput,
//   setIsInput,
//   // fetchLevels,
//   // fetchArticlesByLevel,
//   has_child,
//   fetchDeleteLevel,
//   index,
//   // setSidebarState,
//   // sidebarState,
//   selected,
//   setSelected,
//   activeMenuItems,
//   setActiveMenuItems,
//   sidebarInd,
//   parent_id,
//   before,
//   refreshSidebar,
//   refresh,
// }) => {
//   const selectedSpace = useGeneralStore((state) => state.selectedSpace);
//   const headers = { ...customHeaders, ...getSpace(selectedSpace) };
//   const [isOpenMenu, setIsOpenMenu] = useState(new Map([]));
//   const parrents = parrent;
//   const [sidebarState, setSidebarState] = useState([]);
//   const [place, setPlace] = useState(0);

//   // Изменения уровня WIKI
//   const fetchChangeLevel = (name, parent_id, place, wiki_id) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${RavshanHttps}/api/wiki/level`;
//     } else {
//       url = `${RavshanHttps}/api/wiki/level`;
//     }

//     const body = JSON.stringify({
//       name: name,
//       parent_id: parent_id,
//       // parent_id: 919,
//       before: place,
//       wiki_id: wiki_id,
//     });

//     fetch(url, { body, method: 'PUT', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then((json) => {})
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   // Получение уровней
//   const fetchArticlesByLevel = (parrent, index) => {
//     const abortController = new AbortController();
//     // setLoading(true)
//     const url = `${RavshanHttps}/api/wiki/levels`;
//     const parrents = parrent ? { 'parent-id': parrent } : '';

//     const getHeaders = {
//       ...headers,
//       ...parrents,
//     };

//     fetch(url, { headers: getHeaders, signal: abortController.signal })
//       .then(async (res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           const err = await res.json();
//           throw new Error(JSON.stringify(err));
//         }
//       })
//       .then((json) => {
//         const arr = json.map(
//           ({ id, name, before, has_child, parent_id, subItems }) => {
//             return {
//               label: name,
//               subItems: subItems?.length > 0 ? subItems : [],
//               has_child: has_child,
//               value: id,
//               before: before,
//               parent_id: parent_id,
//             };
//           }
//         );

//         setSidebarState(arr);
//       })
//       .catch((err) => console.error(err))
//       .finally((e) => {
//         // setLoading(false)
//       });
//     return abortController;
//   };

//   const LabelBox = (items, label, value, ind, place, parrent_id) => {
//     const [nameItem, setNameItem] = useState('');

//     const add_point_menu = (top) => {
//       return (
//         <div className="sidebar-miltilevel-settings__btn-container">
//           <button
//             className="sidebar-miltilevel-settings__btn-add"
//             onClick={(e) => {
//               e.stopPropagation();
//               setIsContentLabel(null);
//               if (top) {
//                 setIsInput(value + 2000);
//               } else setIsInput(value);
//             }}
//           >
//             +
//           </button>
//           {isInput == (top ? value + 2000 : value) && (
//             <input
//               value={nameItem}
//               onChange={(e) => setNameItem(e.target.value)}
//               onClick={(e) => {
//                 e.stopPropagation();
//               }}
//               onKeyUp={(e) => {
//                 e.stopPropagation();
//                 if (e.key === 'Enter') {
//                   fetchAddLevel(nameItem, parent_id, value);
//                   setNameItem('');
//                   refresh();
//                 }
//               }}
//               className="sidebar-miltilevel-settings__input"
//               placeholder="Введите название"
//             />
//           )}
//         </div>
//       );
//     };

//     return (
//       <>
//         {!ind && (
//           <DropZone
//             dragElement={dragElement}
//             setDragElement={(e) => setDragElement(e)}
//             isOpenMenu={isOpenMenu}
//             parent_id={parent_id}
//             name={label}
//             place={ind}
//             level_id={null}
//             value={dragElement?.id}
//             fetchChangeLevel={fetchChangeLevel}
//             fetchArticlesByLevel={(e) => fetchArticlesByLevel(e)}
//             refresh={(e) => {
//               refresh();
//             }}
//           />
//         )}
//         <div
//           className="sidebar-miltilevel-settings__label-box"
//           onClick={(e) => {
//             // Cохранить открытые пункты
//             const newState = new Set(isOpenMenu);

//             if (!newState.has(value)) {
//               if (has_child) {
//                 newState.add(value);
//               }
//             } else {
//               newState.delete(value);
//             }
//             setIsOpenMenu(newState);
//             setActiveMenuItems(value);

//             if (has_child) {
//               fetchArticlesByLevel(value);
//             } else {
//               setSidebarState([]);
//             }
//           }}
//         >
//           <div
//             className={
//               activeMenuItems == value
//                 ? 'sidebar-miltilevel-settings__label_active'
//                 : 'sidebar-miltilevel-settings__label'
//             }
//             style={{
//               background:
//                 activeMenuItems == value
//                   ? ''
//                   : parrentValue[sidebarInd] == value
//                   ? 'rgb(240, 242, 244)'
//                   : '',
//             }}
//           >
//             <h3
//               contentEditable={value == isContentLabel ? true : false}
//               draggable={true}
//               onDragStart={(e) => {
//                 setParrentLabel(parrent);
//                 setDragElement({
//                   parent_id: parent_id,
//                   id: value,
//                   name: label,
//                 });
//               }}
//               onDragEnd={(e) => {
//                 refresh();
//                 // setSidebarState([])

//                 // setTimeout(() => fetchArticlesByLevel(before, 0), 800)
//                 setDragElement(null);
//               }}
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter') {
//                   let new_name = e.currentTarget.innerHTML;
//                   fetchChangeLevel(
//                     new_name,
//                     parrentValue[sidebarInd - 1],
//                     before,
//                     value
//                   );
//                   setIsContentLabel(null);
//                   refreshSidebar();
//                 }
//               }}
//               className="sidebar-miltilevel-settings__title"
//             >
//               {label}
//             </h3>

//             <button
//               className="sidebar-miltilevel-settings__btn-edit"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setIsContentLabel(value);
//               }}
//             >
//               <img src={edit_icon} />
//             </button>

//             <button
//               className="sidebar-miltilevel-settings__btn-delete"
//               onClick={(e) => {
//                 if (!isInput) {
//                   e.stopPropagation();
//                   fetchDeleteLevel(value);
//                   // setSidebarState([])
//                   let res;
//                   setParrentValue((prev) => {
//                     let lastSelectedParent = prev?.slice(0, sidebarInd);
//                     res = [...lastSelectedParent];
//                     return res;
//                   });
//                   refresh();
//                 }
//               }}
//             >
//               <img src={trash_icon} />
//             </button>

//             {add_point_menu(true)}
//           </div>

//           {items?.length > 0 && (
//             <button className="sidebar-miltilevel-settings__btn-drop">
//               <img
//                 src={drop_icon}
//                 style={{
//                   display: 'block',
//                   transform: isOpenMenu.has(value) ? 'rotate(180deg)' : '',
//                 }}
//               />
//             </button>
//           )}
//         </div>

//         <DropZone
//           dragElement={dragElement}
//           setDragElement={(e) => setDragElement(e)}
//           parent_id={parent_id}
//           name={label}
//           place={ind}
//           value={dragElement?.id}
//           level_id={value}
//           fetchChangeLevel={fetchChangeLevel}
//           isOpenMenu={isOpenMenu}
//           fetchArticlesByLevel={(e) => fetchArticlesByLevel(e)}
//           refresh={(e) => {
//             refresh();
//           }}
//         />
//       </>
//     );
//   };

//   useEffect(() => {}, [dragElement]);

//   return (
//     <div
//       className="sidebar-miltilevel-settings"
//       key={label + ind}
//       onClick={(e) => {
//         if (e.target.closest('.sidebar-miltilevel-settings__input')) return;
//         setIsInput(null);
//       }}
//     >
//       <div className="modal-miltilevel-sidebar-settings__menu-item">
//         {label && LabelBox(items, label, value, ind, parent_id)}
//         {isOpenMenu.has(value) && (
//           <div className="modal-miltilevel-sidebar-settings__drop-box">
//             {sidebarState?.map(
//               (
//                 { label, subItems, value, has_child, parent_id, before },
//                 ind
//               ) => {
//                 return (
//                   <Menu
//                     fetchArticlesByLevel={(e) => fetchArticlesByLevel(e)}
//                     isContentLabel={isContentLabel}
//                     setIsContentLabel={(e) => setIsContentLabel(e)}
//                     isOpenMenu={isOpenMenu}
//                     setIsOpenMenu={(e) => setIsOpenMenu(e)}
//                     label={label}
//                     has_child={has_child}
//                     items={subItems}
//                     value={value}
//                     dragElement={dragElement}
//                     setDragElement={(e) => setDragElement(e)}
//                     parrentLabel={parrentLabel}
//                     setParrentLabel={(e) => setParrentLabel(e)}
//                     parrent={parrentValue}
//                     ind={ind}
//                     index={index + 1}
//                     isInput={isInput}
//                     setIsInput={(e) => setIsInput(e)}
//                     fetchDeleteLevel={(e) => fetchDeleteLevel(e)}
//                     sidebarState={sidebarState}
//                     setSidebarState={(e) => setSidebarState(e)}
//                     setParrentValue={(e) => setParrentValue(e)}
//                     parrentValue={parrentValue}
//                     fetchAddLevel={fetchAddLevel}
//                     activeMenuItems={activeMenuItems}
//                     setActiveMenuItems={(e) => setActiveMenuItems(e)}
//                     sidebarInd={index + 1}
//                     parent_id={parent_id}
//                     before={before}
//                     refreshSidebar={(e) => refreshSidebar(e)}
//                     refresh={(e) => {
//                       setTimeout(
//                         () => fetchArticlesByLevel(parent_id, 0),
//                         1000
//                       );
//                     }}
//                   />
//                 );
//               }
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// const ModalMiltilevelSidebarSettings = ({ setIsModal, data }) => {
//   const selectedSpace = useGeneralStore((state) => state.selectedSpace);
//   const headers = { ...customHeaders, ...getSpace(selectedSpace) };
//   const [dragElement, setDragElement] = useState(null);
//   const [isContentLabel, setIsContentLabel] = useState(null);
//   const [parrentLabel, setParrentLabel] = useState(null);
//   const [isInput, setIsInput] = useState(false);

//   const [parrentValue, setParrentValue] = useState([]);
//   const [activeMenuItems, setActiveMenuItems] = useState(null);
//   const [nameNewPoints, setNameNewPoints] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [sidebarState, setSidebarState] = useState([]);

//   // Получение уровней
//   const fetchArticlesByLevel = (parrent, index) => {
//     const abortController = new AbortController();
//     // setLoading(true)
//     const url = `${RavshanHttps}/api/wiki/levels`;
//     const parrents = parrent ? { 'parent-id': parrent } : '';

//     const getHeaders = {
//       ...headers,
//       ...parrents,
//     };

//     fetch(url, { headers: getHeaders, signal: abortController.signal })
//       .then(async (res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           const err = await res.json();
//           throw new Error(JSON.stringify(err));
//         }
//       })
//       .then((json) => {
//         const arr = json.map(
//           ({ id, name, before, has_child, parent_id, subItems }) => {
//             return {
//               label: name,
//               subItems: subItems?.length > 0 ? subItems : [],
//               has_child: has_child,
//               value: id,
//               before: before,
//               parent_id: parent_id,
//             };
//           }
//         );

//         setSidebarState(arr);
//       })
//       .catch((err) => console.error(err))
//       .finally((e) => {
//         // setLoading(false)
//       });
//     return abortController;
//   };

//   // Добавления уровня WIKI
//   const fetchAddLevel = (name, parent_id, place) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${RavshanHttps}/api/wiki/level`;
//     } else {
//       url = `${RavshanHttps}/api/wiki/level`;
//     }

//     const body = JSON.stringify({
//       name: name,
//       parent_id: parent_id,
//       before: place,
//     });

//     fetch(url, { body, method: 'POST', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then((json) => {
//         console.log(json);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   // Удаление уровня WIKI
//   const fetchDeleteLevel = (level_id) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${RavshanHttps}/api/wiki/level`;
//     } else {
//       url = `${RavshanHttps}/api/wiki/level`;
//     }

//     const _headers = {
//       ...headers,
//       'level-id': level_id,
//     };

//     fetch(url, { method: 'DELETE', headers: _headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then((json) => {
//         console.log(json);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   useEffect(() => {
//     fetchArticlesByLevel(undefined, 0);
//   }, []);

//   const refreshSidebar = () => {
//     // setIsInput(false)
//     // setSidebarState([])
//     // setTimeout(() => fetchArticlesByLevel(null, 0), 100)
//     // parrentValue.forEach((el, ind) => {
//     //     return setTimeout(() => fetchArticlesByLevel(el, ind + 1), (ind + 1) * 200)
//     // })
//   };

//   return (
//     <div
//       className="blackout"
//       onClick={(e) => {
//         let el = e.target;
//         if (el.classList.contains('blackout')) {
//           setIsModal(false);
//         }
//       }}
//     >
//       <div
//         className="modal-miltilevel-sidebar-settings"
//         onMouseUp={(e) => setDragElement(null)}
//       >
//         <h2 className="modal-miltilevel-sidebar-settings__title">
//           Настройки меню
//         </h2>
//         <div className="modal-miltilevel-sidebar-settings__top-block">
//           <InputDinamycPlaceholder
//             placeholder="Введите название"
//             changeValue={nameNewPoints}
//             onChange={(e) => setNameNewPoints(e)}
//             size="s"
//           />

//           <ButtonBasic
//             width="264px"
//             whiteGreen
//             text="Добавить пункт"
//             onClick={(e) => {
//               fetchAddLevel(
//                 nameNewPoints,
//                 activeMenuItems ? activeMenuItems : null,
//                 null
//               );
//               setNameNewPoints('');
//               setTimeout(() => fetchArticlesByLevel(undefined, 0), 500);
//             }}
//           />
//         </div>

//         {loading ? (
//           <Loader />
//         ) : (
//           <div className="modal-miltilevel-sidebar-settings__group">
//             <div className="modal-miltilevel-sidebar-settings__content">
//               {sidebarState.map(
//                 (
//                   {
//                     label,
//                     subItems,
//                     value,
//                     has_child,
//                     parent_id,
//                     before,
//                     place,
//                   },
//                   ind
//                 ) => {
//                   return (
//                     <Menu
//                       fetchArticlesByLevel={(e) => fetchArticlesByLevel(e)}
//                       isContentLabel={isContentLabel}
//                       setIsContentLabel={(e) => setIsContentLabel(e)}
//                       // isOpenMenu={isOpenMenu}
//                       // setIsOpenMenu={(e) => setIsOpenMenu(e)}
//                       label={label}
//                       has_child={has_child}
//                       items={subItems}
//                       value={value}
//                       dragElement={dragElement}
//                       setDragElement={(e) => setDragElement(e)}
//                       parrentLabel={parrentLabel}
//                       setParrentLabel={(e) => setParrentLabel(e)}
//                       parrent={parrentValue}
//                       ind={ind}
//                       index={0}
//                       isInput={isInput}
//                       setIsInput={(e) => setIsInput(e)}
//                       fetchDeleteLevel={(e) => fetchDeleteLevel(e)}
//                       // sidebarState={sidebarState}
//                       // setSidebarState={(e) => setSidebarState(e)}
//                       setParrentValue={(e) => setParrentValue(e)}
//                       parrentValue={parrentValue}
//                       fetchAddLevel={fetchAddLevel}
//                       activeMenuItems={activeMenuItems}
//                       setActiveMenuItems={(e) => setActiveMenuItems(e)}
//                       sidebarInd={0}
//                       parent_id={parent_id}
//                       before={before}
//                       refreshSidebar={(e) => refreshSidebar(e)}
//                       refresh={(e) => {
//                         setTimeout(
//                           () => fetchArticlesByLevel(undefined, 0),
//                           500
//                         );
//                       }}
//                     />
//                   );
//                 }
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export { ModalMiltilevelSidebarSettings };

import React, { useState, useEffect } from 'react';
import trash_icon from './img/trash_icon.svg';
import edit_icon from './img/edit_icon.svg';
import drop_icon from './img/drop_icon.svg';
import customHeaders, { getSpace } from '../../../common/headers';
import { RavshanHttps } from '../../../fetchUrls';
import {
  ButtonBasic,
  InputDinamycPlaceholder,
} from '@lk-gtcom/ecomlab-components';
import { Loader } from '../../../common/components/Loader/Loader';
import useGeneralStore from '../../../store/general';
import './ModalMiltilevelSidebarSettings.scss';

const DropZone = ({
  dragElement,
  setDragElement,
  level_id,
  parent_id,
  fetchChangeLevel,
  refresh,
  isOpenMenu,
  fetchArticlesByLevel,
}) => {
  return (
    <div
      className={dragElement ? 'drop-placeholder' : 'drop-placeholder_hide'}
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => {
        if (dragElement && dragElement.id !== level_id) {
          let parId = dragElement.parent_id === level_id ? null : level_id;
          fetchChangeLevel(dragElement.name, parId, null, dragElement.id);
          refresh();

          const arr = Array.from(isOpenMenu);
          arr.forEach((el, ind) => {
            setTimeout(() => fetchArticlesByLevel(el), 200 + 1 * ind);
          });

          setDragElement(null);
        }
      }}
      onDragEnter={(e) => e.target.classList.add('hover')}
      onDragLeave={(e) => e.target.classList.remove('hover')}
    ></div>
  );
};

const Menu = ({
  fetchAddLevel,
  items,
  label,
  value,
  setParrentValue,
  setDragElement,
  dragElement,
  isContentLabel,
  parrentValue,
  setIsContentLabel,
  parrentLabel,
  setParrentLabel,
  parrent,
  ind,
  isInput,
  setIsInput,
  has_child,
  fetchDeleteLevel,
  index,
  setActiveMenuItems,
  activeMenuItems,
  sidebarInd,
  parent_id,
  before,
  refreshSidebar,
  refresh,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [isOpenMenu, setIsOpenMenu] = useState(new Map());
  const [sidebarState, setSidebarState] = useState(items);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);

  const fetchChangeLevel = (name, parent_id, place, wiki_id) => {
    const url = `${RavshanHttps}/api/wiki/level`;

    const body = JSON.stringify({
      name,
      parent_id,
      before: place,
      wiki_id,
    });

    fetch(url, { body, method: 'PUT', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchArticlesByLevel = (parrent) => {
    setIsLoadingChildren(true);
    const abortController = new AbortController();
    const url = `${RavshanHttps}/api/wiki/levels`;
    const parrents = parrent ? { 'parent-id': parrent } : '';

    const getHeaders = {
      ...headers,
      ...parrents,
    };

    fetch(url, { headers: getHeaders, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json, 'json');
        const arr = json.map(
          ({ id, name, before, has_child, parent_id, subItems }) => ({
            label: name,
            subItems: subItems?.length > 0 ? subItems : [],
            has_child,
            value: id,
            before,
            parent_id,
          })
        );

        setSidebarState(arr);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingChildren(false);
      });
  };

  const LabelBox = (items, label, value, ind, parent_id) => {
    const [nameItem, setNameItem] = useState('');

    return (
      <>
        {!ind && (
          <DropZone
            dragElement={dragElement}
            setDragElement={setDragElement}
            isOpenMenu={isOpenMenu}
            parent_id={parent_id}
            level_id={value}
            fetchChangeLevel={fetchChangeLevel}
            fetchArticlesByLevel={fetchArticlesByLevel}
            refresh={refresh}
          />
        )}
        <div
          className="sidebar-miltilevel-settings__label-box"
          onClick={() => {
            const newState = new Set(isOpenMenu);

            if (!newState.has(value)) {
              if (has_child) newState.add(value);
            } else {
              newState.delete(value);
            }
            setIsOpenMenu(newState);
            setActiveMenuItems(value);

            if (has_child) {
              fetchArticlesByLevel(value);
            } else {
              setSidebarState([]);
            }
          }}
        >
          <div
            className={
              activeMenuItems === value
                ? 'sidebar-miltilevel-settings__label_active'
                : 'sidebar-miltilevel-settings__label'
            }
            style={{
              background:
                activeMenuItems === value
                  ? ''
                  : parrentValue[sidebarInd] === value
                  ? 'rgb(240, 242, 244)'
                  : '',
            }}
          >
            <h3
              contentEditable={value === isContentLabel}
              draggable
              onDragStart={() => {
                setParrentLabel(parrent);
                setDragElement({
                  parent_id,
                  id: value,
                  name: label,
                });
              }}
              onDragEnd={() => {
                refresh();
                setDragElement(null);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const newName = e.currentTarget.innerHTML;
                  fetchChangeLevel(
                    newName,
                    parrentValue[sidebarInd - 1],
                    before,
                    value
                  );
                  setIsContentLabel(null);
                  refreshSidebar();
                }
              }}
              className="sidebar-miltilevel-settings__title"
            >
              {label}
            </h3>

            <button
              className="sidebar-miltilevel-settings__btn-edit"
              onClick={(e) => {
                e.stopPropagation();
                setIsContentLabel(value);
              }}
            >
              <img src={edit_icon} alt="edit" />
            </button>

            <button
              className="sidebar-miltilevel-settings__btn-delete"
              onClick={(e) => {
                if (!isInput) {
                  e.stopPropagation();
                  fetchDeleteLevel(value);
                  setParrentValue((prev) => {
                    const lastSelectedParent = prev?.slice(0, sidebarInd);
                    return [...lastSelectedParent];
                  });
                  refresh();
                }
              }}
            >
              <img src={trash_icon} alt="delete" />
            </button>

            {has_child && (
              <button className="sidebar-miltilevel-settings__btn-drop">
                <img
                  src={drop_icon}
                  alt="drop"
                  style={{
                    display: 'block',
                    transform: isOpenMenu.has(value) ? 'rotate(180deg)' : '',
                  }}
                />
              </button>
            )}
          </div>

          {isLoadingChildren && <Loader />}
          {items?.length > 0 && (
            <div className="modal-miltilevel-sidebar-settings__drop-box">
              {items.map((item, ind) => (
                <Menu
                  key={item.value}
                  fetchArticlesByLevel={fetchArticlesByLevel}
                  isContentLabel={isContentLabel}
                  setIsContentLabel={setIsContentLabel}
                  isOpenMenu={isOpenMenu}
                  setIsOpenMenu={setIsOpenMenu}
                  label={item.label}
                  has_child={item.has_child}
                  items={item.subItems}
                  value={item.value}
                  dragElement={dragElement}
                  setDragElement={setDragElement}
                  parrentLabel={parrentLabel}
                  setParrentLabel={setParrentLabel}
                  parrent={parrentValue}
                  ind={ind}
                  index={index + 1}
                  isInput={isInput}
                  setIsInput={setIsInput}
                  fetchDeleteLevel={fetchDeleteLevel}
                  sidebarState={sidebarState}
                  setSidebarState={setSidebarState}
                  setParrentValue={setParrentValue}
                  parrentValue={parrentValue}
                  fetchAddLevel={fetchAddLevel}
                  activeMenuItems={activeMenuItems}
                  setActiveMenuItems={setActiveMenuItems}
                  sidebarInd={index + 1}
                  parent_id={item.parent_id}
                  before={item.before}
                  refreshSidebar={refreshSidebar}
                  refresh={refresh}
                />
              ))}
            </div>
          )}
        </div>

        <DropZone
          dragElement={dragElement}
          setDragElement={setDragElement}
          parent_id={parent_id}
          level_id={value}
          fetchChangeLevel={fetchChangeLevel}
          isOpenMenu={isOpenMenu}
          fetchArticlesByLevel={fetchArticlesByLevel}
          refresh={refresh}
        />
      </>
    );
  };

  useEffect(() => {}, [dragElement]);

  return (
    <div
      className="sidebar-miltilevel-settings"
      key={label + ind}
      onClick={(e) => {
        if (e.target.closest('.sidebar-miltilevel-settings__input')) return;
        setIsInput(null);
      }}
    >
      <div className="modal-miltilevel-sidebar-settings__menu-item">
        {label && LabelBox(items, label, value, ind, parent_id)}
        {isOpenMenu.has(value) && (
          <div className="modal-miltilevel-sidebar-settings__drop-box">
            {sidebarState?.map((item, ind) => (
              <Menu
                key={item.value}
                fetchArticlesByLevel={fetchArticlesByLevel}
                isContentLabel={isContentLabel}
                setIsContentLabel={setIsContentLabel}
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={setIsOpenMenu}
                label={item.label}
                has_child={item.has_child}
                items={item.subItems}
                value={item.value}
                dragElement={dragElement}
                setDragElement={setDragElement}
                parrentLabel={parrentLabel}
                setParrentLabel={setParrentLabel}
                parrent={parrentValue}
                ind={ind}
                index={index + 1}
                isInput={isInput}
                setIsInput={setIsInput}
                fetchDeleteLevel={fetchDeleteLevel}
                setParrentValue={setParrentValue}
                parrentValue={parrentValue}
                fetchAddLevel={fetchAddLevel}
                activeMenuItems={activeMenuItems}
                setActiveMenuItems={setActiveMenuItems}
                sidebarInd={index + 1}
                parent_id={item.parent_id}
                before={item.before}
                refreshSidebar={refreshSidebar}
                refresh={refresh}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ModalMiltilevelSidebarSettings = ({ setIsModal }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [dragElement, setDragElement] = useState(null);
  const [isContentLabel, setIsContentLabel] = useState(null);
  const [parrentLabel, setParrentLabel] = useState(null);
  const [isInput, setIsInput] = useState(false);

  const [parrentValue, setParrentValue] = useState([]);
  const [activeMenuItems, setActiveMenuItems] = useState(null);
  const [nameNewPoints, setNameNewPoints] = useState('');
  const [loading, setLoading] = useState(false);
  const [sidebarState, setSidebarState] = useState([]);

  console.log(sidebarState, 'state');

  const fetchArticlesByLevel = (parrent) => {
    const abortController = new AbortController();
    const url = `${RavshanHttps}/api/wiki/levels`;
    const parrents = parrent ? { 'parent-id': parrent } : '';

    const getHeaders = {
      ...headers,
      ...parrents,
    };

    fetch(url, { headers: getHeaders, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json, 'DOWN');
        const arr = json.map(
          ({ id, name, before, has_child, parent_id, subItems }) => ({
            label: name,
            subItems: subItems?.length > 0 ? subItems : [],
            has_child,
            value: id,
            before,
            parent_id,
          })
        );

        setSidebarState(arr);
      })
      .catch((err) => console.error(err));
    return abortController;
  };

  const fetchAddLevel = (name, parent_id, place) => {
    const url = `${RavshanHttps}/api/wiki/level`;

    const body = JSON.stringify({
      name,
      parent_id,
      before: place,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => console.log(json))
      .catch((err) => console.error(err));
  };

  // const fetchDeleteLevel = (level_id) => {
  //   const url = `${RavshanHttps}/api/wiki/level`;

  //   const _headers = {
  //     ...headers,
  //     'level-id': level_id,
  //   };

  //   fetch(url, { method: 'DELETE', headers: _headers })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         throw new Error(res.json());
  //       }
  //     })
  //     .then((json) => console.log(json))
  //     .catch((err) => console.error(err));
  // };
  const fetchDeleteLevel = (level_id) => {
    const url = `${RavshanHttps}/api/wiki/level`;

    const _headers = {
      ...headers,
      'level-id': level_id,
    };

    fetch(url, { method: 'DELETE', headers: _headers })
      .then((res) => {
        if (res.ok) {
          if (res.status === 204) {
            setSidebarState((prevState) => {
              const updatedState = prevState.filter(
                (item) => item.value !== level_id
              );
              return updatedState;
            });
          } else {
            return res.json();
          }
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        if (json) {
          setSidebarState((prevState) => {
            const updatedState = prevState.filter(
              (item) => item.value !== level_id
            );
            return updatedState;
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        fetchArticlesByLevel(undefined, 0);
      });
  };

  useEffect(() => {
    fetchArticlesByLevel(undefined, 0);
  }, []);

  const refreshSidebar = () => {};

  return (
    <div
      className="blackout"
      onClick={(e) => {
        const el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div
        className="modal-miltilevel-sidebar-settings"
        onMouseUp={() => setDragElement(null)}
      >
        <h2 className="modal-miltilevel-sidebar-settings__title">
          Настройки меню
        </h2>
        <div className="modal-miltilevel-sidebar-settings__top-block">
          <InputDinamycPlaceholder
            placeholder="Введите название"
            changeValue={nameNewPoints}
            onChange={(e) => setNameNewPoints(e)}
            size="s"
          />

          <ButtonBasic
            width="264px"
            whiteGreen
            text="Добавить пункт"
            onClick={() => {
              fetchAddLevel(
                nameNewPoints,
                activeMenuItems ? activeMenuItems : null,
                null
              );
              setNameNewPoints('');
              setTimeout(() => fetchArticlesByLevel(undefined, 0), 500);
            }}
          />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="modal-miltilevel-sidebar-settings__group">
            <div className="modal-miltilevel-sidebar-settings__content">
              {sidebarState.map((item, ind) => (
                <Menu
                  key={item.value}
                  fetchArticlesByLevel={fetchArticlesByLevel}
                  isContentLabel={isContentLabel}
                  setIsContentLabel={setIsContentLabel}
                  label={item.label}
                  has_child={item.has_child}
                  items={item.subItems}
                  value={item.value}
                  dragElement={dragElement}
                  setDragElement={setDragElement}
                  parrentLabel={parrentLabel}
                  setParrentLabel={setParrentLabel}
                  parrent={parrentValue}
                  ind={ind}
                  index={0}
                  isInput={isInput}
                  setIsInput={setIsInput}
                  fetchDeleteLevel={fetchDeleteLevel}
                  setParrentValue={setParrentValue}
                  parrentValue={parrentValue}
                  fetchAddLevel={fetchAddLevel}
                  activeMenuItems={activeMenuItems}
                  setActiveMenuItems={setActiveMenuItems}
                  sidebarInd={0}
                  parent_id={item.parent_id}
                  before={item.before}
                  refreshSidebar={refreshSidebar}
                  refresh={() =>
                    setTimeout(() => fetchArticlesByLevel(undefined, 0), 500)
                  }
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ModalMiltilevelSidebarSettings };
