import { useState, useEffect, useRef } from 'react'
import print_icon from './img/print_icon.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalGeneralOrderInformation.scss'
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { ButtonCopy } from '../../ButtonCopy/ButtonCopy';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { OlegHttp, OlegHttps } from '../../../fetchUrls';

const ModalGeneralOrderInformation = ({ setIsModal, postingNumber, apiData }) => {
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [dateFrom, dateTo] = date

    const [loading, setLoading] = useState(false)
    const activeTab = useParams()['*']
    const [pageValue, setPageValue] = useState('assembled')

    const [fetchedData, setFetchedData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])

    const [selectedApi, setSelectedApi] = useState([])
    const [total, setTotal] = useState(0)
    const [tableSort, setTableSort] = useState(false)
    const [columnSize, setColumnSize] = useState([])

    const [sort, setSort] = useState({})
   const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
    const paginatorRef = useRef()

    const auth = new Cookies().get('auth')

    useEffect(() => {
        fetchGoodsData()
    }, [postingNumber])

    const fetchGoodsData = (params) => {
        let sort
        let filters

        if (params) {
            [sort, filters] = params
        }
        setLoading(true)
        
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/tools/order_management/order/goods${sort?.length > 0 ? `?order_param=${sort[0]}&order_param_direction=${sort[1]}` : ''}`
        } else {
            url = `${OlegHttp}/api/v1/tools/order_management/order/goods${sort?.length > 0 ? `?order_param=${sort[0]}&order_param_direction=${sort[1]}` : ''}`
        }

        let api_id_list

        if (selectedApi?.value) {
            api_id_list = [selectedApi.value]
        } else {
            api_id_list = apiData.map(el => el.value)
        }

        const body = JSON.stringify({
            filters,
            page: paginatorRef.current ? paginatorRef.current.page : 1,
            limit: paginatorRef.current ? paginatorRef.current.limit : 10,
            order_status: activeTab,
            posting_number: postingNumber,
            api_id_list,

            //test
            // page: 1,
            // limit: 100,
            // api_id_list: ["55421"],
            // posting_number: "66483561-0072-2"
        })

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const { data, labels, total, sort, column_size } = json
                if (total) {
                    paginatorRef.current.setTotal(total)
                    setTotal(total)
                } else {
                    setTotal(0)
                }
                if (sort) setTableSort(sort)
                if (labels) setTableHeaders(labels)
                if (column_size) setColumnSize(column_size)
                setFetchedData(data.length > 0 ? data : [[]])
                setTableUrl(url) 
                setTableBody(body)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-general-order-information'>
                <div className='modal-general-order-information__header'>
                    <h1 className='title'>Заказ <span style={{ fontWeight: 600 }}>54322588-8766-9</span></h1>
                </div>

                <div className='modal-general-order-information__content'>
                    <div className='general-data'>
                        <div className='general-data__header'>
                            <h3 className='title_medium'>Общие данные о заказе</h3>
                            {/* status_yellow, status_blue, status_green */}
                            <div className='status_yellow'>В пути</div>
                        </div>

                        <div className='general-data__content'>
                            <div className='characteristics-group'>
                                <div className='characteristics-items'>
                                    <div className='characteristics-item'>
                                        <p className='text_grey'>Заказ принят</p>
                                        <div className='border'></div>
                                        <p className='text'>30.10.20023 15:54</p>
                                    </div>

                                    <div className='characteristics-item'>
                                        <p className='text_grey'>Дата отгрузки</p>
                                        <div className='border'></div>
                                        <p className='text'>30.10.20023 15:54</p>
                                    </div>

                                    <div className='characteristics-item'>
                                        <p className='text_grey'>Склад</p>
                                        <div className='border'></div>
                                        <p className='text'>Склад</p>
                                    </div>
                                </div>

                                <div className='characteristics-items'>
                                    <div className='characteristics-item'>
                                        <p className='text_grey'>Трек номер</p>
                                        <hr className='border'/>
                                        <p className='text'>TRR5837736TTR <ButtonCopy value={'TRR5837736TTR'} /></p>
                                    </div>

                                    <div className='characteristics-item'>
                                        <p className='text_grey'>Доставка</p>
                                        <div className='border'></div>
                                        <p className='text'>Наиманование службы доставки</p>
                                    </div>

                                    <div className='characteristics-item'>
                                        <p className='text_grey'>Способ доставки</p>
                                        <div className='border'></div>
                                        <p className='text'>Способ</p>
                                    </div>

                                    <div className='characteristics-item'>
                                        <p className='text_grey'>Дата доставки</p>
                                        <div className='border'></div>
                                        <p className='text'>30.10.20023</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='table-content'>
                        <h3 className='title_medium'>Товары в заказе</h3>
                        <TableBox
                        tableUrl={tableUrl}
                    tableBody={tableBody}
                            fetchedData={fetchedData}
                            fetchCallback={e => fetchGoodsData(e)}
                            sortingFunc={e => fetchGoodsData(e)}
                            headers={tableHeaders}
                            hideColumn
                            hideExport
                            loading={loading}
                            paginator={true}
                            sort={sort}
                            paginatorDependencies={[selectedApi, dateTo, dateFrom]}
                            ref={paginatorRef}
                            columnSize={columnSize}
                        />
                    </div>
                </div>

                <div className='btn-group'>
                    <ButtonBasic
                        grey
                        width='225px'
                        size='56px'
                        text={<div className='content-btn'><img src={print_icon} />Распечатать этикетку</div>}
                    />

                    <ButtonBasic
                        blue
                        width='220px'
                        size='56px'
                        text='Собрать заказ'
                    />
                </div>

            </div>
        </div>
    )
}

export { ModalGeneralOrderInformation }