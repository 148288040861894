import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import cookie from './img/cookie.svg'
import dayjs from 'dayjs';
import "./Cookie.scss"

export const ACCEPT_COOKIE = 'accept_cookie'

const Cookie = ({ setIsModal }) => {
    const cookies = new Cookies()

    const handleAcceptCookies = () => {
        cookies.set(ACCEPT_COOKIE, "yes", { path: '/',  expires: dayjs().add(1, 'year').toDate() })
        setIsModal(false)
    }

    return (
        <div className='cookie'>
            <img src={cookie} />
            <p className='text'>Мы используем Cookie </p>
            <button    
            className='btn__blue' onClick={handleAcceptCookies}>Ок</button>
        </div>
    )
}

export { Cookie }